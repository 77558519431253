import React from 'react';

// Importación de un componente 'SeacrhInput', que parece ser un campo de búsqueda personalizado.
import SeacrhInput from './searchInput';

function SeacrhData({ 
    searchTerm,  // Propiedad que contiene el término de búsqueda actual.
    handleSearchChange,  // Función para manejar los cambios en el campo de búsqueda.
    btnCreate  // Un botón o componente que se pasa como prop, que probablemente se muestra para crear un nuevo elemento.
}) {
    return (
        <div className="buscar_data">
            {/* Componente de entrada de búsqueda con las propiedades necesarias */}
            <SeacrhInput 
                name="search"  // Nombre del input.
                label="Buscar"  // Etiqueta para el campo de búsqueda.
                placeholder="Buscar y filtrar"  // Texto de ejemplo dentro del input.
                value={searchTerm}  // El valor del input se vincula al término de búsqueda.
                onChange={handleSearchChange}  // Llama a la función cuando cambia el valor.
            />
            {/* Renderiza el botón o componente que se pasa como 'btnCreate'. */}
            {btnCreate}
        </div>
    );
}

export default SeacrhData;
