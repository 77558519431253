import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';

import { validateFields } from "./utils/validateFields";
import { validationRules } from "./utils/validationRules";

import IconSVG from '../../contents/icons/icon';

const MultiStepForm = ({ onSubmitState, closeModal }) => {
    const [imageUrlIcon, setimageUrlIcon] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const [inputValueEnlaces, setinputValueEnlaces] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [dragActiveIcon, setdragActiveIcon] = useState(false);
    const [dragActiveM, setDragActiveM] = useState(false);
    const [dragActive, setDragActive] = useState(false);

    const [errors, setErrors] = useState({});
    
    const [formData, setFormData] = useState({
        name: '', type: '', slug: '', intro: '', description: '', deadline: '', portada: null, icon: null,
        aprendices: [], instructores: [], fichas: [], files: [], palabrasClave: [], enlaces: []
    });

    const [step, setStep] = useState(1);

    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);

    };
    const handleBack = () => {
        setStep((prevStep) => prevStep - 1);
    };
    const handleFinal = () => {
        setStep(3);
    };
    const handleInicio = () => {
        setStep(1);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const file = e.dataTransfer.files[0];
        if (file) {
        setFormData((prevData) => ({
            ...prevData,
            portada: file,
        }));
        setImageUrl(URL.createObjectURL(file));
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            portada: file, 
        }));

        if (file) {
        const url = URL.createObjectURL(file);
        setImageUrl(url);
        }
    };

    const handleDragOverIcon = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setdragActiveIcon(true);
    };
    const handleDragLeaveIcon = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setdragActiveIcon(false);
    };
    const handleDropIcon = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setdragActiveIcon(false);

        const file = e.dataTransfer.files[0];
        if (file) {
        setFormData((prevData) => ({
            ...prevData,
            icon: file,
        }));
        setimageUrlIcon(URL.createObjectURL(file));
        }
    };
    const handleFileChangeIcon = (event) => {
        const file = event.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            icon: file, 
        }));

        if (file) {
            const url = URL.createObjectURL(file);
            setimageUrlIcon(url);
        }
    };

    const handleDragOverM = (e) => {
        e.preventDefault();
        setDragActiveM(true);
    };
    const handleDragLeaveM = () => {
        setDragActiveM(false);
    };
    const handleDropM = (e) => {
        e.preventDefault();
        setDragActiveM(false);
        const droppedFiles = Array.from(e.dataTransfer.files);

        setFormData((prevData) => ({
            ...prevData,
            files: [...prevData.files, ...droppedFiles],
        }));
    };
    const handleFileChangeM = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevData) => ({
            ...prevData,
            files: [...prevData.files, ...files],
        }));
    };
    const handleRemoveFileM = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            files: prevFormData.files.filter((_, i) => i !== index)
        }));
    };

    const handleCheckboxChangeaprendiz = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const aprendices = checked
                ? [...prevState.aprendices, id]
                : prevState.aprendices.filter(dataid => dataid !== id);

            return { ...prevState, aprendices };
        });
    };
    const handleCheckboxChangeInstructor = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const instructores = checked
                ? [...prevState.instructores, id]
                : prevState.instructores.filter(dataid => dataid !== id);

            return { ...prevState, instructores };
        });
    };
    const handleCheckboxChangeFicha = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const fichas = checked
                ? [...prevState.fichas, id]
                : prevState.fichas.filter(dataid => dataid !== id);

            return { ...prevState, fichas };
        });
    };

    const handleInputChangePC = (e) => {
        setInputValue(e.target.value);
    };
    const handleKeyPressPC = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
        e.preventDefault();
        setFormData((prevData) => ({
            ...prevData,
            palabrasClave: [...prevData.palabrasClave, inputValue.trim()],
        }));
        setInputValue('');
        }
    };
    const handleRemoveTagPC = (indexToRemove) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            palabrasClave: prevFormData.palabrasClave.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handleInputChangE = (e) => {
        setinputValueEnlaces(e.target.value);
    };
    const handleKeyPressEnlaces = (e) => {
        if (e.key === 'Enter' && inputValueEnlaces.trim() !== '') {
        e.preventDefault();
        setFormData((prevData) => ({
            ...prevData,
            enlaces: [...prevData.enlaces, inputValueEnlaces.trim()],
        }));
        setinputValueEnlaces('');
        }
    };
    const handleRemoveTagE = (indexToRemove) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            enlaces: prevFormData.enlaces.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handleCreate = async () => {
        const { 
            name, type, slug, intro, description, deadline, portada, icon,
            aprendices, instructores, fichas, files, palabrasClave, enlaces 
        } = formData;
        
        const formDataToSend = new FormData();

        formDataToSend.append("API", "talentic");
        formDataToSend.append("MODEL", "talentic");
        formDataToSend.append("RESOURCE", "projects");
        formDataToSend.append("key", "5b8d3b1f084b01c6a8387459e80d4bb9");
        formDataToSend.append("TYPE", "PUT");
        
        formDataToSend.append("name", name);
        formDataToSend.append("type", type);
        formDataToSend.append("slug", slug);
        formDataToSend.append("intro", intro);
        formDataToSend.append("description", description);
        formDataToSend.append("deadline", deadline);

        if (portada) {
            formDataToSend.append("portada", portada);
        }

        if (icon) {
            formDataToSend.append("icon", icon);
        }

        files.forEach((file) => {
            formDataToSend.append('files[]', file);
        });

        formDataToSend.append("aprendices", JSON.stringify(aprendices));
        formDataToSend.append("instructores", JSON.stringify(instructores));
        formDataToSend.append("fichas", JSON.stringify(fichas));
        formDataToSend.append("palabrasClave", JSON.stringify(palabrasClave));
        formDataToSend.append("enlaces", JSON.stringify(enlaces));

        try {
            const response = await axios.post('http://217.15.168.117:8080/api/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            setFormData({
                name: '', type: '', slug: '', intro: '', description: '', deadline: '',
                aprendices: [], instructores: [], fichas: [], files: [], portada: null,
                icon: null, palabrasClave: [], enlaces: []
            });
            
            onSubmitState(true);
        } catch (error) {
            toast.error('Hubo un error al crear el proyecto. Por favor, inténtelo de nuevo.');
        }
    };

    const validateStep1 = () => {
        // Validar campos usando las reglas para el paso 1
        const isValid = validateFields({
            formData,
            rules: validationRules.step1,
            setErrors,
            toast
        });

        if (isValid) {
            return true;
        }
    };

    const validateStep2 = () => {
        // Validar campos usando las reglas para el paso 2
        const isValid = validateFields({
            formData,
            rules: validationRules.step2,
            setErrors,
            toast
        });

        if (isValid) {
            return true;
        }
    };

    const handleVerificarFormulario = () => {
        closeModal(true);
        handleCreate();
    };

    return (
        <div>
            <h3 className='p-0 m-0 text-center text-primary'>Sube tu proyecto</h3>
            <h5 className='p-0 m-0 text-center'>en 3 pasos</h5>

            <nav className="menu_modal">
                <ul>
                    <li className={`menu_modal_Item ${step === 1 ? "menu_modal_active" : ""}`} onClick={() => setStep(1)}>
                        <span>PASO 1</span>
                        <figure>
                            <IconSVG name="Icon_proyectos_talentic" width="70px" />
                            <figcaption>DATOS BASICOS</figcaption>
                        </figure>
                    </li>
                    <li className={`menu_modal_Item ${step === 2 ? "menu_modal_active" : ""}`} onClick={() => {if (validateStep1()) {setStep(2);}}}>
                        <span>PASO 2</span>
                        <figure>
                            <IconSVG name="Icon_agregar_usuarios_talentic" width="70px" />
                            <figcaption>AUTORES</figcaption>
                        </figure>
                    </li>
                    <li className={`menu_modal_Item ${step === 3 ? "menu_modal_active" : ""}`} onClick={() => {if (validateStep1() && validateStep2()) {setStep(3);}}}>
                        <span>PASO 3</span>
                        <figure>
                            <IconSVG name="Icon_multimedia_talentic" width="70px" />    
                            <figcaption>MULTIMEDIA</figcaption>
                        </figure>
                    </li>
                </ul>
            </nav>

            {step === 1 && (
                <Step1
                    formData={formData}
                    setFormData={setFormData}
                    handleDragOver={handleDragOver}
                    handleDragOverIcon={handleDragOverIcon}
                    handleDragLeave={handleDragLeave}
                    handleDragLeaveIcon={handleDragLeaveIcon}
                    handleDrop={handleDrop}
                    handleDropIcon={handleDropIcon}
                    dragActive={dragActive}
                    dragActiveIcon={dragActiveIcon}
                    imageUrl={imageUrl}
                    imageUrlIcon={imageUrlIcon}
                    handleFileChange={handleFileChange}
                    handleFileChangeIcon={handleFileChangeIcon}
                    onNext={handleNext}
                    onFinal={handleFinal}
                    handleCheckboxChangeFicha={handleCheckboxChangeFicha}
                    handleRemoveTag={handleRemoveTagPC}
                    handleKeyPress={handleKeyPressPC}
                    handleInputChange={handleInputChangePC}
                    handleKeyPressEnlaces={handleKeyPressEnlaces}
                    handleRemoveEnlaces={handleRemoveTagE}
                    handleInputChangeEnlaces={handleInputChangE}
                    inputValueEnlaces={inputValueEnlaces}
                />
            )}
            {step === 2 && (
                <Step2
                    formData={formData}
                    handleCheckboxChangeaprendiz={handleCheckboxChangeaprendiz}
                    handleCheckboxChangeInstructor={handleCheckboxChangeInstructor}
                    
                    onNext={handleNext}
                    onBack={handleBack}
                />
            )}
            {step === 3 && (
                <Step3
                    formData={formData}
                    handleDragOver={handleDragOverM}
                    handleDragLeave={handleDragLeaveM}
                    handleDrop={handleDropM}
                    handleFileChange={handleFileChangeM}
                    handleRemoveFile={handleRemoveFileM}
                    handleKeyPressEnlaces={handleKeyPressEnlaces}
                    handleRemoveEnlaces={handleRemoveTagE}
                    handleInputChangeEnlaces={handleInputChangE}
                    inputValueEnlaces={inputValueEnlaces}
                    dragActive={dragActiveM}
                    onBack={handleBack}
                    onSubmit={handleVerificarFormulario}
                    handleInicio={handleInicio}
                />
            )}
        </div>
    );
};

export default MultiStepForm;