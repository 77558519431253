import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom'; 
import { toast } from 'react-toastify';
import axios from 'axios';

import UpdatemultimediaProyect from './updateOrdenMultimedia';
import AddMultimedia from './addMultimedia';
import AddEnlacesExternos from './addEnlacesExternos';
import AddKeywords from './addKeywords';
import UpdatePortada from './updatePortada';
import UpdateIcon from './updateIcon';

import SelectTipos from '../../components/selectApi';
import Textarea from '../../components/textarea';
import CheckBox from '../../components/checkbox';
import Input from '../../components/input';
import Modal from '../../components/modal';

import IconSVG from '../../contents/icons/icon';

const Step1 = ({ formData, setFormData, onNext, onFinal, update }) => {
    const [errors, setErrors] = useState({});
    const descriptionRef = useRef(null);
    const deadlineRef = useRef(null);
    const introRef = useRef(null);
    const nameRef = useRef(null);
    const typeRef = useRef(null);
    const slugRef = useRef(null);

    const validateStep1 = () => {
        let newErrors = {};
    
        if (!formData.name) {
            toast.warning('El nombre del proyecto es requerido');
            newErrors.error = 'error';
            nameRef.current.focus();
            return;
        }
    
        if (!formData.type) {
            toast.warning('El tipo de proyecto es requerido');
            newErrors.error = 'error';
            typeRef.current.focus();
            return;
        }
    
        if (!formData.deadline) {
            toast.warning('La fecha de finalización es requerida');
            newErrors.error = 'error';
            deadlineRef.current.focus();
            return;
        }

        if (!formData.slug) {
            toast.warning('El texto Slug es requerido');
            newErrors.error = 'error';
            slugRef.current.focus();
            return;
        }
    
        if (!formData.intro) {
            toast.warning('El texto introductorio es requerido');
            newErrors.error = 'error';
            introRef.current.focus();
            return;
        }
    
        if (!formData.description) {
            toast.warning('La descripción del proyecto es requerida');
            newErrors.error = 'error';
            descriptionRef.current.focus();
            return;
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validateStep1()) {
            update();
        }
    };
    const handleNext = () => {
        if (validateStep1()) {
            onNext();
        }
    };
    const handleFinal = () => {
        if (validateStep1()) {
            onFinal();
        }
    };

    return (
        <div>
            <div className='d-flex justify-content-center align-items-center mt-4 mb-4'>
                <div 
                    className='d-flex flex-column align-items-center stepIcon'
                    style={{ width: 'fit-content', cursor: 'pointer'}}
                >
                    <IconSVG name="Icon_proyectos_talentic" width="70px" />
                    <strong>DATOS BASICOS</strong>
                </div>
                <div className='stepnull'></div>
                <div
                    className='d-flex flex-column align-items-center stepIcon'
                    style={{ width: 'fit-content', cursor: 'pointer'}}
                    onClick={handleNext}
                >
                    <IconSVG name="Icon_agregar_usuarios_talentic" width="70px" />
                    <strong>AUTORES</strong>
                </div>
                <div className='stepnull'></div>
                <div
                    className='d-flex flex-column align-items-center stepIcon'
                    style={{ width: 'fit-content', cursor: 'pointer'}}
                    onClick={handleFinal}
                >
                    <IconSVG name="Icon_multimedia_talentic" width="70px" />
                    <strong>MULTIMEDIA</strong>
                </div>
            </div>
        
            <div className='d-flex flex-wrap px-4'>
                <Input 
                    ref={nameRef}
                    name="name" 
                    label="Nombre del proyecto"
                    placeholder="Talentic" 
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    colClassName="col-lg-6"
                />
                <SelectTipos
                    ref={typeRef}
                    label="Tipo de Proyecto"
                    name="type"
                    apiEndpoint="http://217.15.168.117:8080/api/"
                    requestBody={{
                        "API": "talentic",
                        "MODEL": "talentic",
                        "RESOURCE": "select",
                        "TYPE": "typeproject",
                        "key": "5b8d3b1f084b01c6a8387459e80d4bb9"
                    }}
                    onSelectChange={(e) => setFormData({ ...formData, type: e.target.value })}
                    defaultOption="tipo de proyecto"
                    className="col-lg-6"
                    value={formData.type}
                />
                <Input 
                    ref={deadlineRef}
                    name="deadline"
                    type="datetime-local"
                    label="Fecha final del proyecto"
                    value={formData.deadline}
                    onChange={(e) => setFormData({ ...formData, deadline: e.target.value })}
                    colClassName="col-lg-6"
                />
                <Input 
                    ref={slugRef}
                    name="slug" 
                    label="Slug"
                    placeholder="slug" 
                    value={formData.slug}
                    onChange={(e) => setFormData({ ...formData, slug: e.target.value })}
                    colClassName="col-lg-6"
                />
                <Textarea 
                    ref={introRef}
                    name="intro" 
                    label="Texto Introductorio"
                    value={formData.intro}
                    onChange={(e) => setFormData({ ...formData, intro: e.target.value })}
                    colClassName=""
                />
                <Textarea 
                    ref={descriptionRef}
                    name="description" 
                    label="Descripción del proyecto"
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    colClassName=""
                />
            </div>

            <div className="mt-3 col-12 d-flex flex-column align-items-end px-4">
                <button className="btn btn-outline-primary" onClick={handleSubmit} >Actualizar</button>
            </div>
        </div>
    );
};

const Step2 = ({ 
    formData, handleCheckboxChangeaprendiz, handleCheckboxChangeInstructor,
    handleCheckboxChangeFicha, onBack, onNext, update
}) => {
    const [searchTermInstructor, setsearchTermInstructor] = useState('');
    const [searchTermAprendiz, setsearchTermAprendiz] = useState('');
    const [searchTermFicha, setsearchTermFicha] = useState('');

    const [apiDataInstructor, setapiDataInstructor] = useState([]);
    const [apiDataAprendiz, setapiDataAprendiz] = useState([]);
    const [apiDataFicha, setapiDataFicha] = useState([]);
    
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            };

            const [responseAprendiz, responseInstructor, responseFichas] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectAprendizCreate" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectInstructorCreate" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectFichaCreate" })
            ]);
            
            const processedDataAperndiz = responseAprendiz.data.map(item => ({
                id: parseInt(item.id, 10),
                name: item.name,
                mail: item.mail,
                state: item.state
            }));
            
            const processedDataInstructor = responseInstructor.data.map(item => ({
                id: parseInt(item.id, 10),
                name: item.name,
                mail: item.mail,
                state: item.state
            }));
            
            const processedDataFicha = responseFichas.data.map(item => ({
                id: parseInt(item.id, 10),
                name: item.name,
                descirption: item.descirption,
                state: item.state
            }));

            setapiDataAprendiz(processedDataAperndiz);
            setapiDataInstructor(processedDataInstructor);
            setapiDataFicha(processedDataFicha);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSearchChangeAprendiz = (e) => {
        setsearchTermAprendiz(e.target.value.toLowerCase());
    };
    const handleSearchChangeInstructor = (e) => {
        setsearchTermInstructor(e.target.value.toLowerCase());
    };
    const handleSearchChangeFicha = (e) => {
        setsearchTermFicha(e.target.value.toLowerCase());
    };

    const filteredDataAprendiz = apiDataAprendiz.filter(item =>
        (item.name || '').toLowerCase().includes(searchTermAprendiz) ||
        (item.mail || '').toLowerCase().includes(searchTermAprendiz)
    );
    const filteredDataInstructor = apiDataInstructor.filter(item =>
        (item.name || '').toLowerCase().includes(searchTermInstructor) ||
        (item.mail || '').toLowerCase().includes(searchTermInstructor)
    );
    const filteredDataFicha = apiDataFicha.filter(item =>
        (item.name || '').toLowerCase().includes(searchTermFicha) ||
        (item.descirption || '').toLowerCase().includes(searchTermFicha)
    );

    const validateStep2 = () => {
        let newErrors = {};
    
        if (!formData.aprendices || formData.aprendices.length === 0) {
            toast.warning('los aprendices del proyecto son requeridos');
            newErrors.error = 'error';
        }
    
        if (!formData.instructores || formData.instructores.length === 0) {
            toast.warning('los instructores del proyecto son requeridos');
            newErrors.error = 'error';
        }
    
        if (!formData.fichas || formData.fichas.length === 0) {
            toast.warning('Las fichas del proyecto son requeridas');
            newErrors.error = 'error';
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
    };

    const handleNext = () => {
        if (validateStep2()) {
            onNext();
        }
    };
    const handleSubmit = () => {
        if (validateStep2()) {
            update();
        }
    };

    return (
        <div>
            <div className='d-flex justify-content-center align-items-center mt-4 mb-4'>
                <div
                    className='d-flex flex-column align-items-center stepIcon'
                    style={{ width: 'fit-content', cursor: 'pointer'}}
                    onClick={onBack}
                >
                    <IconSVG name="Icon_proyectos_talentic" width="70px" />
                    <strong>DATOS BASICOS</strong>
                </div>
                <div className='steppass'></div>
                <div
                    className='d-flex flex-column align-items-center stepIcon'
                    style={{ width: 'fit-content', cursor: 'pointer'}}
                >
                    <IconSVG name="Icon_agregar_usuarios_talentic" width="70px" />
                    <strong>AUTORES</strong>
                </div>
                <div className='stepnull'></div>
                <div
                    className='d-flex flex-column align-items-center stepIcon'
                    style={{ width: 'fit-content', cursor: 'pointer'}}
                    onClick={handleNext}
                >
                    <IconSVG name="Icon_multimedia_talentic" width="70px" />
                    <strong>MULTIMEDIA</strong>
                </div>
            </div>

            <div className='d-flex flex-wrap'>
                <div className="col-12 col-md-6 p-4">
                    <h5>Fichas</h5>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            id="searchDos"
                            className="form-control"
                            placeholder="Buscar Ficha"
                            aria-label="Buscar Ficha"
                            aria-describedby="button-addon2"
                            value={searchTermFicha}
                            onChange={handleSearchChangeFicha}
                        />
                    </div>
                    <div className="aprendices-list">
                        {filteredDataFicha.length > 0 ? filteredDataFicha.map((item) => (
                            <CheckBox
                                key={item.id}
                                name="fichas"
                                value={item.id}
                                icon="bx-lock-alt"
                                userName={item.name}
                                description={item.descirption}
                                checked={formData.fichas.includes(item.id)}
                                handleCheckboxChange={handleCheckboxChangeFicha}
                            />
                        )) : (
                            <div id="no-results-dos">
                                Ficha no encontrado
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-12 col-md-6 p-4">
                    <h5>Aprendices</h5>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            id="searchDos"
                            className="form-control"
                            placeholder="Buscar Aprendiz"
                            aria-label="Buscar Aprendiz"
                            aria-describedby="button-addon2"
                            value={searchTermAprendiz}
                            onChange={handleSearchChangeAprendiz}
                        />
                    </div>
                    <div className="aprendices-list">
                        {filteredDataAprendiz.length > 0 ? filteredDataAprendiz.map((item) => (
                            <CheckBox
                                key={item.id}
                                name="aprendices"
                                value={item.id}
                                icon="bx-lock-alt"
                                userName={item.name}
                                description={item.mail}
                                checked={formData.aprendices.includes(item.id)}
                                handleCheckboxChange={handleCheckboxChangeaprendiz}
                            />
                        )) : (
                            <div id="no-results-dos">
                                Aprendiz no encontrado
                            </div>
                        )}
                    </div>

                    <h5>Instructores</h5>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            id="searchDos"
                            className="form-control"
                            placeholder="Buscar Instructor"
                            aria-label="Buscar Instructor"
                            aria-describedby="button-addon2"
                            value={searchTermInstructor}
                            onChange={handleSearchChangeInstructor}
                        />
                    </div>
                    <div className="aprendices-list">
                        {filteredDataInstructor.length > 0 ? filteredDataInstructor.map((item) => (
                            <CheckBox
                                key={item.id}
                                name="instructores"
                                value={item.id}
                                icon="bx-lock-alt"
                                userName={item.name}
                                description={item.mail}
                                checked={formData.instructores.includes(item.id)}
                                handleCheckboxChange={handleCheckboxChangeInstructor}
                            />
                        )) : (
                            <div id="no-results-dos">
                                Instructor no encontrado
                            </div>
                        )}
                    </div>
                </div>
            </div>
            
            <div className="mt-3 col-12 d-flex flex-column align-items-end px-4">
                <button className="btn btn-outline-primary" onClick={handleSubmit} >Actualizar</button>
            </div>
        </div>
    );
};

const Step3 = ({
    formData, handleDragOver, handleDragLeave, handleDrop,
    dragActive, handleFileChange, handleRemoveFile, 
    handleKeyPress, handleRemoveTag, handleInputChange, inputValue,
    handleKeyPressEnlaces, handleRemoveEnlaces, handleInputChangeEnlaces, inputValueEnlaces,
    onBack, onSubmit, handleInicio, resetFormData
}) => {
    
    const location = useLocation();
    const { id } = location.state || {};

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };
    const closeModalSuspend = () => {
      setIsModalOpen(false);
      setModalContent(null);
      toast.warning('Estamos subiendo los datos, en breve veras el proyecto.');
    };

    const handleCreateClick = (FormComponent) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent project={id} onsumited={closeModalSuspend} refress={resetFormData}/>);
    };

    const handleChangeeditar = (newState) => {
        toast.success("Se editaron los datos con éxito");
        setIsModalOpen(false);
    };
    const handleEditClickMultimedia = (FormComponent, id, multimedia) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent 
            onsumited={handleChangeeditar}
            project={id}
            multimedia={multimedia}
            refress={resetFormData}
        />);
    };

    const handleDeleteMultimedia = (itemId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este registro?')) {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "graphics",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "DELETE",
                "id": itemId
            };

            axios.post('http://217.15.168.117:8080/api/', requestBody)
                .then(response => {
                    toast.success("Se eliminaron los datos con éxito");
                    resetFormData();
                })
                .catch(error => {
                    console.error('Error deleting data:', error);
                });
        }
    };

    const getFileExtension = (filename) => {
        return filename.split('.').pop().toLowerCase(); // Obtiene la extensión
    };

    return(
        <div>
            <div className='d-flex justify-content-center align-items-center mt-4 mb-4'>
                <div
                    className='d-flex flex-column align-items-center stepIcon'
                    style={{ width: 'fit-content', cursor: 'pointer'}}
                    onClick={handleInicio}
                >
                    <IconSVG name="Icon_proyectos_talentic" width="70px" />
                    <strong>DATOS BASICOS</strong>
                </div>
                <div className='steppass'></div>
                <div
                    className='d-flex flex-column align-items-center stepIcon'
                    style={{ width: 'fit-content', cursor: 'pointer'}}
                    onClick={onBack}
                >
                    <IconSVG name="Icon_agregar_usuarios_talentic" width="70px" />
                    <strong>AUTORES</strong>
                </div>
                <div className='steppass'></div>
                <div 
                    className='d-flex flex-column align-items-center stepIcon'
                    style={{ width: 'fit-content', cursor: 'pointer'}}
                >
                    <IconSVG name="Icon_multimedia_talentic" width="70px" />
                    <strong>MULTIMEDIA</strong>
                </div>
            </div>

            <div className='image-gradient-projects'>
                <img
                    src={`http://217.15.168.117:8080/api/Whatever/${formData.portada}`}
                    className={`m-0 img-content`}
                />
                <div 
                    className='content_image_gradient display-none display-md cursor-pointer'
                    onClick={() => handleCreateClick(UpdatePortada)}
                >
                    <img 
                        src={`http://217.15.168.117:8080/api/Whatever/${formData.icon}`}
                        className={`m-0 proyectprofileproyectsProjects display-5`}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCreateClick(UpdateIcon);
                        }}
                    />
                </div>
            </div>

            <div className='d-flex flex-wrap pt-4'>
                <div className='col-12'>
                    <h5
                        className='col m-0 text-primary text-start btn btn-outline-primary'
                        onClick={() => handleCreateClick(AddMultimedia)}
                    >
                        Multimedia
                    </h5>

                    <table className="table table-bordered overX my-3">
                        <thead>
                            <tr>
                                <th>Archivos</th>
                                <th>Tipos</th>
                                <th>Orden</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                        {formData.files.map((multimedia) => {
                            const fileExtension = getFileExtension(multimedia.imgUrl);

                            return (
                                <tr key={multimedia.id}>
                                    <td className='d-flex flex-column align-items-center'>
                                        {(multimedia.tipo === 'Imagen' || multimedia.tipo === 'Icono') ? (
                                            <img
                                                src={`http://217.15.168.117:8080/api/Whatever/${multimedia.imgUrl}`}
                                                style={{ width: 'fit-content', height: '70px', borderRadius: '0.7rem' }}
                                                alt="multimedia"
                                            />
                                        ) : multimedia.tipo === 'Video' ? (
                                        <video controls style={{ width: '100%', height: '130px', borderRadius: '0.7rem' }}>
                                            <source
                                            src={`http://217.15.168.117:8080/api/Whatever/${multimedia.imgUrl}`}
                                            type="video/mp4"
                                            />
                                        </video>
                                        ) : multimedia.tipo === 'Documentos' && fileExtension === 'pdf' ? (
                                        // Si el documento es un PDF
                                        <div style={{ position: 'relative', width: '100%', height: '130px' }}>
                                            <object
                                            data={`http://217.15.168.117:8080/api/Whatever/${multimedia.imgUrl}`}
                                            type="application/pdf"
                                            style={{ borderRadius: '0.7rem', width: '100%', height: '130px' }}
                                            >
                                            <p>
                                                Tu navegador no soporta la visualización del PDF.{' '}
                                                <a href={`http://217.15.168.117:8080/api/Whatever/${multimedia.imgUrl}`}>
                                                Descárgalo aquí
                                                </a>
                                            </p>
                                            </object>
                                            <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '130px',
                                                cursor: 'pointer',
                                            }}
                                            ></div>
                                        </div>
                                        ) : multimedia.tipo === 'Documentos' && fileExtension === 'docx' ? (
                                        // Si el documento es un archivo Word
                                        <div style={{ position: 'relative', width: '100%', height: '130px' }}>
                                            <object
                                            data={`https://docs.google.com/viewer?url=http://217.15.168.117:8080/api/Whatever/${multimedia.imgUrl}&embedded=true`}
                                            style={{ borderRadius: '0.7rem', width: '100%', height: '130px' }}
                                            rel="noopener noreferrer"
                                            >
                                            Ver Documento
                                            </object>
                                            <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '130px',
                                                cursor: 'pointer',
                                            }}
                                            ></div>
                                        </div>
                                        ) : (
                                            <p>Unsupported file type</p>
                                        )}
                                    </td>
                                    <td>{multimedia.tipo}</td>
                                    <td>{multimedia.orden}</td>
                                    <td>
                                        <div className='d-flex'>
                                            <div 
                                                className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                                style={{ width: '54px'}}
                                                onClick={(e) => {
                                                    handleEditClickMultimedia(UpdatemultimediaProyect, id, multimedia.id);
                                                }}
                                            >
                                                <IconSVG name="Icon_editar_talentic" width="20px"/>
                                                <small>EDITAR</small>
                                            </div>
                                            <div 
                                                className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                                style={{ width: '54px'}}
                                                onClick={(e) => {
                                                    handleDeleteMultimedia(multimedia.id); // Llamar a la función de eliminación
                                                }}
                                            >
                                                <IconSVG name="Icon_eliminar_talentic" width="20px"/>
                                                <small>ELIMINAR</small>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>

                <ul className='ps-3 d-flex flex-wrap col-3'>
                    <h5
                        className='col-12 m-0 text-primary text-start btn btn-outline-primary'
                        onClick={() => handleCreateClick(AddKeywords)}
                    >
                        Keywords
                    </h5>
                    {formData.palabrasClave.length > 0 ? (
                        formData.palabrasClave.map((tag, index) => (
                            <li 
                                key={index}
                                style={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '5px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleRemoveTag(tag)}
                                className='badge bg-label-dark'
                            >
                                {tag}
                            </li>
                        ))
                        ) : (
                        <li 
                            style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                margin: '5px',
                                cursor: 'default',
                                color: '#aaa' // color gris para texto inactivo
                            }}
                            className='badge bg-label-dark'
                        >
                            Sin Keywords
                        </li>
                    )}
                </ul>

                <ul className='ps-3 d-flex flex-wrap col-3'>
                    <h5
                        className='col-12 m-0 text-primary text-start btn btn-outline-primary'
                        onClick={() => handleCreateClick(AddEnlacesExternos)}
                    >
                        Enlaces Externos
                    </h5>
                    {formData.enlaces.length > 0 ? (
                        formData.enlaces.map((tag, index) => (
                            <li 
                                key={index}
                                style={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '5px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleRemoveEnlaces(tag)}
                                className='badge bg-label-dark'
                            >
                                {tag}
                            </li>
                        ))
                        ) : (
                        <li 
                            style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                margin: '5px',
                                cursor: 'default',
                                color: '#aaa'
                            }}
                            className='badge bg-label-dark'
                        >
                            Sin Enlaces Externos
                        </li>
                    )}
                </ul>

            </div>

            <div className="mt-3 col-12 d-flex justify-content-between">
                <button className="btn btn-outline-primary" onClick={onBack} >Anterior</button>
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal} widthValue='480px'>
                {modalContent}
            </Modal>
        </div>
    );
}

const MultiStepForm = ( ) => {
    const location = useLocation();
    const { id } = location.state || {};

    const [imageUrlIcon, setimageUrlIcon] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const [inputValueEnlaces, setinputValueEnlaces] = useState('');
    const [inputValue, setInputValue] = useState('');

    const [dragActiveIcon, setdragActiveIcon] = useState(false);
    const [dragActiveM, setDragActiveM] = useState(false);
    const [dragActive, setDragActive] = useState(false);

    const [errors, setErrors] = useState({});

    const [step, setStep] = useState(1);

    const [formData, setFormData] = useState({
        name: '', type: '', slug: '', intro: '', description: '', deadline: '', portada: null, icon: null,
        aprendices: [], instructores: [], fichas: [], files: [], palabrasClave: [], enlaces: []
    });

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "proyecto": id
            };

            const [responseAprendiz, responseInstructor, responseFichas, responseDetails, responseKeywords, responseEnlaces, responseFiles] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectAprendiz" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectInstructor" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectFicha" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "proyectsDetails" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "proyectsKeywords" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "proyectsEnlaces" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "proyectsFiles" }),
            ]);

            
            const processedDataAperndiz = responseAprendiz.data
                .filter(item => item.asignado === 1)
                .map(item => item.id);

            const processedDataInstructor = responseInstructor.data
                .filter(item => item.asignado === 1)
                .map(item => item.id);
            
            const processedDataFicha = responseFichas.data
                .filter(item => item.asignado === 1)
                .map(item => item.id);
            
            const processedDataKeywords = responseKeywords.data
                .map(item => item.name);
                
            const processedDataEnlaces = responseEnlaces.data
                .map(item => item.name);

            const processedDataFiles = responseFiles.data.map(item => ({
                id: item.id,
                tipo: item.type,
                imgUrl: item.name,
                orden: item.orderk
            }));
            
            setFormData(prevFormData => ({
                ...prevFormData,
                name: responseDetails.data[0].name,
                type: responseDetails.data[0].type,
                slug: responseDetails.data[0].slug,
                intro: responseDetails.data[0].intro,
                description: responseDetails.data[0].description,
                deadline: responseDetails.data[0].deadline,
                
                portada: responseDetails.data[0].portada,
                icon: responseDetails.data[0].icon,

                aprendices: processedDataAperndiz,
                instructores: processedDataInstructor,

                fichas: processedDataFicha,
                
                files: processedDataFiles,
                
                palabrasClave: processedDataKeywords,
                
                enlaces: processedDataEnlaces
            }));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);
    };
    const handleBack = () => {
        setStep((prevStep) => prevStep - 1);
    };
    const handleFinal = () => {
        setStep(3);
    };
    const handleInicio = () => {
        setStep(1);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const file = e.dataTransfer.files[0];
        if (file) {
        setFormData((prevData) => ({
            ...prevData,
            portada: file,
        }));
        setImageUrl(URL.createObjectURL(file));
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            portada: file, 
        }));

        if (file) {
        const url = URL.createObjectURL(file);
        setImageUrl(url);
        }
    };

    const handleDragOverIcon = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setdragActiveIcon(true);
    };
    const handleDragLeaveIcon = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setdragActiveIcon(false);
    };
    const handleDropIcon = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setdragActiveIcon(false);

        const file = e.dataTransfer.files[0];
        if (file) {
        setFormData((prevData) => ({
            ...prevData,
            icon: file,
        }));
        setimageUrlIcon(URL.createObjectURL(file));
        }
    };
    const handleFileChangeIcon = (event) => {
        const file = event.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            icon: file, 
        }));

        if (file) {
            const url = URL.createObjectURL(file);
            setimageUrlIcon(url);
        }
    };

    const handleDragOverM = (e) => {
        e.preventDefault();
        setDragActiveM(true);
    };
    const handleDragLeaveM = () => {
        setDragActiveM(false);
    };
    const handleDropM = (e) => {
        e.preventDefault();
        setDragActiveM(false);
        const droppedFiles = Array.from(e.dataTransfer.files);

        setFormData((prevData) => ({
            ...prevData,
            files: [...prevData.files, ...droppedFiles],
        }));
    };
    const handleFileChangeM = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevData) => ({
            ...prevData,
            files: [...prevData.files, ...files],
        }));
    };
    const handleRemoveFileM = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            files: prevFormData.files.filter((_, i) => i !== index)
        }));
    };

    const handleCheckboxChangeaprendiz = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const aprendices = checked
                ? [...prevState.aprendices, id]
                : prevState.aprendices.filter(dataid => dataid !== id);

            return { ...prevState, aprendices };
        });
    };
    const handleCheckboxChangeInstructor = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const instructores = checked
                ? [...prevState.instructores, id]
                : prevState.instructores.filter(dataid => dataid !== id);

            return { ...prevState, instructores };
        });
    };
    const handleCheckboxChangeFicha = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const fichas = checked
                ? [...prevState.fichas, id]
                : prevState.fichas.filter(dataid => dataid !== id);

            return { ...prevState, fichas };
        });
    };

    const handleInputChangePC = (e) => {
        setInputValue(e.target.value);
    };
    const handleKeyPressPC = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
        e.preventDefault();
        setFormData((prevData) => ({
            ...prevData,
            palabrasClave: [...prevData.palabrasClave, inputValue.trim()],
        }));
        setInputValue('');
        }
    };
    const handleRemoveTagPC = (tag) => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "keywords",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "DELETE_FORCE",
            "Z": "deleteKeywordsProject",
            "project": id,
            "name": tag
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
        .then(response => {
            toast.success("Se eliminaron los datos con éxito");
            fetchData();
        })
        .catch(error => {
            console.error('Error deleting data:', error);
        });
    };

    const handleInputChangE = (e) => {
        setinputValueEnlaces(e.target.value);
    };
    const handleKeyPressEnlaces = (e) => {
        if (e.key === 'Enter' && inputValueEnlaces.trim() !== '') {
        e.preventDefault();
        setFormData((prevData) => ({
            ...prevData,
            enlaces: [...prevData.enlaces, inputValueEnlaces.trim()],
        }));
        setinputValueEnlaces('');
        }
    };
    const handleRemoveTagE = (file) => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "graphics",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "DELETE_FORCE",
            "Z": "deleteenlacesProject",
            "project": id,
            "file": file
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
        .then(response => {
            toast.success("Se eliminaron los datos con éxito");
            fetchData();
        })
        .catch(error => {
            console.error('Error deleting data:', error);
        });
    };

    const handleCreate = async () => {
        const { 
            name, type, slug, intro, description, deadline, portada, icon,
            aprendices, instructores, fichas, files, palabrasClave, enlaces 
        } = formData;
        
        const formDataToSend = new FormData();

        formDataToSend.append("API", "talentic");
        formDataToSend.append("MODEL", "talentic");
        formDataToSend.append("RESOURCE", "projects");
        formDataToSend.append("key", "5b8d3b1f084b01c6a8387459e80d4bb9");
        formDataToSend.append("TYPE", "PUT");
        
        formDataToSend.append("name", name);
        formDataToSend.append("type", type);
        formDataToSend.append("slug", slug);
        formDataToSend.append("intro", intro);
        formDataToSend.append("description", description);
        formDataToSend.append("deadline", deadline);

        if (portada) {
            formDataToSend.append("portada", portada);
        }

        if (icon) {
            formDataToSend.append("icon", icon);
        }

        files.forEach((file) => {
            formDataToSend.append('files[]', file);
        });

        formDataToSend.append("aprendices", JSON.stringify(aprendices));
        formDataToSend.append("instructores", JSON.stringify(instructores));
        formDataToSend.append("fichas", JSON.stringify(fichas));
        formDataToSend.append("palabrasClave", JSON.stringify(palabrasClave));
        formDataToSend.append("enlaces", JSON.stringify(enlaces));

        try {
            const response = await axios.post('http://217.15.168.117:8080/api/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            setFormData({
                name: '', type: '', slug: '', intro: '', description: '', deadline: '',
                aprendices: [], instructores: [], fichas: [], files: [], portada: null,
                icon: null, palabrasClave: [], enlaces: []
            });
            
        } catch (error) {
            toast.error('Hubo un error al crear el proyecto. Por favor, inténtelo de nuevo.');
        }
    };

    const validateForm = () => {
        let newErrors = {};
    
        if (!formData.name) {
            toast.warning('El nombre del proyecto es requerido');
            newErrors.error = 'error';
            return;
        }
    
        if (!formData.type) {
            toast.warning('El tipo de proyecto es requerido');
            newErrors.error = 'error';
            return;
        }
    
        if (!formData.deadline) {
            toast.warning('La fecha de finalización es requerida');
            newErrors.error = 'error';
            return;
        }

        if (!formData.slug) {
            toast.warning('El texto Slug es requerido');
            newErrors.error = 'error';
            return;
        }
    
        if (!formData.intro) {
            toast.warning('El texto introductorio es requerido');
            newErrors.error = 'error';
            return;
        }
    
        if (!formData.description) {
            toast.warning('La descripción del proyecto es requerida');
            newErrors.error = 'error';
            return;
        }

        if (!formData.portada) {
            toast.warning('La portada del proyecto es requerida');
            newErrors.error = 'error';
            return;
        }
        
        if (!formData.icon) {
            toast.warning('El icono del proyecto es requerida');
            newErrors.error = 'error';
            return;
        }

        if (!formData.aprendices || formData.aprendices.length === 0) {
            toast.warning('los aprendices del proyecto son requeridos');
            newErrors.error = 'error';
        }
    
        if (!formData.instructores || formData.instructores.length === 0) {
            toast.warning('los instructores del proyecto son requeridos');
            newErrors.error = 'error';
        }
    
        if (!formData.fichas || formData.fichas.length === 0) {
            toast.warning('Las fichas del proyecto son requeridas');
            newErrors.error = 'error';
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleVerificarFormulario = (e) => {
        e.preventDefault();

        if (validateForm()) {
            handleCreate();
        }
    };

    
    const updateDatosBasicos = async () => {
        try {
            await axios.post('http://217.15.168.117:8080/api/', {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "projects",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "UPDATE",
                "id": id,
                "type": formData.type,
                "name": formData.name,
                "slug": formData.slug,
                "intro": formData.intro,
                "description": formData.description,
                "deadline": formData.deadline
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            });

            toast.success("Se actualizaron los datos con exito!");
        } catch (error) {
            toast.error(error);
          if (error.response) {
            toast.error('Request failed:', error.response.data.message);
          } else if (error.request) {
            toast.error('No response received:', error.request);
          } else {
            toast.error('Error setting up request:', error.message);
          }
        }
    }
    const updateAutores = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "TYPE": "UPDATE",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "proyecto": id
            };

            const [responseAprendiz, responseInstructor] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', {
                    ...requestBody,
                    "RESOURCE": "allocationsheetproject",
                    "fichas": formData.fichas
                }),
                axios.post('http://217.15.168.117:8080/api/', { 
                    ...requestBody,
                    "RESOURCE": "allocationusersproject",
                    "aprendices": formData.aprendices,
                    "instructores": formData.instructores,
                })
            ]);
            toast.success("Se actualizaron los Autores y Fichas con exito!");
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return (
        <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-3 col-12">
                            <Link to="/dashboard/projects">
                                <i className="bx bx-chevron-left"></i> Editar los datos básicos del proyecto
                            </Link>
                        </div>

                        <div className="mb-3 row" style={{ maxHeight: '500px', overflowY: 'scroll'}}>
                            { step === 1 && 
                                <Step1
                                    formData={formData}
                                    setFormData={setFormData}
                                    onNext={handleNext}
                                    onFinal={handleFinal}
                                    update={updateDatosBasicos}
                                />
                            }
                            { step === 2 && 
                                <Step2
                                    formData={formData}
                                    handleCheckboxChangeaprendiz={handleCheckboxChangeaprendiz}
                                    handleCheckboxChangeInstructor={handleCheckboxChangeInstructor}
                                    handleCheckboxChangeFicha={handleCheckboxChangeFicha}
                                    onNext={handleNext}
                                    onBack={handleBack}
                                    update={updateAutores}
                                />
                            }
                            {step === 3 && 
                                <Step3
                                    formData={formData}
                                    handleDragOver={handleDragOverM}
                                    handleDragLeave={handleDragLeaveM}
                                    handleDrop={handleDropM}
                                    handleFileChange={handleFileChangeM}
                                    handleRemoveFile={handleRemoveFileM}
                                    handleRemoveTag={handleRemoveTagPC}
                                    handleKeyPress={handleKeyPressPC}
                                    handleInputChange={handleInputChangePC}
                                    handleKeyPressEnlaces={handleKeyPressEnlaces}
                                    handleRemoveEnlaces={handleRemoveTagE}
                                    handleInputChangeEnlaces={handleInputChangE}
                                    inputValueEnlaces={inputValueEnlaces}
                                    dragActive={dragActiveM}
                                    inputValue={inputValue}
                                    onBack={handleBack}
                                    onSubmit={handleVerificarFormulario}
                                    handleInicio={handleInicio}
                                    resetFormData={fetchData}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultiStepForm;
