// Importamos React y sus hooks useState y useEffect para manejar el estado y efectos en el componente.
import React, { useState, useEffect } from 'react';

// Importamos axios para realizar peticiones HTTP.
import axios from 'axios';

// Estilos CSS específicos para el diseño y componentes del proyecto.
import './../css/perfect-scrollbar.css'; // Estilo para la barra de desplazamiento perfecta.
import './../css/theme-default.css'; // Estilo para el tema por defecto.
import './../css/apex-charts.css'; // Estilo para los gráficos de ApexCharts.
import './../css/boxicons.css'; // Estilo para los iconos de Boxicons.
import './../css/demo.css'; // Estilo para la demostración.
import './../css/core.css'; // Estilo central o base del sistema.

import Card from './../components/card'; // Componente para representar una tarjeta.
import Short from './../components/short'; // Componente relacionado con algún tipo de texto o contenido corto.
import IconSVG from '../contents/icons/icon'; // Componente para manejar iconos SVG.
import TruncatedText from './../components/cutString'; // Componente para cortar y truncar textos largos.
import MetricsComponent from '../contents/metrics/metrics'; // Componente para mostrar métricas (probablemente en gráficos o estadísticas).

function Home( ) {
  // Se define un estado para almacenar el ID del programa seleccionado.
  const [selectedProgramId, setSelectedProgramId] = useState(null);

  // Se define un estado para almacenar los programas.
  const [programas, setprogramas] = useState([]);

  // Se define un estado para almacenar los perfiles (probablemente de proyectos o usuarios).
  const [profiles, setprofiles] = useState([]);

  // Se define un estado para almacenar los datos de las diapositivas.
  const [slides, setslides] = useState([]);

  // Se define el estado del formulario que obtiene el ID de usuario desde el localStorage.
  const [formData] = useState(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    return {
      user: user?.index_user || ''
    };
  });

  // useEffect se ejecuta cuando cambia el estado de 'slides' o 'programas'.
  useEffect(() => {
    // Si 'slides' está vacío, realiza una solicitud para obtener los proyectos más importantes.
    if(slides.length === 0){
      axios.post('http://217.15.168.117:8080/api/', {
        "API": "talentic",
        "MODEL": "talentic",
        "RESOURCE": "list",
        "TYPE": "proyectsTop10",
        "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        "user": formData.user
      })
      .then(response => {
        // Limpiar y estructurar los datos de los proyectos
        const cleanedData = response.data.map((item, index) => ({
          id: item.id,
          image: `http://217.15.168.117:8080/api/Whatever/${item.image}`,
          ico: item.ico === null ? '' : `http://217.15.168.117:8080/api/Whatever/${item.ico}`,
          name: item.name,
          description: item.description,
          videoYT: item.videoYT,
          asignado: item.asignado,
          guardado: item.guardado,
          position: index + 1
        }));
        
        // Actualiza el estado de 'slides' con los datos limpios.
        setslides(cleanedData);

        // Limpiar y estructurar los perfiles de los proyectos
        const cleanedDataProfiles = response.data.map(item => ({
          id: item.id,
          imgSrc: `http://217.15.168.117:8080/api/Whatever/${item.image}`,
          name: item.name,
          dateCreate: item.dateCreate,
          favorites: item.favorites,
          hoverText: item.description,
          asignado: item.asignado,
          guardado: item.guardado,
        }));
        // Actualiza el estado de 'profiles' con los datos limpios.
        setprofiles(cleanedDataProfiles);
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
    }

    // Si 'programas' está vacío, realiza una solicitud para obtener los programas.
    if(programas.length === 0){
      axios.post('http://217.15.168.117:8080/api/', {
        "API": "talentic",
        "MODEL": "talentic",
        "RESOURCE": "list",
        "TYPE": "programmes",
        "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
      })
      .then(response => {
          if (Array.isArray(response.data)) {
            // Actualiza el estado de 'programas' si la respuesta es un array.
            setprogramas(response.data);
          } else {
            // Aquí se podrían manejar otros casos si la respuesta no es un array.
          }
      })
      .catch(error => {
        // Aquí se podrían manejar errores al obtener los programas.
      });
    }
  }, [slides, programas]);

  // Función para actualizar los proyectos según el ID del programa seleccionado.
  const updateproyects = async (link) => {
    // Se actualiza el ID del programa seleccionado.
    setSelectedProgramId(link);

    // Realiza una solicitud para obtener los proyectos del programa seleccionado.
    axios.post('http://217.15.168.117:8080/api/', {
        "API": "talentic",
        "MODEL": "talentic",
        "RESOURCE": "list",
        "TYPE": "proyectsXprogramas",
        "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        "id": link
    })
    .then(response => {
        // Limpiar y estructurar los datos de los perfiles para el programa seleccionado.
        const cleanedDataProfiles = response.data.map(item => ({
            id: item.id,
            imgSrc: `http://217.15.168.117:8080/api/Whatever/${item.image}`,
            name: item.name,
            dateCreate: item.dateCreate,
            favorites: item.favorites,
            hoverText: <TruncatedText text={item.description} maxLength={90} /> // Se trunca la descripción
        }));
        // Actualiza el estado de 'profiles' con los datos limpios del programa seleccionado.
        setprofiles(cleanedDataProfiles);
    })
    .catch(error => {
        console.error('Error fetching data:', error);
    });
  };

  return (
    <>
      {/* SLIDER */}
      <Short slides={slides} />

      {/* CATEGORIAS */}
      <div className='col-12 d-flex flex-wrap my-3'>
        <div className='col-12 text-center'>
          <h3>Proyectos</h3>
          <div className='d-flex overX my-1 justify-content-center'>
            {programas.map((programa, index) => (
              <div
                key={index}
                className={`mx-5 d-flex flex-column justify-content-center align-items-center ${selectedProgramId === programa.id ? 'selectedprogramme' : ''}`}
                style={{ cursor: 'pointer', maxWidth: '7rem'}}
                onClick={() => updateproyects(programa.id)}
              >
                <div className='icon_bg_metric'>
                  <IconSVG 
                    name={selectedProgramId === programa.id ? `${programa.icon}_bl` : programa.icon}
                  />
                </div>
                <h5 className='mt-2'>{programa.slung}</h5>
              </div>
            ))}
          </div>
        </div>

        <div className='col-12 d-flex my-2 overX align-items-center justify-content-center height-275'>
          {profiles.map((profile, index) => (
            <Card
                key={index}
                imgSrc={profile.imgSrc}
                name={profile.name}
                hoverText={profile.hoverText}
                socialLinks={profile.socialLinks}
                link={profile.id}
                date={profile.dateCreate}
                favorites={profile.favorites}
                asignado={profile.asignado}
                guardado={profile.guardado}
            />
          ))}
        </div>
      </div>

      {/* EQUIPO TALENTIC */}
      <div className='col-12 d-flex flex-column-reverse flex-md-row bg-light'>
        <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
          <div className='p-3 col-8'>
            <h2 className='text-primary'>Talentic</h2>
            <h5 className='text-start px-0'>Talentic es un sistema de información documental de proyectos formativos de programas de formación presencial y virtual para consulta del personal interno y externo de Cenigraf.</h5>
            <br/>
            <p>Proyecto realizado por:</p>
            <div className='col-11 d-flex flex-wrap justify-content-around'>
              <img src={require(`./../img/nav/Logo_cenigraf.png`)} className={`m-0 img-content col-3`} />
              <IconSVG name="Logo_sennova" className="iconequipotalecticsennova" />
              <IconSVG name="Logo_Ingraf" className="iconequipotalecticsennova" />
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 px-5'>
          <img src={require(`./../img/nav/img_instructores.png`)} className={`m-0 img-content`} />
        </div>
      </div>

      {/* METRICAS */}
      <div className='h-auto col-12 d-flex flex-wrap bg-talentic justify-content-evenly align-items-center'>
        <div className='col-12 col-md-3 text-center py-3 py-md-1'>
          <h2 className='text-white'>Hoy contamos con más de:</h2>
        </div>
        <div className='col-12 col-md-7'>
          <MetricsComponent />
        </div>
      </div>
    </>
  );
}

export default Home;