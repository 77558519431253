import React, { useState, useEffect } from 'react';  // Importación de dependencias necesarias para el componente
import LiteYouTubeEmbed from 'react-lite-youtube-embed';  // Importación del componente de YouTube embebido

// Función auxiliar para extraer el ID del video de una URL de YouTube
const getIdFromUrl = (url) => {
    const regex = /(?:v=|\/v\/|embed\/|be\/)([a-zA-Z0-9_-]{11})/;  // Expresión regular para obtener el ID de video
    const match = url.match(regex);
    return match && match[1];  // Retorna el ID si la URL es válida, sino null
};

// Componente principal 'Viewer', que recibe 'viewer', 'type', y 'data' como props
const Viewer = ({ viewer, type, data }) => {
    const [activeIndex, setActiveIndex] = useState(null);  // Estado para manejar el índice activo en la vista
    const [formData, setFormData] = useState({  // Estado para almacenar los datos del formulario (viewer, type, data)
        viewer: viewer,
        type: type,
        data: data
    });

    // Hook de efecto que actualiza los datos cada vez que 'viewer', 'type' o 'data' cambian
    useEffect(() => {
        setFormData({
            viewer: viewer,
            type: type,
            data: data
        });
    }, [viewer, type, data]);

    // Función para manejar el clic en cada slide y actualizar los datos del formulario
    const handleClick = (slide, index) => {
        setFormData({
            ...formData,
            viewer: slide.file,  // Establece el archivo asociado al slide
            type: slide.type,  // Establece el tipo del slide (por ejemplo, imagen o video)
        });
        setActiveIndex(index);  // Actualiza el índice activo para reflejar el slide seleccionado
    };

    return (
        <div className="col-11 col-md-8">  {/* Contenedor principal */}
            <div className='h70auto mb-4 mb-md-1'>  {/* Contenedor de visualización del contenido */}
                {/* Condicional para mostrar un video o una imagen según el tipo */}
                {formData.type !== 'Video Youtube' ? (
                    <div
                        className="item_viewer mx-3"  // Estilo para el contenedor de la imagen
                        style={{ backgroundImage: `url(http://217.15.168.117:8080/api/Whatever/${formData.viewer})` }}  // Imagen de fondo
                    >
                    </div>
                ) : (
                    <div className='h70auto transition'>  {/* Si es un video de YouTube */}
                        <LiteYouTubeEmbed 
                            id={getIdFromUrl(formData.viewer)}  // Extrae el ID de YouTube de la URL
                            title="Video de ejemplo"  // Título del video embebido
                        />
                    </div>
                )}
            </div>
            <div className='h20 positionR'>  {/* Contenedor para los detalles del contenido multimedia */}
                <div className='multimefiaDetailsitems overX w-auto'>  {/* Lista de elementos multimedia */}
                    {data.map((slide, index) => (  // Mapea los elementos de 'data' para generar los items
                        <div key={index} className={`${activeIndex === index ? 'activedetailclikeditem' : ''}`} onClick={() => handleClick(slide, index)}>  {/* Marca el item activo */}
                            {/* Condicional para cada tipo de slide (Imagen o Video) */}
                            {slide.type !== 'Video Youtube' ? (
                                <div
                                    className={`Witem mx-2 mx-md-3`}  // Estilo del item (Imagen)
                                    data-index={slide.id}  // Asocia un índice al item
                                    style={{ backgroundImage: `url(http://217.15.168.117:8080/api/Whatever/${slide.file})` }}  // Establece la imagen de fondo
                                >
                                    <small className="name">{slide.type}</small>  {/* Muestra el tipo de slide */}
                                </div>
                            ) : (
                                <div
                                    className={`contdetailyt`}  // Contenedor para el video de YouTube
                                >
                                    <LiteYouTubeEmbed 
                                        id={getIdFromUrl(slide.file)}  // Extrae el ID del video de la URL
                                    />
                                    <small className="nameyt">{slide.type}</small>  {/* Muestra el tipo de video */}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Botones de navegación para el carrusel (previo y siguiente) */}
                <button className="carousel-control-prev" type="button">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default Viewer;  // Exporta el componente para su uso en otras partes de la aplicación
