import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; 
import { toast } from 'react-toastify';
import axios from 'axios';

import ShowProject from './show';
import CreateProject from './create';
import UpdateProject from './update';

import Modal from '../../components/modalDashboard';
import IconSVG from '../../contents/icons/icon';
import SeacrhData from '../../components/searchData';

import ViewToggle from '../../components/viewToggle';
import SortFilter from '../../components/sortFilter';

import ListView from '../../components/listView';
import CardView from '../../components/cardView';

const Projects = ( ) => {
    const [apiData, setApiData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const [roleFilter, setRoleFilter] = useState('Todos');
    const [sortConfig, setSortConfig] = useState({ key: 'dateCreation', direction: 'desc' });
    const [activeMenuActions, setActiveMenuActions] = useState(null);
    const menuRef = useRef(null);
    const [viewType, setViewType] = useState('cards'); // 'cards' para tarjetas y 'list' para lista

    const navigate = useNavigate();
    
    
    const toggleMenuActions = (menuName) => {
        setActiveMenuActions(activeMenuActions === menuName ? null : menuName);
    };

    const hashToVer = "bf5b05bfb279c5213c83a52b9fdd2f61";
    const hashToCrear = "7bdf3b3cef6968c2dbbf4d5ed4e3f647";
    const hashToEditar = "3336abe80e58646e95aaf74379c76e53";
    const hashToEliminar = "9d298a4ffe97543e79b9a0398ef03fc4";
    const hashToAprobar = "964b43f9d7828bcefed26ebd92f98f2e";

    const [formDatauser] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        return {
            permisos: user?.permissions || ''
        };
    });

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = () => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "list",
            "TYPE": "projects",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                const cleanedData = response.data.map(item => ({
                    id: item.id,
                    name: item.name,
                    type: item.type,
                    idType: item.idType,
                    image: item.image,
                    slug: item.slug,
                    intro: item.intro,
                    description: item.description,
                    dateCreation: item.dateCreate,
                    deadline: item.deadline,
                    state: item.state === 0 ? "Aprobado" : item.state === 1 ? "Borrador" : "Por Aprobar",
                }));
                setApiData(cleanedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };
    const closeModalSuspend = () => {
      setIsModalOpen(false);
      setModalContent(null);
      toast.warning('Estamos subiendo los datos, en breve veras el proyecto.');
    };

    const handleChangecrear = (newState) => {
        toast.success("Proyecto enviado con éxito");
        setIsModalOpen(false);
        setModalContent(null);
        fetchData();
    };

    const handleChangeeditar = (newState) => {
        toast.success("Se editaron los datos con éxito");
        setIsModalOpen(false);
        fetchData();
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };


    const handleDelete = (itemId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este registro?')) {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "projects",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "DELETE",
                "id": itemId
            };

            axios.post('http://217.15.168.117:8080/api/', requestBody)
                .then(response => {
                    toast.success("Se eliminaron los datos con éxito");
                    fetchData();
                })
                .catch(error => {
                    console.error('Error deleting data:', error);
                });
        }
    };

    const handleAprobar = (itemId) => {
        if (window.confirm('¿Estás seguro de que deseas aprobar este proyecto?')) {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "projects",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "DELETE",
                "id": itemId,
                "state": 1
            };

            axios.post('http://217.15.168.117:8080/api/', requestBody)
                .then(response => {
                    toast.success("Se aprobó el proyecto con éxito");
                    fetchData();
                })
                .catch(error => {
                    console.error('Error deleting data:', error);
                });
        }
    };

    const handleCreateClick = (FormComponent) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent onSubmitState={handleChangecrear} closeModal={closeModalSuspend}/>);
    };

    const handleEditClick = (FormComponent, id) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent idDefault={id} />);
    };
    
    const handleShowClick = (FormComponent, id) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent idDefault={id} />);
    };

    const filteredData = apiData
    .filter((item) => {
        // Filtra por estado
        const isRoleFilterMatched = 
            roleFilter === 'Todos' ||
            (roleFilter === 'Aprobado' && item.state === "Aprobado") ||
            (roleFilter === 'Borrador' && item.state === "Borrador") ||
            (roleFilter === 'Por Aprobar' && item.state === "Por Aprobar");
        
        // Filtra por búsqueda de nombre
        const isSearchMatched = (item.name || '').toLowerCase().includes(searchTerm);

        return isRoleFilterMatched && isSearchMatched;
    })
    .sort((a, b) => {
        if (sortConfig.key === 'dateCreation' && a.dateCreation && b.dateCreation) {
            return sortConfig.direction === 'asc'
                ? new Date(a.dateCreation) - new Date(b.dateCreation)
                : new Date(b.dateCreation) - new Date(a.dateCreation);
        } else if (sortConfig.key === 'name') {
            return sortConfig.direction === 'asc'
                ? (a.name || '').localeCompare(b.name || '')
                : (b.name || '').localeCompare(a.name || '');
        } else if (sortConfig.key === 'type') {
            return sortConfig.direction === 'asc'
                ? (a.type || '').localeCompare(b.type || '')
                : (b.type || '').localeCompare(a.type || '');
        }
        return 0;
    });

    const handleSort = (value) => {
        let key, direction;

        if (value === 'fechaDesc') {
            key = 'dateCreation';
            direction = 'desc';
        } else if (value === 'fechaAsc') {
            key = 'dateCreation';
            direction = 'asc';
        } else if (value === 'nombreAsc') {
            key = 'username';
            direction = 'asc';
        } else if (value === 'nombreDesc') {
            key = 'username';
            direction = 'desc';
        }

        setSortConfig({ key, direction });
    };
    

    // Manejar el cambio en el filtro de rol
    const handleRoleFilterChange = (role) => {
        setRoleFilter(role);
    };

    // Alterna entre los tipos de visualización
    const showCards = () => setViewType('cards');
    const showList = () => setViewType('list');
    
    useEffect(() => {
        // Detectar clics fuera del menú
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveMenuActions(null);
            }
        };

        // Añadir evento al hacer clic en el documento
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Limpiar el evento al desmontar el componente
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Función que define el menú de acciones específico para cada usuario
    const actionMenuProps = (item) => ({
        isActive: activeMenuActions === item.id,
        onClose: () => setActiveMenuActions(null),
        actions: [
            formDatauser.permisos.includes(hashToVer) && {
                label: 'Ver',
                icon: 'Icon_ver_talentic_2',
                onClick: () => handleShowClick(ShowProject, item.id),
            },
            formDatauser.permisos.includes(hashToEditar) && {
                label: 'Editar',
                icon: 'Icon_editar_talentic_2',
                onClick: () => handleEditClick(UpdateProject, item.id),
                className: 'menu_acciones_editar'
            },
            (formDatauser.permisos.includes(hashToAprobar) && item.state !== "Aprobado") && {
                label: 'Aprobar',
                icon: 'Icon_aprobar_talentic_2',
                onClick: () => handleAprobar(item.id),
                className: 'menu_acciones_aprobar'
            },
            (formDatauser.permisos.includes(hashToAprobar) && item.state === "Aprobado") && {
                label: 'Desaprobar',
                icon: 'Icon_x_talentic_2',
                onClick: () => handleAprobar(item.id),
                className: 'menu_acciones_eliminar'
            },
        
            formDatauser.permisos.includes(hashToEliminar) && {
                label: 'Eliminar',
                icon: 'Icon_eliminar_talentic_2',
                onClick: () => handleDelete(item.id, item.state),
                className: 'menu_acciones_eliminar'
            }
        ].filter(Boolean)
    });

    return (
        <div className='dashboard_content'>
            <main className='main__dashboard_content'>
                <div className='form_content'>
                    <section className='dashboard_titulo'>
                        <h2>Proyectos</h2>
                    </section>
                    <div className='main__dashboard_primera_parte'>
                        <nav className='main__dashboard_menu_interno'>
                            <ul>
                                <li className={` ${roleFilter === 'Todos' ? 'active_item_dash' : ''}`} onClick={() => handleRoleFilterChange('Todos')}>Todos</li>
                                <li className={` ${roleFilter === 'Aprobado' ? 'active_item_dash' : ''}`} onClick={() => handleRoleFilterChange('Aprobado')}>Aprobados</li>
                                <li className={` ${roleFilter === 'Por Aprobar' ? 'active_item_dash' : ''}`} onClick={() => handleRoleFilterChange('Por Aprobar')}>Por aprobar</li>
                            </ul>
                        </nav>
                        <div className='btn_nuevo_dashboard' onClick={() => handleCreateClick(CreateProject)}>
                            <IconSVG name="Icon_mas_talentic"/> Crear Nuevo
                        </div>
                        
                    </div>
                    <div className='form_content_dashboard'>
                        <div className='filters_dashboard'>
                            <div className='buscador_dashboard'>
                                <SeacrhData
                                    searchTerm={searchTerm}
                                    handleSearchChange={handleSearchChange}
                                />
                            </div>
                            <div className='filtros_content_dashboard'>
                                <SortFilter sortConfig={sortConfig} onSortChange={handleSort}/>
                                <ViewToggle viewType={viewType} showCards={showCards} showList={showList} />
                            </div>
                        </div>
                        {/* Vista en formato de tarjetas */}
                        {viewType === 'cards' && (
                            <div className='layout_cards'>
                                {filteredData.length > 0 ? (
                                filteredData.map((item) => (
                                    <CardView
                                        key={item.id}
                                        titulo={item.name}
                                        subtitulo={item.type}
                                        parrafo={item.state}
                                        actionMenuProps={actionMenuProps}
                                        item={item}
                                        toggleMenuActions={toggleMenuActions}
                                        ref={menuRef}
                                        img={item.image}
                                    />
                                ))
                            ) : (
                                <p>No se encontraron registros</p>
                            )}
                            </div>
                        )}

                        {/* Vista en formato de lista */}
                        {viewType === 'list' && (
                            <div className='layout_list_dashboard'>
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('nombreAsc')}><h4>Proyecto<IconSVG name="Icon_flechas_talentic"/></h4></th>
                                        <th><h4>Tipo</h4></th>
                                        <th><h4>Estado</h4></th>
                                        <th><h4>Acciones</h4></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length > 0 ? filteredData.map((item) => (
                                        <ListView
                                            key={item.id}
                                            img={item.image}
                                            titulo={item.name}
                                            subtitulo={item.type}
                                            parrafo={item.state}
                                            actionMenuProps={actionMenuProps}
                                            toggleMenuActions={toggleMenuActions}
                                            ref={menuRef}
                                            item={item}
                                        />
                                    )) : (
                                        <tr>
                                            <td colSpan="3">No se encontraron registros</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                            
                        )}
                    </div>
                </div>
            </main>
            <Modal isOpen={isModalOpen} onClose={closeModal} className={'modal_proyects'} titulo={'Proyecto'}>
                {modalContent}
            </Modal>
        </div>
    );
};

export default Projects;