import React, { useState, useEffect } from 'react';
import IconSVG from '../../contents/icons/icon';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }).format(date);
};

const ShowCenters = ({ nameDefault, dateCreationDefault }) => {
    const [formData, setFormData] = useState({
        name: nameDefault,
        dateCreation: formatDate(dateCreationDefault),
    });

    useEffect(() => {
        setFormData({
            name: nameDefault,
            dateCreation: formatDate(dateCreationDefault)
        });
    }, [nameDefault, dateCreationDefault]);

    return (
        <div className="modal_user_content">
            <section className='modal_icons_actions'>
                <div className='modal_icon_action '>
                    <IconSVG name="Icon_editar_talentic_2"/>
                    <span>Editar</span>
                </div>
                <div className='modal_icon_action menu_acciones_eliminar'>
                    <IconSVG name="Icon_eliminar_talentic_2"/>
                    <span>Eliminar</span>
                </div>
            </section>
            <section className='modal_all_info'>
                <div className='modal_info_user_item'>
                    <figure>
                        <IconSVG name="Icon_usuario_talentic"/>
                    </figure>
                    <div>
                        <h5>Nombre</h5> 
                        <p>{formData.name}</p>
                    </div>
                </div>
                
                <div className='modal_info_user_item'>
                    <figure>
                        <IconSVG name="Icon_roles_talentic"/>
                    </figure>
                    <div>
                        <h5>Descripción</h5> 
                        <p>{formData.description}</p>
                    </div>
                </div>

                <div className='modal_info_user_item'>
                    <figure>
                        <IconSVG name="Icon_inicio_talentic"/>
                    </figure>
                    <div>
                        <h5>Fecha de creación</h5> 
                        <p>{formData.dateCreation}</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ShowCenters;
