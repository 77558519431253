import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import CheckBox from '../components/checkbox';

const AssignUser = ( ) => {
    const { state: { id, username, type } = {} } = useLocation();

    const [searchTermRol, setsearchTermRol] = useState('');
    const [searchTermSpecial, setsearchTermSpecial] = useState('');
    const [apiDataRol, setapiDataRol] = useState([]);
    const [apiDataSpecial, setapiDataSpecial] = useState([]);
    const [formDataRol, setformDataRol] = useState({ roles: [] });
    const [formDataSpecial, setformDataSpecial] = useState({ speciales: [] });

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "userId": id,
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            };

            const [responseRol, responseSpecial] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationroll" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "specialleave" })
            ]);
            
            const processedDataRol = responseRol.data.map(item => ({
                id: parseInt(item.id, 10),
                name: item.name,
                description: item.description,
                asignado: item.asignado,
                state: item.state
            }));
            
            const processedDataSpecial = responseSpecial.data.map(item => ({
                id: parseInt(item.id, 10), 
                name: item.name,
                crypter: item.crypter,
                asignado: item.asignado,
                state: item.state
            }));

            setapiDataRol(processedDataRol);
            setapiDataSpecial(processedDataSpecial);

            const permisosInicialesRol = processedDataRol
                .filter(item => item.asignado === 1)
                .map(item => item.id);
            
            const permisosInicialesSpecial = processedDataSpecial
                .filter(item => item.asignado === 1)
                .map(item => item.id);

            setformDataRol({ roles: permisosInicialesRol });
            setformDataSpecial({ speciales: permisosInicialesSpecial });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "userId": id,
            };

            await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { 
                    ...requestBody,
                    "TYPE": "UPDATE",
                    "RESOURCE": "allocationroll",
                    "roles": formDataRol.roles 
                }),
                axios.post('http://217.15.168.117:8080/api/', { 
                    ...requestBody, 
                    "TYPE": "UPDATE", 
                    "RESOURCE": "specialleave",
                    "speciales": formDataSpecial.speciales
                })
            ]);

            fetchData();
        } catch (error) {
            console.error('Error updating permissions:', error);
        }
    };

    const handleCheckboxChangeRol = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setformDataRol(prevState => {
            const roles = checked
                ? [...prevState.roles, id]
                : prevState.roles.filter(rolid => rolid !== id);

            return { ...prevState, roles };
        });
    };
    const handleCheckboxChangeSpecial = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setformDataSpecial(prevState => {
            const speciales = checked
                ? [...prevState.speciales, id]
                : prevState.speciales.filter(specialid => specialid !== id);

            return { ...prevState, speciales };
        });
    };

    const handleSearchChangeRol = (e) => {
        setsearchTermRol(e.target.value.toLowerCase());
    };
    const handleSearchChangeSpecial = (e) => {
        setsearchTermSpecial(e.target.value.toLowerCase());
    };

    const filteredDataRol = apiDataRol.filter(item =>
        (item.name || '').toLowerCase().includes(searchTermRol) ||
        (item.description || '').toLowerCase().includes(searchTermRol)
    );
    const filteredDataSpecial = apiDataSpecial.filter(item =>
        (item.name || '').toLowerCase().includes(searchTermSpecial) ||
        (item.crypter || '').toLowerCase().includes(searchTermSpecial)
    );

    return (
        <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Modulo de administración de permisos asignados a roles</h4>

                <div className="row">
                    <div className="col-xl">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Datos básicos del usuario</h5>
                                <small className="text-muted float-end">Formulario de asociación de permisos especiales y roles</small>
                            </div>

                            <div className="card-body row">
                                <div className="mb-3 col-12 col-lg-6">
                                    <label className="form-label">Nombre del usuario</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={username}
                                    />
                                </div>

                                <div className="mb-3 col-12 col-lg-6">
                                    <label className="form-label">Tipo de usuario</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={type}
                                    />
                                </div>

                                <div className="mb-3 col-12">
                                    <Link to="/dashboard/users">
                                        <i className="bx bx-chevron-left"></i> Usuarios
                                    </Link>
                                </div>
                            </div>

                            <div className="card-body">
                                <form id="formModel" className="row" onSubmit={handleSubmit}>
                                    <div className="col-12 p-4 col-lg-6">
                                        <h5>Roles disponibles</h5>
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                id="searchDos"
                                                className="form-control"
                                                placeholder="Buscar Rol"
                                                aria-label="Buscar Rol"
                                                aria-describedby="button-addon2"
                                                value={searchTermRol}
                                                onChange={handleSearchChangeRol}
                                            />
                                        </div>

                                        <div className="aprendices-list">
                                            {filteredDataRol.length > 0 ? filteredDataRol.map((item) => (
                                                <CheckBox
                                                    key={item.id}
                                                    name="roles"
                                                    value={item.id}
                                                    icon="bx-lock-alt"
                                                    userName={item.name}
                                                    description={item.description}
                                                    checked={item.asignado === 1}
                                                    handleCheckboxChange={handleCheckboxChangeRol}
                                                />
                                            )) : (
                                                <div id="no-results-dos">
                                                    Rol no encontrado
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 p-4 col-lg-6">
                                        <h5>Permisos disponibles</h5>
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                id="searchDos"
                                                className="form-control"
                                                placeholder="Buscar Permiso"
                                                aria-label="Buscar Permiso"
                                                aria-describedby="button-addon2"
                                                value={searchTermSpecial}
                                                onChange={handleSearchChangeSpecial}
                                            />
                                        </div>

                                        <div className="aprendices-list">
                                            {filteredDataSpecial.length > 0 ? filteredDataSpecial.map((item) => (
                                                <CheckBox
                                                    key={item.id}
                                                    name="especiales"
                                                    value={item.id}
                                                    icon="bx-lock-alt"
                                                    userName={item.name}
                                                    description={item.crypter}
                                                    checked={item.asignado === 1}
                                                    handleCheckboxChange={handleCheckboxChangeSpecial}
                                                />
                                            )) : (
                                                <div id="no-results-dos">
                                                    Permiso no encontrado
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mb-3 col-12">
                                        <Link to="/dashboard/roles" className="btn btn-danger">
                                            Cancelar
                                        </Link>
                                        <button type="submit" className="btn btn-primary">
                                            Actualizar roles y permisos especiales del usuario - {username}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssignUser;
