import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Suspense } from 'react';

const componentes = {
    proyectos: React.lazy(() => import('./proyectos')),
    empresarios: React.lazy(() => import('./empresarios')),
    aprendices: React.lazy(() => import('./aprendices')),
};

const MetricsComponent = ( ) => {
    const [componentesCargados, setComponentesCargados] = useState([]);

    useEffect(() => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "list",
            "TYPE": "allocationMetrics",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                const cleanedData = response.data.map(item => ({
                    id: parseInt(item.id, 10),
                    name: item.name,
                    code: item.code,
                    icon: item.icon,
                    position: item.position
                }));
                setComponentesCargados(cleanedData);
            })
            .catch(error => {
                console.error('Error al cargar componentes:', error);
            });
    }, []);

    return (
        <div className="metricas no-gutters justify-content-center" >
            {componentesCargados.map((item, index) => {
                const ComponenteSeleccionado = componentes[item.code];

                // Si el componente no existe, mostrar un mensaje de error o un componente por defecto
                if (!ComponenteSeleccionado) {
                    return <div key={index}>Componente {item.clave} no encontrado</div>;
                }

                return (
                    <Suspense fallback={<div key={index}>Cargando...</div>} key={index}>
                        <ComponenteSeleccionado valor={item.code} />
                    </Suspense>
                );
            })}
        </div>
    );
};

export default MetricsComponent;
