import React from 'react'; // Importa React para usar JSX en el componente.
import { Navigate } from 'react-router-dom'; // Importa Navigate para redirigir a otra ruta si no está autenticado.
import { useAuth } from './AuthContext'; // Importa el hook useAuth para obtener el estado de autenticación.

const PrivateRoute = ({ element }) => {
  // Usa el hook useAuth para obtener el estado de autenticación del usuario.
  const { isAuthenticated } = useAuth();

  // Si el usuario está autenticado, muestra el componente 'element', si no redirige a la página de inicio.
  return isAuthenticated ? element : <Navigate to="/" />;
};

export default PrivateRoute; // Exporta el componente PrivateRoute para que pueda ser usado en otras partes de la aplicación.
