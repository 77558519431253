import React from 'react';
import IconSVG from '../contents/icons/icon'; // Importa un componente de icono SVG

// Componente para alternar entre las vistas de tarjeta y lista
const ViewToggle = ({ viewType, showCards, showList }) => {
    return (
        <div className='organizacion_dashboard'>
            {/* Botón para la vista de tarjetas */}
            <div 
                className={`option_diseño_card ${viewType === 'cards' ? 'active_organizacion_dashboard' : ''}`} 
                onClick={showCards} // Llama a la función showCards cuando se hace clic
            >
                <IconSVG name="Icon_tipo_tarjetas_talentic" /> {/* Icono para la vista de tarjetas */}
            </div>

            {/* Botón para la vista de lista */}
            <div 
                className={`option_diseño_card ${viewType === 'list' ? 'active_organizacion_dashboard' : ''}`} 
                onClick={showList} // Llama a la función showList cuando se hace clic
            >
                <IconSVG name="Icon_tipo_listas_talentic" /> {/* Icono para la vista de lista */}
            </div>
        </div>
    );
};

export default ViewToggle;
