import React from 'react';

import CryptoJS from 'crypto-js';
import axios from 'axios';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const App = () => {
  const handleLoginSuccess = async (response) => {
    if (!response.credential) {
      console.error('No credential found in the response');
      return;
    }
    // OBTENER GOOGLE PAGAR ___

  };

  const handleLoginFailure = (error) => {
    console.error('Login Failure:', error);
  };

  return (
    <GoogleOAuthProvider clientId="573168101184-i308rgbnd2i227c29jepo2ofqqm0jkh0.apps.googleusercontent.com">
      <div>
        <h2>Login with Google</h2>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
          useOneTap
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default App;
