import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UpdateMetricsPosition = ({ onUPSubmitState, idDefault, nameDefault, positionDefault }) => {
    const [formData, setFormData] = useState({
        id: idDefault,
        name: nameDefault,
        position: positionDefault,
    });
    const [error, setError] = useState('');

    useEffect(() => {
        setFormData({
            id: idDefault,
            name: nameDefault,
            position: positionDefault,
        });
    }, [idDefault, nameDefault, positionDefault]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const { id, name, position } = formData;

        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "metrics",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "UPDATE",
            "id": id,
            "name": name,
            "position": position
        };

        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody);
            onUPSubmitState(true);
        } catch (error) {
            console.error('Error updating data:', error);
            setError('Hubo un error al actualizar el tipo de usuario. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className="row">
            <div className="col-xl">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Editando Componente</h5>
                        <small className="text-muted float-end">Formulario de edición</small>
                    </div>
                    <div className="card-body">
                        <form className="mb-3 row" method="POST" onSubmit={handleUpdate}>
                            <div className="mb-3 col-12 col-lg-6">
                                <label className="form-label">Nombre</label>
                                <input
                                    type="text"
                                    className="form-control required"
                                    name="name"
                                    value={formData.name}
                                    placeholder="nombre"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3 col-12 col-lg-6">
                                <label className="form-label">Posición</label>
                                <input
                                    type="text"
                                    className="form-control required"
                                    name="position"
                                    value={formData.position}
                                    placeholder="posición"
                                    onChange={handleChange}
                                />
                            </div>
                            {error && <div className="mb-3 col-12 text-danger">{error}</div>}
                            <div className="mb-3 col-12">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateMetricsPosition;
