import React from 'react'; // Importa React, necesario para crear el componente funcional.
import './../css/modal.css'; // Importa los estilos CSS para el modal.

function Modal({ isOpen, onClose, children, widthValue, titulo, className }) {
  // Si el modal no está abierto (isOpen es false), no se renderiza nada (retorna null).
  if (!isOpen) return null;

  return (
    // Se crea un contenedor principal para el modal, que tiene un evento onClick que cierra el modal.
    <section className="modal_content_dashboard" onClick={onClose}>
      {/* Contenedor del contenido del modal. El evento onClick evita que el cierre ocurra cuando se hace clic dentro del contenido del modal. */}
      <aside className={`modal_content_items ${className}`} onClick={e => e.stopPropagation()}>
        {/* Cabecera del modal, donde se muestra el título (prop 'titulo') */}
        <header className='modal_header'>
          <h3>{titulo}</h3> {/* El título del modal es pasado como prop 'titulo'. */}
          <button className="modal__close_dashboard" onClick={onClose}>×</button> {/* Botón para cerrar el modal. */}
        </header>
        {children} {/* Aquí se renderizan los elementos hijos que se pasen al modal. */}
      </aside>
    </section>  
  );
}

export default Modal; // Exporta el componente Modal para que pueda ser utilizado en otras partes de la aplicación.
