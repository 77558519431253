import React, { useState, useEffect } from 'react';
import axios from 'axios';
import IconSVG from '../../contents/icons/icon';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }).format(date);
};

const ShowCenters = ({ idDefault,
    personDefault,
    usernameDefault,
    nameDefault,
    lastnameDefault,
    typeIdDefault,
    typeDefault,
    nameuserDefault,
    imgDefault,
    sheetIdDefault,
    sheetDefault,
    emailDefault,
    }) => {
        const [formData, setFormData] = useState({
            username: usernameDefault,
            lastName: lastnameDefault,
            email: emailDefault,
            sheet: sheetDefault,
            type: typeDefault,
            person: personDefault,
            name: nameDefault,
            img: imgDefault,
            sheetId: sheetIdDefault
        });

        useEffect(() => {
            setFormData({
                username: usernameDefault,
                lastName: lastnameDefault,
                email: emailDefault,
                sheet: sheetDefault,
                type: typeDefault,
                person: personDefault,
                name: nameDefault,
                img: imgDefault,
                sheetId: sheetIdDefault
            });
        }, [
            usernameDefault,
            lastnameDefault,
            emailDefault,
            sheetDefault,
            typeDefault,
            personDefault,
            nameDefault,
            imgDefault,
            sheetIdDefault
        ]);
    return (
        <div className="modal_user_content">
            <figure className='modal_user_imagen'>
                <img src={`http://217.15.168.117:8080/api/Whatever/${formData.img}`} alt={`${formData.idDefault}`} />
                <div></div>
            </figure>
            <section className="modal_user_name">
                <h4>{formData.username}</h4>
                <p>{formData.type}</p>
            </section>
            <section className='modal_icons_actions'>
                <div className='modal_icon_action'>
                    <IconSVG name="Icon_permisos_talentic" />
                    <span>Permisos</span>
                </div>
                <div className='modal_icon_action'>
                    <IconSVG name="Icon_editar_talentic_2" />
                    <span>Editar</span>
                </div>
                <div className='modal_icon_action menu_acciones_eliminar'>
                    <IconSVG name="Icon_eliminar_talentic_2" />
                    <span>Eliminar</span>
                </div>
            </section>
            <section className='modal_all_info'>
                <div className='modal_info_user_item'>
                    <figure>
                        <IconSVG name="Icon_usuario_talentic" />
                    </figure>
                    <div>
                        <h5>Nombre</h5>
                        <p>{formData.name} {formData.lastname}</p>
                    </div>
                </div>
                <div className='modal_info_user_item'>
                    <figure>
                        <IconSVG name="Icon_correo_talentic_2" />
                    </figure>
                    <div>
                        <h5>Correo</h5>
                        <p>{formData.username}</p>
                    </div>
                </div>
                <div className='modal_info_user_item'>
                    <figure>
                        <IconSVG name="Icon_programas_talentic" />
                    </figure>
                    <div>
                        <h5>Ficha de formación</h5>
                        <p>{formData.sheet}</p>
                    </div>
                </div>
                <div className='modal_info_user_item'>
                    <figure>
                        <IconSVG name="Icon_roles_talentic" />
                    </figure>
                    <div>
                        <h5>Rol</h5>
                        <p>{formData.type}</p>
                    </div>
                </div>
            </section>
        </div>

    );
};

export default ShowCenters;
