import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CheckBoxClassic from '../components/checkboxClassic';
import UpdateNavigatePosition from '../forms/updateUpdateNavigatePosition';
import Navigate from '../contents/navigate/navigate';

const NavMenu = ( ) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [apiData, setApiData] = useState([]);
    const [formData, setFormData] = useState({ components: [] });
    const [isEditing, setIsEditing] = useState(false);
    const [editData, setEditData] = useState({ id: '', name: '', position: '' });
    const [key, setKey] = useState(0);

    const hashToEditar = "f10240c319397220413dd42a055ed226";
    
    const [formDatauser] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        return {
            permisos: user?.permissions || ''
        };
    });

    useEffect(() => {
        fetchData();
    }, [key]);

    const fetchData = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "TYPE": "navmenu",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            };

            const response = await axios.post('http://217.15.168.117:8080/api/', requestBody);
            const cleanedData = response.data.map(item => ({
                id: parseInt(item.id, 10),
                name: item.name,
                code: item.code,
                icon: item.icon,
                position: item.position,
                state: item.state
            }));
            const componentesIniciales = cleanedData
                .filter(item => item.state === 0)
                .map(item => item.id);

            setFormData({ components: componentesIniciales });
            setApiData(cleanedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleCheckboxChange = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const components = checked
                ? [...prevState.components, id]
                : prevState.components.filter(componentId => componentId !== id);

            return { ...prevState, components };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "navmenu",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "DELETE",
                "components": formData.components,
            };

            await axios.post('http://217.15.168.117:8080/api/', requestBody);
            fetchData();
            setKey(prevKey => prevKey + 1); 
        } catch (error) {
            console.error('Error updating permissions:', error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = apiData.filter(item =>
        (item.name || '').toLowerCase().includes(searchTerm) ||
        (item.code || '').toLowerCase().includes(searchTerm)
    );

    const handleEditClick = (id, name, position) => {
        setEditData({ id, name, position });
        setIsEditing(true);
    };
    const handleChangeeditar = (newState) => {
        setIsEditing(false);
        fetchData();
    };

    return (
        <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Modulo de administración de menús de navegación</h4>

                <div className="row">
                    <div className="col-xl">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Menu Navegación</h5>
                                <small className="text-muted float-end">Formulario de activación de menús</small>
                            </div>
                            <div className="card-body col-12">
                                <Navigate data={apiData} key={key} />
                            </div>
                            <div className="d-flex">
                                
                                <div className="card-body col-12 col-lg-6">
                                    <form id="formModel" className="row" onSubmit={handleSubmit}>
                                        <div className="col-12 p-4 ">
                                            <h5>Métricas disponibles</h5>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    id="searchDos"
                                                    className="form-control"
                                                    placeholder="Buscar Instructor"
                                                    aria-label="Buscar Instructor"
                                                    aria-describedby="button-addon2"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>

                                            {formDatauser.permisos.includes(hashToEditar) ? (
                                                <div className="aprendices-list">
                                                    {filteredData.length > 0 ? filteredData.map((item) => (
                                                        <div 
                                                            className="col-12"
                                                            key={item.id}
                                                            onClick={(e) => {
                                                                handleEditClick(item.id, item.name, item.position); 
                                                            }}
                                                        >
                                                            <CheckBoxClassic
                                                                name="components"
                                                                value={item.id}
                                                                icon={item.icon}
                                                                userName={item.name}
                                                                description={`componente ${item.code} - posición ${item.position}`}
                                                                checked={item.state === 0}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                    )) : (
                                                        <div id="no-results-dos">
                                                            Métrica no encontrada
                                                        </div>
                                                    )}
                                                </div>
                                            ): null }
                                        </div>

                                        <div className="mb-3">
                                            <Link to="/dashboard/roles" className="btn btn-danger">
                                                Cancelar
                                            </Link>
                                            <button type="submit" className="btn btn-primary">
                                                Actualizar métricas 
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                
                                <div className="card-body col-12 col-lg-6">
                                    {isEditing ? (
                                        <UpdateNavigatePosition onUPSubmitState={handleChangeeditar} idDefault={editData.id} nameDefault={editData.name} positionDefault={editData.position}/>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavMenu;
