import React, { forwardRef } from 'react';

// Importa un ícono SVG desde el directorio de iconos
import IconSVG from './../contents/icons/icon';

// Componente OnlyFile que maneja la carga de archivos con funcionalidad de arrastrar y soltar (drag & drop)
const OnlyFile = forwardRef(({
    handleDragOver, // Función para manejar el evento de "drag over" (cuando un archivo está siendo arrastrado sobre el área)
    onDragLeave,    // Función para manejar el evento cuando el archivo deja el área de arrastre
    onDrop,         // Función para manejar el evento cuando un archivo es soltado en el área
    dragActive,     // Estado que indica si la zona de arrastre está activa (cuando un archivo está sobre ella)
    imageUrl,       // URL de la imagen seleccionada para previsualización
    handleFileChange, // Función que maneja el cambio de archivo cuando un archivo es seleccionado
    name            // Nombre del input (para usar con el ref)
}, ref) => {
    return (
        <div
          // Maneja los eventos de arrastrar y soltar
          onDragOver={handleDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          // Aplica clases dinámicas dependiendo si la zona está activa o no
          className={`col-12 d-flex justify-content-center align-items-center drag-drop-zone ${dragActive ? 'active' : ''}`}
          style={{
            // Estilo de la zona de arrastre
            border: '1px dashed rgb(204, 204, 204)',
            borderRadius: '.5rem',
            padding: '0px',
            overflow: 'hidden',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: dragActive ? '#e3f2fd' : '#fff', // Cambia el color de fondo si la zona está activa
            margin: '1rem',
            position: 'relative',
          }}
          onClick={() => ref.current.click()} // Permite que el área de arrastre también sea un área clickeable
        >
          {/* Si hay una imagen seleccionada, muestra una vista previa de la imagen */}
          {imageUrl ? (
            <img
              src={imageUrl} // La URL de la imagen seleccionada
              alt="Preview"  // Texto alternativo de la imagen
              style={{
                maxWidth: '100%',
                maxHeight: '200px',
                objectFit: 'contain', // Ajusta la imagen para que encaje dentro del contenedor
              }}
            />
          ) : (
            // Si no hay una imagen, muestra un ícono y un texto indicativo
            <>
              <IconSVG name="Icon_subir_multimedia_talentic" width="50px" />
              <p className='text-primary' style={{ marginBottom: '0', marginLeft: '1rem'}}>Selecciona multimedia</p>
            </>
          )}
          {/* Input tipo file oculto para seleccionar archivos */}
          <input
            type="file"
            onChange={handleFileChange} // Maneja el cambio de archivo
            style={{ display: 'none' }}   // Oculta el input
            id={name}                     // Asocia un id con el input
            ref={ref}                     // Asocia un ref para hacer click programáticamente
          />
        </div>
    );
});

// Exporta el componente OnlyFile
export default OnlyFile;
