import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import BtnSubmitDashboard from '../../components/btnSubmitDashboard';
import InputDashboard from '../../components/inputDashboard';

const CreateCenters = ({ onSubmitState }) => {
    const [formData, setFormData] = useState({
        name: '',
        cod: '',
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        const { name, cod } = formData;

        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "centers",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "PUT",
            "name": name,
            "cod": cod
        };

        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody);
            setFormData({ name: '', cod: '' });
            onSubmitState(true);
        } catch (error) {
            toast.error('Hubo un error al crear el tipo de usuario. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className="modal_user_content">
            <form className='modal_form' method="POST" onSubmit={handleCreate}>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="name" 
                        label="Nombre"
                        placeholder="nombre" 
                        value={formData.name}
                        onChange={handleChange}
                        colClassName=""
                    />
                    
                </div>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="cod" 
                        label="Abreviación"
                        placeholder="Siglas o Abreviación" 
                        value={formData.cod}
                        onChange={handleChange}
                        colClassName=""
                    />
                </div>
                
                <BtnSubmitDashboard text="Guardar"/>
            </form>
        </div>
    );
};

export default CreateCenters;
