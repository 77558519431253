import React from 'react';

// Componente para truncar un texto si excede un límite de longitud
const TruncatedText = ({ text, maxLength, classn = '' }) => {

    // Función para truncar el texto a una longitud máxima y agregar "..." al final si es necesario
    const truncateString = (str, maxLength) => {
        // Si el texto es más largo que la longitud máxima, se trunca y se agrega "..."
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + '..';
        }
        // Si el texto es más corto o igual que la longitud máxima, se devuelve tal cual
        return str;
    };

    return (
        // Renderiza el texto truncado con una clase adicional si se proporciona
        <p className={`${classn} mb-1`}>{truncateString(text, maxLength)}</p>
    );
};

export default TruncatedText;
