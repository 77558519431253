import React from 'react';
import Tabs from './../components/tabs';

const UserProfile = ( ) => {
  return (
    <div className='p-0 m-0 p-sm-3 m-sm-3'>
        <Tabs />
    </div>
  );
};

export default UserProfile;
