import React, { useState } from 'react';
import axios from 'axios';

import BtnSubmit from '../../components/btnSubmit';
import Textarea from '../../components/textarea';
import Input from '../../components/input';

const CreateTypeUsers = ({ onSubmitState }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        const { name, description } = formData;

        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "typeusers",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "PUT",
            "name": name,
            "description": description
        };

        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody);
            setFormData({ name: '', description: '' });
            onSubmitState(true);
        } catch (error) {
            console.error('Error creating data:', error);
            setError('Hubo un error al crear el tipo de usuario. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className="row">
            <div className="col-xl">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Nuevo Tipo de Usuario</h5>
                        <small className="text-muted float-end">Formulario de creación</small>
                    </div>
                    <div className="card-body">
                        <form className="row" method="POST" onSubmit={handleCreate}>
                            <Input 
                                name="name" 
                                label="Nombre"
                                placeholder="nombre" 
                                value={formData.name}
                                onChange={handleChange}
                                colClassName=""
                            />
                            <Textarea 
                                name="description" 
                                label="Descripción"
                                placeholder="descripción" 
                                value={formData.description}
                                onChange={handleChange}
                                colClassName=""
                            />
                            <BtnSubmit />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateTypeUsers;
