import React from 'react';

// Componente Icon que recibe dos propiedades:
// - 'code': el código del ícono que se desea renderizar (probablemente HTML o SVG).
// - 'classr': la clase CSS que se aplicará al ícono (por defecto es "icons").
const Icon = ({ code, classr = "icons" }) => {
    return (
        // Renderiza el ícono usando 'dangerouslySetInnerHTML' para inyectar el HTML/SVG de forma segura.
        // Esto es útil cuando el ícono se pasa como código HTML o SVG en bruto.
        <p className={classr} dangerouslySetInnerHTML={{ __html: code }} />
    );
};

export default Icon;
