import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import CryptoJS from 'crypto-js';
import axios from 'axios';

import { useAuth } from './../components/AuthContext';

import BtnSubmit from './../components/btnSubmit';
import Input from './../components/input';

function FormA() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
      e.preventDefault();

      const requestBody = {
          "API": "talentic",
          "MODEL": "talentic",
          "RESOURCE": "users",
          "REQUEST": "auth",
          "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
          "TYPE": "GET",
          "username": email,
          "password": CryptoJS.MD5(password).toString()
      };

      try {
          const response = await axios.post('http://217.15.168.117:8080/api/', requestBody, {
              headers: {
                'Content-Type': 'application/json'
              }
          });

          if (response.status === 200) {
              if (
                response.data === null ||
                response.data === undefined ||
                (typeof response.data === 'object' && Object.keys(response.data).length === 0) ||
                (Array.isArray(response.data) && response.data.length === 0) ||
                (typeof response.data === 'string' && response.data.trim() === '')
              ) {
                alert('Login failed. Please check your credentials and try again.');
              } else {

                try {

                  const mainRequestBody = {
                    "API": "talentic",
                    "MODEL": "talentic",
                    "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                  };
                  const [responseDataUser, responseDataPermission] = await Promise.all([
                      axios.post('http://217.15.168.117:8080/api/', {
                        ...mainRequestBody,
                        "RESOURCE": "people",
                        "TYPE": "GETID",
                        "id": response.data[0].person
                      }),
                      axios.post('http://217.15.168.117:8080/api/', {
                        ...mainRequestBody,
                        "RESOURCE": "list",
                        "TYPE": "userPermissions",
                        "id": response.data[0].id
                      })
                  ]);

                  const userData = { 
                    index_user: response.data[0].id,
                    person: response.data[0].person,
                    name: responseDataUser.data[0].name,
                    lastName: responseDataUser.data[0].lastName,
                    dni: responseDataUser.data[0].dni,
                    email: responseDataUser.data[0].email,
                    notifyMessages: responseDataUser.data[0].notifyMessages,
                    notifyChangePass: responseDataUser.data[0].notifyChangePass,
                    img: responseDataUser.data[0].img,
                    permissions: responseDataPermission?.data && Array.isArray(responseDataPermission.data) ? responseDataPermission.data.map(item => item.crypter) : []
                  };

                  login(userData);
                  navigate('/dashboard');

                } catch (error) {
                  console.error('Error occurred:', error);
                }
              }
          }
      } catch (error) {
          console.error('Error occurred:', error);
          if (error.response) {
            console.error('Request failed:', error.response.data.message);
          } else if (error.request) {
            console.error('No response received:', error.request);
          } else {
            console.error('Error setting up request:', error.message);
          }
      }
    };

  return (
    <>
      {/* Logo */}
      <div className="app-brand justify-content-center">
        <a href="https://www.talentic.com.co" className="app-brand-link gap-2">
          <span className="app-brand-logo">
            <img
              src={`http://217.15.168.117:8080/api/Whatever/logo.png`}
              width="150"
              alt="Talentic Logo"
            />
          </span>
        </a>
      </div>
      {/* /Logo */}
      <h4 className="mb-2 ps-3">Bienvenido a Talentic!</h4>
      <p className="mb-4 ps-3">Inicia sesión con tus datos de cuenta</p>

      <form className="row" method="POST" onSubmit={handleSubmit}>
        <Input 
          name="email" 
          label="Correo electrónico"
          placeholder="Ingresa tu correo electrónico" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          colClassName=""
        />
        <Input 
          name="email" 
          type="password"
          label="Contraseña"
          placeholder="Ingresa tu contraseña" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          colClassName=""
        />
        <BtnSubmit text='Ingresar'/>
      </form>
    </>
  );
}

export default FormA;
