import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import SelectDashboard from '../../components/selectDashboard';
import BtnSubmitDashboard from '../../components/btnSubmitDashboard';
import InputDashboard from '../../components/inputDashboard';
import TextareaDashboard from '../../components/textareaDashboard';

const CreateRoles = ({ onSubmitState }) => {
    const [formData, setFormData] = useState({
        name: '',
        center: '',
        programme: '',
        description: ''
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        const { name, center, programme, description } = formData;
        
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "sheet",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "PUT",
            "name": name,
            "center": center,
            "programme": programme,
            "descirption": description
        };

        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody);
            setFormData({ name: '', center: '', programme: '', description: '' });
            onSubmitState(true);
        } catch (error) {
            toast.error('Hubo un error al crear el tipo de usuario. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className="modal_user_content">
            <form className='modal_form' method="POST" onSubmit={handleCreate}>
                <div className='modal_form_item'>
                    <SelectDashboard
                        label="Centro de formación"
                        name="center"
                        apiEndpoint="http://217.15.168.117:8080/api/"
                        requestBody={{
                            "API": "talentic",
                            "MODEL": "talentic",
                            "RESOURCE": "select",
                            "TYPE": "centers",
                            "key": "5b8d3b1f084b01c6a8387459e80d4bb9"
                        }}
                        onSelectChange={handleChange}
                        defaultOption="Selecciona un centro"
                        className=""
                        value={formData.center}
                        icon="Icon_centros_talentic"
                    />
                </div>
                <div className='modal_form_item'>
                    <SelectDashboard
                        label="Programa de formación"
                        name="programme"
                        apiEndpoint="http://217.15.168.117:8080/api/"
                        requestBody={{
                            "API": "talentic",
                            "MODEL": "talentic",
                            "RESOURCE": "select",
                            "TYPE": "programme",
                            "key": "5b8d3b1f084b01c6a8387459e80d4bb9"
                        }}
                        onSelectChange={handleChange}
                        defaultOption="Selecciona un programa"
                        className=""
                        value={formData.programme}
                        icon="Icon_programas_talentic"
                    />
                </div>
            
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="name" 
                        label="Numero de ficha"
                        placeholder="Numero de ficha" 
                        value={formData.name}
                        onChange={handleChange}
                        colClassName=""
                    />
                </div>
                <div className='modal_form_item'>
                    <TextareaDashboard
                        name="description" 
                        label="Descripción"
                        placeholder="Descripción" 
                        value={formData.description}
                        onChange={handleChange}
                        colClassName=""
                    />
                </div>
                <BtnSubmitDashboard text="Guardar"/>
            </form>
        </div>
    );
};

export default CreateRoles;
