import React, { useEffect, useState, useRef } from 'react'; // Importación de React, useEffect, useState y useRef.
import { useParams, useNavigate } from 'react-router-dom'; // Importación de hooks de React Router para gestionar la URL y navegación.
import { toast } from 'react-toastify'; // Importación de la librería para mostrar notificaciones.
import CryptoJS from 'crypto-js'; // Importación de la librería de cifrado para encriptar la contraseña.
import axios from 'axios'; // Importación de Axios para realizar solicitudes HTTP.

import { useAuth } from '../components/AuthContext'; // Importación del contexto de autenticación.

import BtnSubmit from '../components/btnSubmit'; // Importación del componente de botón para enviar el formulario.
import Input from '../components/input'; // Importación del componente de input reutilizable.

function ValidateUser() {
    const navigate = useNavigate(); // Hook para la navegación programática.
    const { login } = useAuth(); // Desestructuración para obtener la función de login del contexto de autenticación.
    const { token } = useParams(); // Recuperación del parámetro 'token' desde la URL.
    const isFirstRun = useRef(true); // Uso de useRef para manejar si el efecto se ejecuta por primera vez.
    const [apiData, setApiData] = useState([]); // Estado para almacenar los datos obtenidos de la API.
    const [formData, setFormData] = useState({ password: '' }); // Estado para almacenar la contraseña ingresada por el usuario.

    useEffect(() => {
        if (isFirstRun.current && token) {
            // Si es la primera ejecución y hay un token, realiza la solicitud API.
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "subscriptions",
                "TYPE": "GET",
                "list": "token",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "token": token
            };

            axios.post('http://217.15.168.117:8080/api/', requestBody, { // Solicitud POST a la API.
                headers: { 'Content-Type': 'application/json' },
            })
            .then(response => {
                console.log('Respuesta del servidor:', response.data); // Log de la respuesta.
                isFirstRun.current = false; // Marca que ya se ha ejecutado.
                const cleanedData = response.data
                    .filter(item => item.state === 0) // Filtra los datos por el estado 0.
                    .map(item => ({
                        id: item.id,
                        email: item.email
                    }));
                setApiData(cleanedData); // Establece los datos en el estado.
            })
            .catch(error => {
                console.log('Error en la solicitud:', error); // Manejo de errores.
            });
        }

        return () => {
            isFirstRun.current = false; // Marca como ejecutado después del desmontaje del componente.
        };
    }, [token]); // Este useEffect se ejecuta cuando el token cambia.

    const handleChange = (e) => {
        const { name, value } = e.target; // Desestructuración del nombre y valor del input.
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCreate = async (e) => {
        e.preventDefault(); // Previene el comportamiento predeterminado del formulario.
        const { password } = formData; // Desestructuración de la contraseña desde el estado.

        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "users",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "PUT",
            "username": apiData[0].email, // Establece el email como nombre de usuario.
            "email": apiData[0].email, // Utiliza el correo como email.
            "password": CryptoJS.MD5(password).toString(), // Encripta la contraseña con MD5.
            "type": "4"
        };

        try {
            const responseApi = await axios.post('http://217.15.168.117:8080/api/', requestBody); // Solicitud API para actualizar el usuario.
            setFormData({ password: '' }); // Limpia la contraseña del estado.
            const requestBodyUser = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "users",
                "REQUEST": "auth",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "GET",
                "username": apiData[0].email,
                "password": CryptoJS.MD5(password).toString()
            };

            try {
                const response = await axios.post('http://217.15.168.117:8080/api/', requestBodyUser, {
                    headers: { 'Content-Type': 'application/json' }
                });

                if (response.status === 200) {
                    if (
                        response.data === null ||
                        response.data === undefined ||
                        (typeof response.data === 'object' && Object.keys(response.data).length === 0) ||
                        (Array.isArray(response.data) && response.data.length === 0) ||
                        (typeof response.data === 'string' && response.data.trim() === '')
                    ) {
                        alert('Login failed. Please check your credentials and try again.');
                    } else {

                        try {
                            const mainRequestBody = {
                                "API": "talentic",
                                "MODEL": "talentic",
                                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                            };
                            const [responseDataUser, responseDataPermission] = await Promise.all([
                                axios.post('http://217.15.168.117:8080/api/', {
                                    ...mainRequestBody,
                                    "RESOURCE": "people",
                                    "TYPE": "GETID",
                                    "id": response.data[0].person
                                }),
                                axios.post('http://217.15.168.117:8080/api/', {
                                    ...mainRequestBody,
                                    "RESOURCE": "list",
                                    "TYPE": "userPermissions",
                                    "id": response.data[0].id
                                })
                            ]);

                            const userData = {
                                index_user: response.data[0].id,
                                person: response.data[0].person,
                                name: responseDataUser.data[0].name,
                                lastName: responseDataUser.data[0].lastName,
                                dni: responseDataUser.data[0].dni,
                                email: responseDataUser.data[0].email,
                                notifyMessages: responseDataUser.data[0].notifyMessages,
                                notifyChangePass: responseDataUser.data[0].notifyChangePass,
                                img: responseDataUser.data[0].img,
                                permissions: responseDataPermission.data.map(item => item.crypter)
                            };
                            login(userData); // Llama a la función de login con los datos del usuario.
                            navigate('/dashboard'); // Redirige al dashboard.
                            toast.success('Bienvenido, puedes actualizar tus datos aquí'); // Muestra una notificación de éxito.

                        } catch (error) {
                            toast.error('Hubo un error al crear el tipo de usuario. Por favor, inténtelo de nuevo.'); // Error al obtener datos del usuario.
                        }
                    }
                }
            } catch (error) {
                toast.error('Hubo un error al crear el tipo de usuario. Por favor, inténtelo de nuevo.'); // Error al autenticar al usuario.
            }
        } catch (error) {
            toast.error('Hubo un error al crear el tipo de usuario. Por favor, inténtelo de nuevo.'); // Error en la solicitud para crear el tipo de usuario.
        }
    };

    return (
        <div className="container-lg d-flex flex-column justify-content-center align-items-center">
            <h2 className="text-start pt-5" style={{ fontSize: '1.7rem', margin: '0' }} >Validación de usuarios</h2>
            <div className='d-flex flex-wrap'>
                <div>
                    {apiData.length === 0 ? (
                        <h1 style={{ color: 'crimson' }}>{"Token inválido"}</h1> // Muestra mensaje de token inválido si no hay datos.
                    ) : (
                        <>
                            <form className="mb-3 row" method="POST" onSubmit={handleCreate}>
                                <Input // Componente reutilizable para el campo de entrada de la contraseña.
                                    name="password"
                                    type='password'
                                    label="Nueva Contraseña"
                                    placeholder="Contraseña"
                                    value={formData.password}
                                    onChange={handleChange}
                                    colClassName=""
                                />
                                <BtnSubmit /> // Componente de botón para enviar el formulario.
                            </form>
                            <h4>Tu usuario es tu mismo correo, para ingresar solo debes poner una nueva contraseña</h4> // Instrucción para el usuario.
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ValidateUser; // Exportación del componente.
