import React, { useState, useEffect, useRef } from 'react';  // Se importan las librerías necesarias, incluyendo React y Hooks.
import { formatDistanceToNow, parseISO } from 'date-fns'; // Se importan funciones para manejar fechas.
import { Dropdown } from 'react-bootstrap';  // Importa el componente Dropdown de react-bootstrap para los menús desplegables.
import { Link } from 'react-router-dom';  // Importa el componente Link para la navegación entre rutas.
import es from 'date-fns/locale/es';  // Importa la configuración de idioma español para date-fns.
import axios from 'axios';  // Importa axios para realizar solicitudes HTTP.

import IconSVG from '../contents/icons/icon';  // Importa el componente IconSVG para mostrar iconos SVG.
import CutText from '../components/cutString';  // Importa el componente CutText para cortar textos largos.

const FechaRelativa = ({ fecha }) => {  // Componente para mostrar la fecha en formato relativo.
    const fechaISO = parseISO(fecha);  // Convierte la fecha en formato ISO.

    return (
        <div>
            {`Hace ${formatDistanceToNow(fechaISO, { addSuffix: true, locale: es })}`}  // Muestra la fecha relativa en español.
        </div>
    );
};

const Menssages = () => {  // Componente principal que maneja y muestra los mensajes.
    const [mensajes, setmensajes] = useState([]);  // Estado para almacenar los mensajes.
    const [formData] = useState(() => {  // Estado para obtener el ID del usuario desde el localStorage.
        const user = JSON.parse(localStorage.getItem('user'));  // Obtiene el objeto del usuario desde el almacenamiento local.
    
        return {
            user: user?.index_user || ''  // Devuelve el ID del usuario o una cadena vacía si no está disponible.
        };
    });

    useEffect(() => {  // Hook que se ejecuta cuando el componente se monta o cuando cambia el usuario.
        if (formData.user && mensajes.length === 0) {  // Si el usuario está disponible y no hay mensajes.
            axios.post('http://217.15.168.117:8080/api/', {  // Realiza una solicitud HTTP POST a la API para obtener los mensajes del usuario.
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "TYPE": "userMensages",
                "user": formData.user,
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",  // Clave de acceso.
            })
            .then(response => {  // Si la solicitud tiene éxito.
                if (Array.isArray(response.data)) {  // Si la respuesta es un arreglo.
                    setmensajes(response.data);  // Actualiza el estado con los mensajes.
                }
            })
            .catch(error => {  // Si ocurre un error en la solicitud.
                // Maneja el error aquí (aunque está vacío en este caso).
            });
        }
    }, [formData.user, mensajes.length]);  // Dependencia de usuario y la longitud de los mensajes para volver a ejecutar el efecto.

    return (
        <Dropdown>  {/* El componente Dropdown para mostrar el menú de mensajes.*/}
            <Dropdown.Toggle className='drop_menu_default'>  {/* Botón para abrir el menú.*/}
                <IconSVG name="Icon_correo_talentic_2" />  {/* Icono para el botón de mensajes.*/}
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" style={{ maxWidth: '250px'}} >  {/* Menú desplegable con un máximo de ancho de 250px.*/}
                <Dropdown.ItemText>
                    <div>
                        <h5 style={{ marginBottom: '20px' }} >Sus mensajes</h5>  {/* Título del menú de mensajes.*/}
                    </div>
                    <div className={`buscartres usuario__encontrado`}>
                        {(mensajes.length > 0) ? (  // Si hay mensajes, los muestra.
                            mensajes.map(item => (  // Itera sobre los mensajes.
                                <div className="d-flex align-items-center my-1 border-bottom pb-1">
                                    <div>
                                        <img
                                            src={`http://217.15.168.117:8080/api/Whatever/e5e483c6128e2d812739d384e3c2683a.png`}  // Imagen predeterminada para cada mensaje.
                                            className="m-0 notifications display-5"
                                        />
                                    </div>
                                    <div className="ms-2">
                                        <h6 className="mb-0" style={{ fontSize: '12px'}} >
                                            <CutText classn="" text={item.name} maxLength={70}/>  {/* Muestra el nombre del mensaje, cortado a 70 caracteres si es necesario.*/}
                                        </h6>
                                        <small className="text-muted">
                                            <FechaRelativa fecha={item.date} />  {/* Muestra la fecha relativa del mensaje.*/}
                                        </small>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <>  {/* Si no hay mensajes, muestra un mensaje indicando que no hay mensajes.*/}
                                <h5 style={{ marginBottom: '20px' }} >Sin Mensajes</h5>
                            </>
                        )}
                    </div>
                </Dropdown.ItemText>
                
                <Dropdown.Item style={{ padding: '.3rem 1rem' }}>  {/* Enlace para ver todos los mensajes.*/}
                    <Link 
                        to="/dashboard"  // Redirige a la página del dashboard.
                        state={{ 
                            tab: 'messages'  // Pasa un estado que indica que se deben mostrar los mensajes.
                        }}
                        style={{ width: 'inherit' }}
                        className="d-flex col-12 justify-content-center btn btn-outline-info py-1"
                    >
                        <p
                            style={{ margin: '0px' }}
                            className='align-content-center'
                        >
                            Ver todos  {/* Texto que aparece en el botón.*/}
                        </p>
                        <IconSVG name="Icon_flecha_derecha_talentic" width="11px" style={{ marginLeft: '10px', cursor: 'pointer'}} />  {/* Icono de flecha para el botón.*/}
                    </Link>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default Menssages;  // Exporta el componente Menssages.
