import React from 'react'; // Importa React para utilizar JSX
import './../css/modal.css'; // Importa el archivo CSS para los estilos del modal

// Define el componente MultimediaModal como una función
function MultimediaModal({ isOpen, onClose, children }) {
  // Si el modal no está abierto, no renderiza nada (regresa null)
  if (!isOpen) return null;

  // Si el modal está abierto, renderiza el modal
  return (
    <div className="modal-overlay" onClick={onClose}> {/* La capa de fondo del modal que cierra el modal cuando se hace clic fuera */}
        <div 
            className="multimedia-modal-content d-flex justify-content-center" // Contenedor del contenido del modal, con clases para diseño (flex y justificación)
            onClick={e => e.stopPropagation()} // Impide que el clic en el contenido del modal cierre el modal
            style={{ width: '-webkit-fill-available', marginLeft: '15px', marginRight: '15px'}} // Estilos en línea, ajusta el ancho y margenes del modal
        >
        <button className="modal-close" onClick={onClose}>×</button> {/* Botón de cierre del modal */}
        {children} {/* Los elementos hijos (contenido) del modal que se pasan como prop */}
      </div>
    </div>
  );
}

export default MultimediaModal; // Exporta el componente para usarlo en otras partes de la aplicación
