import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import ShowCenters from './show';
import CreateCenters from './create';
import UpdateCenters from './update';

import Icon from '../../components/icons';
import Modal from '../../components/modalDashboard';
import IconSVG from '../../contents/icons/icon';
import SeacrhData from '../../components/searchData';

import ViewToggle from '../../components/viewToggle';
import SortFilter from '../../components/sortFilter';

import ListView from '../../components/listView';
import CardView from '../../components/cardView';

const Centers = ( ) => {
    const [apiData, setApiData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    
    const [roleFilter, setRoleFilter] = useState('Todos'); // Estado para el filtro de rol
    const [activeMenuActions, setActiveMenuActions] = useState(null);
    const menuRef = useRef(null);
    const [viewType, setViewType] = useState('cards'); // 'cards' para tarjetas y 'list' para lista
    const [sortConfig, setSortConfig] = useState({ key: 'dateCreation', direction: 'desc' });

    const hashToVer = "1f84054ad4c62f3985b0d54867b787d1";
    const hashToCrear = "f5ba551d8b0a05541e1ede6ff80772ff";
    const hashToEditar = "196a64cdbed0029959e97d6d5f207bd5";
    const hashToEliminar = "45cd06c8265d491f331a84d099a1bb04";

    const [formDatauser] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        return {
            permisos: user?.permissions || ''
        };
    });

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = () => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "list",
            "TYPE": "centers",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                const cleanedData = response.data.map(item => ({
                    id: item.id,
                    name: item.name,
                    dateCreation: item.dateCreation,
                    cod: item.cod
                }));
                setApiData(cleanedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    const handleChangecrear = (newState) => {
        toast.success("Se gestionaron los datos con éxito");
        setIsModalOpen(false);
        setModalContent(null);
        fetchData();
    };

    const handleChangeeditar = (newState) => {
        toast.success("Se editaron los datos con éxito");
        setIsModalOpen(false);
        fetchData();
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = apiData.filter(item =>
        (item.name || '').toLowerCase().includes(searchTerm) ||
        (item.cod || '').toLowerCase().includes(searchTerm)
    );

    const handleDelete = (itemId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este registro?')) {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "centers",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "DELETE",
                "id": itemId
            };

            axios.post('http://217.15.168.117:8080/api/', requestBody)
                .then(response => {
                    toast.success("Se eliminaron los datos con éxito");
                    fetchData();
                })
                .catch(error => {
                    console.error('Error deleting data:', error);
                });
        }
    };

    const handleCreateClick = (FormComponent) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent onSubmitState={handleChangecrear}/>);
    };

    const handleEditClick = (FormComponent, id, name, cod) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent onUPSubmitState={handleChangeeditar} idDefault={id} nameDefault={name} codDefault={cod}/>);
    };
    
    const handleShowClick = (FormComponent, name, cod, dateCreation) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent onUPSubmitState={handleChangeeditar} nameDefault={name} codDefault={cod} dateCreationDefault={dateCreation} />);
    };

    // Alterna entre los tipos de visualización
    const showCards = () => setViewType('cards');
    const showList = () => setViewType('list');

    useEffect(() => {
        // Detectar clics fuera del menú
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveMenuActions(null);
            }
        };

        // Añadir evento al hacer clic en el documento
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Limpiar el evento al desmontar el componente
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSort = (value) => {
        let key, direction;
    
        if (value === 'fechaDesc') {
            key = 'dateCreation';
            direction = 'desc';
        } else if (value === 'fechaAsc') {
            key = 'dateCreation';
            direction = 'asc';
        } else if (value === 'nombreAsc') {
            key = 'username';
            direction = 'asc';
        } else if (value === 'nombreDesc') {
            key = 'username';
            direction = 'desc';
        } else if (value === 'emailAsc') {
            key = 'email';
            direction = 'asc';
        } else if (value === 'emailDesc') {
            key = 'email';
            direction = 'desc';
        } else if (value === 'tipoAsc') {
            key = 'type';
            direction = 'asc';
        } else if (value === 'tipoDesc') {
            key = 'type';
            direction = 'desc';
        }
    
        setSortConfig({ key, direction });
    };

    const toggleMenuActions = (menuName) => {
        setActiveMenuActions(activeMenuActions === menuName ? null : menuName);
    };

    // Función que define el menú de acciones específico para cada usuario
    const actionMenuProps = (item) => ({
        isActive: activeMenuActions === item.id,
        onClose: () => setActiveMenuActions(null),
        actions: [
            formDatauser.permisos.includes(hashToVer) && {
                label: 'Ver',
                icon: 'Icon_ver_talentic_2',
                onClick: () => handleShowClick(ShowCenters, item.name, item.cod, item.dateCreation),
            },
            formDatauser.permisos.includes(hashToEditar) && {
                label: 'Editar',
                icon: 'Icon_editar_talentic_2',
                onClick: () => handleEditClick(UpdateCenters, item.id, item.name, item.cod),
                className: 'menu_acciones_editar'
            },
            formDatauser.permisos.includes(hashToEliminar) && {
                label: 'Eliminar',
                icon: 'Icon_eliminar_talentic_2',
                onClick: () => handleDelete(item.id, item.state), // Llamar a la función de eliminación,
                className: 'menu_acciones_eliminar'
            }
        ].filter(Boolean)
    });

    return (
        <div className='dashboard_content'>
            <main className='main__dashboard_content'>
                <div className='form_content'>
                    <section className='dashboard_titulo'>
                        <h2>Centros de formación</h2>
                    </section>
                    <div className='main__dashboard_primera_parte'>
                        <div className='btn_nuevo_dashboard' onClick={() => handleCreateClick(CreateCenters)}>
                            <IconSVG name="Icon_mas_talentic"/> Crear Nuevo
                        </div>
                    </div>
                    <div className='form_content_dashboard'>
                        <div className='filters_dashboard'>
                            <div className='buscador_dashboard'>
                                <SeacrhData
                                    searchTerm={searchTerm}
                                    handleSearchChange={handleSearchChange}
                                />
                            </div>
                            <div className='filtros_content_dashboard'>
                                <SortFilter sortConfig={sortConfig} onSortChange={handleSort}/>
                                <ViewToggle viewType={viewType} showCards={showCards} showList={showList} />
                            </div>
                        </div>
                        {/* Vista en formato de tarjetas */}
                        {viewType === 'cards' && (
                            <div className='layout_cards'>
                                {filteredData.length > 0 ? (
                                filteredData.map((item) => (
                                    <CardView
                                        key={item.id}
                                        titulo={item.cod}
                                        subtitulo={item.name}
                                        parrafo={item.dateCreation}
                                        actionMenuProps={actionMenuProps}
                                        item={item}
                                        toggleMenuActions={toggleMenuActions}
                                        ref={menuRef}
                                        svg="Icon_centros_talentic"
                                    />
                                ))
                            ) : (
                                <p>No se encontraron registros</p>
                            )}
                            </div>
                        )}

                        {/* Vista en formato de lista */}
                        {viewType === 'list' && (
                            <div className='layout_list_dashboard'>
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('nombreAsc')}><h4>Programa<IconSVG name="Icon_flechas_talentic"/></h4></th>
                                        <th><h4>Siglas o Abreviación</h4></th>
                                        <th><h4>Fecha</h4></th>
                                        <th><h4>Acciones</h4></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length > 0 ? filteredData.map((item) => (
                                        <ListView
                                            key={item.id}
                                            titulo={item.name}
                                            subtitulo={item.cod}
                                            parrafo={item.dateCreation}
                                            actionMenuProps={actionMenuProps}
                                            item={item}
                                            toggleMenuActions={toggleMenuActions}
                                            ref={menuRef}
                                            svg="Icon_centros_talentic"
                                        />
                                    )) : (
                                        <tr>
                                            <td colSpan="3">No se encontraron registros</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                            
                        )}
                    </div>
                </div>
            </main>
            <Modal isOpen={isModalOpen} onClose={closeModal} titulo={"Centro"}>
                {modalContent}
            </Modal>
        </div>
    );
};

export default Centers;
