import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import BtnSubmit from './../components/btnSubmit';
import Textarea from '../components/textarea';

function SendMensaje({ project, userSend, onSubmit }) {
    const [comment, setcomment] = useState('');
    const [projectData] = useState(project);
    const [userSendData] = useState(userSend);

    const hashToCrear = "be4eecb34a58b591331a1f8a5e041731";

    const [formDatauser] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        return {
            permisos: user?.permissions || ''
        };
    });

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (formDatauser.permisos.includes(hashToCrear)) {
        const requestBody = {
          "API": "talentic",
          "MODEL": "talentic",
          "RESOURCE": "mensages",
          "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
          "TYPE": "PUT",
          "comment": comment,
          "project": projectData,
          "userFrom": userSendData,
        };
    
        try {
          await axios.post('http://217.15.168.117:8080/api/', requestBody, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
    
          onSubmit(true);
        } catch (error) {
          toast.error('Algo pasó, necesitas volver a gestionar la información');
        }
      }else{
        toast.error('No tienes permiso de enviar mensajes!');
      }
    };

  return (
    <>
      {/* Logo */}
      <h4 className="mb-2 ps-3">Contáctate con los autores de este proyecto</h4>

      <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit}>
        <Textarea 
            name="comment"
            label="Mensaje"
            placeholder="Escribe tu mensaje" 
            value={comment} 
            onChange={(e) => setcomment(e.target.value)}
        />
        <BtnSubmit />
      </form>
    </>
  );
}

export default SendMensaje;
