import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaWhatsapp, FaTelegram } from 'react-icons/fa';
import { FaLink } from 'react-icons/fa';

function SharedSocial({ project }) {
    const url = 'http://217.15.168.117/detailsProyects/' + project;
    const text = 'Hola, mira mi proyecto';

    return (
        <div className="cont__buttons">
            <p className='col-12 mb-3'>Compártelos en:</p>

            {/* LinkedIn */}
            <a 
                className="linkedin" 
                target="_blank" 
                rel="noopener noreferrer" 
                href={`https://www.linkedin.com/sharing/share-offsite/?text=${encodeURIComponent(text + ' ' + url)}`}
            >
                <FaLinkedin />
            </a>

            {/* Twitter */}
            <a 
                className="twitter" 
                target="_blank" 
                rel="noopener noreferrer" 
                href={`https://x.com/intent/post?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}&hashtags=Talentic,sena`}
            >
                <FaTwitter />
            </a>

            {/* Instagram */}
            <a 
                className="instagram" 
                target="_blank" 
                rel="noopener noreferrer" 
                href={`https://www.instagram.com/direct/new/?text=${encodeURIComponent(text + ' ' + url)}`}
            >
                <FaInstagram />
            </a>

            {/* Facebook */}
            <a 
                className="facebook" 
                target="_blank" 
                rel="noopener noreferrer" 
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
            >
                <FaFacebook />
            </a>
            
            
            
            {/* 
                <a className="copy-link" target="_blank" rel="noopener noreferrer" href={`https://www.google.com/search?q=${url}`}>
                    <FaLink />
                </a>

                WhatsApp 
                <a 
                    className="whatsapp" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    href={`https://wa.me/?text=${encodeURIComponent(text + ' ' + url)}`}
                >
                    <FaWhatsapp />
                </a>
                
                Telegram 
                <a 
                    className="telegram" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    href={`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`}
                >
                    <FaTelegram />
                </a>
            */}
        </div>
    );
}

export default SharedSocial;
