// Importación de dependencias
import React, { useState, useEffect } from 'react'; // Para manejar el estado y los efectos secundarios
import { formatDistanceToNow, parseISO } from 'date-fns'; // Para formatear fechas
import { useNavigate, Link } from 'react-router-dom'; // Para navegación y enlaces en React Router
import { useLocation } from 'react-router-dom'; // Para obtener la ubicación actual en la navegación
import { toast } from 'react-toastify'; // Para mostrar notificaciones de tipo "toast"
import es from 'date-fns/locale/es'; // Para usar el idioma español en la fecha
import axios from 'axios'; // Para realizar peticiones HTTP

// Componentes importados
import CreateProject from '../forms/projects/create'; // Componente para crear proyectos
import UpdateProject from '../forms/projects/update'; // Componente para actualizar proyectos
import ShowProject from '../forms/projects/show'; // Componente para mostrar proyectos

import IconSVG from './../contents/icons/icon'; // Componente para íconos SVG
import Icon from './../contents/icons/icon'; // Otro componente para íconos

import ImgUser from '../forms/subirImgUser'; // Componente para subir imagen de usuario

import CardFavorite from './cardFavorites'; // Componente para mostrar tarjetas de favoritos
import TruncatedText from './cutString'; // Componente para truncar texto
import SeacrhData from './searchData'; // Componente para buscar datos
import CutText from './cutString'; // Componente para cortar texto
import CheckBox from './checkbox'; // Componente de casillas de verificación
import Modal from './modal'; // Componente para mostrar modales
import Input from './input'; // Componente para campos de entrada
import Card from './card'; // Componente para tarjetas

// Componente que muestra la fecha en formato relativo, como "Hace X tiempo"
const FechaRelativa = ({ fecha }) => {
  // Se convierte la fecha recibida a formato ISO
  const fechaISO = parseISO(fecha);

  return (
      // Muestra el texto de la fecha relativa en formato "Hace X tiempo"
      <p className='text-primary'>
          {`Hace ${formatDistanceToNow(fechaISO, { addSuffix: true, locale: es })}`}
      </p>
  );
};

const Tabs = () => {
  // Obtiene la ubicación actual de la ruta usando useLocation de react-router-dom
  const location = useLocation();
  // Extrae el valor de "tab" y "open" del estado de la ubicación, si existen
  const { tab, open } = location.state || {};

  // Declaración de estados utilizando useState
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
  const [apiData, setApiData] = useState([]); // Estado para almacenar los datos de la API

  const [mensage_focus, setmensage_focus] = useState([]); // Estado para el mensaje de enfoque (posiblemente para resaltar mensajes o notificaciones)

  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar la apertura del modal
  const [modalContent, setModalContent] = useState(null); // Estado para almacenar el contenido del modal
  const [openFisrt, setopenFisrt] = useState(false); // Estado para manejar si se debe abrir algo por primera vez

  // Estados para almacenar los elementos de favoritos, guardados, y mensajes
  const [favoritos, setfavoritos] = useState([]); // Estado para los favoritos del usuario
  const [guardados, setguardados] = useState([]); // Estado para los elementos guardados
  const [mensajes, setmensajes] = useState([]); // Estado para los mensajes

  // Estado para almacenar los datos del formulario, con un valor inicial proveniente del localStorage (información del usuario)
  const [formData, setFormData] = useState(() => {
    const user = JSON.parse(localStorage.getItem('user')); // Obtiene el usuario desde localStorage

    // Devuelve un objeto con los datos del usuario, usando valores predeterminados si no existen
    return {
      name: user?.name || '', // Nombre
      index_user: user?.index_user || '', // Índice del usuario
      person: user?.person || '', // Persona
      lastName: user?.lastName || '', // Apellido
      notifyChangePass: user?.notifyChangePass === 0 ? true : false, // Notificación de cambio de contraseña
      notifyMessages: user?.notifyMessages === 0 ? true : false, // Notificación de mensajes
      img: user?.img || '', // Imagen del usuario
      email: user?.email || '', // Correo electrónico
      dni: user?.dni || '' // DNI
    };
  });

  // Cadenas de hash, probablemente utilizadas para acceder a datos o validar información
  const hashToVer = "3b230954edd8325e5102abe791c99775"; // Hash para ver
  const hashToVerproyectos = "1bc61d3d42375e00a881ddc55815b0ca"; // Hash para proyectos
  const hashToEditarproyectos = "515c0b231c5d0c5090b422071b32b5ad"; // Hash para editar proyectos

  // Estado para los permisos del usuario, extraídos desde el localStorage
  const [formDatauser] = useState(() => {
    const user = JSON.parse(localStorage.getItem('user')); // Obtiene el usuario desde localStorage
    return {
      permisos: user?.permissions || '' // Permisos del usuario
    };
  });

  // useEffect se ejecuta una sola vez después de montar el componente
  useEffect(() => {
    // Llamadas a las funciones para obtener los datos de favoritos, guardados y proyectos
    fetchDataFavorites();
    fetchDatasaved();
    fetchData();

    // Si `openFisrt` es falso, revisa si la variable `open` tiene el valor "crearProyectos"
    if (!openFisrt) {
      if (open === "crearProyectos") {
        // Si es "crearProyectos", ejecuta la acción de crear proyecto
        handleCreateClick(CreateProject);
        // Cambia el estado para que no vuelva a ejecutarse esta lógica
        setopenFisrt(!openFisrt);
      }
    }
  }, []); // Dependencia vacía para que se ejecute solo una vez cuando se monta el componente

  // Función para obtener los proyectos del usuario
  const fetchData = () => {
    const requestBody = {
      "API": "talentic",
      "MODEL": "talentic",
      "RESOURCE": "list",
      "TYPE": "myProjects",
      "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
      "user": formData.index_user, // Usa el índice del usuario
    };

    // Realiza una solicitud POST para obtener los proyectos
    axios.post('http://217.15.168.117:8080/api/', requestBody)
      .then(response => {
        // Mapea la respuesta para obtener los datos necesarios
        const cleanedData = response.data.map(item => ({
          id: item.id,
          name: item.name,
          type: item.type,
          idType: item.idType,
          image: item.image,
          slug: item.slug,
          intro: item.intro,
          description: item.description,
          dateCreation: item.dateCreate,
          deadline: item.deadline,
          state: item.state,
        }));
        // Actualiza el estado con los datos obtenidos
        setApiData(cleanedData);
      })
      .catch(error => {
        console.error('Error fetching data:', error); // Muestra un error si la solicitud falla
      });

    // Si no hay mensajes, realiza otra solicitud para obtener los mensajes del usuario
    if (formData.index_user && mensajes.length === 0) {
      axios.post('http://217.15.168.117:8080/api/', {
        "API": "talentic",
        "MODEL": "talentic",
        "RESOURCE": "list",
        "TYPE": "userMensages",
        "user": formData.index_user,
        "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
      })
      .then(response => {
        if (Array.isArray(response.data)) {
          setmensajes(response.data); // Actualiza el estado con los mensajes si la respuesta es un arreglo
        }
      })
      .catch(error => { });
    }
  };

  // Función para obtener los favoritos del usuario
  const fetchDataFavorites = () => {
    const requestBody = {
      "API": "talentic",
      "MODEL": "talentic",
      "RESOURCE": "list",
      "TYPE": "favorites",
      "user": formData.index_user,
      "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
    };

    // Solicita los favoritos del usuario
    axios.post('http://217.15.168.117:8080/api/', requestBody)
      .then(response => {
        // Mapea los datos recibidos para extraer la información relevante
        const cleanedDataProfiles = response.data.map(item => ({
          id: item.id,
          imgSrc: `http://217.15.168.117:8080/api/Whatever/${item.image}`,
          name: item.name,
          dateCreate: item.dateCreate,
          favorites: item.favorites,
          hoverText: <TruncatedText text={item.description} maxLength={40} /> // Muestra un texto truncado
        }));
        // Actualiza el estado con los favoritos
        setfavoritos(cleanedDataProfiles);
      })
      .catch(error => {
        console.error('Error fetching data:', error); // Muestra error si la solicitud falla
      });
  };

  // Función para obtener los datos guardados por el usuario
  const fetchDatasaved = () => {
    const requestBody = {
      "API": "talentic",
      "MODEL": "talentic",
      "RESOURCE": "list",
      "TYPE": "saved",
      "user": formData.index_user,
      "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
    };

    // Solicita los proyectos guardados del usuario
    axios.post('http://217.15.168.117:8080/api/', requestBody)
      .then(response => {
        // Mapea los datos de la respuesta
        const cleanedDataProfiles = response.data.map(item => ({
          id: item.id,
          imgSrc: `http://217.15.168.117:8080/api/Whatever/${item.image}`,
          name: item.name,
          dateCreate: item.dateCreate,
          favorites: item.favorites,
          hoverText: <TruncatedText text={item.description} maxLength={40} /> // Muestra un texto truncado
        }));
        // Actualiza el estado con los datos guardados
        setguardados(cleanedDataProfiles);
      })
      .catch(error => {
        console.error('Error fetching data:', error); // Muestra error si la solicitud falla
      });
  };

  // Función para refrescar los datos del usuario después de cambios
  const refrscarImg = () => {
    setIsModalOpen(false); // Cierra el modal
    setModalContent(null); // Limpia el contenido del modal
    const user = JSON.parse(localStorage.getItem('user')); // Obtiene los datos del usuario
    // Actualiza el estado con los nuevos datos del usuario
    setFormData({
      name: user?.name || '',
      person: user?.person || '',
      lastName: user?.lastName || '',
      notifyChangePass: user?.notifyChangePass === 0 ? true : false,
      notifyMessages: user?.notifyMessages === 0 ? true : false,
      img: user?.img || '',
      email: user?.email || '',
      dni: user?.dni || ''
    });
  };

  // Función para abrir un modal con un formulario específico
  const openModalWithForm = (FormComponent) => {
    setModalContent(<FormComponent userdata={formData.person} sumit={refrscarImg}/>); // Carga el componente del formulario
    setIsModalOpen(true); // Abre el modal
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  // Función para cerrar el modal y mostrar un mensaje de advertencia
  const closeModalSuspend = () => {
    setIsModalOpen(false);
    setModalContent(null);
    toast.warning('Estamos subiendo los datos, en breve veras el proyecto.');
  };

  // Función para manejar el cambio de un campo en el formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Función para manejar el cambio de un campo de tipo checkbox
  const handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked 
    }));
  };

  // Función para manejar el envío del formulario de creación de usuario o actualización de datos
  const handleCreate = async (e) => {
    e.preventDefault();
    const { name, lastName, email, dni, notifyMessages, notifyChangePass, person } = formData;

    const requestBody = {
      "API": "talentic",
      "MODEL": "talentic",
      "RESOURCE": "people",
      "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
      "TYPE": "UPDATE",
      "name": name,
      "lastName": lastName,
      "email": email,
      "dni": dni,
      "notifyMessages": notifyMessages === true ? 0 : 1, // Convierte los valores booleanos a enteros
      "notifyChangePass": notifyChangePass === true ? 0 : 1, // Convierte los valores booleanos a enteros
      "id": person
    };

    try {
      // Realiza una solicitud POST para actualizar los datos del usuario
      await axios.post('http://217.15.168.117:8080/api/', requestBody)
        .then(response => {
          // Actualiza la información del usuario en localStorage
          let user = JSON.parse(localStorage.getItem('user'));
          if (user) {
            user.name = name;
            user.lastName = lastName;
            user.email = email;
            user.dni = dni;
            user.notifyMessages = notifyMessages === true ? 0 : 1;
            user.notifyChangePass = notifyChangePass === true ? 0 : 1;

            // Guarda los datos actualizados en localStorage
            localStorage.setItem('user', JSON.stringify(user));
            refrscarImg(); // Refresca los datos del usuario
          }
        })
        .catch(error => {
          console.error('Error updating data:', error); // Muestra error si la solicitud falla
        });
    } catch (error) {
      console.error('Error submitting form:', error); // Muestra error si algo falla al enviar el formulario
    }
  };

  // Función que maneja el cambio cuando se crea un nuevo proyecto
  const handleChangecrear = (newState) => {
    // Muestra un mensaje de éxito y cierra el modal
    toast.success("Proyecto enviado con éxito");
    setIsModalOpen(false); // Cierra el modal
    setModalContent(null); // Limpia el contenido del modal
    fetchData(); // Vuelve a obtener los datos actualizados
  };

  // Función que maneja el cambio cuando se editan los datos de un proyecto
  const handleChangeeditar = (newState) => {
    // Muestra un mensaje de éxito y cierra el modal
    toast.success("Se editaron los datos con éxito");
    setIsModalOpen(false); // Cierra el modal
    fetchData(); // Vuelve a obtener los datos actualizados
  };

  // Función que maneja el cambio en el campo de búsqueda
  const handleSearchChange = (e) => {
    // Convierte el valor de búsqueda a minúsculas y lo guarda en el estado
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Filtra los proyectos basándose en el término de búsqueda
  const filteredData = apiData.filter(item =>
    (item.name || '').toLowerCase().includes(searchTerm) 
  );

  // Función que maneja la eliminación de un proyecto
  const handleDelete = (itemId) => {
    // Pide confirmación antes de eliminar el proyecto
    if (window.confirm('¿Estás seguro de que deseas eliminar este registro?')) {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "projects",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "DELETE",
            "id": itemId // ID del proyecto a eliminar
        };

        // Realiza una solicitud para eliminar el proyecto
        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                toast.success("Se eliminaron los datos con éxito"); // Muestra un mensaje de éxito
                fetchData(); // Vuelve a obtener los datos actualizados
            })
            .catch(error => {
                console.error('Error deleting data:', error); // Muestra errores si los hay
            });
    }
  };

  // Función que maneja la aprobación de un proyecto
  const handleAprobar = (itemId) => {
    // Pide confirmación antes de aprobar el proyecto
    if (window.confirm('¿Estás seguro de que deseas aprobar este proyecto?')) {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "projects",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "DELETE", // Aquí parece que se usa el tipo DELETE, pero se debería usar un tipo diferente para aprobación.
            "id": itemId, // ID del proyecto a aprobar
            "state": 1 // Estado 1, que probablemente sea "aprobado"
        };

        // Realiza una solicitud para aprobar el proyecto
        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                toast.success("Se aprobó el proyecto con éxito"); // Muestra un mensaje de éxito
                fetchData(); // Vuelve a obtener los datos actualizados
            })
            .catch(error => {
                console.error('Error deleting data:', error); // Muestra errores si los hay
            });
    }
  };

  // Función que abre el modal para crear un proyecto
  const handleCreateClick = (FormComponent) => {
    setIsModalOpen(true); // Abre el modal
    setModalContent(<FormComponent onSubmitState={handleChangecrear} closeModal={closeModalSuspend}/>); // Establece el contenido del modal con el formulario de creación
  };

  // Función que abre el modal para editar un proyecto existente
  const handleEditClick = (FormComponent, id, name, type, slug, intro, description, deadline) => {
    setIsModalOpen(true); // Abre el modal
    setModalContent(<FormComponent 
        onUPSubmitState={handleChangeeditar}
        idDefault={id} // Pasa los datos predeterminados del proyecto para la edición
        nameDefault={name}
        typeDefault={type}
        slugDefault={slug}
        introDefault={intro}
        descriptionDefault={description}
        deadlineDefault={deadline}
    />);
  };

  // Función que abre el modal para mostrar los detalles de un proyecto
  const handleShowClick = (FormComponent, name, type, image, slug, intro, description, deadline, dateCreation) => {
    setIsModalOpen(true); // Abre el modal
    setModalContent(<FormComponent
        nameDefault={name} // Pasa los datos del proyecto para visualizar
        typeDefault={type}
        imageDefault={image}
        slugDefault={slug}
        introDefault={intro}
        descriptionDefault={description}
        deadlineDefault={deadline}
        dateCreationDefault={dateCreation}
    />);
  };

  // Función que actualiza el mensaje de un usuario específico
  const actualizar_mensaje = (user, msg) => {
    setmensage_focus([]); // Limpia los mensajes anteriores
    setmensage_focus((prevFocus) => [...prevFocus, { // Agrega el nuevo mensaje al estado
        user: user,
        msg: msg
    }]);
  };

  return (
    <>
      <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${tab === "user" ? 'active' : ''}`}
            id="perfil-tab"
            data-bs-toggle="tab"
            data-bs-target="#perfil"
            type="button"
            role="tab"
            aria-controls="perfil"
            aria-selected="true"
          >
            <Icon name="Icon_editar_usuario_talentic" width="17px"/>&nbsp;&nbsp;Perfil
          </button>
        </li>
        {formDatauser.permisos.includes(hashToVerproyectos) ? (
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${tab === "projects" ? 'active' : ''}`}
              id="proyectos-tab"
              data-bs-toggle="tab"
              data-bs-target="#proyectos"
              type="button"
              role="tab"
              aria-controls="proyectos"
              aria-selected="false"
            >
              < Icon name="Icon_carpeta_talentic" width="17px"/>&nbsp;&nbsp;Proyectos
            </button>
          </li>
        ): null }
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${tab === "favorites" ? 'active' : ''}`}
            id="favoritos-tab"
            data-bs-toggle="tab"
            data-bs-target="#favoritos"
            type="button"
            role="tab"
            aria-controls="favoritos"
            aria-selected="false"
          >
            < Icon name="Icon_corazon_talentic" width="17px"/>&nbsp;&nbsp;Favoritos
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${tab === "saved" ? 'active' : ''}`}
            id="guardados-tab"
            data-bs-toggle="tab"
            data-bs-target="#guardados"
            type="button"
            role="tab"
            aria-controls="guardados"
            aria-selected="false"
          >
            < Icon name="Icon_favorito_talentic" width="17px"/>&nbsp;&nbsp;Guardados
          </button>
        </li>
        {formDatauser.permisos.includes(hashToVer) ? (
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${tab === "messages" ? 'active' : ''}`}
              id="mensajes-tab"
              data-bs-toggle="tab"
              data-bs-target="#mensajes"
              type="button"
              role="tab"
              aria-controls="mensajes"
              aria-selected="false"
            >
              < Icon name="Icon_mensaje_talentic" width="17px"/>&nbsp;&nbsp;Mensajes
            </button>
          </li>
        ): null }
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className={`p-4 card tab-pane fade flex-wrap flex-md-row ${tab === "user" ? 'show active' : ''}`}
          id="perfil"
          role="tabpanel"
          aria-labelledby="perfil-tab"
        >
          <div className='col-12 col-md-4 d-flex flex-column align-items-center bordermainpanel'>
            <img 
              src={`http://217.15.168.117:8080/api/Whatever/${formData.img}`}
              className="mb-2 userprofile display-5 mt-5"
              onClick={() => openModalWithForm(ImgUser)}
            />
            <h6 className="mb-0">{formData.name} {formData.lastName}</h6>
            <small className="text-muted">{formData.email}</small>
          </div>
          <div className='col-12 col-md-8 d-flex flex-column align-items-start py-3 px-5'>
            <h5 className="mb-0 ps-3">Datos de tu cuenta</h5>
            <form className="flex-wrap d-flex" method="POST" onSubmit={handleCreate}>
              <Input 
                name="name" 
                label="Nombre"
                placeholder="nombre" 
                value={formData.name} 
                onChange={handleChange} 
                colClassName="col-lg-6"
              />
              <Input 
                name="lastName" 
                label="Apellidos"
                placeholder="apellidos" 
                value={formData.lastName} 
                onChange={handleChange} 
                colClassName="col-lg-6"
              />
              <Input 
                name="email" 
                label="Correo"
                placeholder="correo" 
                value={formData.email} 
                onChange={handleChange} 
                colClassName=""
              />
              <Input 
                name="dni" 
                label="Cédula"
                placeholder="cédula" 
                value={formData.dni} 
                onChange={handleChange} 
                colClassName=""
              />
              <div className='d-flex flex-column align-items-start col-12'>
                <h5 className="mb-0 pt-3 ps-3">Notificaciones</h5>
                <small className="text-muted ps-3">Recibir notificación vía correo electrónico</small>
              </div>
              <CheckBox
                name="notifyMessages"
                value={formData.notifyMessages}
                icon=""
                userName="Nuevos mensajes"
                description=""
                checked={formData.notifyMessages}
                handleCheckboxChange={handleChangeCheckBox}
                colClassName="col-lg-5 me-5"
              />
              <CheckBox
                name="notifyChangePass"
                value={formData.notifyChangePass}
                icon=""
                userName="Cambio de contraseña"
                description=""
                checked={formData.notifyChangePass}
                handleCheckboxChange={handleChangeCheckBox}
                colClassName="col-lg-5"
              />
              <div className="mt-3 col-12 d-flex flex-column align-items-end">
                  <button type="submit" className="btn btn-outline-primary">Guardar</button>
              </div>
            </form>
          </div>
        </div>
        {formDatauser.permisos.includes(hashToVerproyectos) ? (
          <div
            className={`p-4 card tab-pane fade ${tab === "projects" ? 'show active' : ''}`}
            id="proyectos"
            role="tabpanel"
            aria-labelledby="proyectos-tab"
          >
            <div className="container-xxl flex-grow-1 container-p-y">
              <SeacrhData 
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                btnCreate={
                    <div className="col-lg-2 ps-2">
                        <h5 className="card d-flex align-self-stretch justify-content-center m-0">
                            <button 
                                onClick={() => handleCreateClick(CreateProject)}
                                className="btn btn-outline-primary"
                            >
                                Crear Nuevo
                            </button>
                        </h5>
                    </div>
                }
              />

              <div className="card">
                  <div className="card-body">
                      <h5 className="mb-0">Proyectos por aprobar</h5>
                      <div className="aprendices-list">
                          {filteredData.length > 0 ? filteredData.filter(item => item.state === 2).map((item) => (
                              <div 
                                  key={item.id}
                                  className={`buscarDos usuario__encontrado`}
                                  data-user={`${item.name} ${item.slung} ${item.description}`}
                              >
                                  <div className='d-flex align-items-center'>
                                      <div className='ms-3'>
                                          <img 
                                              src={`http://217.15.168.117:8080/api/Whatever/${item.image}`}
                                              className="m-0 userprofileproyects display-5 "
                                          />
                                      </div>
                                      <div className='ms-3'>
                                          <h6 className="mb-0">Proyecto: {item.name}</h6>
                                          <small className="text-muted">{item.dateCreation}</small>
                                      </div>
                                  </div>
                                  <div className="d-flex">
                                      <div 
                                          className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                          style={{ width: '54px'}}
                                          onClick={(e) => {
                                              handleShowClick(ShowProject,
                                                  item.name, item.type, item.image, item.slug, item.intro, item.description,
                                                  item.deadline, item.dateCreation
                                              );
                                          }}
                                      >
                                          <IconSVG name="Icon_ver_talentic" width="25px"/>
                                          <small>VER</small>
                                      </div>
                                      {formDatauser.permisos.includes(hashToEditarproyectos) ? (
                                        <Link 
                                            to="/dashboard/manageProject"
                                            state={{
                                                id: item.id
                                            }}
                                        >
                                            <div 
                                                className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                                style={{ width: '54px'}}
                                            >
                                                <IconSVG name="Icon_editar_talentic" width="20px"/>
                                                <small style={{ marginTop: '5px'}} >EDITAR</small>
                                            </div>
                                        </Link>
                                      ): null }
                                      <div 
                                          className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                          style={{ width: '54px'}} 
                                          onClick={(e) => {
                                              handleDelete(item.id, item.state); // Llamar a la función de eliminación
                                          }}
                                      >
                                          <IconSVG name="Icon_eliminar_talentic" width="20px"/>
                                          <small>ELIMINAR</small>
                                      </div>
                                  </div>
                              </div>
                          )) : (
                              <div>
                                  <div className="text-center">No se encontraron registros</div>
                              </div>
                          )}
                      </div>
                  </div>
              </div>

              <hr/>

              <div className="card">
                  <div className="card-body">
                      <h5 className="mb-0">Proyectos aprobados</h5>
                      <div className="aprendices-list">
                          {filteredData.length > 0 ? filteredData.filter(item => item.state === 0).map((item) => (
                              <div 
                                  key={item.id}
                                  className={`buscarDos usuario__encontrado`}
                                  data-user={`${item.name} ${item.slung} ${item.description}`}
                              >
                                  <div className='d-flex align-items-center'>
                                      <div className='ms-3'>
                                          <img 
                                              src={`http://217.15.168.117:8080/api/Whatever/${item.image}`}
                                              className="m-0 userprofileproyects display-5 "
                                          />
                                      </div>
                                      <div className='ms-3'>
                                          <h6 className="mb-0">Proyecto: {item.name}</h6>
                                          <small className="text-muted">{item.dateCreation}</small>
                                      </div>
                                  </div>
                                  <div className="d-flex">
                                      <div 
                                          className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                          style={{ width: '54px'}}
                                          onClick={(e) => {
                                              handleShowClick(ShowProject,
                                                  item.name, item.type, item.image, item.slug, item.intro, item.description,
                                                  item.deadline, item.dateCreation
                                              );
                                          }}
                                      >
                                          <IconSVG name="Icon_ver_talentic" width="25px"/>
                                          <small>VER</small>
                                      </div>
                                      {formDatauser.permisos.includes(hashToEditarproyectos) ? (
                                        <Link 
                                            to="/dashboard/manageProject"
                                            state={{
                                                id: item.id
                                            }}
                                        >
                                            <div 
                                                className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                                style={{ width: '54px'}}
                                            >
                                                <IconSVG name="Icon_editar_talentic" width="20px"/>
                                                <small style={{ marginTop: '5px'}} >EDITAR</small>
                                            </div>
                                        </Link>
                                      ): null }
                                      <div 
                                          className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                          style={{ width: '54px'}} 
                                          onClick={(e) => {
                                              handleDelete(item.id, item.state); // Llamar a la función de eliminación
                                          }}
                                      >
                                          <IconSVG name="Icon_eliminar_talentic" width="20px"/>
                                          <small>ELIMINAR</small>
                                      </div>
                                  </div>
                              </div>
                          )) : (
                              <div>
                                  <div className="text-center">No se encontraron registros</div>
                              </div>
                          )}
                      </div>
                  </div>
              </div>
            </div>
          </div>
        ): null }
        <div
          className={`p-4 card tab-pane fade ${tab === "favorites" ? 'show active' : ''}`}
          id="favoritos"
          role="tabpanel"
          aria-labelledby="favoritos-tab"
        >
          <div className="d-flex flex-wrap justify-content-center">
            {favoritos.map((favoritos, index) => (
              <CardFavorite
                key={index}
                imgSrc={favoritos.imgSrc}
                name={favoritos.name}
                hoverText={favoritos.hoverText}
                socialLinks={favoritos.socialLinks}
                link={favoritos.id}
                date={favoritos.dateCreate}
                favorites={favoritos.favorites}
                remove={fetchDataFavorites}
              />
            ))}
          </div>
        </div>
        <div
          className={`p-4 card tab-pane fade ${tab === "saved" ? 'show active' : ''}`}
          id="guardados"
          role="tabpanel"
          aria-labelledby="guardados-tab"
        >
          <div className="d-flex flex-wrap justify-content-center">
            {guardados.map((guardados, index) => (
              <Card
                key={index}
                imgSrc={guardados.imgSrc}
                name={guardados.name}
                hoverText={guardados.hoverText}
                socialLinks={guardados.socialLinks}
                link={guardados.id}
                date={guardados.dateCreate}
                favorites={guardados.favorites}
              />
            ))}
          </div>
        </div>
        {formDatauser.permisos.includes(hashToVer) ? (
          <div
            className={`p-4 card tab-pane fade ${tab === "messages" ? 'show active' : ''}`}
            id="mensajes"
            role="tabpanel"
            aria-labelledby="mensajes-tab"
          >
            <h3 className='text-center'>Datos de tu cuenta</h3>
            <div className='d-flex flex-wrap'>
              <div className='col-4 borderR d-flex flex-column'>
                {(mensajes.length > 0) ? (
                  mensajes.map(item => (
                    <>
                      <div
                        className='mx-4 my-2 borderB cursor-pointer'
                        onClick={(e) => {
                          actualizar_mensaje(item.from_user, item.name);
                        }}
                      >
                        <FechaRelativa fecha={item.date} />
                        <h5 className='text-start text-primary-emphasis p-0'>{item.from_user}</h5>
                        <CutText classn="text-justify" text={item.name} maxLength={70}/>
                      </div>
                    </>
                  ))
                ) : (
                    <> <h5 style={{ marginBottom: '20px' }} >Sin Mensajes</h5> </>
                )}
              </div>
              <div className='col-8 d-flex flex-column justify-content-center align-items-center'>
                {(mensage_focus.length > 0) ? (
                  mensage_focus.map(item => (
                    <>
                      <div className='mx-4 my-2 ' >
                        <h5 className='text-start text-primary-emphasis p-0'>{item.user}</h5>
                        <p classn="text-justify">{item.msg}</p>
                      </div>
                    </>
                  ))
                ) : (
                    <> 
                      <Icon name="Icon_correo_talentic" width="100px"/>
                      <h5 className='text-center'>No se han seleccionado conversaciones</h5>
                    </>
                )}
              </div>
            </div>
          </div>
        ): null }
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal} widthValue='650px'>
          {modalContent}
      </Modal>
    </>
  );
};

export default Tabs;
