import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import axios from 'axios';

import CreatePermissions from './create';
import UpdatePermissions from './update';
import ShowPermissions from './show';

import Modal from '../../components/modalDashboard';
import IconSVG from '../../contents/icons/icon';
import SeacrhData from '../../components/searchData';

import ViewToggle from '../../components/viewToggle';
import SortFilter from '../../components/sortFilter';

import ListView from '../../components/listView';
import CardView from '../../components/cardView';

const TypeUsers = ( ) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [apiData, setApiData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const navigate = useNavigate();

    const hashToVer = "cf8ed42e615479f6ca7e932b858ca30f";
    const hashToCrear = "2e973482cc4e61754aa680c5d27ad739";
    const hashToEditar = "8f6463b8579d1f20a30284afef835655";
    const hashToEliminar = "da800e9e62a622edc64dd5e9343f4c66";

    const [formDatauser] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        return {
            permisos: user?.permissions || ''
        };
    });

    useEffect(() => {
        fetchData();
    }, []);

    const [roleFilter, setRoleFilter] = useState('Todos'); // Estado para el filtro de rol
    const [activeMenuActions, setActiveMenuActions] = useState(null);
    const menuRef = useRef(null);
    const [viewType, setViewType] = useState('cards'); // 'cards' para tarjetas y 'list' para lista
    const [sortConfig, setSortConfig] = useState({ key: 'dateCreation', direction: 'desc' });

    // Alterna entre los tipos de visualización
    const showCards = () => setViewType('cards');
    const showList = () => setViewType('list');

    useEffect(() => {
        // Detectar clics fuera del menú
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveMenuActions(null);
            }
        };

        // Añadir evento al hacer clic en el documento
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Limpiar el evento al desmontar el componente
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSort = (value) => {
        let key, direction;
    
        if (value === 'fechaDesc') {
            key = 'dateCreation';
            direction = 'desc';
        } else if (value === 'fechaAsc') {
            key = 'dateCreation';
            direction = 'asc';
        } else if (value === 'nombreAsc') {
            key = 'username';
            direction = 'asc';
        } else if (value === 'nombreDesc') {
            key = 'username';
            direction = 'desc';
        } else if (value === 'emailAsc') {
            key = 'email';
            direction = 'asc';
        } else if (value === 'emailDesc') {
            key = 'email';
            direction = 'desc';
        } else if (value === 'tipoAsc') {
            key = 'type';
            direction = 'asc';
        } else if (value === 'tipoDesc') {
            key = 'type';
            direction = 'desc';
        }
    
        setSortConfig({ key, direction });
    };

    const toggleMenuActions = (menuName) => {
        setActiveMenuActions(activeMenuActions === menuName ? null : menuName);
    };

    const fetchData = () => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "list",
            "TYPE": "permissions",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                const cleanedData = response.data.map(item => ({
                    id: item.id,
                    name: item.name,
                    cod: item.cod,
                    crypter: item.crypter,
                    typeId: item.typeId,
                    type: item.type,
                    icon: item.name.startsWith("Ver") ? "Icon_ver_talentic_2" :
                            item.name.startsWith("Editar") ? "Icon_editar_talentic_2" :
                            item.name.startsWith("Eliminar") ? "Icon_eliminar_talentic_2" :
                            item.name.startsWith("Crear") ? "Icon_mas_talentic" :
                            item.name.startsWith("Aprobar") ? "Icon_aprobar_talentic_2" :
                            item.name.startsWith("Me Gusta") ? "Icon_corazon_talentic" :
                            item.name.startsWith("Guardar") ? "Icon_guardar_talentic" :
                            "Icon_ver_talentic_2" // O un icono por defecto
                }));
                setApiData(cleanedData);
            })
            .catch(error => {
            });
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    const handleChangecrear = (newState) => {
        toast.success("Se gestionaron los datos con éxito");
        setIsModalOpen(false);
        setModalContent(null);
        fetchData();
    };

    const handleChangeeditar = (newState) => {
        toast.success("Se editaron los datos con éxito");
        setIsModalOpen(false);
        fetchData();
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = apiData.filter(item =>
        (item.name || '').toLowerCase().includes(searchTerm) ||
        (item.cod || '').toLowerCase().includes(searchTerm) ||
        (item.crypter || '').toLowerCase().includes(searchTerm)
    );

    const handleDelete = (itemId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este registro?')) {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "permissions",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "DELETE",
                "id": itemId
            };

            axios.post('http://217.15.168.117:8080/api/', requestBody)
                .then(response => {
                    fetchData();
                    toast.success("Se eliminaron los datos con éxito");
                })
                .catch(error => {
                    console.error('Error deleting data:', error);
                });
        }
    };

    const handleCreateClick = (FormComponent) => {        
        setIsModalOpen(true);
        setModalContent(<FormComponent onSubmitState={handleChangecrear}/>);
    };

    const handleEditClick = (FormComponent, id, name, cod, crypter, type) => {        
        setIsModalOpen(true);
        setModalContent(<FormComponent 
            onUPSubmitState={handleChangeeditar}
            idDefault={id}
            nameDefault={name}
            codDefault={cod}
            crypterDefault={crypter}
            typeDefault={type}
        />);
    };
    
    const handleShowClick = (FormComponent, name, cod, crypter, type) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent
            nameDefault={name}
            codDefault={cod}
            crypterDefault={crypter}
            typeDefault={type}
        />);
    };

    // Función que define el menú de acciones específico para cada usuario
    const actionMenuProps = (item) => ({
        isActive: activeMenuActions === item.id,
        onClose: () => setActiveMenuActions(null),
        actions: [
            formDatauser.permisos.includes(hashToVer) && {
                label: 'Ver',
                icon: 'Icon_ver_talentic_2',
                onClick: () => handleShowClick(ShowPermissions, item.name, item.cod, item.crypter, item.type),
            },
            formDatauser.permisos.includes(hashToEditar) && {
                label: 'Editar',
                icon: 'Icon_editar_talentic_2',
                onClick: () => handleEditClick(UpdatePermissions, item.id, item.name, item.cod, item.crypter, item.typeId),
                className: 'menu_acciones_editar'
            },
            formDatauser.permisos.includes(hashToEliminar) && {
                label: 'Eliminar',
                icon: 'Icon_eliminar_talentic_2',
                onClick: () => handleDelete(item.id, item.state), // Llamar a la función de eliminación,
                className: 'menu_acciones_eliminar'
            }
        ].filter(Boolean)
    });

    return (
        <div className='dashboard_content'>
            <main className='main__dashboard_content'>
                <div className='form_content'>
                    <section className='dashboard_titulo'>
                        <h2>Permisos</h2>
                    </section>
                    <div className='main__dashboard_primera_parte'>
                        <div className='btn_nuevo_dashboard' onClick={() => handleCreateClick(CreatePermissions)}>
                            <IconSVG name="Icon_mas_talentic"/> Crear Nuevo
                        </div>
                    </div>
                    <div className='form_content_dashboard'>
                        <div className='filters_dashboard'>
                            <div className='buscador_dashboard'>
                                <SeacrhData
                                    searchTerm={searchTerm}
                                    handleSearchChange={handleSearchChange}
                                />
                            </div>
                            <div className='filtros_content_dashboard'>
                                <SortFilter sortConfig={sortConfig} onSortChange={handleSort}/>
                                <ViewToggle viewType={viewType} showCards={showCards} showList={showList} />
                            </div>
                        </div>
                        {/* Vista en formato de tarjetas */}
                        {viewType === 'cards' && (
                            <div className='layout_cards'>
                                {filteredData.length > 0 ? (
                                filteredData.map((item) => (
                                    <CardView
                                        key={item.id}
                                        titulo={item.name}
                                        subtitulo={item.crypter}
                                        parrafo={item.type}
                                        actionMenuProps={actionMenuProps}
                                        item={item}
                                        toggleMenuActions={toggleMenuActions}
                                        ref={menuRef}
                                        svg={item.icon}
                                    />
                                ))
                            ) : (
                                <p>No se encontraron registros</p>
                            )}
                            </div>
                        )}

                        {/* Vista en formato de lista */}
                        {viewType === 'list' && (
                            <div className='layout_list_dashboard'>
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('nombreAsc')}><h4>Permiso<IconSVG name="Icon_flechas_talentic"/></h4></th>
                                        <th><h4>Código</h4></th>
                                        <th><h4>Tipo</h4></th>
                                        <th><h4>Acciones</h4></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length > 0 ? filteredData.map((item) => (
                                        <ListView
                                            key={item.id}
                                            titulo={item.name}
                                            subtitulo={item.crypter}
                                            parrafo={item.type}
                                            actionMenuProps={actionMenuProps}
                                            item={item}
                                            toggleMenuActions={toggleMenuActions}
                                            ref={menuRef}
                                            svg={item.icon}
                                        />
                                    )) : (
                                        <tr>
                                            <td colSpan="3">No se encontraron registros</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                            
                        )}
                    </div>
                </div>
            </main>
            <Modal isOpen={isModalOpen} onClose={closeModal} titulo={"Permiso"}>
                {modalContent}
            </Modal>
        </div>
    );
};

export default TypeUsers;
