import React, { forwardRef } from 'react';

// Se utiliza forwardRef para permitir que el componente reciba una referencia (ref) desde su componente padre.
const Input = forwardRef(({
    type = "text", // Tipo de campo de entrada, por defecto es "text".
    name = "name", // Nombre del campo de entrada, por defecto es "name".
    value, // El valor actual del campo de entrada, pasado como una propiedad.
    placeholder = "nombre", // Texto que aparece cuando el campo está vacío, por defecto es "nombre".
    label = "Nombre", // Etiqueta que aparece junto al campo de entrada, por defecto es "Nombre".
    colClassName = "", // Clase adicional para controlar el diseño de la columna del campo.
    onChange // Función que se ejecuta cuando el valor del campo cambia.
}, ref) => {
    return (
        <div className={`inputContent col-12 ${colClassName}`}>
            {/* Contenedor para los elementos de entrada con clases para el diseño de la columna */}
            <div className="form-content-inputs">
                <label className="form-label">{label}</label> {/* Etiqueta del campo de entrada */}
                <input
                    ref={ref} // Se asigna la referencia al campo de entrada.
                    type={type} // Tipo de campo (texto, contraseña, etc.).
                    className="form-control required" // Clases para el estilo del campo.
                    name={name} // Nombre del campo, utilizado para identificación en formularios.
                    value={value} // Valor del campo, utilizado para enlazar con el estado.
                    placeholder={placeholder} // Texto de marcador de posición para el campo.
                    onChange={onChange} // Manejador de cambio de valor del campo.
                    autoComplete="off" // Deshabilita la autocompletación del navegador.
                />
            </div>
        </div>
    );
});

export default Input;
