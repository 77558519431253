import React from 'react';  // Importa React para crear el componente
import './../css/homesas.css';  // Importa el archivo de estilos específico para este componente
import './../css/styles.css';  // Importa el archivo de estilos generales
import './../css/app-c15aace3.css';  // Importa otro archivo de estilos

// Componente Info que describe qué es Talentic
function Info() {
    return (
        <div className="container mb-5 pb-5 li_custom">  {/* Contenedor principal del componente */}
            <div className="que_es mx-auto">  {/* Contenedor de la sección "Qué es Talentic" */}
                <h1 className="display-4 py-5">¿Qué es Talentic?</h1>  {/* Título principal */}
                
                <div className="row featurette align-items-start">  {/* Fila para organizar el contenido en dos columnas */}
                    
                    <div className="col-md-7 order-md-2">  {/* Columna que contiene el texto */}
                        <h2 className="featurette-heading fw-normal">
                            Talentic es un sistema de información documental de proyectos formativos de programas de formación presencial y virtual
                            <span className="text-muted"> para consulta del personal interno y externo de Cenigraf.</span>
                        </h2>
                        <p className="lead mt-5">
                            <i>"Talentic es un proyecto que nació en el año 2021 con el objetivo de construir un repositorio de resultados de proyectos formativos generados en los programas de formación titulada de la línea de contenidos digitales, que cumpliera la función de ser un espacio de consulta y preservación de la producción académica del centro y un espacio de referencia para la industria de los productos que producen los aprendices en el marco de sus formaciones."</i>
                        </p>
                    </div>

                    <div className="col-md-5 order-md-2">  {/* Columna que contiene la imagen */}
                        <img 
                            src="https://www.talentic.com.co/assets/front/images/queestalentic.gif" 
                            alt="¿Qué es Talentic?" 
                            width="100%"  // Imagen que se ajusta al ancho de su contenedor 
                        />
                    </div>

                    <div className="col-md-12 order-md-2 mt-5">  {/* Columna con la descripción y detalles adicionales */}
                        <p>En el marco de la ejecución de la primera fase, se desarrolló la construcción de un prototipo de repositorio que cumpliera con la finalidad planteada, además del establecimiento de un modelo de estandarización de características de los resultados finales de los proyectos a ser curados y registrados en el sistema, permitiendo que los mismos cumplan con estándares de registro y clasificación documental a nivel mundial.</p>

                        <p>En el marco de la ejecución, se detectaron algunas problemáticas en el proceso que ameritaban ser estimadas, estudiadas y que motivan la continuación del proyecto de investigación a una segunda fase de ejecución:</p>
                        <ul>
                            <li> {/* Lista de problemáticas detectadas */}
                                <p>En primer lugar, el Centro para la Industria de la Comunicación Gráfica presenta una oferta de formación en programas de las áreas de contenidos digitales y artes gráficas, siendo estas últimas las que dieron origen a la existencia del centro de formación. No tener en cuenta estos programas en el marco de la ejecución del proyecto implica perder la posibilidad de enriquecer la memoria de proyectos de formación generados por la institución en estas temáticas y aumenta el riesgo potencial de una pérdida de información de alto valor institucional.</p>
                            </li>
                            <li>
                                <p>En segundo lugar, es importante reconocer que una de las modalidades de formación más importantes que imparte el Sena y, en especial, el Centro para la Industria de la Comunicación Gráfica, es la modalidad de formación virtual. En la actualidad, el centro imparte formación bajo esta modalidad en 7 programas titulados (varios de los cuales pertenecen a la línea de contenidos digitales) con una oferta cercana a las 40 fichas de formación activas para diciembre de 2022. Se estima que para 2023, Cenigraf tenga un aproximado de 2300 aprendices en formación por modalidad virtual y se generen un aproximado de 100 resultados de proyectos de formación al finalizar su proceso. La valoración de estas evidencias y la consideración de acceso de los aprendices de modalidad virtual al prototipo de Talentic para poder ser partícipes como comunidad del proyecto y de sus resultados, amerita ampliar el alcance del proyecto actual y justifica una segunda fase de ejecución del proyecto.</p>
                            </li>
                            <li>
                                <p>Por último, de acuerdo con la resolución 1519 de 2020 del MinTic, la cual establece la necesidad de alinear los servicios de contenidos web desarrollados por entidades del estado hacia los estándares de la Accessibility Initiative (WCAG) establecidos por el W3 Consortium (W3C) y el acuerdo 010 de 2016 del Sena que favorece el enfoque pluralista y diferencial de la institución, se considera que una de las principales características que puede establecerse en la segunda fase de Talentic es la de fortalecer estos aspectos en el desarrollo del aplicativo, dando un mayor alcance y permitiendo una correcta consulta por parte de diferentes actores de la comunidad con capacidades diversas, tanto físicas como tecnológicas, permitiendo una mejor integración del aplicativo con la comunidad a la cual está dirigido.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;  // Exporta el componente Info para que pueda ser utilizado en otras partes de la aplicación 
