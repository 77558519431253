// Importa las dependencias necesarias de React
import React, { createContext, useState, useContext, useEffect } from 'react';

// Crea un contexto para la autenticación
const AuthContext = createContext();

// Hook personalizado para usar el contexto de autenticación en otros componentes
export const useAuth = () => useContext(AuthContext);

// Componente proveedor que gestiona el estado de autenticación y proporciona métodos relacionados
export const AuthProvider = ({ children }) => {
  // Estado que indica si el usuario está autenticado, se inicializa con un valor almacenado en localStorage
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const savedAuth = localStorage.getItem('isAuthenticated');
    return savedAuth === 'true'; // Se convierte a booleano
  });

  // Estado que almacena los datos del usuario, también inicializado desde localStorage
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null; // Si no hay usuario, se establece como null
  });

  // Función para iniciar sesión, guarda los datos del usuario y actualiza el estado
  const login = (userData) => {
    setIsAuthenticated(true); // Marca al usuario como autenticado
    setUser(userData); // Guarda los datos del usuario
    localStorage.setItem('isAuthenticated', 'true'); // Almacena el estado de autenticación en localStorage
    localStorage.setItem('user', JSON.stringify(userData)); // Almacena los datos del usuario
  };

  // Función para cerrar sesión, borra el estado y limpia localStorage
  const logout = () => {
    setIsAuthenticated(false); // Marca al usuario como no autenticado
    setUser(null); // Elimina los datos del usuario
    localStorage.removeItem('isAuthenticated'); // Borra el estado de autenticación de localStorage
    localStorage.removeItem('user'); // Borra los datos del usuario de localStorage
  };

  // Hook que ejecuta una acción cuando el componente se monta, carga el estado inicial desde localStorage
  useEffect(() => {
    const savedAuth = localStorage.getItem('isAuthenticated'); // Recupera el estado de autenticación
    const savedUser = localStorage.getItem('user'); // Recupera los datos del usuario
    if (savedAuth) {
      setIsAuthenticated(savedAuth === 'true'); // Actualiza el estado de autenticación
      if (savedUser) {
        setUser(JSON.parse(savedUser)); // Actualiza el estado del usuario
      }
    }
  }, []);

  // Devuelve el contexto de autenticación con las funciones y valores que otros componentes pueden usar
  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children} {/* Renderiza los componentes hijos dentro del proveedor */}
    </AuthContext.Provider>
  );
};
