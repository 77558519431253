import React from 'react';

// Definición del componente funcional BtnSubmit.
// Este componente recibe una propiedad `text` con un valor predeterminado "Guardar".
function BtnSubmit({ text = "Guardar" }) {
    return (
        // Contenedor principal del botón, con estilos CSS de margen y alineación.
        <div className="mt-3 col-12 d-flex flex-column align-items-end">
            {/* Botón de tipo "submit" con clase de estilo. 
                Se utiliza la propiedad `text` para definir el texto mostrado en el botón. */}
            <button type="submit" className="btn btn-outline-primary">{text}</button>
        </div>
    );
}

// Exporta el componente para que pueda ser usado en otros archivos.
export default BtnSubmit;
