import React from 'react'; // Importa React para utilizar JSX y otros hooks
import ReactDOM from 'react-dom/client'; // Importa ReactDOM para renderizar el componente raíz
import './css/index.css'; // Importa el archivo de estilos globales para la aplicación
import App from './App'; // Importa el componente principal App
import reportWebVitals from './reportWebVitals'; // Importa la función para medir el rendimiento de la aplicación

const root = ReactDOM.createRoot(document.getElementById('root')); // Crea un nodo raíz en el DOM
root.render( 
  <React.StrictMode> {/* StrictMode es un entorno de desarrollo que resalta potenciales problemas en la aplicación*/}
    <App /> {/* Renderiza el componente App como componente raíz*/}
  </React.StrictMode>
);

// reportWebVitals es una función opcional para medir el rendimiento de la aplicación
// Si deseas medir el rendimiento, puedes pasar una función de registro (por ejemplo, reportWebVitals(console.log))
// o enviar los resultados a un punto final de análisis. Aprende más: https://bit.ly/CRA-vitals
reportWebVitals(); 
