import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import CheckBox from '../../../components/checkbox';

import { validateFields } from "../utils/validateFields";
import { validationRules } from "../utils/validationRules";
import { toast } from 'react-toastify';

import OnlyFile from '../../../components/onlyFile';
import SelectDashboard from '../../../components/selectDashboard';
import InputDashboard from '../../../components/inputDashboard';
import TextareaDashboard from '../../../components/textareaDashboard';

const Step1 = ({
    formData,
    setFormData,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    dragActive,
    imageUrl,
    handleFileChange,
    handleDragOverIcon,
    handleDragLeaveIcon,
    handleDropIcon,
    dragActiveIcon,
    imageUrlIcon,
    handleFileChangeIcon,
    onNext,
    inputValue,
    handleRemoveTag,
    handleInputChange,
    handleKeyPress,
    handleKeyPressEnlaces,
    handleRemoveEnlaces,
    handleInputChangeEnlaces,
    inputValueEnlaces,
    handleCheckboxChangeFicha,
    action,
    update,
}) => {
    const [errors, setErrors] = useState({});
    const fichaWrapperRef = useRef(null);
    const [searchTermFicha, setsearchTermFicha] = useState('');
    const [apiDataFicha, setapiDataFicha] = useState([]);

    const refs = {
        name: useRef(),
        type: useRef(),
        deadline: useRef(),
        slug: useRef(),
        intro: useRef(),
        description: useRef(),
        portada: useRef(),
        icon: useRef(),

    };

    const fetchData = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            };

            const [responseFichas] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectFichaCreate" })
            ]);

            setapiDataFicha(
                Array.isArray(responseFichas.data)
                    ? responseFichas.data.map(item => ({
                          id: parseInt(item.id, 10),
                          name: item.name,
                          description: item.description,
                          state: item.state
                      }))
                    : [] // Devuelve un array vacío si no es un array
            );

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSearchChangeFicha = (e) => {
        setsearchTermFicha(e.target.value.toLowerCase());
    };

    const filteredDataFicha = apiDataFicha.filter(item =>
        (item.name || '').toLowerCase().includes(searchTermFicha) ||
        (item.description || '').toLowerCase().includes(searchTermFicha)
    );

    const validateStep1 = () => {
        // Validar campos usando las reglas para el paso 1
        const isValid = validateFields({
            formData,
            rules: validationRules.step1,
            setErrors,
            toast,
            refs,
        });

        if (isValid) {
            onNext();
        }
    };
    useEffect(() => {
        fetchData();
        // Detectar clic fuera de los componentes de búsqueda
        const handleClickOutside = (event) => {
            if (
                fichaWrapperRef.current &&
                !fichaWrapperRef.current.contains(event.target)
            ) {
                setsearchTermFicha('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="modal_user_content">
            <form className="modal_form">
                <div className='modal_form_item'>
                    <SelectDashboard
                        ref={refs.type}
                        label="Tipo de Proyecto"
                        name="type"
                        apiEndpoint="http://217.15.168.117:8080/api/"
                        requestBody={{
                            "API": "talentic",
                            "MODEL": "talentic",
                            "RESOURCE": "select",
                            "TYPE": "typeproject",
                            "key": "5b8d3b1f084b01c6a8387459e80d4bb9"
                        }}
                        onSelectChange={(e) => setFormData({ ...formData, type: e.target.value })}
                        defaultOption="tipo de proyecto"
                        value={formData.type}
                    />
                </div>
                {/* Buscador de Fichas */}
                <div className="modal_form_cont">
                    <div className="modal_form_item" ref={fichaWrapperRef}>
                        <InputDashboard
                            type="text"
                            id="searchFicha"
                            className="form-control"
                            placeholder="Buscar Ficha"
                            aria-label="Buscar Ficha"
                            aria-describedby="button-addon2"
                            value={searchTermFicha}
                            onChange={handleSearchChangeFicha}
                            icon="Icon_busqueda_talentic"
                            label="Fichas de formación"
                        />
                    </div>

                    {/* Lista del Buscador de Fichas */}
                    <div className="aprendices-list-dash" ref={fichaWrapperRef}>
                        {searchTermFicha ? (
                            filteredDataFicha.length > 0 ? (
                                filteredDataFicha.map((item) => (
                                    <CheckBox
                                        key={item.id}
                                        name="fichas"
                                        value={item.id}
                                        icon="bx-lock-alt"
                                        userName={item.name}
                                        description={item.description}
                                        checked={formData.fichas.includes(item.id)}
                                        handleCheckboxChange={handleCheckboxChangeFicha}
                                    />
                                ))
                            ) : (
                                <div id="no-results-dos">Ficha no encontrada</div>
                            )
                        ) : (
                            <></>
                        )}
                    </div>

                    {/* Lista de Fichas Seleccionadas */}
                    <div className="list_fichas_autores">
                        {formData.fichas.length > 0 ? (
                            formData.fichas.map((id) => {
                                const selectedItem = apiDataFicha.find((item) => item.id === id);
                                return selectedItem ? (
                                    <CheckBox
                                        key={selectedItem.id}
                                        name="fichas"
                                        value={selectedItem.id}
                                        icon="bx-lock-alt"
                                        userName={selectedItem.name}
                                        description={selectedItem.description}
                                        checked={true} // Siempre estará marcado porque ya está seleccionado
                                        handleCheckboxChange={handleCheckboxChangeFicha}
                                    />
                                ) : null;
                            })
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                {errors.type && <span className="error">{errors.type}</span>}
                <div className='modal_form_item'>
                    <InputDashboard
                        ref={refs.name}
                        name="name" 
                        label="Nombre del proyecto"
                        placeholder="Talentic" 
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                </div>
                {errors.name && <span className="error">{errors.name}</span>}
                <div className='modal_form_item'>
                    <InputDashboard
                        ref={refs.deadline}
                        name="deadline"
                        type="datetime-local"
                        label="Fecha final del proyecto"
                        value={formData.deadline}
                        onChange={(e) => setFormData({ ...formData, deadline: e.target.value })}
                        colClassName="col-lg-6"
                    />
                </div>
                {errors.deadline && <span className="error">{errors.deadline}</span>}
                <div className='modal_form_item'>
                    <InputDashboard
                        ref={refs.slug}
                        name="slug" 
                        label="Slug"
                        placeholder="slug" 
                        value={formData.slug}
                        onChange={(e) => setFormData({ ...formData, slug: e.target.value })}
                        colClassName="col-lg-6"
                    />
                </div>
                {errors.slug && <span className="error">{errors.slug}</span>}
                <div className='modal_form_item'>
                    <TextareaDashboard
                        ref={refs.intro}
                        name="intro" 
                        label="Texto Introductorio"
                        value={formData.intro}
                        onChange={(e) => setFormData({ ...formData, intro: e.target.value })}
                        colClassName=""
                    />
                </div>
                {errors.intro && <span className="error">{errors.intro}</span>}
                <div className='modal_form_item'>
                    <TextareaDashboard
                        ref={refs.description}
                        name="description" 
                        label="Descripción del proyecto"
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        colClassName=""
                    />
                </div>
                {errors.description && <span className="error">{errors.description}</span>}
                <div className="modal_form_cont">
                    <InputDashboard
                        type="text"
                        value={inputValue}
                        className='form-control required'
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                        placeholder="palabras clave"
                        label="Palabras claves"
                    />
                    <ul className='ps-3 d-flex flex-wrap'>
                        {formData.palabrasClave.map((tag, index) => (
                            <li 
                                key={index}
                                style={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '5px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleRemoveTag(index)}
                                className='badge bg-label-dark'
                            >
                                {tag}
                            </li>
                        ))}
                    </ul>
                </div>
                <span className='modal_form_item_title'>Portada del proyecto</span>
                <div className='modal_form_item'>
                    <OnlyFile
                        ref={refs.portada}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        dragActive={dragActive}
                        imageUrl={imageUrl}
                        handleFileChange={handleFileChange}
                        name='portada'
                    />
                </div>
                {errors.portada && <span className="error">{errors.portada}</span>}
                
                <span className='modal_form_item_title'>Logo del proyecto</span>
                <div className='modal_form_item'>
                    <OnlyFile
                        ref={refs.icon}
                        onDragOver={handleDragOverIcon}
                        onDragLeave={handleDragLeaveIcon}
                        onDrop={handleDropIcon}
                        dragActive={dragActiveIcon}
                        imageUrl={imageUrlIcon}
                        handleFileChange={handleFileChangeIcon}
                        name='icon'
                    />
                </div>
                {errors.icon && <span className="error">{errors.icon}</span>}
                <div className="mt-3 col-12 d-flex justify-content-between">

                    {action === "update" ? (
                        <div className="btn btn-outline-primary" onClick={update} >Actualizar</div>
                    ) : (
                        <></>
                    )}
                
                    
                    <div className="btn btn-outline-primary" onClick={() => validateStep1()} >Siguiente</div>
                </div>
            </form>
        </div>
    );
};

export default Step1;
