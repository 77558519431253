// Importa las dependencias necesarias para el componente
import React, { useRef, useState, useEffect } from 'react'; // hooks de React para manejar referencias, estado y efectos
import { Link } from 'react-router-dom'; // Componente de React Router para navegación entre rutas
import { toast } from 'react-toastify'; // Librería para mostrar notificaciones tipo toast
import axios from 'axios'; // Librería para realizar solicitudes HTTP

// Importación de componentes adicionales
import Modal from './modal'; // Modal para mostrar contenido emergente
import CutText from './cutString'; // Componente para cortar texto de manera controlada
import { useAuth } from './AuthContext'; // Contexto de autenticación para manejar el estado del usuario

// Importa los estilos CSS para el componente
import './../css/app-c15aace3.css'; // Hoja de estilos CSS relacionada con el componente

// Iconos SVG para usar en el componente
import IconSVG from '../contents/icons/icon'; // Componente para mostrar iconos SVG

// Componentes para compartir en redes sociales y enviar mensajes
import SharedSocial from '../forms/sharedSocial'; // Componente para compartir contenido en redes sociales
import SendMensaje  from './../forms/sendMensaje'; // Componente para enviar mensajes

// Función para obtener el ID de un video de una URL de YouTube
const getIdFromUrl = (url) => {
    // Expresión regular para capturar el ID del video de YouTube
    const regex = /(?:v=|\/v\/|embed\/|be\/)([a-zA-Z0-9_-]{11})/;
    // Ejecuta la expresión regular sobre la URL proporcionada
    const match = url.match(regex);
    // Si se encuentra una coincidencia, retorna el ID del video (que está en el primer grupo de captura)
    return match && match[1];
};

function Short({ slides }) {
    // Estado para almacenar la imagen del banner, inicialmente se carga una imagen por defecto desde la ruta especificada.
    const [img_banner, setimg_banner] = useState(require('../img/nav/images.jpeg'));

    // Estado para almacenar el ícono, inicialmente se carga una imagen por defecto desde la ruta especificada.
    const [ico, setico] = useState(require('../img/nav/images.jpeg'));

    // Estado para manejar la clase de animación de entrada, con el valor predeterminado 'fade-in'.
    const [fadeClass, setFadeClass] = useState('fade-in');

    // Estado para gestionar el índice actual, inicialmente se establece en 1.
    const [currentIndex, setCurrentIndex] = useState(1);

    // Estado para almacenar la descripción, inicialmente vacía.
    const [description, setdescription] = useState('');

    // Estado para almacenar el enlace del video de YouTube, inicialmente vacío.
    const [videoYT, setvideoYT] = useState('');

    // Estado para almacenar el valor de "asignado", inicialmente vacío.
    const [asignado, setasignado] = useState('');

    // Estado para almacenar el valor de "guardado", inicialmente vacío.
    const [guardado, setguardado] = useState('');

    // Estado para almacenar el ID activo, inicialmente no hay ID seleccionado (null).
    const [activeId, setActiveId] = useState(null);

    // Estado para almacenar un objeto de índice, inicialmente vacío.
    const [index, setIndex] = useState({});

    // Estado para almacenar el nombre, inicialmente vacío.
    const [name, setname] = useState('');

    // Estado para almacenar la posición, inicialmente vacío.
    const [position, setposition] = useState('');

    // Referencia para manipular el slide, si es necesario en el DOM.
    const slideRef = useRef(null);

    // Extrae el estado de autenticación utilizando el hook `useAuth`, que probablemente proporciona un contexto de autenticación.
    const { isAuthenticated } = useAuth();

    // Estado para controlar la visibilidad de un modal, inicialmente cerrado (false).
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Estado para almacenar el contenido que se mostrará dentro del modal, inicialmente vacío.
    const [modalContent, setModalContent] = useState(null);

    const intervalRef = useRef(null); // Referencia para el intervalo

    // Estado para almacenar los datos del formulario, que incluye el índice de usuario almacenado en el `localStorage`.
    // Si no hay usuario en el `localStorage`, se asigna un valor vacío.
    const [formData] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        return {
            user: user?.index_user || ''  // Si el usuario no existe, se asigna un valor vacío.
        };
    });

    // Función para reiniciar el intervalo
    const resetInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current); // Limpia el intervalo actual
        }

        intervalRef.current = setInterval(() => {
            setCurrentIndex((prevIndex) => {
                const nextIndex = (prevIndex + 1) % slides.length; // Calcula el siguiente índice
                updateSlide(nextIndex); // Actualiza el slide
                return nextIndex;
            });
        }, 20000); // Reinicia el intervalo con 20 segundos
    };

    useEffect(() => {
        // Si existen elementos en el array 'slides', se ejecuta el código
        if (slides.length > 0) {
            // Se crea un objeto 'indexedData' que mapea cada 'id' de los slides a su respectivo objeto
            const indexedData = slides.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});
            // Se guarda el objeto 'indexedData' en el estado
            setIndex(indexedData);
    
            // Se establece el primer elemento como el inicial
            const firstItem = slides[0];
            setico(firstItem.ico || require('../img/nav/images.jpeg'));
            setimg_banner(firstItem.image || require('../img/nav/images.jpeg'));
            setname(firstItem.name || '');
            setdescription(firstItem.description || '');
            setActiveId(firstItem.id);
            setposition(firstItem.position);
            setvideoYT(firstItem.videoYT);
            setasignado(firstItem.asignado);
            setguardado(firstItem.guardado);
    
            // Intervalo que actualiza la imagen de fondo cada 20 segundos
            resetInterval(); // Inicia el intervalo al cargar
        }
    }, [slides]);
    
    // Función que actualiza el slide basado en el índice
    const updateSlide = (index) => {
        setFadeClass('fade-out'); // Aplica una animación de desvanecimiento al cambiar de slide
        setTimeout(() => {
            const item = slides[index];
            // Se actualizan los valores del nuevo slide
            setico(item.ico || require('../img/nav/images.jpeg'));
            setimg_banner(item.image || require('../img/nav/images.jpeg'));
            setname(item.name || '');
            setdescription(item.description || '');
            setActiveId(item.id);
            setFadeClass('fade-in'); // Aplica una animación de entrada para el nuevo slide
            setposition(item.position || '');
            setCurrentIndex(item.position);
            setvideoYT(item.videoYT);
            setasignado(item.asignado);
            setguardado(item.guardado);

            resetInterval(); // Reinicia el intervalo después de actualizar el slide

        }, 500); // Tiempo para que la animación de fade-out termine
    };
    
    // Función que maneja el movimiento hacia la derecha en los slides
    const updateR = () => {
        if (position == 1) {
            updateSlide(4); // Si está en el primer slide, va al último
        } else {
            updateSlide(position - 2); // De lo contrario, mueve un slide hacia atrás
        }
    };
    
    // Función que maneja el movimiento hacia la izquierda en los slides
    const updateL = () => {
        if (position == 5) {
            updateSlide(0); // Si está en el último slide, va al primero
        } else {
            updateSlide(position); // De lo contrario, se queda en el mismo slide
        }
    };
    
    // Función que maneja el clic en un item para actualizar el contenido
    const handleItemClick = (item) => {
        const itemId = parseInt(item.getAttribute('data-index'), 10); // Asegúrate de que sea un número
    
        const selectedSlide = slides.find((slide) => slide.id === itemId); // Encuentra el elemento en el array
        if (selectedSlide) {
            setico(selectedSlide.ico || require('../img/nav/images.jpeg'));
            setimg_banner(selectedSlide.image || require('../img/nav/images.jpeg'));
            setname(selectedSlide.name || '');
            setdescription(selectedSlide.description || '');
            setvideoYT(selectedSlide.videoYT);
            setasignado(selectedSlide.asignado);
            setguardado(selectedSlide.guardado);
            setActiveId(itemId); // Actualiza el estado de `activeId`
            setCurrentIndex(itemId); // Actualiza el índice actual

            resetInterval(); // Reinicia el intervalo al seleccionar un item
        } else {
            console.error(`No se encontró un slide con id: ${itemId}`);
        }
    };
    
    // Función para cerrar el modal
    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };
    
    // Función para cerrar el modal con un mensaje de éxito
    const closeModalSuccess = () => {
        toast.success("Se envio con éxito"); // Muestra un mensaje de éxito con toast
        setIsModalOpen(false);
        setModalContent(null);
    };
    
    // Función para abrir un modal con un formulario de mensaje
    const openModalWithFormMenssaje = (FormComponent, link) => {
        setModalContent(<FormComponent
            project={link}
            userSend={formData.user}
            onSubmit={closeModalSuccess}
        />);
        setIsModalOpen(true);
    };
    
    // Función para abrir un modal con un formulario de compartir en redes sociales
    const openModalWithFormSharedSocial = (FormComponent, link) => {
        setModalContent(<FormComponent
            project={link}
        />);
        setIsModalOpen(true);
    };
    
    // Función para agregar un proyecto a favoritos
    const addFavorites = async (e, link, name) => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "favorites",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "PUT",
            "proyect": link,
            "user": formData.user
        };
    
        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            setasignado(1); // Actualiza el estado a asignado
        } catch (error) {
            // Manejo de errores con mensajes de toast
            toast.error(error);
            if (error.response) {
                toast.error('Request failed:', error.response.data.message);
            } else if (error.request) {
                toast.error('No response received:', error.request);
            } else {
                toast.error('Error setting up request:', error.message);
            }
        }
    };
    
    // Función para eliminar un proyecto de favoritos
    const removeFavorites = async (e, link, name) => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "favorites",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "DELETE_FORCE",
            "Z": "favorites",
            "proyect": link,
            "user": formData.user
        };
    
        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setasignado(0); // Actualiza el estado a no asignado
        } catch (error) {
            // Manejo de errores con mensajes de toast
            toast.error(error);
            if (error.response) {
                toast.error('Request failed:', error.response.data.message);
            } else if (error.request) {
                toast.error('No response received:', error.request);
            } else {
                toast.error('Error setting up request:', error.message);
            }
        }
    };
    
    // Función para agregar un proyecto a la lista de guardados
    const addsaved = async (e, link, name) => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "saved",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "PUT",
            "proyect": link,
            "user": formData.user
        };
    
        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            setguardado(1); // Actualiza el estado a guardado
        } catch (error) {
            // Manejo de errores con mensajes de toast
            toast.error(error);
            if (error.response) {
                toast.error('Request failed:', error.response.data.message);
            } else if (error.request) {
                toast.error('No response received:', error.request);
            } else {
                toast.error('Error setting up request:', error.message);
            }
        }
    };
    
    // Función para eliminar un proyecto de la lista de guardados
    const removeSaved = async (e, link, name) => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "saved",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "DELETE_FORCE",
            "Z": "saved",
            "proyect": link,
            "user": formData.user
        };
    
        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setguardado(0); // Actualiza el estado a no guardado
        } catch (error) {
            // Manejo de errores con mensajes de toast
            toast.error(error);
            if (error.response) {
                toast.error('Request failed:', error.response.data.message);
            } else if (error.request) {
                toast.error('No response received:', error.request);
            } else {
                toast.error('Error setting up request:', error.message);
            }
        }
    };    

    if (slides.length === 0) {
        return null;
    }

    return (
        <>
            <section className="banner__content_all">
                <div className="banner_content_multimedia">
                    <div className="content_multimedia">
                        {videoYT ? (
                            <iframe 
                                width="560" 
                                height="315" 
                                src={`https://www.youtube.com/embed/${getIdFromUrl(videoYT)}?autoplay=1&mute=1&controls=0&modestbranding=1&showinfo=0&iv_load_policy=3&rel=0&loop=1`}
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen>
                            </iframe>
                            
                        ) : (
                            <img
                                src={`${img_banner}`}
                                className={`m-0 img-content ${fadeClass}`}
                                alt=""
                            />
                        )}
                    </div>
                </div>
                <div className="banner__content_info">
                    <section className="banner_first_part">
                        <figure className="banner__img_proyect">
                            <img 
                                src={`${ico}`}
                                className={`${fadeClass}`}
                                alt=""
                            />
                        </figure>
                        <figcaption className="banner__info_tittle">
                            <h3>{name}</h3>
                            <p>ago. 04, 2024 | CENIGRAF | Producción multimedia</p>
                        </figcaption>
                    </section>
                    <div className="banner__info_text">
                        <p>{description}</p>
                        <section className="btn_buttons">
                            <Link to={`/detailsProyects/${activeId}`} >
                                <div className="btn_action">Ver más</div>
                            </Link>
                            {(isAuthenticated ? (
                                    <>
                                        <div className={`${asignado === 1 ? 'widgetsbannerOn' : 'widgetsbanner'} btn_interaction`} onClick={(e) => asignado === 1 ? 
                                                    removeFavorites(e, activeId, name) : 
                                                    addFavorites(e, activeId, name)
                                                }>
                                            <IconSVG name="Icon_corazon_talentic_bl" />
                                        </div>
                                        <div className={`${guardado === 1 ? 'widgetsbannerOn' : 'widgetsbanner'} btn_interaction`} onClick={(e) => guardado === 1 ? 
                                                    removeSaved(e, activeId, name) : 
                                                    addsaved(e, activeId, name)
                                                }>
                                            <IconSVG name="Icon_favorito_talentic_bl" />
                                        </div>
                                        <div className={`btn_interaction`} onClick={() =>
                                            openModalWithFormMenssaje(SendMensaje, activeId)}>
                                            <IconSVG name="Icon_enviar_talentic_bl" />
                                        </div>
                                        
                                        </>
                                    ) : (
                                        ''
                                    )
                                )}
                                
                                <div
                                    className='widgetsbanner mx-2 px-2'
                                    style={{ borderRadius: `12rem` }}
                                    onClick={() => openModalWithFormSharedSocial(SharedSocial, activeId)}
                                >
                                    <IconSVG name="Icon_compartir_talentic_bl" width="25px" style={{marginRight: '8px', cursor: 'pointer'}} />
                                    Compartir
                                </div>
                        </section>
                    </div>
                </div>
                <div className="banner_content_proyects">
                    <p>Top proyectos</p>
                        <div className="arrow_top">
                            <img src="https://cdn-icons-png.flaticon.com/512/271/271220.png" alt="img"/>
                        </div>
                        <div className="banner_proyects">
                            {slides.map((slide, index) => (
                                <div key={index} data-index={slide.id} className={`banner_card_proyect ${activeId === slide.id ? 'banner__card_on' : ''}`} onClick={(e) => handleItemClick(e.currentTarget)}>
                                    <div className={`banner_card_number ${activeId === slide.id ? 'banner_card_number_active' : ''}`}>
                                        {index + 1}
                                    </div>
                                    <figure className="banner__card_img">
                                        <img src={slide.image} alt={slide.name}/>
                                    </figure>
                                    <figcaption>
                                        {slide.name}
                                    </figcaption>
                                </div>
                            ))}
                        </div>
                        <div className="arrow_top botton">
                            <img src="https://cdn-icons-png.flaticon.com/512/271/271220.png" alt="img" />
                        </div>
                </div>
                <div className="benner_quotes">
                    {slides.map((_, index) => (
                        <div
                            key={index}
                            className={`${activeId === slides[index].id ? 'active_quote' : ''}`} // Cambia el color si es el activo
                            onClick={() => updateSlide(index)} // Función para cambiar el slide cuando se hace click en el indicador
                        />
                    ))}
                </div>
            </section>

            <Modal isOpen={isModalOpen} onClose={closeModal} widthValue='300px'>
                {modalContent}
            </Modal>
        </>
    );
}

export default Short;
