import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import axios from 'axios';

import CreateTypeUsers from './create';
import UpdateTypeUsers from './update';
import ShowTypeUsers from './show';

import SeacrhData from '../../components/searchData';
import IconSVG from '../../contents/icons/icon';
import Modal from '../../components/modal';
import Icon from '../../components/icons';

const TypeUsers = ( ) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [apiData, setApiData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    
    const hashToVer = "3547d37ab3a1355871fda3745cc61987";
    const hashToCrear = "ef24c0e63a6a1e95f784ffc284d92881";
    const hashToEditar = "062109723c36d5604eaf69a3ab7f2eaf";
    const hashToEliminar = "7c0963f3b4c59d79a9b1057fe2154537";

    const [formDatauser] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        return {
            permisos: user?.permissions || ''
        };
    });

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = () => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "list",
            "TYPE": "typeusers",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                console.log(response);
                const cleanedData = response.data.map(item => ({
                    id: item.id,
                    name: item.name,
                    description: item.description
                }));
                setApiData(cleanedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    const handleChangecrear = (newState) => {
        toast.success("Se gestionaron los datos con éxito");
        setIsModalOpen(false);
        setModalContent(null);
        fetchData();
    };

    const handleChangeeditar = (newState) => {
        toast.success("Se editaron los datos con éxito");
        setIsModalOpen(false);
        fetchData();
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = apiData.filter(item =>
        (item.name || '').toLowerCase().includes(searchTerm) ||
        (item.description || '').toLowerCase().includes(searchTerm)
    );

    const handleDelete = (itemId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este registro?')) {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "typeusers",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "DELETE",
                "id": itemId
            };

            axios.post('http://217.15.168.117:8080/api/', requestBody)
                .then(response => {
                    fetchData();
                })
                .catch(error => {
                    console.error('Error deleting data:', error);
                });
        }
    };

    const handleCreateClick = (FormComponent) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent onSubmitState={handleChangecrear}/>);
    };

    const handleEditClick = (FormComponent, id, name, description) => {
        setIsModalOpen(true);
        setModalContent(
            <FormComponent
                onUPSubmitState={handleChangeeditar}
                idDefault={id}
                nameDefault={name}
                descriptionDefault={description}
            />
        );
    };
    
    const handleShowClick = (FormComponent, name, description) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent 
            onUPSubmitState={handleChangeeditar}
            nameDefault={name}
            descriptionDefault={description}
        />);
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                    <SeacrhData
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                        btnCreate={
                            formDatauser.permisos.includes(hashToCrear) ? (
                                <div className="col-lg-2 ps-2">
                                    <h5 className="card d-flex align-self-stretch justify-content-center m-0">
                                        <button 
                                            onClick={() => handleCreateClick(CreateTypeUsers)}
                                            className="btn btn-outline-primary"
                                        >
                                            Crear Nuevo
                                        </button>
                                    </h5>
                                </div>
                            ) : null 
                        }
                    />
                    
                    <div className="card">
                        <div className="card-body">
                            <div className="aprendices-list">
                                {filteredData.length > 0 ? filteredData.map((item) => (
                                    <div 
                                        className={`buscarDos usuario__encontrado`}
                                        data-user={`${item.name} ${item.description}`}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <Icon code="&#xe45a;" />
                                            <div className='ms-3'>
                                                <h6 className="mb-0">{item.name}</h6>
                                                <small className="text-muted">{item.description}</small>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            {formDatauser.permisos.includes(hashToVer) ? (
                                                <div 
                                                    className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                                    style={{ width: '54px'}}
                                                    onClick={(e) => {
                                                        handleShowClick(ShowTypeUsers, item.name, item.description);
                                                    }}
                                                >
                                                    <IconSVG name="Icon_ver_talentic" width="25px"/>
                                                    <small>VER</small>
                                                </div>
                                            ): null }
                                            {formDatauser.permisos.includes(hashToEditar) ? (
                                                <div 
                                                    className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                                    style={{ width: '54px'}}
                                                    onClick={(e) => {
                                                        handleEditClick(UpdateTypeUsers, item.id, item.name, item.description);
                                                    }}
                                                >
                                                    <IconSVG name="Icon_editar_talentic" width="20px"/>
                                                    <small>EDITAR</small>
                                                </div>
                                            ): null }
                                            {formDatauser.permisos.includes(hashToEliminar) ? (
                                                <div 
                                                    className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                                    style={{ width: '54px'}} 
                                                    onClick={(e) => {
                                                        handleDelete(item.id, item.state); // Llamar a la función de eliminación
                                                    }}
                                                >
                                                    <IconSVG name="Icon_eliminar_talentic" width="20px"/>
                                                    <small>ELIMINAR</small>
                                                </div>
                                            ): null }
                                        </div>
                                    </div>
                                )) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">No se encontraron registros</td>
                                    </tr>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} widthValue='400px'>
                {modalContent}
            </Modal>
        </>
    );
};

export default TypeUsers;
