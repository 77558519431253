// Importación de dependencias necesarias. React y forwardRef para crear un componente que pueda recibir una referencia externa.
import React, { forwardRef } from 'react';
// Importación del componente IconSVG para mostrar íconos SVG.
import IconSVG from '../contents/icons/icon';

// Definición del componente TextareaDashboard utilizando forwardRef para que pueda recibir una referencia externa (ref).
const TextareaDashboard = forwardRef(({
    type = "text",  // Tipo de input por defecto, en este caso "text".
    name,           // El nombre del campo de texto.
    value,          // El valor del campo de texto (controlado).
    placeholder = "nombre",  // El texto por defecto en el placeholder.
    label = "Nombre",       // El texto de la etiqueta que aparece antes del textarea.
    colClassName = "",      // Clase opcional para personalizar el estilo del contenedor.
    onChange,       // Función que maneja el cambio de valor en el campo.
    icon            // Icono que se muestra junto al textarea, se pasa como prop.
}, ref) => {

    // Estructura del componente. 
    // El componente devuelve un contenedor div con la clase "input_dashboard".
    return (
        <div className="input_dashboard">
            {/* Etiqueta que se muestra arriba del textarea. Usa el atributo "for" para enlazar con el id del textarea. */}
            <label for={name}>{label}</label>
            {/* Contenedor donde se ubica el icono y el textarea. */}
            <section className='input_dashboard_content'>
                {/* El componente IconSVG recibe el nombre del ícono que se pasa como prop. */}
                <IconSVG name={icon}/>
                {/* Elemento textarea donde se pasa el ref, tipo, nombre, valor, placeholder y onChange. */}
                <textarea
                    ref={ref}               // Asocia el ref con el textarea para poder acceder desde el componente padre.
                    type={type}             // Establece el tipo del campo (aunque textarea no utiliza type).
                    className="required"    // Aplica la clase "required", lo que podría implicar validación en el formulario.
                    name={name}             // El nombre del campo para referencia en el formulario.
                    value={value}           // El valor del campo (controlado), se sincroniza con el estado del componente padre.
                    placeholder={placeholder} // Texto que aparece dentro del textarea cuando está vacío.
                    onChange={onChange}     // Función que se ejecuta cuando cambia el valor del textarea.
                    autoComplete="off"      // Desactiva el autocompletado del navegador.
                    id={name}               // Asigna un id único basado en el nombre del campo.
                />
            </section>
        </div>
    );
});

// Exportación del componente para que pueda ser utilizado en otras partes de la aplicación.
export default TextareaDashboard;
