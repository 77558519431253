import React from 'react';

// Componente principal FAQAccordion
const FAQAccordion = () => {
  return (
    <>
        {/* Contenedor principal */}
        <div className="container">
        <div className="que_es mx-auto">
            <div className="row featurette">
                {/* Título de la sección */}
                <h1 className="display-4 py-5">Preguntas frecuentes</h1>
                
                {/* Columna 1 - Primer conjunto de preguntas */}
                <div className="col-md-6">
                    <div className="accordion" id="accordionExample1">
                    {/* Mapea los elementos de accordionItems1 */}
                    {accordionItems1.map((item, index) => (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={item.headingId}>
                                <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#${item.collapseId}`}
                                aria-controls={item.collapseId}
                                >
                                {/* Muestra la pregunta */}
                                {item.question}
                                </button>
                            </h2>
                            {/* Contenido desplegable */}
                            <div
                                id={item.collapseId}
                                className="accordion-collapse collapse"
                                aria-labelledby={item.headingId}
                                data-bs-parent="#accordionExample1"
                            >
                                <div className="accordion-body">
                                    <strong>{item.bodyTitle}</strong> {item.bodyContent}
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>

                {/* Columna 2 - Segundo conjunto de preguntas */}
                <div className="col-md-6">
                    <div className="accordion" id="accordionExample2">
                    {/* Mapea los elementos de accordionItems2 */}
                    {accordionItems2.map((item, index) => (
                        <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={item.headingId}>
                            <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${item.collapseId}`}
                            aria-controls={item.collapseId}
                            >
                            {/* Muestra la pregunta */}
                            {item.question}
                            </button>
                        </h2>
                        {/* Contenido desplegable */}
                        <div
                            id={item.collapseId}
                            className="accordion-collapse collapse"
                            aria-labelledby={item.headingId}
                            data-bs-parent="#accordionExample2"
                        >
                            <div className="accordion-body">
                            <strong>{item.bodyTitle}</strong> {item.bodyContent}
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
        </div>
        {/* Línea de separación */}
        <hr className="divididor"></hr>
    </>
  );
};

// Definición de los elementos de la primera columna de preguntas
const accordionItems1 = [
  {
    headingId: 'headingOne',
    collapseId: 'collapseOne',
    question: '1. ¿Cómo funciona Talentic?',  // Pregunta de la primera columna
    bodyTitle: "This is the first item's accordion body.",  // Título del cuerpo
    bodyContent:
      "It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element...", // Contenido de la respuesta
  },
  {
    headingId: 'headingTwo',
    collapseId: 'collapseTwo',
    question: '2. ¿Cuales son los beneficios para mi proyecto?', // Pregunta de la primera columna
    bodyTitle: "This is the second item's accordion body.",  // Título del cuerpo
    bodyContent:
      "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element...", // Contenido de la respuesta
  },
  // Se pueden agregar más elementos en este array
];

// Definición de los elementos de la segunda columna de preguntas
const accordionItems2 = [
  {
    headingId: 'headingEleven',
    collapseId: 'collapseEleven',
    question: '11. ¿Es correcto contactar a los empresarios?',  // Pregunta de la segunda columna
    bodyTitle: "This is the third item's accordion body.",  // Título del cuerpo
    bodyContent:
      "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element...", // Contenido de la respuesta
  },
  {
    headingId: 'headingTwelve',
    collapseId: 'collapseTwelve',
    question: '12. ¿Como encuentro un proyecto?',  // Pregunta de la segunda columna
    bodyTitle: "This is the third item's accordion body.",  // Título del cuerpo
    bodyContent:
      "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element...", // Contenido de la respuesta
  },
  // Se pueden agregar más elementos en este array
];

// Exporta el componente FAQAccordion para su uso en otros archivos
export default FAQAccordion;
