import React, { forwardRef } from 'react';  // Importa React y forwardRef de la librería React
import IconSVG from '../contents/icons/icon';  // Importa el componente IconSVG para mostrar íconos

// Componente InputDashboard que utiliza forwardRef para permitir la referencia al input
const InputDashboard = forwardRef(({
    type = "text",  // Define el tipo de input, por defecto es 'text'
    name,  // Propiedad del nombre del input (utilizado en el atributo 'name' y 'id')
    value,  // Valor del input, generalmente vinculado al estado
    placeholder = "nombre",  // Texto que se muestra cuando el campo está vacío
    label = "Nombre",  // Texto que aparece como la etiqueta del input
    colClassName = "",  // Clase adicional para el contenedor (puede ser para estilos responsivos)
    onChange,  // Función que se llama cuando cambia el valor del input
    icon,  // Nombre del ícono a mostrar en el input (debe ser pasado como prop)
    onKeyDown  // Función que se llama cuando se presiona una tecla dentro del input
}, ref) => {

    return (
        <div className="input_dashboard">  {/* Contenedor principal del input */}
            <label htmlFor={name}>{label}</label>  {/* Etiqueta para el input, el atributo 'htmlFor' se vincula con el 'id' */}
            <section className='input_dashboard_content'>  {/* Sección que envuelve el ícono y el input */}
                <IconSVG name={icon}/>  {/* Componente IconSVG que muestra el ícono */}
                <input
                    ref={ref}  //* Asigna la referencia al input (usado por 'forwardRef') 
                    type={type}  //* Tipo de input, como texto, email, etc. 
                    className="required"  //* Clase de estilo para indicar que el input es obligatorio 
                    name={name}  //* Atributo 'name' utilizado en el formulario 
                    value={value}  //* Vincula el valor del input con el estado 
                    placeholder={placeholder}  //* Texto de sugerencia cuando el input está vacío 
                    onChange={onChange}  //* Llama a la función pasada por prop cuando el valor cambia 
                    autoComplete="off"  //* Desactiva la función de autocompletado en el navegador 
                    id={name}  //* Establece el 'id' del input para asociarlo con la etiqueta 
                    onKeyDown={onKeyDown}  //* Función que se ejecuta cuando se presiona una tecla 
                />
            </section>
        </div>
    );
});

export default InputDashboard;  // Exporta el componente para que se pueda usar en otras partes de la aplicación
