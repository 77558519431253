import React, { useState } from 'react';

// Importación de componentes externos e íconos
import IconSVG from '../contents/icons/icon';
import Notifications from '../components/notifications';
import Menssages from '../components/menssages';
import UserMenu from '../components/userMenu';

import '../css/app-c15aace3.css';

// Componente NavDashBoard
function NavDashBoard({ titulo }) {
    // Estado inicial con datos del usuario almacenados en el localStorage
    const [formData] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user')); // Obtener los datos del usuario desde localStorage
        return {
          name: user?.name || '',           // Nombre del usuario
          person: user?.person || '',       // Persona asociada
          lastName: user?.lastName || '',   // Apellido del usuario
          notifyChangePass: user?.notifyChangePass === 0 ? true : false, // Estado de notificación de cambio de contraseña
          notifyMessages: user?.notifyMessages === 0 ? true : false, // Estado de notificación de mensajes
          img: user?.img || '',             // Imagen del usuario
          email: user?.email || '',         // Correo electrónico
          dni: user?.dni || ''              // DNI del usuario
        };
    });

    // Función para manejar el clic en el ícono de menú y alternar la visibilidad del menú
    const handleClick = () => {
        const htmlElement = document.querySelector('#layout-menu_dashboard'); // Selección del menú de navegación
        const htmlElementBg = document.querySelector('.bg_das');  // Fondo para el menú
        htmlElement.classList.toggle('layout-menu_dashboard_active'); // Alterna la clase activa en el menú
        htmlElementBg.classList.toggle('layout__pagina_dashboard_active'); // Alterna la clase activa en el fondo
    };

    return (
        <div className='content_submenu__dashboard'> {/* Contenedor principal para el submenú */}
            <header className="submenu__dashboard"> {/* Encabezado del submenú */}
                <div className='submenu__dashboard_icon' onClick={handleClick}>  {/* Ícono del menú, que activa el cambio de clases */}
                    <div className='submenu__dashboard_user_icon icon_action_menu_dasboard'>
                        <IconSVG name="Icon_salir_talentic"/> {/* Icono SVG para "Salir" */}
                    </div>
                </div>
                <section className='submenu__dashboard_user'> {/* Sección para los íconos de usuario */}
                    <div className='submenu__dashboard_icon'>
                        <div className='submenu__dashboard_user_icon'>
                            <Menssages/> {/* Componente de mensajes */}
                        </div>
                    </div>
                    <div className='submenu__dashboard_icon'>
                        <div className='submenu__dashboard_user_icon'>
                            <Notifications/> {/* Componente de notificaciones */}
                        </div>
                    </div>
                    <div className='submenu__dashboard_user_info'>
                        <UserMenu/> {/* Componente de menú de usuario */}
                    </div>
                </section>
            </header>
        </div>
    );
}

export default NavDashBoard; 
