import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';

import { validateFields } from "./utils/validateFields";
import { validationRules } from "./utils/validationRules";

import IconSVG from '../../contents/icons/icon';

const MultiStepForm = ({ onSubmitState, closeModal, idDefault }) => {
    const id  = idDefault;

    const [imageUrlIcon, setimageUrlIcon] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const [inputValueEnlaces, setinputValueEnlaces] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [dragActiveIcon, setdragActiveIcon] = useState(false);
    const [dragActiveM, setDragActiveM] = useState(false);
    const [dragActive, setDragActive] = useState(false);

    const [errors, setErrors] = useState({});
    
    const [formData, setFormData] = useState({
        name: '', type: '', slug: '', intro: '', description: '', deadline: '', portada: null, icon: null,
        aprendices: [], instructores: [], fichas: [], files: [], palabrasClave: [], enlaces: []
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "proyecto": id
            };

            const [responseAprendiz, responseInstructor, responseFichas, responseDetails, responseKeywords, responseEnlaces, responseFiles] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectAprendiz" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectInstructor" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectFicha" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "proyectsDetails" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "proyectsKeywords" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "proyectsEnlaces" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "proyectsFiles" }),
            ]);

            
            const processedDataAperndiz = responseAprendiz.data
                .filter(item => item.asignado === 1)
                .map(item => item.id);

            const processedDataInstructor = responseInstructor.data
                .filter(item => item.asignado === 1)
                .map(item => item.id);
            
            const processedDataFicha = responseFichas.data
                .filter(item => item.asignado === 1)
                .map(item => item.id);
            
            const processedDataKeywords = responseKeywords.data
                .map(item => item.name);
                
            const processedDataEnlaces = responseEnlaces.data
                .map(item => item.name);

            const processedDataFiles = responseFiles.data.map(item => ({
                id: item.id,
                tipo: item.type,
                imgUrl: item.name,
                orden: item.orderk
            }));
            
            setFormData(prevFormData => ({
                ...prevFormData,
                name: responseDetails.data[0].name,
                type: responseDetails.data[0].type,
                slug: responseDetails.data[0].slug,
                intro: responseDetails.data[0].intro,
                description: responseDetails.data[0].description,
                deadline: responseDetails.data[0].deadline,
                
                portada: responseDetails.data[0].portada,
                icon: responseDetails.data[0].icon,

                aprendices: processedDataAperndiz,
                instructores: processedDataInstructor,

                fichas: processedDataFicha,
                
                files: processedDataFiles,
                
                palabrasClave: processedDataKeywords,
                
                enlaces: processedDataEnlaces
            }));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const [step, setStep] = useState(1);

    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);

    };
    const handleBack = () => {
        setStep((prevStep) => prevStep - 1);
    };
    const handleFinal = () => {
        setStep(3);
    };
    const handleInicio = () => {
        setStep(1);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const file = e.dataTransfer.files[0];
        if (file) {
        setFormData((prevData) => ({
            ...prevData,
            portada: file,
        }));
        setImageUrl(URL.createObjectURL(file));
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            portada: file, 
        }));

        if (file) {
        const url = URL.createObjectURL(file);
        setImageUrl(url);
        }
    };

    const handleDragOverIcon = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setdragActiveIcon(true);
    };
    const handleDragLeaveIcon = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setdragActiveIcon(false);
    };
    const handleDropIcon = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setdragActiveIcon(false);

        const file = e.dataTransfer.files[0];
        if (file) {
        setFormData((prevData) => ({
            ...prevData,
            icon: file,
        }));
        setimageUrlIcon(URL.createObjectURL(file));
        }
    };
    const handleFileChangeIcon = (event) => {
        const file = event.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            icon: file, 
        }));

        if (file) {
            const url = URL.createObjectURL(file);
            setimageUrlIcon(url);
        }
    };

    const handleDragOverM = (e) => {
        e.preventDefault();
        setDragActiveM(true);
    };
    const handleDragLeaveM = () => {
        setDragActiveM(false);
    };
    const handleDropM = (e) => {
        e.preventDefault();
        setDragActiveM(false);
        const droppedFiles = Array.from(e.dataTransfer.files);

        setFormData((prevData) => ({
            ...prevData,
            files: [...prevData.files, ...droppedFiles],
        }));
    };
    const handleFileChangeM = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevData) => ({
            ...prevData,
            files: [...prevData.files, ...files],
        }));
    };
    const handleRemoveFileM = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            files: prevFormData.files.filter((_, i) => i !== index)
        }));
    };

    const handleCheckboxChangeaprendiz = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const aprendices = checked
                ? [...prevState.aprendices, id]
                : prevState.aprendices.filter(dataid => dataid !== id);

            return { ...prevState, aprendices };
        });
    };
    const handleCheckboxChangeInstructor = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const instructores = checked
                ? [...prevState.instructores, id]
                : prevState.instructores.filter(dataid => dataid !== id);

            return { ...prevState, instructores };
        });
    };
    const handleCheckboxChangeFicha = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const fichas = checked
                ? [...prevState.fichas, id]
                : prevState.fichas.filter(dataid => dataid !== id);

            return { ...prevState, fichas };
        });
    };

    const handleInputChangePC = (e) => {
        setInputValue(e.target.value);
    };
    const handleKeyPressPC = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
        e.preventDefault();
        setFormData((prevData) => ({
            ...prevData,
            palabrasClave: [...prevData.palabrasClave, inputValue.trim()],
        }));
        setInputValue('');
        }
    };
    const handleRemoveTagPC = (indexToRemove) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            palabrasClave: prevFormData.palabrasClave.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handleInputChangE = (e) => {
        setinputValueEnlaces(e.target.value);
    };
    const handleKeyPressEnlaces = (e) => {
        if (e.key === 'Enter' && inputValueEnlaces.trim() !== '') {
        e.preventDefault();
        setFormData((prevData) => ({
            ...prevData,
            enlaces: [...prevData.enlaces, inputValueEnlaces.trim()],
        }));
        setinputValueEnlaces('');
        }
    };
    const handleRemoveTagE = (indexToRemove) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            enlaces: prevFormData.enlaces.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handleCreate = async () => {
        const { 
            name, type, slug, intro, description, deadline, portada, icon,
            aprendices, instructores, fichas, files, palabrasClave, enlaces 
        } = formData;
        
        const formDataToSend = new FormData();

        formDataToSend.append("API", "talentic");
        formDataToSend.append("MODEL", "talentic");
        formDataToSend.append("RESOURCE", "projects");
        formDataToSend.append("key", "5b8d3b1f084b01c6a8387459e80d4bb9");
        formDataToSend.append("TYPE", "PUT");
        
        formDataToSend.append("name", name);
        formDataToSend.append("type", type);
        formDataToSend.append("slug", slug);
        formDataToSend.append("intro", intro);
        formDataToSend.append("description", description);
        formDataToSend.append("deadline", deadline);

        if (portada) {
            formDataToSend.append("portada", portada);
        }

        if (icon) {
            formDataToSend.append("icon", icon);
        }

        files.forEach((file) => {
            formDataToSend.append('files[]', file);
        });

        formDataToSend.append("aprendices", JSON.stringify(aprendices));
        formDataToSend.append("instructores", JSON.stringify(instructores));
        formDataToSend.append("fichas", JSON.stringify(fichas));
        formDataToSend.append("palabrasClave", JSON.stringify(palabrasClave));
        formDataToSend.append("enlaces", JSON.stringify(enlaces));

        try {
            const response = await axios.post('http://217.15.168.117:8080/api/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            setFormData({
                name: '', type: '', slug: '', intro: '', description: '', deadline: '',
                aprendices: [], instructores: [], fichas: [], files: [], portada: null,
                icon: null, palabrasClave: [], enlaces: []
            });
            
            onSubmitState(true);
        } catch (error) {
            toast.error('Hubo un error al crear el proyecto. Por favor, inténtelo de nuevo.');
        }
    };

    const validateStep1 = () => {
        // Validar campos usando las reglas para el paso 1
        const isValid = validateFields({
            formData,
            rules: validationRules.step1,
            setErrors,
            toast
        });

        if (isValid) {
            return true;
        }
    };

    const validateStep2 = () => {
        // Validar campos usando las reglas para el paso 2
        const isValid = validateFields({
            formData,
            rules: validationRules.step2,
            setErrors,
            toast
        });

        if (isValid) {
            return true;
        }
    };

    const handleVerificarFormulario = () => {
        handleCreate();
    };

    const updateDatosBasicos = async () => {
        try {
            // Actualizar los datos básicos del proyecto
            await axios.post('http://217.15.168.117:8080/api/', {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "projects",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "UPDATE",
                "id": id,
                "type": formData.type,
                "name": formData.name,
                "slug": formData.slug,
                "intro": formData.intro,
                "description": formData.description,
                "deadline": formData.deadline
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            toast.success("Se actualizaron los datos básicos con éxito!");
    
            // Actualizar las fichas
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "TYPE": "UPDATE",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "proyecto": id
            };
    
            await axios.post('http://217.15.168.117:8080/api/', {
                ...requestBody,
                "RESOURCE": "allocationsheetproject",
                "fichas": formData.fichas
            });

            await axios.post('http://217.15.168.117:8080/api/', {
                ...requestBody,
                "RESOURCE": "keywords",
                "fichas": formData.palabrasClave
            });
    
            toast.success("Se actualizaron las fichas con éxito!");
        } catch (error) {
            // Manejo de errores genéricos
            if (error.response) {
                toast.error(`Error en la solicitud: ${error.response.data.message}`);
            } else if (error.request) {
                toast.error("No se recibió respuesta del servidor.");
            } else {
                toast.error(`Error configurando la solicitud: ${error.message}`);
            }
        }
    };
    const updateAutores = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "TYPE": "UPDATE",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "proyecto": id
            };

            const [responseAprendiz, responseInstructor] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { 
                    ...requestBody,
                    "RESOURCE": "allocationusersproject",
                    "aprendices": formData.aprendices,
                    "instructores": formData.instructores,
                })
            ]);
            toast.success("Se actualizaron los Autores y Fichas con exito!");
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    return (
        <div>
            <h3 className='p-0 m-0 text-center text-primary'>Sube tu proyecto</h3>
            <h5 className='p-0 m-0 text-center'>en 3 pasos</h5>

            <nav className="menu_modal">
                <ul>
                    <li className={`menu_modal_Item ${step === 1 ? "menu_modal_active" : ""}`} onClick={() => setStep(1)}>
                        <span>PASO 1</span>
                        <figure>
                            <IconSVG name="Icon_proyectos_talentic" width="70px" />
                            <figcaption>DATOS BASICOS</figcaption>
                        </figure>
                    </li>
                    <li className={`menu_modal_Item ${step === 2 ? "menu_modal_active" : ""}`} onClick={() => {if (validateStep1()) {setStep(2);}}}>
                        <span>PASO 2</span>
                        <figure>
                            <IconSVG name="Icon_agregar_usuarios_talentic" width="70px" />
                            <figcaption>AUTORES</figcaption>
                        </figure>
                    </li>
                    <li className={`menu_modal_Item ${step === 3 ? "menu_modal_active" : ""}`} onClick={() => {if (validateStep1() && validateStep2()) {setStep(3);}}}>
                        <span>PASO 3</span>
                        <figure>
                            <IconSVG name="Icon_multimedia_talentic" width="70px" />    
                            <figcaption>MULTIMEDIA</figcaption>
                        </figure>
                    </li>
                </ul>
            </nav>

            {step === 1 && (
                <Step1
                    formData={formData}
                    setFormData={setFormData}
                    handleDragOver={handleDragOver}
                    handleDragOverIcon={handleDragOverIcon}
                    handleDragLeave={handleDragLeave}
                    handleDragLeaveIcon={handleDragLeaveIcon}
                    handleDrop={handleDrop}
                    handleDropIcon={handleDropIcon}
                    dragActive={dragActive}
                    dragActiveIcon={dragActiveIcon}
                    imageUrl={imageUrl}
                    imageUrlIcon={imageUrlIcon}
                    handleFileChange={handleFileChange}
                    handleFileChangeIcon={handleFileChangeIcon}
                    onNext={handleNext}
                    onFinal={handleFinal}
                    handleCheckboxChangeFicha={handleCheckboxChangeFicha}
                    handleRemoveTag={handleRemoveTagPC}
                    handleKeyPress={handleKeyPressPC}
                    handleInputChange={handleInputChangePC}

                    inputValueEnlaces={inputValueEnlaces}
                    update={updateDatosBasicos}
                    action="update"
                />
            )}
            {step === 2 && (
                <Step2
                    formData={formData}
                    handleCheckboxChangeaprendiz={handleCheckboxChangeaprendiz}
                    handleCheckboxChangeInstructor={handleCheckboxChangeInstructor}
                    onNext={handleNext}
                    onBack={handleBack}
                    update={updateAutores}
                    action="update"
                />
            )}
            {step === 3 && (
                <Step3
                    formData={formData}
                    handleDragOver={handleDragOverM}
                    handleDragLeave={handleDragLeaveM}
                    handleDrop={handleDropM}
                    handleFileChange={handleFileChangeM}
                    handleRemoveFile={handleRemoveFileM}
                    handleRemoveTag={handleRemoveTagPC}
                    
                    handleKeyPressEnlaces={handleKeyPressEnlaces}
                    handleRemoveEnlaces={handleRemoveTagE}
                    handleInputChangeEnlaces={handleInputChangE}
                    inputValueEnlaces={inputValueEnlaces}
                    dragActive={dragActiveM}
                    inputValue={inputValue}
                    onBack={handleBack}
                    onSubmit={handleVerificarFormulario}
                    handleInicio={handleInicio}
                    resetFormData={fetchData}
                    id={idDefault}
                    action="update"
                />
            )}
        </div>
    );
};

export default MultiStepForm;