import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import CheckBox from '../components/checkbox';

const Allocationrigths = ( ) => {
    const { state: { rol, name, description } = {} } = useLocation();

    const [searchTerm, setSearchTerm] = useState('');
    const [apiData, setApiData] = useState([]);
    const [formData, setFormData] = useState({ permisos: [] });

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "TYPE": "allocationrigths",
                "rolId": rol,
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            };

            const response = await axios.post('http://217.15.168.117:8080/api/', requestBody);
            const cleanedData = response.data.map(item => ({
                id: parseInt(item.id, 10),
                name: item.name,
                crypter: item.crypter,
                asignado: item.asignado,
                state: item.state
            }));
            const permisosIniciales = cleanedData
                .filter(item => item.asignado === 1)
                .map(item => item.id);

            setFormData({ permisos: permisosIniciales });
            setApiData(cleanedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleCheckboxChange = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setFormData(prevState => {
            const permisos = checked
                ? [...prevState.permisos, id]
                : prevState.permisos.filter(permissionId => permissionId !== id);

            return { ...prevState, permisos };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "allocationrigths",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "UPDATE",
                "rol": rol,
                "permisos": formData.permisos,
            };

            await axios.post('http://217.15.168.117:8080/api/', requestBody);
            fetchData();
        } catch (error) {
            console.error('Error updating permissions:', error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = apiData.filter(item =>
        (item.name || '').toLowerCase().includes(searchTerm) ||
        (item.crypter || '').toLowerCase().includes(searchTerm)
    );

    return (
        <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Modulo de administración de permisos asignados a roles</h4>

                <div className="row">
                    <div className="col-xl">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Datos básicos del rol</h5>
                                <small className="text-muted float-end">Formulario de asociación de permisos</small>
                            </div>

                            <div className="card-body row">
                                <div className="mb-3 col-12">
                                    <label className="form-label">Nombre del rol</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={name}
                                    />
                                </div>

                                <div className="mb-3 col-12">
                                    <label className="form-label">Descripción del rol</label>
                                    <textarea
                                        className="form-control"
                                        disabled
                                        value={description}
                                    />
                                </div>

                                <div className="mb-3 col-12">
                                    <Link to="/dashboard/roles">
                                        <i className="bx bx-chevron-left"></i> Editar los datos básicos del proyecto
                                    </Link>
                                </div>
                            </div>

                            <div className="card-body">
                                <form id="formModel" className="row" onSubmit={handleSubmit}>
                                    <div className="col-12 p-4">
                                        <h5>Permisos disponibles</h5>
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                id="searchDos"
                                                className="form-control"
                                                placeholder="Buscar Instructor"
                                                aria-label="Buscar Instructor"
                                                aria-describedby="button-addon2"
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                            />
                                        </div>

                                        <div className="aprendices-list">
                                            {filteredData.length > 0 ? filteredData.map((item) => (
                                                <CheckBox
                                                    key={item.id}
                                                    name="permisos"
                                                    value={item.id}
                                                    icon="bx-lock-alt"
                                                    userName={item.name}
                                                    description={item.crypter}
                                                    checked={item.asignado === 1}
                                                    handleCheckboxChange={handleCheckboxChange}
                                                />
                                            )) : (
                                                <div id="no-results-dos">
                                                    Permiso no encontrado
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mb-3 col-12">
                                        <Link to="/dashboard/roles" className="btn btn-danger">
                                            Cancelar
                                        </Link>
                                        <button type="submit" className="btn btn-primary">
                                            Actualizar permisos del rol - {name}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Allocationrigths;
