import React, { useState, useEffect, forwardRef } from 'react';  // Importamos los hooks de React y forwardRef para trabajar con referencias
import axios from 'axios';  // Importamos axios para realizar solicitudes HTTP

// Definimos el componente SelectApi utilizando forwardRef para permitir que el componente reciba un ref
const SelectApi = forwardRef(({
    label,  // El texto que se mostrará como etiqueta para el select
    name,  // El nombre del campo select
    apiEndpoint,  // La URL del endpoint de la API para obtener las opciones
    requestBody,  // El cuerpo de la solicitud que se enviará a la API
    onSelectChange,  // Función que se llamará cuando cambie la selección del select
    defaultOption,  // La opción predeterminada que se mostrará en el select
    value,  // El valor actual seleccionado
    className  // Clase CSS adicional que se aplicará al contenedor del select
}, ref) => {
    // Creamos un estado para almacenar las opciones del select
    const [options, setOptions] = useState([]);

    // Efecto para realizar la solicitud a la API cuando el componente se monta
    useEffect(() => {
        // Realizamos la solicitud POST a la API usando axios
        axios.post(apiEndpoint, requestBody)
            .then(response => {
                // Si la solicitud es exitosa, actualizamos las opciones con los datos obtenidos
                setOptions(response.data);
            })
            .catch(error => {
                // En caso de error, mostramos un mensaje en la consola
                console.error('Error al cargar las opciones:', error);
            });
    }, [apiEndpoint, requestBody]);  // El efecto se ejecuta cada vez que cambian apiEndpoint o requestBody

    return (
        <div className={`inputContent col-12 ${className}`}>  // Contenedor principal del select
            <div className={`form-content-inputs`}>  // Contenedor para la etiqueta y el select
                <label className="form-label">{label}</label>  // Mostramos la etiqueta del select
                <select 
                    ref={ref}  // Asignamos el ref aquí para que se pueda referenciar desde fuera del componente
                    name={name}  // Nombre del select
                    className="form-select required"  // Clase CSS para estilizar el select
                    data-required={`Debe seleccionar un ${label}`}  // Mensaje de validación para el campo
                    onChange={onSelectChange}  // Llamamos a onSelectChange cuando el valor del select cambie
                    value={value}  // El valor actual del select
                >
                    <option value="">{defaultOption}</option>  // Opción predeterminada del select
                    {options && options.length > 0 ? (
                        options.map(option => (
                            // Mapea las opciones de la API para crear los elementos <option>
                            <option key={option.key} value={option.key}>
                                {option.value}
                            </option>
                        ))
                    ) : (
                        // Mensaje cuando no hay opciones
                        <option value="">Sin opciones disponibles</option>
                    )}
                </select>
            </div>
        </div>
    );
});

// Exportamos el componente SelectApi para su uso en otras partes de la aplicación
export default SelectApi;
