import React, { useState } from 'react';  // Importa React y el hook useState para manejar el estado.
import { toast } from 'react-toastify';  // Importa la función toast para mostrar notificaciones.
import axios from 'axios';  // Importa axios para hacer solicitudes HTTP.

import IconSVG from '../contents/icons/icon';  // Importa el componente de icono SVG.

function Footer() {  // Componente funcional Footer.
    const [email, setEmail] = useState('');  // Hook de estado para almacenar el correo del usuario.

    // Función que maneja el envío del formulario.
    const handleSubmit = async (e) => {
      e.preventDefault();  // Previene el comportamiento por defecto del formulario (recarga de página).

      // Cuerpo de la solicitud que se enviará al servidor.
      const requestBody = {
          "API": "talentic",
          "MODEL": "talentic",
          "RESOURCE": "subscriptions",
          "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
          "TYPE": "PUT",
          "email": email  // El correo que el usuario ingresa.
      };

      try {
          // Realiza la solicitud POST con axios para suscribirse.
          const response = await axios.post('http://217.15.168.117:8080/api/', requestBody, {
            headers: {
            'Content-Type': 'application/json'  // Define el tipo de contenido de la solicitud.
            }
          });
          toast.info('Enviamos un correo a tu bandeja de entrada, sigue los pasos y forma parte de nosotros');  // Muestra un mensaje de éxito al usuario.
      } catch (error) {
        // Maneja posibles errores, pero no hace nada con ellos en este bloque.
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      }
    };

    // Función para limpiar el input de correo.
    const clearInput = () => {
        setEmail('');  // Establece el correo a vacío.
    };

    return (
        <footer>
            <div className='d-flex flex-wrap justify-content-center'>
                <div className='col-11 d-flex flex-column-reverse flex-md-row justify-content-between align-items-center'>
                    <div className='col-12 col-md-3 mb-2 mt-4 bordered_bottom noborder-md'>
                        <form id="formAuthentication" className="mb-3 d-flex flex-column align-items-center" onSubmit={handleSubmit}>
                            <div className='mx-2 mb-3'>Suscríbete</div>
                            <div className="suscribiteInputContent">
                                <IconSVG name="Icon_correo_talentic" width="20px" style={{ marginLeft: '10px'}} />
                                <input
                                    id="searchProyets"
                                    type="text"
                                    className="form-control"
                                    placeholder="Ejemplo@misena.edu.com"
                                    value={email}  // Muestra el valor del estado email.
                                    onChange={(e) => setEmail(e.target.value)}  // Actualiza el estado email cuando el usuario escribe.
                                    autoComplete="off"
                                />
                                <IconSVG
                                    name="Icon_borrador_talentic"
                                    width="20px"
                                    style={{ margin: '5px', cursor: 'pointer' }}
                                    onClick={clearInput}  // Llama a la función para limpiar el input.
                                />
                            </div>
                        </form>
                    </div>

                    <div className='display-none display-md col-12 col-md-4 mb-2 mt-4'>
                        <p className='mb-3 text-center'>
                            Términos y condiciones | Política de tratamiento de datos
                        </p>
                    </div>
                    
                    <div className='col-12 d-flex flex-column align-items-center col-md-5 mb-2 mt-4'>
                        <div className='d-flex flex-wrap justify-content-center'>
                            <IconSVG name="Logo_cenigraf_gris" className="logofottercenigraf"/>  {/* Muestra los logos.*/}
                            <IconSVG name="Logo_sennova_gris_1" className="logofottersennova"/>
                            <IconSVG name="Logo_ingraf_gris" className="logofotteringraf"/>
                        </div>
                    </div>
                </div>
                <hr className='col-11'/>  {/* Línea horizontal para separar el contenido.*/}
                <div className='col-12 text-center mb-3'>© 2024 Equipo Talentic, Inc. Todos los derechos reservados.</div>  {/* Mensaje de derechos de autor.*/}
            </div>
        </footer>
    );
}

export default Footer;
