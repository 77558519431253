import React from 'react'; // Importa React para usar JSX
import Icon from './icons'; // Importa el componente Icon para mostrar el icono asociado al checkbox

// Componente CheckBoxClassic
const CheckBoxClassic = ({ icon, name, value, userName, description, checked, handleCheckboxChange }) => {
    return (
        // Contenedor del checkbox, incluye un atributo de datos con la información del usuario
        <div className="buscarDos usuario__encontrado" data-user={`${userName} ${description}`}>
            {/* Etiqueta que envuelve el nombre y descripción del usuario junto con el icono */}
            <label htmlFor={`${value}${description}`}>
                {/* Componente Icon muestra el icono basado en el código pasado como prop */}
                <Icon code={icon} />
                <div className="ps-3"> {/* Espaciado a la izquierda */}
                    {/* Nombre del usuario */}
                    <h6 className="mb-0">{userName}</h6>
                    {/* Descripción del usuario */}
                    <small className="text-muted">{description}</small>
                </div>
            </label>
            {/* Contenedor para el checkbox */}
            <div className="form-check form-switch">
                <input
                    // Checkbox con algunas clases para estilo
                    className="form-check-input float-end"
                    type="checkbox" // Define el tipo como checkbox
                    name={name} // Asocia el nombre del checkbox
                    id={`${value}${description}`} // Genera un id único con el valor y descripción
                    value={value} // Establece el valor del checkbox
                    role="switch" // Define el rol del elemento como interruptor
                    defaultChecked={checked} // Establece el estado inicial del checkbox (checked o no)
                    // Controlador de cambio del checkbox
                    onChange={(e) => handleCheckboxChange(e)}
                />
            </div>
        </div>
    );
};

export default CheckBoxClassic; // Exporta el componente para ser usado en otros archivos
