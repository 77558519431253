import React, { useState } from 'react'; // Se importa React y el hook useState
import { Link, Route, Routes, Navigate } from 'react-router-dom'; // Se importan componentes de routing para manejar rutas y navegación

import { useAuth } from '../components/AuthContext'; // Se importa el contexto de autenticación personalizado

import './../css/app-c15aace3.css'; // Se importa el archivo CSS para los estilos

import IconSVG from '../contents/icons/icon'; // Se importa un componente para manejar iconos SVG

// Se importan los componentes de formularios que se utilizarán en las rutas
import TypeGraphics from '../forms/typegraphics/typegraphics';
import TypeProject from '../forms/typeprojects/typeproject';
import Permissions from '../forms/permissions/permissions';
import Programme from '../forms/programmes/programme';
import TypeUsers from '../forms/typeusers/typeusers';
import Projects from '../forms/projects/projects';
import ManageProject from '../forms/projects/manageProjects';
import Centers from '../forms/centers/centers';
import Sheet from '../forms/sheets/sheet';
import Roles from '../forms/roles/roles';
import Users from '../forms/users/users';

import Allocationusersproject from '../forms/allocationusersproject'; // Formulario de asignación de usuarios a proyectos
import Allocationrigths from '../forms/allocationrigths'; // Formulario para asignación de derechos
import AssignUsers from '../forms/assignusers'; // Formulario para asignación de usuarios
import AssignUser from '../forms/assignuser'; // Formulario para asignación de un solo usuario
import Profile from '../forms/profile'; // Formulario de perfil de usuario
import Metrics from '../forms/metrics'; // Formulario de métricas
import NavMenu from '../forms/navMenu'; // Menú de navegación

import NavDashBoard from '../components/navDashBoard'; // Componente de barra de navegación del dashboard


const Dashboard = () => {
    const [titulo, setTitulo] = useState("Titulo"); // Estado para almacenar el título que se mostrará en la interfaz

    const handleMenuClick = (tituloNuevo) => {
        setTitulo(tituloNuevo); // Actualiza el título cuando se selecciona un elemento del menú
    };
    const { logout } = useAuth(); // Se obtiene la función de logout del contexto de autenticación para cerrar sesión

    const [activeMenu, setActiveMenu] = useState(null); // Estado para el menú activo, inicializado como nulo
    const [activeSubMenu, setActiveSubMenu] = useState(null); // Estado para el submenú activo, inicializado como nulo

    // Definición de varios hashes que probablemente representan accesos a diferentes secciones o permisos
    const hashToVerroles = "a959cc809c209a915e2b2f55846f656f"; // Hash asociado al acceso de roles
    const hashToVerfichas = "fbc90e32576bfd81524b0e4de43c9e05"; // Hash asociado al acceso de fichas
    const hashToVercentros = "1f84054ad4c62f3985b0d54867b787d1"; // Hash asociado al acceso de centros
    const hashToVerusuarios = "6b2af060217dd53ea0a2b2d6eb97954e"; // Hash asociado al acceso de usuarios
    const hashToVerpermisos = "cf8ed42e615479f6ca7e932b858ca30f"; // Hash asociado al acceso de permisos
    const hashToVerproyectos = "bf5b05bfb279c5213c83a52b9fdd2f61"; // Hash asociado al acceso de proyectos
    const hashToVerprogramas = "e04d144294a8d6c0f41ead965f31eec6"; // Hash asociado al acceso de programas
    const hashToVercomponentes = "f0e5b21982b9a65bb897292b1b24d781"; // Hash asociado al acceso de componentes
    const hashToVertiposUsuarios = "3547d37ab3a1355871fda3745cc61987"; // Hash asociado al acceso de tipos de usuarios
    const hashToVertiposproyectos = "dc3511bb360dab613e957b0554d80dc2"; // Hash asociado al acceso de tipos de proyectos
    const hashToVertiposmultimedia = "c1b5944ef8126c26450c577d2deafcc9"; // Hash asociado al acceso de tipos de multimedia

    // Se obtiene el objeto del usuario desde localStorage y se extraen sus permisos
    const [formDatauser] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user')); // Se obtiene el usuario almacenado en localStorage
        return {
            permisos: user?.permissions || '' // Si existe el usuario, se extraen sus permisos, sino, se asigna una cadena vacía
        };
    });

    const toggleMenu = (menuName) => {
        // Alterna el menú activo entre el nombre del menú pasado o 'null'
        setActiveMenu(activeMenu === menuName ? null : menuName);
    };
    
    const toggleSubMenu = (menuName) => {
        // Alterna el submenú activo entre el nombre del submenú pasado o 'null'
        setActiveSubMenu(activeSubMenu === menuName ? null : menuName);
    };
    
    const handleClick = () => {
        // Selecciona los elementos HTML correspondientes a la clase 'bg_das' y el id 'layout-menu_dashboard'
        const htmlElementBg = document.querySelector('.bg_das'); 
        const htmlElement = document.querySelector('#layout-menu_dashboard');
        
        // Activa o desactiva la clase 'layout-menu_dashboard_active' en el menú
        htmlElement.classList.toggle('layout-menu_dashboard_active');
        
        // Activa o desactiva la clase 'layout__pagina_dashboard_active' en el fondo
        htmlElementBg.classList.toggle('layout__pagina_dashboard_active');
    };   


    return (
        <div>
            <div className='dashboard_layout'>
                <aside id="layout-menu_dashboard" className="layout-menu_dashboard">
                    <div className="dashboard_menu_logo">
                        <Link className="" to="/dashboard">
                            <IconSVG name="Logo_Talentic_1"/>
                        </Link>
                    </div>

                    <ul className="dashboard_menu_items">

                        <div className="dashboard_menu_main">

                            {/* PROYECTOS */}
                            {formDatauser.permisos.includes(hashToVerproyectos) ? (
                                <li className="dashboard_menu_item_drop">
                                    <Link to="/dashboard/projects" className={` dashboard_menu_item_content ${activeSubMenu === 'item_proyectos' ? 'active__item_menu' : ''}`}
                                        onClick={() => {
                                            toggleSubMenu('item_proyectos');
                                            toggleMenu('default_item');
                                            handleMenuClick("Proyectos");
                                        }}
                                    >
                                        <IconSVG name="Icon_Proyecto"/>
                                        <span>Proyectos</span>
                                    </Link>
                                </li>
                            ):null}

                            {/* USUARIOS */}
                            {formDatauser.permisos.includes(hashToVerusuarios) ? (
                                <li className="dashboard_menu_item_drop">
                                    <Link to="/dashboard/users" className={` dashboard_menu_item_content ${activeSubMenu === 'item_usuarios' ? 'active__item_menu' : ''}`}
                                        onClick={() => {
                                            toggleSubMenu('item_usuarios');
                                            toggleMenu('default_item');
                                            handleMenuClick("Usuarios");
                                        }}
                                    >
                                        <IconSVG name="Icon_usuario_talentic"/>
                                        <span>Usuarios</span>
                                    </Link>
                                </li>
                            ):null}

                            {/* FORMACIÓN ACADEMICA */}
                            {(formDatauser?.permisos?.includes(hashToVerfichas) || formDatauser?.permisos?.includes(hashToVerprogramas) || formDatauser?.permisos?.includes(hashToVercentros)) ? (
                                <li className={`dashboard_menu_item_drop ${activeMenu === 'formacion' ? 'active__menu' : ''}`} id='formacion' onClick={() => toggleMenu('formacion')}> 
                                    <div className="dashboard_menu_item_content">
                                        <IconSVG name="Icon_formacion_centros_talentic"/>
                                        <span>Formación y Centros</span>
                                    </div>
                                    <IconSVG name="Icon_flecha_abajo_talentic" width="15px" style={{ marginRight: '40px', cursor: 'pointer'}} />
                                </li>
                            ):null}
                            
                            {activeMenu === 'formacion' && (
                                <div className='dashboard_submenu_items'>
                                    
                                    {/* FICHAS */}
                                    {formDatauser.permisos.includes(hashToVerfichas) ? (
                                        <li className="menu_item_content">
                                            <div className='arrow_submenu'></div>
                                            <Link to="/dashboard/sheet" className={`${activeSubMenu === 'item_fichas' ? 'active__item_menu' : ''}`} onClick={() => {
                                                toggleSubMenu('item_fichas');
                                                handleMenuClick("Fichas");
                                            }}>
                                                <IconSVG name="Icon_fichas_talentic"/>
                                                <div>Fichas</div>
                                            </Link>
                                        </li>
                                    ): null}
                                    {/* PROGRAMAS */}
                                    {formDatauser.permisos.includes(hashToVerprogramas) ? (
                                        <li className="menu_item_content">
                                            <div className='arrow_submenu'></div>
                                            <Link to="/dashboard/programme" className={`${activeSubMenu === 'item_programas' ? 'active__item_menu' : ''}`} onClick={() => {
                                                toggleSubMenu('item_programas');
                                                handleMenuClick("Programas de formación");
                                            }}>
                                                <IconSVG name="Icon_programas_talentic"/>
                                                <div>Programas</div>
                                            </Link>
                                        </li>
                                    ): null}

                                    {formDatauser.permisos.includes(hashToVercentros) ? (
                                        <li className="menu_item_content">
                                            <div className='arrow_submenu'></div>
                                            <Link to="/dashboard/centers" className={`${activeSubMenu === 'item_centros' ? 'active__item_menu' : ''}`} onClick={() => {
                                                toggleSubMenu('item_centros');
                                                handleMenuClick("Centros de formación");
                                            }}>
                                                <IconSVG name="Icon_centros_talentic"/>
                                                <div>Centros</div>
                                            </Link>
                                        </li>
                                    ): null}
                                </div>
                            )}
                            {/* PERMISOS Y ROLES */}
                            {(formDatauser.permisos.includes(hashToVerpermisos) || formDatauser.permisos.includes(hashToVerroles)) ? (
                                <li className={`dashboard_menu_item_drop ${activeMenu === 'roles_y_permisos' ? 'active__menu' : ''}`} id='roles_y_permisos' onClick={() => toggleMenu('roles_y_permisos')}> 
                                    <div className="dashboard_menu_item_content">
                                        <IconSVG name="Icon_roles_permisos_talentic"/>
                                        <span>Roles y Permisos</span>
                                    </div>
                                    <IconSVG name="Icon_flecha_abajo_talentic" width="15px" style={{ marginRight: '40px', cursor: 'pointer'}} />
                                </li>
                            ):null}
                            
                            {activeMenu === 'roles_y_permisos' && (
                                <div className='dashboard_submenu_items'>
                                    {formDatauser.permisos.includes(hashToVerroles) ? (
                                        <li className="menu_item_content">
                                            <div className='arrow_submenu'></div>
                                            <Link to="/dashboard/roles" className={`${activeSubMenu === 'item_roles' ? 'active__item_menu' : ''}`} onClick={() => {
                                                toggleSubMenu('item_roles')
                                                handleMenuClick("Roles");
                                            }}>
                                                <IconSVG name="Icon_roles_talentic"/>
                                                <div>Roles</div>
                                            </Link>
                                        </li>
                                    ):null}
                                    {formDatauser.permisos.includes(hashToVerpermisos) ? (
                                        <li className="menu_item_content">
                                            <div className='arrow_submenu'></div>
                                            <Link to="/dashboard/permissions" className={`${activeSubMenu === 'item_permisos' ? 'active__item_menu' : ''}`} onClick={() => {
                                                toggleSubMenu('item_permisos')
                                                handleMenuClick("Permisos y Permisos Especiales");
                                            }}>
                                                <IconSVG name="Icon_permisos_talentic"/>
                                                <div>Permisos</div>
                                            </Link>
                                        </li>
                                    ):null}
                                    
                                </div>
                            )}
                            {/* TIPOS */}
                            {(formDatauser.permisos.includes(hashToVertiposproyectos) || formDatauser.permisos.includes(hashToVertiposmultimedia)) ? (
                                <li className={`dashboard_menu_item_drop ${activeMenu === 'tipos' ? 'active__menu' : ''}`} id='tipos' onClick={() => toggleMenu('tipos')}> 
                                    <div className="dashboard_menu_item_content">
                                        <IconSVG name="Icon_fichas_talentic"/>
                                        <span>Tipos</span>
                                    </div>
                                    <IconSVG name="Icon_flecha_abajo_talentic" width="15px" style={{ marginRight: '40px', cursor: 'pointer'}} />
                                </li>
                            ):null}
                            
                            {activeMenu === 'tipos' && (
                                <div className='dashboard_submenu_items'>
                                    {formDatauser.permisos.includes(hashToVertiposproyectos) ? (
                                        <li className="menu_item_content">
                                            <div className='arrow_submenu'></div>
                                            <Link to="/dashboard/typeproject" className={`${activeSubMenu === 'tipos_proyectos' ? 'active__item_menu' : ''}`} onClick={() => {
                                                toggleSubMenu('tipos_proyectos');
                                                handleMenuClick("Tipos de Proyectos");
                                            }}>
                                                <IconSVG name="Icon_tipos_proyectos_talentic"/>
                                                <div>Tipos de Proyectos</div>
                                            </Link>
                                        </li>
                                    ):null}
                                    {formDatauser.permisos.includes(hashToVertiposmultimedia) ? (
                                        <li className="menu_item_content">
                                            <div className='arrow_submenu'></div>
                                            <Link to="/dashboard/typegraphics" className={`${activeSubMenu === 'tipos_multimedias' ? 'active__item_menu' : ''}`} onClick={() => {
                                                toggleSubMenu('tipos_multimedias');
                                                handleMenuClick("Tipos de Multimedias");
                                            }}>
                                                <IconSVG name="Icon_tipos_multimedias_talentic"/>
                                                <div>Tipos de Multimedias</div>
                                            </Link>
                                        </li>
                                    ):null}
                                </div>
                            )}
                            {/* COMPONENTES */}
                            {formDatauser.permisos.includes(hashToVercomponentes) ? (
                                <li className={`dashboard_menu_item_drop ${activeMenu === 'componentes' ? 'active__menu' : ''}`} id='componentes' onClick={() => toggleMenu('componentes')}> 
                                    <div className="dashboard_menu_item_content">
                                        <IconSVG name="Icon_componentes_talentic"/>
                                        <span>Componentes</span>
                                    </div>
                                    <IconSVG name="Icon_flecha_abajo_talentic" width="15px" style={{ marginRight: '40px', cursor: 'pointer'}} />
                                </li>
                            ):null}
                            
                            {activeMenu === 'componentes' && (
                                <div className='dashboard_submenu_items'>
                                    
                                    <li className="menu_item_content">
                                        <div className='arrow_submenu'></div>
                                        <Link to="/dashboard/navmenu" className={`${activeSubMenu === 'item_menu' ? 'active__item_menu' : ''}`} onClick={() => {
                                            toggleSubMenu('item_menu');
                                            handleMenuClick("Modulo de administración de menús de navegación");
                                        }}>
                                            <IconSVG name="Icon_menus_talentic"/>
                                            <div>Menú</div>
                                        </Link>
                                    </li>
                                    <li className="menu_item_content">
                                        <div className='arrow_submenu'></div>
                                        <Link to="/dashboard/metrics" className={`${activeSubMenu === 'item_metricas' ? 'active__item_menu' : ''}`} onClick={() => {
                                            toggleSubMenu('item_metricas');
                                            handleMenuClick("Modulo de administración de métricas");
                                        }}>
                                            <IconSVG name="Icon_estadisticas_talentic"/>
                                            <div>Métricas</div>
                                        </Link>
                                    </li>
                                </div>
                            )}
                            <div className='dashboard_menu_exit'>
                                <li>Salida</li>
                                <li className="dashboard_menu_item">
                                    <Link to="/">
                                        <IconSVG name="Icon_inicio_talentic"/>
                                        <div>Página principal</div>
                                    </Link>
                                </li>
                                <li className="dashboard_menu_item dashboard_cerrar_sesion">
                                    <Link to="/" onClick={() => logout()}>
                                        <IconSVG name="Icon_salir_talentic"/>
                                        <div>Cerrar sesión</div>
                                    </Link>
                                </li>
                            </div>
                        </div>
                        <footer className='dashboard_footer'>
                            <li><Link to="/">Términos y condiciones</Link> | <Link to="/">Política de tratamiento de datos</Link></li>
                            <li><p>© 2024 Equipo Talentic, Inc.</p></li>
                        </footer>
                    </ul>
                </aside>
                <div className="layout__pagina_dashboard">
                    {/* Componente de navegación principal (Dashboard) con el título dinámico */}
                    <NavDashBoard titulo={titulo}/>
                    
                    {/* Definición de rutas para diferentes componentes y páginas */}
                    <Routes>
                        {/* Definición de las rutas que renderizan componentes específicos */}
                        <Route path="/allocationusersproject" element={<Allocationusersproject />} />
                        <Route path="/allocationrigths" element={<Allocationrigths />} />
                        <Route path="/manageProject" element={<ManageProject />} />
                        <Route path="/typegraphics" element={<TypeGraphics />} />
                        <Route path="/typeproject" element={<TypeProject />} />
                        <Route path="/permissions" element={<Permissions />} />
                        <Route path="/assignusers" element={<AssignUsers />} />
                        <Route path="/assignuser" element={<AssignUser />} />
                        <Route path="/typeusers" element={<TypeUsers />} />
                        <Route path="/programme" element={<Programme />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/centers" element={<Centers />} />
                        <Route path="/metrics" element={<Metrics />} />
                        <Route path="/navmenu" element={<NavMenu />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/roles" element={<Roles />} />
                        <Route path="/sheet" element={<Sheet />} />
                        <Route path="/" element={<Profile />} />
                    </Routes>
                </div>
            </div>
            <div className='bg_das' onClick={handleClick}></div>
        </div>
    );
};

export default Dashboard;