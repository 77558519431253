import React, { useEffect, useState, useRef } from 'react';  // Importa los hooks de React para manejar el estado y los efectos
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'; // Importa el CSS para el componente LiteYouTubeEmbed
import LiteYouTubeEmbed from 'react-lite-youtube-embed'; // Importa el componente LiteYouTubeEmbed para incrustar videos de YouTube de manera ligera
import { useParams } from 'react-router-dom'; // Importa el hook useParams para obtener parámetros de la URL
import { Link } from 'react-router-dom'; // Componente de React Router para navegación entre rutas
import { Dropdown } from 'react-bootstrap'; // Importa el componente Dropdown de react-bootstrap
import { toast } from 'react-toastify'; // Importa la librería toast para mostrar notificaciones
import axios from 'axios'; // Importa axios para hacer peticiones HTTP

import { useAuth } from './../components/AuthContext';  // Importa el contexto de autenticación

import SendMensaje from './../forms/sendMensaje';  // Importa el formulario para enviar mensajes
import SharedSocial from './../forms/sharedSocial';  // Importa el formulario para compartir en redes sociales

import './../css/styles.css';  // Importa el archivo CSS de estilos generales
import './../css/homesas.css';  // Importa el archivo CSS específico para la home
import './../css/app-c15aace3.css';  // Importa otro archivo CSS de la app

import IconSVG from '../contents/icons/icon';  // Importa el componente de íconos SVG
import CutText from '../components/cutString';  // Importa el componente para cortar texto largo
import MultimediaModal from '../components/multimediaModal';  // Importa el modal para multimedia
import Modal from '../components/modal';  // Importa el componente modal
import Viewer from '../components/multimediaViewer';  // Importa el componente para visualizar multimedia

// Función para formatear la fecha en formato corto
const formatDate = (dateString) => {
  const date = new Date(dateString); // Crea un objeto Date a partir de la cadena de fecha
  return date.toLocaleDateString(
    'es-ES',
    {month: 'short', day: '2-digit', year: 'numeric'} // Devuelve la fecha en formato español (mes corto, día y año)
  );
};

// Función para obtener el ID del video de YouTube desde su URL
const getIdFromUrl = (url) => {
  const regex = /(?:v=|\/v\/|embed\/|be\/)([a-zA-Z0-9_-]{11})/;  // Expresión regular para capturar el ID del video
  const match = url.match(regex);  // Intenta hacer coincidir la URL con la expresión regular
  return match && match[1];  // Si hay coincidencia, devuelve el ID del video
};

function DeteilsProyects( ) {
  // Extrae el parámetro 'id' de la URL (usando react-router-dom)
  const { id } = useParams();

  // Inicializa varios estados para manejar los datos del API y otras interacciones
  const [apiData, setApiData] = useState([
    {
      id: null, // Valor inicial
      name: '', // Valor inicial
      intro: '', // Valor inicial
      type: '', // Valor inicial
      description: '', // Valor inicial
      image: '', // Valor inicial
      fichas: '', // Valor inicial
      video: '', // Valor inicial
      portada: '', // Valor inicial
      icon: '', // Valor inicial
      favorites: 0, // Valor inicial
      saved: 0, // Valor inicial
      dateCreation: '', // Valor inicial
      asignado: 0, // Valor inicial
      guardado: 0, // Valor inicial
    },
  ]); // Almacena los datos del proyecto
  const [apiFichas, setapiFichas] = useState([]); // Almacena las fichas relacionadas con el proyecto
  const [apiDataAutores, setapiDataAutores] = useState([]); // Almacena la información de los autores
  const [apiDataKeyWords, setapiDataKeyWords] = useState([]); // Almacena las palabras clave relacionadas
  const [apiDataMultimedia, setapiDataMultimedia] = useState([]); // Almacena los datos multimedia
  
  const [prev, setprev] = useState([]); // Almacena los datos proyecto anterior
  const [next, setnext] = useState([]); // Almacena los datos proyecto siguiente

  // Estado para manejar la animación de desvanecimiento de la página
  const [fadeClass, setFadeClass] = useState('fade-in');

  // Estados para manejar los modales
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal 1 (abierto o cerrado)
  const [modalContent, setModalContent] = useState(null); // Contenido del Modal 1

  const [isModalOpen2, setIsModalOpen2] = useState(false); // Modal 2 (abierto o cerrado)
  const [modalContent2, setModalContent2] = useState(null); // Contenido del Modal 2

  // Obtiene el estado de autenticación del usuario
  const { isAuthenticated } = useAuth();

  // Referencia que se usa para ejecutar la lógica solo en la primera carga
  const isFirstRun = useRef(true);

  // Obtiene y guarda los datos del usuario desde el localStorage (si existen)
  const [formDatauser] = useState(() => {
    const user = JSON.parse(localStorage.getItem('user')); // Extrae el usuario desde el localStorage

    return {
      user: user?.index_user || '' // Retorna el índice del usuario, si está disponible
    };
  });

  // Efecto que se ejecuta cada vez que cambia el 'id' (por ejemplo, en la URL)
  useEffect(() => {
      // Llama a las funciones para obtener los datos del proyecto
      fetchDataPrev();
      fetchDataNext();

      fetchData();
      fetchDataMultimedia();
      fetchDataAutores();
      fetchDataKeywords();
  }, [id]);

  // Funciones para cerrar los modales
  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };
  const closeModal2 = () => {
    setIsModalOpen2(false);
    setModalContent2(null);
  };

  // Función para mostrar un mensaje de éxito cuando se envía un formulario
  const closeModalSuccess = () => {
    toast.success("Se envio con éxito");
    setIsModalOpen(false);
    setModalContent(null);
  };

  // Función para obtener los datos del proyecto anterior
  const fetchDataPrev = async () => {
    try {
      const response = await axios.post('http://217.15.168.117:8080/api/', {
        API: "talentic",
        MODEL: "talentic",
        RESOURCE: "list",
        TYPE: "proyectsDetailsPrev",
        proyecto: id, // Envía el id del proyecto
        key: "5b8d3b1f084b01c6a8387459e80d4bb9", // Clave API
      });

      // Limpia y prepara los datos antes de guardarlos
      const cleanedData = response?.data?.[0]?.prev ?? "";
      setprev(cleanedData); // Actualiza el estado con los datos del proyecto
    } catch (error) {
      toast.error('Upps, no pudimos obtener los datos del proyecto'); // Muestra un error si falla la solicitud
    }
  };
  // Función para obtener los datos del proyecto siguiente 
  const fetchDataNext = async () => {
    try {
      const response = await axios.post('http://217.15.168.117:8080/api/', {
        API: "talentic",
        MODEL: "talentic",
        RESOURCE: "list",
        TYPE: "proyectsDetailsNext",
        proyecto: id, // Envía el id del proyecto
        key: "5b8d3b1f084b01c6a8387459e80d4bb9", // Clave API
      });

      // Limpia y prepara los datos antes de guardarlos
      const cleanedData = response?.data?.[0]?.next ?? "";
      setnext(cleanedData); // Actualiza el estado con los datos del proyecto
    } catch (error) {
      toast.error('Upps, no pudimos obtener los datos del proyecto'); // Muestra un error si falla la solicitud
    }
  };

  // Función para obtener los datos del proyecto
  const fetchData = async () => {
    try {
      const response = await axios.post('http://217.15.168.117:8080/api/', {
        API: "talentic",
        MODEL: "talentic",
        RESOURCE: "list",
        TYPE: "proyectsDetails",
        proyecto: id, // Envía el id del proyecto
        key: "5b8d3b1f084b01c6a8387459e80d4bb9", // Clave API
        user: formDatauser.user
      });

      // Limpia y prepara los datos antes de guardarlos
      const cleanedData = response.data.map(item => ({
        id: item.id,
        name: item.name,
        intro: item.intro,
        type: item.type,
        description: item.description,
        image: item.image,
        fichas: item.fichas,
        video: item.video,
        portada: item.portada,
        icon: item.icon,
        favorites: item.favorites,
        saved: item.saved,
        dateCreation: item.dateCreate,
        asignado: item.asignado,
        guardado: item.guardado,
        videoYT: item.videoYT,
      }));
      setApiData(cleanedData); // Actualiza el estado con los datos del proyecto

      // Obtiene las fichas del proyecto
      const responseFichas = await axios.post('http://217.15.168.117:8080/api/', {
        API: "talentic",
        MODEL: "talentic",
        RESOURCE: "list",
        TYPE: "fichas",
        fichas: response.data[0].fichas, // Utiliza las fichas del proyecto actual
        key: "5b8d3b1f084b01c6a8387459e80d4bb9", // Clave API
      });
      
      // Limpia y prepara los datos de las fichas
      const cleanedDataFichas = responseFichas.data.map(item => ({
        id: item.id,
        name: item.name,
        center: item.center,
        programme: item.programme,
      }));
      
      setapiFichas(cleanedDataFichas); // Actualiza el estado con los datos de las fichas
    } catch (error) {
      toast.error('Upps, no pudimos obtener los datos del proyecto'); // Muestra un error si falla la solicitud
    }
  };

  // Función para obtener los archivos multimedia del proyecto
  const fetchDataMultimedia = async () => {
    try {
      const requestBodymultimedia = {
        API: "talentic",
        MODEL: "talentic",
        RESOURCE: "list",
        TYPE: "proyectsDetailsMultimedia",
        proyecto: id, // Envía el id del proyecto
        key: "5b8d3b1f084b01c6a8387459e80d4bb9", // Clave API
      };

      const response = await axios.post('http://217.15.168.117:8080/api/', requestBodymultimedia);
      
      // Limpia y prepara los datos multimedia
      const cleanedData = response.data.map(item => ({
        id: item.id,
        file: item.file,
        type: item.type,
        dateCreation: item.dateCreate,
      }));
      
      setapiDataMultimedia(cleanedData); // Actualiza el estado con los datos multimedia
    } catch (error) {
      toast.error('Upps, no pudimos obtener los archivos multimedia del proyecto'); // Muestra un error si falla la solicitud
    }
  };

  // Función para obtener los autores del proyecto
  const fetchDataAutores = async () => {
    try {
      const requestBodymAutores = {
        API: "talentic",
        MODEL: "talentic",
        RESOURCE: "list",
        TYPE: "proyectsDetailsAutores",
        proyecto: id, // Envía el id del proyecto
        key: "5b8d3b1f084b01c6a8387459e80d4bb9", // Clave API
      };

      const response = await axios.post('http://217.15.168.117:8080/api/', requestBodymAutores);
      
      // Limpia y prepara los datos de los autores
      const cleanedData = response.data.map(item => ({
        name: item.name,
        description: item.mail,
        src: `http://217.15.168.117:8080/api/Whatever/${item.img}`,
        type: item.typeId,
      }));
      
      setapiDataAutores(cleanedData); // Actualiza el estado con los datos de los autores
    } catch (error) {
      toast.error('Upps, no pudimos obtener los autores del proyecto'); // Muestra un error si falla la solicitud
    }
  };

  // Función para obtener las palabras clave del proyecto
  const fetchDataKeywords = async () => {
    try {
      const requestBodymKeywords = {
        API: "talentic",
        MODEL: "talentic",
        RESOURCE: "list",
        TYPE: "proyectsKeywords",
        proyecto: id, // Envía el id del proyecto
        key: "5b8d3b1f084b01c6a8387459e80d4bb9", // Clave API
      };

      const response = await axios.post('http://217.15.168.117:8080/api/', requestBodymKeywords);
      
      // Limpia y prepara los datos de las palabras clave
      const cleanedData = response.data.map(item => ({
        name: item.name,
      }));
      
      setapiDataKeyWords(cleanedData); // Actualiza el estado con los datos de las palabras clave
    } catch (error) {
      toast.error('Upps, no pudimos obtener los autores del proyecto'); // Muestra un error si falla la solicitud
    }
  };

  // Función para manejar el clic en un modal con un componente de formulario
  const handleMultimediaModalClick = (FormComponent, viewer, type, data) => {
    setIsModalOpen(true);
    setModalContent(<FormComponent viewer={viewer} type={type} data={data}/>); // Abre el modal con el contenido adecuado
  };

  // Funciones para agregar o quitar un proyecto de favoritos y guardados
  const addFavorites = async (e, link, name) => {
    const requestBody = {
      "API": "talentic",
      "MODEL": "talentic",
      "RESOURCE": "favorites",
      "key": "5b8d3b1f084b01c6a8387459e80d4bb9", // Clave API
      "TYPE": "PUT",
      "proyect": link, // Enlace del proyecto
      "user": formDatauser.user, // Usuario actual
    };
    try {
      await axios.post('http://217.15.168.117:8080/api/', requestBody);
      
      setApiData((prevData) =>
        prevData.map((item) => ({
          ...item,
          asignado: 1, // Actualizamos solo el campo 'asignado'
        }))
      );
      setApiData((prevData) =>
        prevData.map((item) => ({
          ...item,
          favorites: item.favorites + 1, // Actualizamos solo el campo 'numero de favoritos'
        }))
      );
    } catch (error) {
      toast.error('No se pudo agregar a favoritos'); // Mensaje de error
    }
  };
  // Función para eliminar un proyecto de favoritos
  const removeFavorites = async (e, link, name) => {
    const requestBody = {
        "API": "talentic",
        "MODEL": "talentic",
        "RESOURCE": "favorites",
        "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        "TYPE": "DELETE_FORCE",
        "Z": "favorites",
        "proyect": link,
        "user": formDatauser.user
    };

    try {
        await axios.post('http://217.15.168.117:8080/api/', requestBody, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setApiData((prevData) =>
          prevData.map((item) => ({
            ...item,
            asignado: 0, // Actualizamos solo el campo 'asignado'
          }))
        );
        setApiData((prevData) =>
          prevData.map((item) => ({
            ...item,
            favorites: item.favorites - 1, // Actualizamos solo el campo 'numero de favoritos'
          }))
        );
    } catch (error) {
        // Manejo de errores con mensajes de toast
        toast.error(error);
        if (error.response) {
            toast.error('Request failed:', error.response.data.message);
        } else if (error.request) {
            toast.error('No response received:', error.request);
        } else {
            toast.error('Error setting up request:', error.message);
        }
    }
  };

  const addsaved = async (e, link, name) => {
    const requestBody = {
      "API": "talentic",
      "MODEL": "talentic",
      "RESOURCE": "saved",
      "key": "5b8d3b1f084b01c6a8387459e80d4bb9", // Clave API
      "TYPE": "PUT",
      "proyect": link, // Enlace del proyecto
      "user": formDatauser.user, // Usuario actual
    };

    try {
      await axios.post('http://217.15.168.117:8080/api/', requestBody);
      setApiData((prevData) =>
        prevData.map((item) => ({
          ...item,
          guardado: 1, // Actualizamos solo el campo 'asignado'
        }))
      );
      setApiData((prevData) =>
        prevData.map((item) => ({
          ...item,
          saved: item.saved + 1, // Actualizamos solo el campo 'numero de favoritos'
        }))
      );
    } catch (error) {
      toast.error('No se pudo guardar el proyecto'); // Mensaje de error
    }
  };
  // Función para eliminar un proyecto de la lista de guardados
  const removeSaved = async (e, link, name) => {
      const requestBody = {
          "API": "talentic",
          "MODEL": "talentic",
          "RESOURCE": "saved",
          "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
          "TYPE": "DELETE_FORCE",
          "Z": "saved",
          "proyect": link,
          "user": formDatauser.user
      };
  
      try {
          await axios.post('http://217.15.168.117:8080/api/', requestBody, {
              headers: {
                  'Content-Type': 'application/json'
              }
          });
          setApiData((prevData) =>
            prevData.map((item) => ({
              ...item,
              guardado: 0, // Actualizamos solo el campo 'asignado'
            }))
          );
          setApiData((prevData) =>
            prevData.map((item) => ({
              ...item,
              saved: item.saved - 1, // Actualizamos solo el campo 'numero de favoritos'
            }))
          );
      } catch (error) {
          // Manejo de errores con mensajes de toast
          toast.error(error);
          if (error.response) {
              toast.error('Request failed:', error.response.data.message);
          } else if (error.request) {
              toast.error('No response received:', error.request);
          } else {
              toast.error('Error setting up request:', error.message);
          }
      }
  }; 

  const openModalWithFormMenssaje = (FormComponent, link) => {
    setModalContent2(<FormComponent
        project={link}
        userSend={formDatauser.user}
        onSubmit={closeModalSuccess}
    />);
    setIsModalOpen2(true);
  };

  const openModalWithFormSharedSocial = (FormComponent, link) => {
    setModalContent2(<FormComponent
        project={link}
    />);
    setIsModalOpen2(true);
  };

  return (
      <>
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 m-0 p-0 px-md-4">
            <div className="card mt-0 mx-0 mt-md-4 mx-md-5">
              <div className="card-body p-0">
                <div id="carousel" className="carousel slide carousel-fade" data-bs-touch="false" data-bs-interval="false"> 
                  <div className="carousel-inner">
                    <div className="carousel-item active detaillproyect p-0">
                      {apiData.map((item) => (
                        <>
                          {item.videoYT ? (
                            <iframe 
                                width="100%" 
                                height="100%" 
                                src={`https://www.youtube.com/embed/${getIdFromUrl(item.videoYT)}?autoplay=1&mute=1&controls=0&modestbranding=1&showinfo=0&iv_load_policy=3&rel=0&loop=1`}
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen>
                            </iframe>
                          ) : (
                            <img
                                src={`http://217.15.168.117:8080/api/Whatever/${item.portada}`}
                                className={`m-0 img-content `}
                            />
                          )}
                          <div className='content_image_gradient_detaill display-none display-md'>
                              <img 
                                  src={`http://217.15.168.117:8080/api/Whatever/${item.icon}`}
                                  className={`m-0 proyectprofileproyectsDetails display-5 `}
                              />
                              <div className='info_image_gradient_detail'>
                                  <h3 className='text-white m-0'>{item.name}</h3>
                                  <p className='text-white'>{formatDate(item.dateCreation)} | {apiFichas.map((item) => (`${item.center} | ${item.programme}`))}</p>
                                  <br/>
                                  <CutText classn="text-white text-justify aligndetailintro" text={item.intro} maxLength={350}/>
                              </div>

                              <div className='multimefiaDetails overX'>
                                <div className='multimefiaDetailsitems'>
                                  {apiDataMultimedia.map((slide, index) => (
                                    <div
                                      key={index}
                                      onClick={(e) => handleMultimediaModalClick(Viewer, slide.file, slide.type, apiDataMultimedia)}
                                    >
                                      {slide.type != 'Video Youtube' ? (
                                        <div
                                          className={`Witem mx-2 mx-md-3`}
                                          data-index={slide.id}
                                          style={{ backgroundImage: `url(http://217.15.168.117:8080/api/Whatever/${slide.file})` }}
                                        >
                                          <small className="name">{slide.type}</small>
                                        </div>
                                      ) : (
                                        <div className='contdetailyt'>
                                          <LiteYouTubeEmbed 
                                            id={getIdFromUrl(slide.file)}
                                          />
                                          <small className="nameyt">{slide.type}</small>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                          </div>

                          <div className='info_image_gradient_mobil my-0 overY positionR'>
                            <img 
                                src={`http://217.15.168.117:8080/api/Whatever/${item.icon}`}
                                className={`mx-4 proyectprofileproyects_mobil display-5 ${fadeClass}`}
                            />

                            <h3 className='text-white m-0 pt-2'>{item.name}</h3>
                            <p className='text-white'>{formatDate(item.dateCreation)} | {apiFichas.map((item) => (`${item.center} | ${item.programme}`))}</p>
                            <br/>

                            <h4 className='text-white m-0 pt-2'>Descripción</h4>
                            <br/>

                            <p className='text-white'>{item.description} </p>
                            <br/>
                            <p className='text-white'>{item.intro}</p>
                            <br/>

                            <h4>Multimedia del proyecto</h4>

                            <div className='detaillmultimedia d-flex flex-column align-items-start m-0 overX positionR w-auto h-auto'>
                              <div className='d-flex justify-content-center detallesmultimediapanel2'>
                                {apiDataMultimedia.map((slide, index) => (
                                  <div
                                    key={index}
                                    onClick={(e) => handleMultimediaModalClick(Viewer, slide.file, slide.type, apiDataMultimedia)}
                                  >
                                    {slide.type != 'Video Youtube' ? (
                                      <div
                                        className={`Witem mx-2 mx-md-3`}
                                        data-index={slide.id}
                                        style={{ backgroundImage: `url(http://217.15.168.117:8080/api/Whatever/${slide.file})` }}
                                      >
                                        <p className="name">{slide.type}</p>
                                      </div>
                                    ) : (
                                      <div className='contdetailyt2pane'>
                                        <LiteYouTubeEmbed 
                                          id={getIdFromUrl(slide.file)}
                                        />
                                        <small className="nameyt">{slide.type}</small>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className='contenbtnbannerprimary' style={{height: '180px'}}>
                            </div>
                          </div>

                          <div className='vermasinfodetallesbtn display-none d-md-flex' data-bs-target="#carousel" data-bs-slide="next">
                            <div className='text-white'>Mas información del proyecto</div>
                            <IconSVG name="Icon_flecha_abajo_talentic_bl" width="25px"/>
                          </div>
                        </>
                      ))}
                    </div>
                    <div className="carousel-item detallespane2">
                      {apiData.map((item) => (
                        <>
                          <div className='overY positionR'>
                            <div className='detailltext'>
                              <h4>Descripción</h4>
                              <p className='text-justify'>{item.intro}</p>
                              <p className='text-justify'>{item.description}</p>
                            </div>

                            <div className='detaillmultimedia d-flex flex-column align-items-center mb-5'>
                              <h4>Multimedia del proyecto</h4>
                              <div className='d-flex flex-wrap justify-content-center detallesmultimediapanel2 overY'>
                                {apiDataMultimedia.map((slide, index) => (
                                  <div
                                    key={index}
                                    onClick={(e) => handleMultimediaModalClick(Viewer, slide.file, slide.type, apiDataMultimedia)}
                                  >
                                    {slide.type != 'Video Youtube' ? (
                                      <div
                                        className={`Witem mx-2 mx-md-3`}
                                        data-index={slide.id}
                                        style={{ backgroundImage: `url(http://217.15.168.117:8080/api/Whatever/${slide.file})` }}
                                      >
                                        <small className="name">{slide.type}</small>
                                      </div>
                                    ) : (
                                      <div className='contdetailyt2pane'>
                                        <LiteYouTubeEmbed 
                                          id={getIdFromUrl(slide.file)}
                                        />
                                        <small className="nameyt">{slide.type}</small>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                              <div className='col-12 text-center text-white'>
                                <IconSVG name="Icon_flecha_abajo_talentic_bl" width="20px" style={{cursor: 'pointer', marginRight: '10px'}} />
                                Ver más multimedia
                              </div>
                            </div>

                            <div className='detailltext mt-5'>
                              <div className='d-flex'>
                                <div className='col-6'>
                                  <h4 className='m-0'>Fecha de publicación</h4>
                                  <p>{formatDate(item.dateCreation)}</p>
                                  <br/>
                                  
                                  <h4 className='m-0'>Centro de formación</h4>
                                    {apiFichas.map((item) => (
                                      <p>{item.center}</p>
                                    ))}
                                  <br/>

                                  <h4 className='m-0'>Programa de formación</h4>
                                    {apiFichas.map((item) => (
                                      <p>{item.programme}</p>
                                    ))}
                                  <br/>

                                  <h4 className='m-0'>Ficha</h4>
                                  {apiFichas.map((item) => (
                                    <p>{item.name}</p>
                                  ))}
                                </div>
                                <div className='col-6'>
                                  <h4 className='m-0'>Autores</h4>
                                    <p>
                                      {apiDataAutores.map((item, index) => (
                                        <>
                                          {item.type === 5 && item.name}
                                          {index > 0 && index < apiDataAutores.length - 1 && ', '}
                                        </>
                                      ))}
                                    </p>
                                  <br/>

                                  <h4 className='m-0'>Instructores</h4>
                                    <p>
                                      {apiDataAutores.map((item, index) => (
                                        <>
                                          {item.type === 3 && item.name}
                                          {index > 0 && index < apiDataAutores.length - 1 && ', '}
                                        </>
                                      ))}
                                    </p>
                                  <br/>

                                  <h4 className='m-0'>Keywords</h4>
                                  <div className='d-flex flex-wrap'>
                                    {apiDataKeyWords.map((item, index) => (
                                      <div key={index} className='boton1 borderWhite py-1 px-2 m-1 d-flex cursor-pointer'>
                                        <IconSVG
                                            name="Icon_buscador_talentic_bl"
                                            className='align-content-center'
                                            width="11px"
                                            style={{ marginRight: '15px', cursor: 'pointer' }}
                                        />
                                        <p
                                            style={{ margin: '0px', color: 'white' }}
                                            className='align-content-center'
                                        >
                                            {item.name}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  
                  {prev !== "" ? (
                    <Link to={`/detailsProyects/${prev}`}>
                      <button
                        className="display-none d-md-flex carousel-control-prev"
                        type="button"
                      >
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                    </Link>
                  ) : (
                    null
                  )}
                  {next !== "" ? (
                    <Link to={`/detailsProyects/${next}`}>
                      <button
                        className="display-none d-md-flex carousel-control-next"
                        type="button"
                      >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </Link>
                  ) : (
                    null
                  )}
                </div>
              </div>
            </div>

            <div className='barrainferiordetalles d-flex flex-wrap mx-0 mx-md-5 mt-1'>
              <div className='col-3 col-md-6'>
                <div className="d-flex align-items-center justify-content-center justify-content-md-start my-1 pb-1">
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle 
                          className="nav-link dropdown-toggle hide-arrow btn btn-outline-primary-dropmenu-transparent d-flex flex-column flex-md-row justify-content-center align-items-center"
                          id="dropdown-basic"
                      >
                        <img
                          src={`http://217.15.168.117:8080/api/Whatever/e5e483c6128e2d812739d384e3c2683a.png`}
                          className="m-0 notificationsdetailsautores display-5"
                        />
                        <h6 className="mb-0 ms-0 ms-md-2 mt-1 mt-md-0" style={{ fontSize: '12px' }}>
                          Autores
                        </h6>
                      </Dropdown.Toggle>

                      <Dropdown.Menu 
                          align="end"
                          style={{ 
                              maxWidth: '200px',
                              maxHeight: '150px',
                              overflowY: 'scroll'
                          }}
                      >
                          <Dropdown.ItemText>
                            <div className={`buscartres usuario__encontrado`} >
                              <div className='d-flex flex-wrap col-12'>
                                {apiDataAutores.length > 0 ? apiDataAutores.map((item) => (
                                    <div className={`buscarDos usuario__encontrado`} >
                                      <div className='d-flex align-items-center'>
                                        <img 
                                            src={`${item.src}`}
                                            alt="User Icon" 
                                            className="display-5"
                                            style={{
                                                width: '2rem',
                                                height: '2rem',
                                                borderRadius: '50%'
                                            }} 
                                        />
                                        <div className='ms-3'>
                                            <p className="mb-0">{item.name}</p>
                                        </div>
                                      </div>
                                    </div>
                                )) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">No se encontraron registros</td>
                                    </tr>
                                )}
                              </div>
                            </div>
                          </Dropdown.ItemText>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className='col-9 col-md-6 d-flex flex-wrap justify-content-end align-items-center'>
                {(isAuthenticated ? (
                  <>
                      <div
                          className='mx-1 d-flex flex-column flex-md-row justify-content-center align-items-center'
                          onClick={(e) => apiData[0].asignado === 1 ? 
                            removeFavorites(e, id, formatDate.name) : 
                            addFavorites(e, id, formatDate.name)
                          }
                      >
                          <div className={`${apiData[0].asignado === 1 ? 'widgetsbannerdetaillsOn' : 'widgetsbannerdetaills'} mx-1`}>
                            <IconSVG
                              name={`${apiData[0].asignado === 1 ? 'Icon_corazon_talentic_bl' : 'Icon_corazon_talentic_details'}`}
                              width="15px"
                              style={{cursor: 'pointer'}}
                            />
                          </div>
                          {apiData.map((item, index) => (
                            <>
                              <h6 className='display-none display-md' style={{margin: '0', color: '#B0B0B0', marginRight: '5px'}}> {item.favorites} </h6>
                              <p className='display-md-none' style={{margin: '0', color: '#B0B0B0', marginRight: '5px'}}> Me gustas </p>
                            </>
                          ))}
                      </div>
                      <div
                          className='mx-1 d-flex flex-column flex-md-row justify-content-center align-items-center'
                          onClick={(e) => apiData[0].guardado === 1 ? 
                            removeSaved(e, id, formatDate.name) : 
                            addsaved(e, id, formatDate.name)
                          }
                      >
                          <div className={`${apiData[0].guardado === 1 ? 'widgetsbannerdetaillsOn' : 'widgetsbannerdetaills'} mx-1`} >
                            <IconSVG
                              name={`${apiData[0].guardado === 1 ? 'Icon_favorito_talentic_bl' : 'Icon_favorito_talentic_details'}`}
                              width="15px"
                              style={{cursor: 'pointer'}}
                            />
                          </div>
                          {apiData.map((item, index) => (
                            <>
                              <h6 className='display-none display-md' style={{margin: '0', color: '#B0B0B0', marginRight: '5px'}}> {item.saved} </h6>
                              <p className='display-md-none' style={{margin: '0', color: '#B0B0B0', marginRight: '5px'}}>Guardar</p>
                            </>
                          ))}
                      </div>
                      <div
                          className='mx-1 d-flex flex-column flex-md-row justify-content-center align-items-center'
                          onClick={() => openModalWithFormMenssaje(SendMensaje, id)}
                      >
                          <div className='widgetsbannerdetaills mx-1' >
                            <IconSVG name="Icon_enviar_talentic_details" width="15px" style={{cursor: 'pointer'}} />
                          </div>
                          <p className='display-md-none' style={{margin: '0', color: '#B0B0B0', marginRight: '5px'}}>Mensaje</p>
                      </div>
                  </>
                  ) : (
                    ''
                  )
                )}
                <div
                    className='mx-1 d-flex flex-column flex-md-row justify-content-center align-items-center'
                    onClick={() => openModalWithFormSharedSocial(SharedSocial, id)}
                >
                  <div className='widgetsbannerdetaills ms-1 px-2' >
                      <IconSVG name="Icon_compartir_talentic_details" width="15px" style={{cursor: 'pointer'}} />
                      <h6 className='display-none display-md' style={{margin: '0', color: '#B0B0B0', marginRight: '5px'}}>Compartir</h6>
                  </div>
                  <p className='display-md-none' style={{margin: '0', color: '#B0B0B0', marginRight: '5px'}}>Compartir</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MultimediaModal isOpen={isModalOpen} onClose={closeModal} widthValue='350px'>
            {modalContent}
        </MultimediaModal>

        <Modal isOpen={isModalOpen2} onClose={closeModal2} widthValue='300px'>
            {modalContent2}
        </Modal>
      </>
  );
}

export default DeteilsProyects;
