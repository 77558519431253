import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import BtnSubmit from '../components/btnSubmit';
import OnlyFile from '../components/onlyFile';

const ImgUser = ({ userdata, sumit }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [user, setuser] = useState({ user: userdata });
  const [imageUrl, setImageUrl] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const myRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const url = URL.createObjectURL(file);
      setImageUrl(url);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      alert('Por favor selecciona un archivo primero.');
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('API', 'talentic');
    formData.append('MODEL', 'talentic');
    formData.append('RESOURCE', 'files');
    formData.append('key', '5b8d3b1f084b01c6a8387459e80d4bb9');

    try {
      const response = await axios.post('http://217.15.168.117:8080/api/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      if (response.data.status === 200) {
        const requestBody = {
          API: 'talentic',
          MODEL: 'talentic',
          RESOURCE: 'people',
          Z: 'img',
          TYPE: 'UPDATE',
          id: user.user,
          img: response.data.mms,
          key: '5b8d3b1f084b01c6a8387459e80d4bb9',
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
          .then((responseupdate) => {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user) {
              sumit(true);
              toast.success('Se subió la imagen con éxito');
            } else {
              toast.error('Algo pasó, necesitas volver a iniciar para ver los cambios');
            }
          })
          .catch((error) => {
            toast.error("El archivo excede el tamaño permitido, el tamaño permitido es de 2M");
          });
      } else {
        toast.error(response.data.mms);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        
        <OnlyFile
          ref={myRef}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          dragActive={dragActive}
          imageUrl={imageUrl}
          handleFileChange={handleFileChange}
          name='file'
        />

        <BtnSubmit />
      </form>
    </div>
  );
};

export default ImgUser;
