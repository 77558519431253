import React, { useState, useRef  } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import IconSVG from '../../contents/icons/icon';

const AddMultimedia = ({ project, multimedia, onsumited, refress}) => {
    const [error, setError] = useState('');
    const [dragActive, setDragActive] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [formData, setFormData] = useState({
        files: [],
    });
    
    const handleDragOver = (e) => {
        e.preventDefault();
        setDragActive(true);
    };
    const handleDragLeave = () => {
        setDragActive(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        setDragActive(false);
        const droppedFiles = Array.from(e.dataTransfer.files);

        setFormData((prevData) => ({
            ...prevData,
            files: [...prevData.files, ...droppedFiles],
        }));
    };
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevData) => ({
            ...prevData,
            files: [...prevData.files, ...files],
        }));
    };
    const handleRemoveFile = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            files: prevFormData.files.filter((_, i) => i !== index)
        }));
    };

    const handldeCreate = async () => {
        const { files } = formData;
        
        const formDataToSend = new FormData();

        formDataToSend.append("API", "talentic");
        formDataToSend.append("MODEL", "talentic");
        formDataToSend.append("RESOURCE", "graphics");
        formDataToSend.append("key", "5b8d3b1f084b01c6a8387459e80d4bb9");
        formDataToSend.append("TYPE", "PUT");
        formDataToSend.append("project", project);
        
        files.forEach((file) => {
            formDataToSend.append('files[]', file);
        });

        try {
            const response = await axios.post('http://217.15.168.117:8080/api/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            setFormData({ files: [] });
            
            onsumited(true);
            refress(true);
        } catch (error) {
            toast.error('Hubo un error al crear el proyecto. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className="row">
            <div className="col-xl">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Actualizar Orden</h5>
                        <small className="text-muted float-end">Formulario de creación</small>
                    </div>
                    <div className="card-body">
                        <div
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            className={`drag-area col-12 ${dragActive ? 'active' : ''}`}
                            style={{
                                border: '1px dashed rgb(204, 204, 204)',
                                borderRadius: '.5rem',
                                padding: '10px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                backgroundColor: dragActive ? '#e3f2fd' : '#fff',
                                marginBottom: '.5rem',
                                position: 'relative',
                            }}
                            onClick={() => document.getElementById('archivos').click()}
                            >
                            {dragActive ? (
                                <p>Suelta los archivos aquí...</p>
                            ) : (
                                <>
                                    <IconSVG name="Icon_multimedia_talentic" width="50px" />
                                    <p className='text-primary' style={{ marginBottom: '0', marginLeft: '1rem'}}>Arrastra y suelta los archivos aquí o haz clic para seleccionar</p>
                                </>
                            )}
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                style={{ display: 'none' }} // Ocultar el input por defecto
                                id="archivos"
                            />
                        </div>
                        {formData.files.length > 0 && (
                            <ul className='ps-3 d-flex flex-wrap'>
                                {formData.files.map((archivos, index) => (
                                <li
                                    key={index}
                                    className='badge bg-label-dark'
                                    style={{ 
                                        display: 'flex', 
                                        alignItems: 'center',
                                        margin: '5px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleRemoveFile(index)}
                                >
                                    {archivos.name}
                                </li>
                                ))}
                            </ul>
                        )}

                        <div className="mt-3 col-12 d-flex justify-content-between">
                            <button className="btn btn-outline-primary" onClick={handldeCreate} >Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMultimedia;
