import React from 'react';
// Importa el componente IconSVG, que es utilizado para mostrar iconos en el botón
import IconSVG from '../contents/icons/icon';

function BtnSubmitDashboard({text}) {
    return (
        // Contenedor principal del botón con clase personalizada 'btn_dashboard'
        <div className="btn_dashboard">
            <button type="submit">
                {/* Componente IconSVG para mostrar un ícono, el nombre del ícono es "Icon_guardar_talentic" */}
                <IconSVG name="Icon_guardar_talentic"/>
                {/* El texto del botón que se pasa como prop, se muestra a la derecha del ícono */}
                <span>{text}</span>
            </button>
        </div>
    );
}

export default BtnSubmitDashboard;
