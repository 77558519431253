import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import { validateFields } from "../utils/validateFields";
import { validationRules } from "../utils/validationRules";

import CheckBox from '../../../components/checkbox';
import InputDashboard from '../../../components/inputDashboard';

const Step2 = ({ 
    formData, handleCheckboxChangeaprendiz, handleCheckboxChangeInstructor, onBack, onNext, action, update
}) => {
    const [errors, setErrors] = useState({});
    const [searchTermInstructor, setsearchTermInstructor] = useState('');
    const [searchTermAprendiz, setsearchTermAprendiz] = useState('');

    const [apiDataInstructor, setapiDataInstructor] = useState([]);
    const [apiDataAprendiz, setapiDataAprendiz] = useState([]);

    const aprendizWrapperRef = useRef(null);
    const instructorWrapperRef = useRef(null);

    useEffect(() => {
        fetchData();

        // Detectar clic fuera de los componentes de búsqueda
        const handleClickOutside = (event) => {
            if (
                aprendizWrapperRef.current &&
                !aprendizWrapperRef.current.contains(event.target)
            ) {
                setsearchTermAprendiz('');
            }

            if (
                instructorWrapperRef.current &&
                !instructorWrapperRef.current.contains(event.target)
            ) {
                setsearchTermInstructor('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchData = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            };

            const [responseAprendiz, responseInstructor, responseFichas] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectAprendizCreate" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectInstructorCreate" }),
            ]);
            
            setapiDataAprendiz(
                Array.isArray(responseAprendiz.data)
                    ? responseAprendiz.data.map(item => ({
                          id: parseInt(item.id, 10),
                          name: item.name,
                          mail: item.mail,
                          state: item.state
                      }))
                    : [] // Devuelve un array vacío si `data` no es un array
            );

            setapiDataInstructor(
                Array.isArray(responseInstructor.data)
                    ? responseInstructor.data.map(item => ({
                          id: parseInt(item.id, 10),
                          name: item.name,
                          mail: item.mail,
                          state: item.state
                      }))
                    : [] // Devuelve un array vacío si `data` no es un array
            );

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSearchChangeAprendiz = (e) => {
        setsearchTermAprendiz(e.target.value.toLowerCase());
    };
    const handleSearchChangeInstructor = (e) => {
        setsearchTermInstructor(e.target.value.toLowerCase());
    };
    

    const filteredDataAprendiz = apiDataAprendiz.filter(item =>
        (item.name || '').toLowerCase().includes(searchTermAprendiz) ||
        (item.mail || '').toLowerCase().includes(searchTermAprendiz)
    );
    const filteredDataInstructor = apiDataInstructor.filter(item =>
        (item.name || '').toLowerCase().includes(searchTermInstructor) ||
        (item.mail || '').toLowerCase().includes(searchTermInstructor)
    );
    

    const validateStep2 = (e) => {
        
        const isValid = validateFields({
            formData,
            rules: validationRules.step2,
            setErrors,
            toast,
        });

        if (isValid) {
            onNext();
        }
    };

    return (
        <div className="modal_user_content">
            <div className='modal_form'>
                <div className='modal_form_cont'>
                    <h4>Lista de aprendices</h4>
                    <div className="modal_form_item" ref={aprendizWrapperRef}>
                        <InputDashboard
                            type="text"
                            id="searchDos"
                            className="form-control"
                            placeholder="Buscar Aprendiz"
                            aria-label="Buscar Aprendiz"
                            aria-describedby="button-addon2"
                            value={searchTermAprendiz}
                            onChange={handleSearchChangeAprendiz}
                            icon="Icon_busqueda_talentic"
                        />
                    </div>
                    {/* Buscador de aprendices */}
                    <div className="aprendices-list-dash" ref={aprendizWrapperRef}>
                        {searchTermAprendiz ? (
                            filteredDataAprendiz.length > 0 ? (
                                filteredDataAprendiz.map((item) => (
                                    <CheckBox
                                        key={item.id}
                                        name="aprendices"
                                        value={item.id}
                                        icon="bx-lock-alt"
                                        userName={item.name}
                                        description={item.mail}
                                        checked={formData.aprendices.includes(item.id)}
                                        handleCheckboxChange={handleCheckboxChangeaprendiz}
                                    />
                                ))
                            ) : (
                                <div id="no-results-dos">Aprendiz no encontrado</div>
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                    {/* Lista de aprendices ya seleccionados */}
                    <div className="list_aprendices_autores">
                        
                        {formData.aprendices.length > 0 ? (
                            formData.aprendices.map((id) => {
                                const selectedItem = apiDataAprendiz.find((item) => item.id === id);
                                return selectedItem ? (
                                    <CheckBox
                                        key={selectedItem.id}
                                        name="aprendices"
                                        value={selectedItem.id}
                                        icon="bx-lock-alt"
                                        userName={selectedItem.name}
                                        description={selectedItem.mail}
                                        checked={true} // Siempre estará marcado porque ya está seleccionado
                                        handleCheckboxChange={handleCheckboxChangeaprendiz}
                                    />
                                ) : null;
                            })
                        ) : (
                            <div id="no-results-dos">No hay aprendices seleccionados</div>
                        )}
                    </div>
                </div>
                <div className='modal_form_cont'>
                    <h4>Lista de instructores</h4>
                    <div className="modal_form_item" ref={instructorWrapperRef}>
                        <InputDashboard
                            type="text"
                            id="searchDos"
                            className="form-control"
                            placeholder="Buscar Instructor"
                            aria-label="Buscar Instructor"
                            aria-describedby="button-addon2"
                            value={searchTermInstructor}
                            onChange={handleSearchChangeInstructor}
                            icon="Icon_busqueda_talentic"
                        />
                    </div>
                    {/* Buscador de aprendices */}
                    <div className="aprendices-list-dash" ref={instructorWrapperRef}>
                        {searchTermInstructor ? (
                            filteredDataInstructor.length > 0 ? (
                                filteredDataInstructor.map((item) => (
                                    <CheckBox
                                        key={item.id}
                                        name="instructores"
                                        value={item.id}
                                        icon="bx-lock-alt"
                                        userName={item.name}
                                        description={item.mail}
                                        checked={formData.instructores.includes(item.id)}
                                        handleCheckboxChange={handleCheckboxChangeInstructor}
                                    />
                                ))
                            ) : (
                                <div id="no-results-dos">Instructor no encontrado</div>
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                    {/* Lista de instructores ya seleccionados */}
                    <div className="list_instructores_autores">
                        
                        {formData.instructores.length > 0 ? (
                            formData.instructores.map((id) => {
                                // Usa apiDataInstructor como fuente original
                                const selectedItem = apiDataInstructor.find((item) => item.id === id);
                                return selectedItem ? (
                                    <CheckBox
                                        key={selectedItem.id}
                                        name="instructores"
                                        value={selectedItem.id}
                                        icon="bx-lock-alt"
                                        userName={selectedItem.name}
                                        description={selectedItem.mail}
                                        checked={true} // Siempre estará marcado porque ya está seleccionado
                                        handleCheckboxChange={handleCheckboxChangeInstructor} // Maneja cambios para instructores
                                    />
                                ) : null;
                            })
                        ) : (
                            <div id="no-results-dos">No hay instructores seleccionados</div>
                        )}
                    </div>

                </div>
                <div className="mt-3 col-12 d-flex justify-content-between">
                    <button className="btn btn-outline-primary" onClick={onBack} >Anterior</button>
                    {action === "update" ? (
                        <button className="btn btn-outline-primary" onClick={update} >Actualizar</button>
                    ) : (
                        <></>
                    )}
                    <button className="btn btn-outline-primary" onClick={validateStep2} >Siguiente</button>
                </div>
            </div>
        </div>
    );
};

export default Step2;
