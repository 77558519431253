import React, { useState, useRef  } from 'react';
import axios from 'axios';

import SelectDashboard from '../../components/selectDashboard';
import BtnSubmitDashboard from '../../components/btnSubmitDashboard';
import InputDashboard from '../../components/inputDashboard';

const UpdatemultimediaProyect = ({ project, multimedia, onsumited, refress}) => {
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        order: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCreate = async (e) => {
        e.preventDefault();

        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "graphics",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "UPDATE",
            "Z": "orden",
            "project": project,
            "id": multimedia,
            "orderk": formData.order
        };

        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody);
            setFormData({ order: '' });
            onsumited(true);
            refress(true);
        } catch (error) {
            console.error('Error creating data:', error);
            setError('Hubo un error al editar. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className="modal_user_content">
            <form className='modal_form' method="POST" onSubmit={handleCreate}>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="order" 
                        label="Nombre de la pieza multimedia"
                        type="number"
                        placeholder="Nombre de la pieza multimedia" 
                        value={formData.username}
                        onChange={handleChange}
                        colClassName=""
                    />
                </div>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="portada" 
                        label="portada"
                        placeholder="portada" 
                        value={formData.email}
                        onChange={handleChange}
                        colClassName=""
                    />
                </div>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="order" 
                        label="Orden"
                        type="number"
                        placeholder="orden del archivo multimedia" 
                        value={formData.username}
                        onChange={handleChange}
                        colClassName=""
                    />
                </div>
                <div className='modal_form_item'>
                    <SelectDashboard 
                        label="Estado"
                        name="Estado"
                        apiEndpoint="http://217.15.168.117:8080/api/"
                        onSelectChange={handleChange}
                        defaultOption="Selecciona un estado"
                        className=""
                        value={formData.sheet}
                        icon="Icon_programas_talentic"
                    />
                </div>
                <BtnSubmitDashboard text="Guardar"/>
            </form>
        </div>
    );
};

export default UpdatemultimediaProyect;
