import React, { useState, useRef  } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const AddEnlacesExternos = ({ project, multimedia, onsumited, refress}) => {
    const [error, setError] = useState('');
    const [inputValueEnlaces, setinputValueEnlaces] = useState('');
    const [formData, setFormData] = useState({
        enlaces: []
    });

    const handleInputChangeEnlaces = (e) => {
        setinputValueEnlaces(e.target.value);
    };
    const handleKeyPressEnlaces = (e) => {
        if (e.key === 'Enter' && inputValueEnlaces.trim() !== '') {
        e.preventDefault();
        setFormData((prevData) => ({
            ...prevData,
            enlaces: [...prevData.enlaces, inputValueEnlaces.trim()],
        }));
        setinputValueEnlaces('');
        }
    };
    const handleRemoveEnlaces = (indexToRemove) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            enlaces: prevFormData.enlaces.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handldeCreate = async () => {
        const { enlaces } = formData;
        
        const formDataToSend = new FormData();

        formDataToSend.append("API", "talentic");
        formDataToSend.append("MODEL", "talentic");
        formDataToSend.append("RESOURCE", "graphics");
        formDataToSend.append("key", "5b8d3b1f084b01c6a8387459e80d4bb9");
        formDataToSend.append("TYPE", "PUT");
        formDataToSend.append("project", project);
        formDataToSend.append("Z", "enlaces");
        
        formDataToSend.append("enlaces", JSON.stringify(enlaces));

        try {
            const response = await axios.post('http://217.15.168.117:8080/api/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            setFormData({ enlaces: [] });
            
            onsumited(true);
            refress(true);
        } catch (error) {
            toast.error('Hubo un error al crear el proyecto. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className="row">
            <div className="col-xl">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Nuevos Enlaces Externos</h5>
                        <small className="text-muted float-end">Formulario de creación</small>
                    </div>
                    <div className="card-body">
                    <div className={`inputContent col-12`}>
                    <div className="form-content-inputs">
                        <label className="form-label">Enlaces Externos</label>
                                <input
                                    type="text"
                                    value={inputValueEnlaces}
                                    className='form-control required'
                                    onChange={handleInputChangeEnlaces}
                                    onKeyDown={handleKeyPressEnlaces}
                                    placeholder="enlaces"
                                />
                            </div>
                        </div>
                        <ul className='ps-3 d-flex flex-wrap'>
                            {formData.enlaces.map((tag, index) => (
                                <li 
                                    key={index}
                                    style={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: '5px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleRemoveEnlaces(index)}
                                    className='badge bg-label-dark'
                                >
                                    {tag}
                                </li>
                            ))}
                        </ul>
                        <div className="mt-3 col-12 d-flex justify-content-between">
                            <button className="btn btn-outline-primary" onClick={handldeCreate} >Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEnlacesExternos;
