import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'; // Se importan las herramientas necesarias de React Router para el enrutamiento de la aplicación
import { ToastContainer } from 'react-toastify'; // Se importa el contenedor para las notificaciones Toast

// @ts-ignore
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa el CSS de Bootstrap para los estilos predeterminados
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Importa el JavaScript de Bootstrap para la funcionalidad de los componentes interactivos
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Se repite la importación de Bootstrap JavaScript, redundante
import 'react-toastify/dist/ReactToastify.css'; // Importa los estilos de las notificaciones Toast

import './css/icons.css'; // Importa los íconos personalizados definidos en un archivo CSS

import Menu from './components/menu'; // Componente de menú
import Footer from './components/footer'; // Componente de pie de página
import { AuthProvider } from './components/AuthContext'; // Proveedor de contexto de autenticación
import PrivateRoute from './components/PrivateRoute'; // Componente para proteger las rutas privadas

import Home from './views/home'; // Vista de la página de inicio
import Info from './views/info'; // Vista de la página de información
import HowWorks from './views/howWorks'; // Vista de cómo funciona la aplicación
import FAQs from './views/faqs'; // Vista de preguntas frecuentes
import DetailsProyects from './views/detailsProyects'; // Vista de detalles de los proyectos
import Dashboard from './views/dashboard'; // Vista de la página de dashboard
import ValidateUser from './views/validate'; // Vista para validar un usuario mediante un token

function App() {
  const location = useLocation(); // Obtiene la ubicación actual de la ruta
  const isDashboardRoute = location.pathname.startsWith('/dashboard'); // Verifica si la ruta comienza con '/dashboard'
  const isDetailsProyectsRoute = location.pathname.startsWith('/detailsProyects'); // Verifica si la ruta comienza con '/detailsProyects'

  return (
    <>
      {!isDashboardRoute && <Menu />} {/*  No muestra el menú si estamos en una ruta de dashboard*/}

      <ToastContainer /> {/* Muestra el contenedor de notificaciones Toast */}

      <div className={`${isDashboardRoute ? 'dashboard-content' : 'content'}`}> 
        {/* Aplica la clase 'dashboard-content' si la ruta es del dashboard, de lo contrario 'content' */}
        <Routes>
          {/* Definición de las rutas para las vistas de la aplicación */}
          <Route path="/" element={<Home />} /> // Ruta principal que carga la vista Home
          <Route path="/info" element={<Info />} /> // Ruta para la vista Info
          <Route path="/howWorks" element={<HowWorks />} /> // Ruta para la vista HowWorks
          <Route path="/faqs" element={<FAQs />} /> // Ruta para la vista FAQs

          <Route path="/detailsProyects/:id" element={<DetailsProyects />} /> // Ruta para detalles de proyectos

          <Route path="/validate/:token" element={<ValidateUser />} /> // Ruta para validar un usuario con un token

          <Route path="/dashboard/*" element={<PrivateRoute element={<Dashboard />} />} /> 
          {/* Ruta protegida para el dashboard que solo es accesible para usuarios autenticados */}
        </Routes>
      </div>
      
      {!isDashboardRoute && !isDetailsProyectsRoute && <Footer />} 
      {/* No muestra el pie de página si estamos en una ruta de dashboard o detalles de proyectos */}
    </>
  );
}

function AppWrapper() {
  return (
    <AuthProvider> {/* Proveedor de contexto para la autenticación*/}
      <Router> {/* Configura el enrutador de la aplicación*/}
        <App /> {/* Renderiza la aplicación principal*/}
      </Router>
    </AuthProvider>
  );
}

export default AppWrapper; // Exporta el componente principal envuelto en el contexto de autenticación
