import React from 'react';

// Componente que permite seleccionar el orden de los elementos a mostrar
const SortFilter = ({ sortConfig, onSortChange }) => {
    return (
        <div className='filtros_ordenar'>
            {/* Texto que indica la acción de ordenar */}
            <span>Ordenar:</span>
            {/* 
                Select para elegir el criterio de ordenación
                - value: el valor actual basado en la configuración de sortConfig
                - onChange: llama a onSortChange cuando se cambia la opción
            */}
            <select 
                value={`${sortConfig.key}${sortConfig.direction === 'asc' ? 'Asc' : 'Desc'}`} 
                onChange={(e) => onSortChange(e.target.value)}
            >
                {/* Opciones de ordenación */}
                <option value='fechaDesc'>Últimos</option>
                <option value='fechaAsc'>Antiguos</option>
                <option value='nombreAsc'>Nombre A-Z</option>
                <option value='nombreDesc'>Nombre Z-A</option>
            </select>
        </div>
    );
};

export default SortFilter;
