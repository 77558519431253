import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import axios from 'axios';

import SelectDashboard from '../../components/selectDashboard';
import BtnSubmitDashboard from '../../components/btnSubmitDashboard';
import InputDashboard from '../../components/inputDashboard';
import TextareaDashboard from '../../components/textareaDashboard';
import ImgUser from './../subirImgUserGeneral'; // Componente para subir imagen de usuario
import Modal from '../../components/modalDashboard'; // Componente para mostrar modales

const UpdateRoles = ({ onUPSubmitState, idDefault, usernameDefault, typeDefault, lastnameDefault, emailDefault, sheetDefault, imgDefault, personDefault }) => {    

    const [apiData, setApiData] = useState({});
    const [formData, setFormData] = useState({
        id: idDefault,
        username: usernameDefault,
        lastName: lastnameDefault,
        email: emailDefault,
        sheet: sheetDefault,
        password: '',
        type: typeDefault,
        img: imgDefault,
        person: personDefault
    });
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar la apertura del modal
    const [modalContent, setModalContent] = useState(null); // Estado para almacenar el contenido del modal

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    const requestBody = {
        "API": "talentic",
        "MODEL": "talentic",
        "RESOURCE": "select",
        "TYPE": "typeusers",
        "key": "5b8d3b1f084b01c6a8387459e80d4bb9"
    };
    
    const requestBodySheet = {
        "API": "talentic",
        "MODEL": "talentic",
        "RESOURCE": "select",
        "TYPE": "sheets",
        "key": "5b8d3b1f084b01c6a8387459e80d4bb9"
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const { id, username, password, type } = formData;

        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "users",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "UPDATE",
            "id": id,
            "username": formData.username,
            "lastName": formData.lastName,
            "email": formData.email,
            "sheet": formData.sheet,
            "password": formData.password != "" ? CryptoJS.MD5(formData.password).toString() : "",
            "description": formData.description,
            "type": formData.type,
            "img": formData.img,
            "person": formData.person
        };

        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody);
            onUPSubmitState(true);
        } catch (error) {
            toast.error('Hubo un error al editar. Por favor, inténtelo de nuevo.');
        }
    };
    // Función para refrescar los datos del usuario después de cambios
    const refrscarImg = () => {
        setIsModalOpen(false); // Cierra el modal
        setModalContent(null); // Limpia el contenido del modal
    };
    // Función para abrir un modal con un formulario específico
    const openModalWithForm = (FormComponent) => {
        setModalContent(<FormComponent userdata={formData.person} sumit={refrscarImg}/>); // Carga el componente del formulario
        setIsModalOpen(true); // Abre el modal
    };
    // Función para cerrar el modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };


    return (
        <div className="modal_user_content">
            <figure className='modal_user_imagen'>
                <img src={`http://217.15.168.117:8080/api/Whatever/${formData.img}`} alt={`${formData.id}`} onClick={() => openModalWithForm(ImgUser)}/>
                
                <div></div>
            </figure>
            <form className='modal_form' method="POST" onSubmit={handleUpdate}>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="username" 
                        label="Nombre"
                        placeholder="nombre usuario" 
                        value={formData.username}
                        onChange={handleChange}
                        colClassName=""
                    />
                    <InputDashboard 
                        name="lastName" 
                        label="Apellido"
                        placeholder="Apellido" 
                        value={formData.lastName}
                        onChange={handleChange}
                        colClassName=""
                    />
                </div>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="email" 
                        label="Correo"
                        placeholder="correo" 
                        value={formData.email}
                        onChange={handleChange}
                        colClassName=""
                        icon="Icon_correo_talentic_2"
                    />
                </div>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="password" 
                        label="Contraseña"
                        placeholder="Contraseña" 
                        value={formData.password}
                        onChange={handleChange}
                        colClassName=""
                        icon="Icon_permisos_talentic"
                    />
                </div>
                <div className='modal_form_item'>
                    <SelectDashboard
                        label="Ficha de formación"
                        name="sheet"
                        apiEndpoint="http://217.15.168.117:8080/api/"
                        requestBody={requestBodySheet}
                        onSelectChange={handleChange}
                        defaultOption="Selecciona una ficha"
                        className=""
                        value={formData.sheet}
                        icon="Icon_programas_talentic"
                    />
                </div>
                <div className='modal_form_item'>
                    <SelectDashboard
                        label="Rol"
                        name="type"
                        apiEndpoint="http://217.15.168.117:8080/api/"
                        requestBody={requestBody}
                        onSelectChange={handleChange}
                        defaultOption="Selecciona un tipo de usuario"
                        className=""
                        value={formData.type}
                        icon="Icon_roles_talentic"
                    />
                </div>
                    <div className='modal_form_item'>
                        <TextareaDashboard
                            name="description" 
                            label="Descripción"
                            placeholder="nombre usuario o nick" 
                            value={formData.description}
                            onChange={handleChange}
                            colClassName=""
                        />
                    </div>
                <BtnSubmitDashboard text="Guardar"/>
            </form>
            <Modal isOpen={isModalOpen} onClose={closeModal} widthValue='650px'>
                {modalContent}
            </Modal>
        </div>
    );
};

export default UpdateRoles;