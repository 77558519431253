import React from 'react';

// Importación de los archivos CSS para los estilos del componente.
import './../css/homesas.css';
import './../css/styles.css';
import './../css/app-c15aace3.css';

// Componente funcional HowWorks que describe el proceso de Talentic en 3 pasos.
function HowWorks() {
    return (
        <div className="container">
            <div className="que_es mx-auto">
                {/* Título principal de la página */}
                <h1 className="display-4 py-5">¿Cómo funciona Talentic?</h1>
                
                {/* Subtítulo que describe el objetivo del componente */}
                <h2 className="featurette-heading fw-normal lh-1">Publica tu proyecto en solo 3 pasos</h2>
                
                <div className="c_list_pass mb-5">
                
                    {/* Paso 1 */}
                    <div className="row featurette align-items-center mt-5">
                        <div className="col-md-6 position-relative">
                            {/* Imagen representativa del paso 1 */}
                            <img src="https://www.talentic.com.co/assets/front/images/foto.jpg" alt="" className="img_card" />
                            {/* Icono que representa el paso 1 */}
                            <img src="https://www.talentic.com.co/assets/front/images/paso1.png" alt="paso 1" className="ico_number" />
                        </div>
                        <div className="col-md-6 px-md-5 px-4">
                            {/* Descripción del paso 1 */}
                            <p className="lead">
                                En el marco de la ejecución de la primera fase, se desarrolló la construcción de un prototipo de repositorio que cumpliera con la finalidad planteada, además del establecimiento de un modelo de estandarización de características de los resultados finales de los proyectos a ser curados y registrados en el sistema, permitiendo que los mismos cumplan con estándares de registro y clasificación documental a nivel mundial.
                            </p>
                        </div>
                    </div>
                    
                    {/* Paso 2 */}
                    <div className="row featurette align-items-center mt-5">
                        <div className="col-md-6 position-relative">
                            {/* Imagen representativa del paso 2 */}
                            <img src="https://www.talentic.com.co/assets/front/images/foto.jpg" alt="" className="img_card" />
                            {/* Icono que representa el paso 2 */}
                            <img src="https://www.talentic.com.co/assets/front/images/paso2.png" alt="paso 2" className="ico_number" />
                        </div>
                        <div className="col-md-6 px-md-5 px-4">
                            {/* Descripción del paso 2 */}
                            <p className="lead">
                                En el marco de la ejecución de la primera fase se desarrolló la construcción de un prototipo de repositorio que cumpliera con la finalidad planteada, además del establecimiento de un modelo de estandarización de características de los resultados finales de los proyectos a ser curados y registrados en el sistema, permitiendo que los mismo cumplan con estándares de registro y clasificación documental a nivel mundial.
                            </p>
                        </div>
                    </div>
                    
                    {/* Paso 3 */}
                    <div className="row featurette align-items-center mt-5">
                        <div className="col-md-6 position-relative">
                            {/* Imagen representativa del paso 3 */}
                            <img src="https://www.talentic.com.co/assets/front/images/foto.jpg" alt="" className="img_card" />
                            {/* Icono que representa el paso 3 */}
                            <img src="https://www.talentic.com.co/assets/front/images/paso3.png" alt="paso 3" className="ico_number" />
                        </div>
                        <div className="col-md-6 px-md-5 px-4">
                            {/* Descripción del paso 3 */}
                            <p className="lead">
                                En el marco de la ejecución de la primera fase se desarrolló la construcción de un prototipo de repositorio que cumpliera con la finalidad planteada, además del establecimiento de un modelo de estandarización de características de los resultados finales de los proyectos a ser curados y registrados en el sistema, permitiendo que los mismo cumplan con estándares de registro y clasificación documental a nivel mundial.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default HowWorks;
