import React, { useState, useEffect } from 'react';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }).format(date);
};

const ShowCenters = ({ nameDefault, descriptionDefault }) => {
    const [formData, setFormData] = useState({
        name: nameDefault,
        description: descriptionDefault,
    });

    useEffect(() => {
        setFormData({
            name: nameDefault,
            description: descriptionDefault,
        });
    }, [nameDefault, descriptionDefault]);

    return (
        <div className="row">
            <div className="col-xl">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center p-1">
                    </div>
                    <div className="card-body">
                        <p className='p-1 m-0'>{formData.name}</p>
                        <p className='p-1 m-0'>{formData.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowCenters;
