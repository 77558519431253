import React, { useState } from 'react'; // Importamos React y useState para manejar el estado del componente
import { Link } from 'react-router-dom'; // Importamos Link para navegación dentro de la aplicación
import { toast } from 'react-toastify'; // Importamos toast para mostrar mensajes emergentes de éxito y error
import axios from 'axios'; // Importamos axios para realizar peticiones HTTP

import { useAuth } from './AuthContext'; // Importamos el contexto de autenticación para acceder al estado de autenticación del usuario
import Modal from './modal'; // Importamos el componente Modal que se usa para mostrar formularios emergentes

import SendMensaje from './../forms/sendMensaje'; // Importamos el formulario para enviar mensajes
import SharedSocial from '../forms/sharedSocial'; // Importamos el formulario para compartir en redes sociales

import IconSVG from '../contents/icons/icon'; // Importamos el componente que maneja los íconos SVG

// Función para formatear las fechas en formato 'mes abreviado, día, año'
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {month: 'short', day: '2-digit', year: 'numeric'};
    return date.toLocaleDateString('es-ES', options); // Usamos la configuración regional de España
};

const CardFavorites = ({ imgSrc, name, favorites, hoverText, link, date, remove, widthValue = "210px" }) => {
    const { isAuthenticated } = useAuth(); // Obtenemos el estado de autenticación desde el contexto de autenticación
    const [hovered, setHovered] = useState(false); // Estado para manejar el hover sobre el card
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para manejar si el modal está abierto o cerrado
    const [modalContent, setModalContent] = useState(null); // Estado para manejar el contenido del modal
    const [fadeClass, setFadeClass] = useState('fade-in'); // Clase para animación de desvanecimiento
    const [formData] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user')); // Recuperamos los datos del usuario desde el localStorage

        return {
          user: user?.index_user || '' // Si existe el usuario, obtenemos su índice de usuario, si no, asignamos un valor vacío
        };
    });

    // Función para abrir un modal con un formulario
    const openModalWithForm = (FormComponent) => {
        setModalContent(<FormComponent />); // Establecemos el contenido del modal con el componente recibido
        setIsModalOpen(true); // Abrimos el modal
    };

    // Función para abrir el modal con el formulario de mensaje
    const openModalWithFormMenssaje = (FormComponent, link) => {
        setModalContent(<FormComponent
            project={link}
            userSend={formData.user} // Pasamos el índice del usuario
            onSubmit={closeModalSuccess} // Definimos la función que se ejecutará al enviar el formulario
        />);
        setIsModalOpen(true); // Abrimos el modal
    };

    // Función para abrir el modal con el formulario de compartir en redes sociales
    const openModalWithFormSharedSocial = (FormComponent, link) => {
        setModalContent(<FormComponent
            project={link} // Pasamos el proyecto que se va a compartir
        />);
        setIsModalOpen(true); // Abrimos el modal
    };

    // Función para cerrar el modal
    const closeModal = () => {
        setIsModalOpen(false); // Cerramos el modal
        setModalContent(null); // Limpiamos el contenido del modal
    };

    // Función que se ejecuta al enviar un mensaje con éxito
    const closeModalSuccess = () => {
        toast.success("Se envio con éxito"); // Mostramos un mensaje de éxito
        setIsModalOpen(false); // Cerramos el modal
        setModalContent(null); // Limpiamos el contenido del modal
    };

    // Función para eliminar un proyecto de los favoritos
    const removeFavorites = async (e, link, name) => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "favorites",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9", // Llave de autenticación
            "TYPE": "DELETE_FORCE", // Tipo de acción (eliminar forzadamente)
            "Z": "favorites",
            "proyect": link, // Enlace del proyecto que se eliminará
            "user": formData.user // ID del usuario que está realizando la acción
        };

        try {
            // Realizamos la solicitud DELETE con los datos necesarios
            await axios.post('http://217.15.168.117:8080/api/', requestBody, {
              headers: {
              'Content-Type': 'application/json'
              }
            });
            remove(true); // Llamamos a la función de eliminación pasada como prop
            toast.success("Se quito el proyecto " + name + " de tus favoritos!"); // Mostramos un mensaje de éxito
        } catch (error) {
            toast.error(error); // En caso de error, mostramos un mensaje de error
            if (error.response) {
                toast.error('Request failed:', error.response.data.message); // Si la respuesta tiene un error, mostramos el mensaje
            } else if (error.request) {
                toast.error('No response received:', error.request); // Si no se recibe respuesta, mostramos un mensaje
            } else {
                toast.error('Error setting up request:', error.message); // Si ocurre algún otro error, mostramos el mensaje
            }
        }
    };

    return (
        <>
            {/* Contenedor del card de favoritos */}
            <div className='d-flex flex-column align-content-flex-start'>
                {/* Card con información del proyecto */}
                <div className={`profile-card-2 my-2 mx-4 on ${fadeClass}`} style={{ width: widthValue}}>

                    {/* Enlace al detalle del proyecto */}
                    <Link to={`/detailsProyects/${link}`} >
                        <img src={imgSrc} alt="Profile" /> {/* Imagen del proyecto */}
                    </Link>

                    {/* Texto que aparece al pasar el mouse sobre el card */}
                    <div className={`profile-hover-text ${fadeClass}`}>
                        <h4 className='mb-2 text-white'>{name}</h4> {/* Nombre del proyecto */}
                        {hoverText} {/* Texto adicional cuando el mouse está sobre el card */}
                        <div className='mb-2 d-flex justify-content-between'>
                            <div>
                                <IconSVG name="Icon_corazon_talentic_bl" width="15px" style={{cursor: 'pointer', marginRight: '5px'}} /> {/* Ícono de corazón */}
                                {favorites === "" ? 0 : favorites} {/* Cantidad de favoritos */}
                            </div>
                            <div>
                                {formatDate(date)} {/* Fecha de creación del proyecto */}
                            </div>
                        </div>
                    </div>

                    {/* Íconos para interactuar con el proyecto */}
                    <div className={`profile-icons ${fadeClass}`}>
                        {( isAuthenticated ? (
                                <>
                                    {/* Íconos visibles si el usuario está autenticado */}
                                    <div>
                                        <IconSVG
                                            name="Icon_compartir_2_talentic_bl"
                                            width="17px"
                                            style={{cursor: 'pointer', marginRight: '5px'}}
                                            onClick={() => openModalWithFormMenssaje(SendMensaje, link)} // Abre el modal de envío de mensaje
                                        />
                                    </div>

                                    <div>
                                        <IconSVG
                                            name="Icon_corazon_talentic_bl"
                                            width="16px"
                                            style={{cursor: 'pointer', marginRight: '5px'}}
                                            onClick={(e) => removeFavorites(e, link, name)} // Elimina el proyecto de favoritos
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    {/* Íconos visibles si el usuario no está autenticado */}
                                    <div>
                                        <IconSVG
                                            name="Icon_compartir_2_talentic_bl"
                                            width="17px"
                                            style={{cursor: 'pointer', marginRight: '5px'}}
                                            onClick={() => openModalWithFormSharedSocial(SharedSocial, link)} // Abre el modal de compartir en redes sociales
                                        />
                                    </div>

                                    <div></div>
                                </>
                            )
                        )}
                    </div>
                </div>
                <div className="profile-name text-primary mx-4">{name}</div> {/* Nombre del proyecto debajo de la imagen */}
            </div>

            {/* Modal que se abre cuando el usuario interactúa con los íconos */}
            <Modal isOpen={isModalOpen} onClose={closeModal} widthValue='400px'>
                {modalContent} {/* Contenido del modal */}
            </Modal>
        </>
    );
};

export default CardFavorites;
