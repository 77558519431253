import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';  // Importa el componente Dropdown de react-bootstrap para crear un menú desplegable.
import { Link } from 'react-router-dom';  // Importa Link para la navegación sin recargar la página.

import { useAuth } from '../components/AuthContext';  // Importa un contexto personalizado para la autenticación.

import TabUserMenu from './tabUserMenu';  // Importa el componente TabUserMenu (aunque no se usa en este código).

import IconSVG from '../contents/icons/icon';  // Importa un componente para mostrar íconos en formato SVG.

const UserMenu = () => {
    const { logout } = useAuth();  // Obtiene la función logout del contexto de autenticación.
    
    // Estado inicial con la información del usuario, que se obtiene del localStorage.
    const [formData] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));  // Recupera los datos del usuario del almacenamiento local.
        
        return {
            name: user?.name || '',  // Nombre del usuario.
            person: user?.person || '',  // Persona del usuario.
            lastName: user?.lastName || '',  // Apellido del usuario.
            notifyChangePass: user?.notifyChangePass === 0 ? true : false,  // Si el usuario ha activado las notificaciones de cambio de contraseña.
            notifyMessages: user?.notifyMessages === 0 ? true : false,  // Si el usuario ha activado las notificaciones de mensajes.
            img: user?.img || '',  // Imagen del usuario.
            email: user?.email || '',  // Correo electrónico del usuario.
            dni: user?.dni || ''  // DNI del usuario.
        };
    });

    return (
        <Dropdown >
            {/* Botón para desplegar el menú con la imagen del usuario y un ícono de flecha hacia abajo. */}
            <Dropdown.Toggle 
                className="nav-link dropdown-toggle hide-arrow btn btn-outline-primary-dropmenu"
                id="dropdown-basic"
            >
                {/* Imagen del usuario */}
                <img 
                    src={`http://217.15.168.117:8080/api/Whatever/${formData.img}`}
                    alt="User Icon" 
                    className="display-5 imgusermenu"
                />
                {/* Ícono de flecha hacia abajo */}
                <IconSVG name="Icon_flecha_abajo_talentic" width="10px" style={{ marginLeft: '5px'}} />
            </Dropdown.Toggle>

            {/* Menú del Dropdown */}
            <Dropdown.Menu align="end" style={{ maxWidth: '300px'}} className='bg-info'>
                {/* Información del usuario dentro del menú */}
                <Dropdown.ItemText>
                    <div className="d-flex align-items-center my-2 bg-body py-2 px-1" style={{ borderRadius: '.5rem'}}>
                        <div className="ms-1">
                            <img
                                src={`http://217.15.168.117:8080/api/Whatever/${formData.img}`}
                                className="m-0 profilecard display-5"
                            />
                        </div>
                        <div className="ms-2">
                            {/* Nombre y correo del usuario */}
                            <h6 className="mb-0 text-primary">
                                {`${formData.name} ${formData.lastName}`}
                            </h6>
                            <small style={{ overflowWrap: 'anywhere'}} className="text-muted">{formData.email}</small>
                            {/* Enlace para editar el perfil */}
                            <Link
                                to="/dashboard"
                                state={{ 
                                    tab: 'user'
                                }}
                                style={{ width: 'inherit' }}
                            >
                                <IconSVG name="Icon_editar_talentic" width="11px" style={{ marginRight: '10px', cursor: 'pointer'}} />
                                <small className="align-middle text-muted">Editar perfil</small>
                            </Link>
                        </div>
                    </div>
                </Dropdown.ItemText>

                {/* Enlaces para diferentes secciones del menú */}
                <Dropdown.Item  style={{ padding: '.3rem 1rem' }}>
                    <Link 
                        to="/dashboard"
                        state={{ 
                            tab: 'favorites'
                        }}
                        style={{ width: 'inherit' }}
                    >
                        <IconSVG name="Icon_corazon_talentic_bl" width="11px" style={{ marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}} />
                        <small className="align-middle text-light">Favoritos</small>
                    </Link>
                </Dropdown.Item>

                <Dropdown.Item  style={{ padding: '.3rem 1rem' }}>
                    <Link 
                        to="/dashboard"
                        state={{ tab: 'saved' }}
                        style={{ width: 'inherit' }}
                    >
                        <IconSVG name="Icon_favorito_talentic_bl" width="11px" style={{ marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}} />
                        <small className="align-middle text-light">Guardados</small>
                    </Link>
                </Dropdown.Item>

                {/* Separador entre las opciones */}
                <Dropdown.Divider />
                
                {/* Enlaces adicionales para crear proyectos y ver los proyectos guardados */}
                <Dropdown.Item  style={{ padding: '.3rem 1rem' }}>
                    <Link
                        to="/dashboard"
                        state={{ tab: 'projects', open: 'crearProyectos' }}
                        style={{ width: 'inherit' }}
                    >
                        <IconSVG name="Icon_proyectos_talentic_bl" width="11px" style={{ marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}} />
                        <small className="align-middle text-light">Subir un proyecto</small>
                    </Link>
                </Dropdown.Item>

                <Dropdown.Item  style={{ padding: '.3rem 1rem' }}>
                    <Link
                        to="/dashboard"
                        state={{ tab: 'projects' }}
                        style={{ width: 'inherit' }}
                    >
                        <IconSVG name="Icon_carpeta_talentic_bl" width="11px" style={{ marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}} />
                        <small className="align-middle text-light">Mis proyectos</small>
                    </Link>
                </Dropdown.Item>
                
                {/* Separador antes de las opciones finales */}
                <Dropdown.Divider />

                {/* Enlace para ir al Dashboard */}
                <Dropdown.Item  style={{ padding: '.3rem 1rem' }}>
                    <Link to="/dashboard" style={{ width: 'inherit' }}>
                        <IconSVG name="Icon_Fotografia_talentic_bl" width="11px" style={{ marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}} />
                        <small className="align-middle text-light">Dashboard</small>
                    </Link>
                </Dropdown.Item>

                {/* Opción para cerrar sesión */}
                <Dropdown.Item  style={{ padding: '.3rem 1rem' }}>
                    <btn className="d-flex col-12 justify-content-center btn btn-info" onClick={() => logout()}>
                        <p
                            style={{ margin: '0px' }}
                            className='align-content-center'
                        >
                            Cerrar sesión
                        </p>
                    </btn>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserMenu;
