import { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import axios from 'axios';

import ShowUsers from './show';
import CreateUsers from './create';
import UpdateUsers from './update';

import SeacrhData from '../../components/searchData';
import IconSVG from '../../contents/icons/icon';
import Modal from '../../components/modalDashboard';
import ViewToggle from '../../components/viewToggle';
import SortFilter from '../../components/sortFilter';

import ListView from '../../components/listView';
import CardView from '../../components/cardView';

const Users = ( ) => {
    const [apiData, setApiData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [roleFilter, setRoleFilter] = useState('Todos'); // Estado para el filtro de rol
    const [sortConfig, setSortConfig] = useState({ key: 'dateCreation', direction: 'desc' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [activeMenuActions, setActiveMenuActions] = useState(null);
    const menuRef = useRef(null);
    const [viewType, setViewType] = useState('cards'); // 'cards' para tarjetas y 'list' para lista
    const navigate = useNavigate();

    const hashToVer = "6b2af060217dd53ea0a2b2d6eb97954e";
    const hashToCrear = "3dbd9224b142cbeee8f514b74c6e2468"; // FALTA COLOCAR EL PERMISO EN LA CREACIÓN, POR QUE SE DESHABILITÓ?
    const hashToEditar = "62e3583a4d31ecaa98170075962a6107";
    const hashToEliminar = "08c47c84ad58d169294833613e69a5cc";

    const columns = [ // CÓDIGO INNECESARIO
        { label: 'Usuario', key: 'username', sortable: true },
        { label: 'Email', key: 'email', sortable: true },
        { label: 'Tipo', key: 'type', sortable: false },
        { label: 'Acciones', key: 'actions', sortable: false }
    ];

    const toggleMenuActions = (menuName) => {
        setActiveMenuActions(activeMenuActions === menuName ? null : menuName);
    };

    // Alterna entre los tipos de visualización
    const showCards = () => setViewType('cards');
    const showList = () => setViewType('list');

    useEffect(() => {
        // Detectar clics fuera del menú
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveMenuActions(null);
            }
        };

        // Añadir evento al hacer clic en el documento
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Limpiar el evento al desmontar el componente
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [formDatauser] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        return {
            permisos: user?.permissions || ''
        };
    });

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = () => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "list",
            "TYPE": "users",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                const cleanedData = response.data.map(item => ({
                    id: item.id,
                    person: item.person,
                    username: item.username,
                    name: item.name,
                    lastname: item.lastname,
                    typeId: item.typeId,
                    type: item.type,
                    nameuser: item.nameuser,
                    img: item.img,
                    sheetId: item.sheetId,
                    sheet: item.sheet,
                    email: item.email
                }));
                setApiData(cleanedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    const handleChangecrear = (newState) => {
        toast.success("Se gestionaron los datos con éxito");
        setIsModalOpen(false);
        setModalContent(null);
        fetchData();
    };

    const handleChangeeditar = (newState) => {
        toast.success("Se editaron los datos con éxito");
        setIsModalOpen(false);
        fetchData();
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = apiData
    .filter((item) =>
        (roleFilter === 'Todos' || item.type === roleFilter) &&
        (item.username || '').toLowerCase().includes(searchTerm)
    )
    .sort((a, b) => {
        if (sortConfig.key === 'dateCreation' && a.dateCreation && b.dateCreation) {
            return sortConfig.direction === 'asc'
                ? new Date(a.dateCreation) - new Date(b.dateCreation)
                : new Date(b.dateCreation) - new Date(a.dateCreation);
        } else if (sortConfig.key === 'username') {
            return sortConfig.direction === 'asc'
                ? (a.username || '').localeCompare(b.username || '')
                : (b.username || '').localeCompare(a.username || '');
        }
        return 0;
    });

    const handleSort = (value) => {
        let key, direction;

        if (value === 'fechaDesc') {
            key = 'dateCreation';
            direction = 'desc';
        } else if (value === 'fechaAsc') {
            key = 'dateCreation';
            direction = 'asc';
        } else if (value === 'nombreAsc') {
            key = 'username';
            direction = 'asc';
        } else if (value === 'nombreDesc') {
            key = 'username';
            direction = 'desc';
        }

        setSortConfig({ key, direction });
    };

    // Manejar el cambio en el filtro de rol
    const handleRoleFilterChange = (role) => {
        setRoleFilter(role);
    };
    
    const handleDelete = (itemId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este registro? Escribe "ELIMINAR" para confirmar.')) {
            const userInput = prompt('Escribe "ELIMINAR" para confirmar la eliminación:');
            
            if (userInput === "ELIMINAR") {
                const requestBody = {
                    "API": "talentic",
                    "MODEL": "talentic",
                    "RESOURCE": "users",
                    "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                    "TYPE": "DELETE_FORCE",
                    "id": itemId
                };
    
                axios.post('http://217.15.168.117:8080/api/', requestBody)
                    .then(response => {
                        toast.success("Se eliminaron los datos con éxito");
                        fetchData();
                    })
                    .catch(error => {
                        console.error('Error deleting data:', error);
                    });
            } else {
                toast.warning("La eliminación fue cancelada.");
            }
        }
    };

    const handleCreateClick = (FormComponent) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent onSubmitState={handleChangecrear}/>);
    };

    const handleEditClick = (FormComponent, id, username, type, lastname, email, sheetId, img, person ) => {
        setIsModalOpen(true);
        setModalContent(
            <FormComponent
                onUPSubmitState={handleChangeeditar}
                idDefault={id}
                usernameDefault={username}
                typeDefault={type}
                lastnameDefault={lastname}
                emailDefault={email}
                sheetDefault={sheetId}
                imgDefault={img}
                personDefault={person}
            />
        );
    };
    
    const handleShowClick = (FormComponent, id,
        person,
        username,
        name,
        lastname,
        typeId,
        type,
        nameuser,
        img,
        sheetId,
        sheet,
        email) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent 
            onUPSubmitState={handleChangeeditar}
            idDefault={id}
            personDefault={person}
            usernameDefault={username}
            nameDefault={name}
            lastnameDefault={lastname}
            typeIdDefault={typeId}
            typeDefault={type}
            nameuserDefault={nameuser}
            imgDefault={img}
            sheetIdDefault={sheetId}
            sheetDefault={sheet}
            emailDefault={email}
        />);
    };

    // Función que define el menú de acciones específico para cada usuario
    const actionMenuProps = (item) => ({
        isActive: activeMenuActions === item.id,
        onClose: () => setActiveMenuActions(null),
        actions: [
            formDatauser.permisos.includes(hashToVer) && {
                label: 'Ver',
                icon: 'Icon_ver_talentic_2',
                onClick: () => handleShowClick(ShowUsers, item.id,
                    item.person,
                    item.username,
                    item.name,
                    item.lastname,
                    item.typeId,
                    item.type,
                    item.nameuser,
                    item.img,
                    item.sheetId,
                    item.sheet,
                    item.email),
            },
            formDatauser.permisos.includes(hashToEditar) && {
                label: 'Editar',
                icon: 'Icon_editar_talentic_2',
                onClick: () => handleEditClick(UpdateUsers, item.id, item.name, item.typeId, item.lastname, item.username, item.sheetId, item.img, item.person),
                className: 'menu_acciones_editar'
            },
            formDatauser.permisos.includes(hashToEditar) && {
                label: 'Permisos',
                icon: 'Icon_permisos_talentic',
                onClick: () => navigate('/dashboard/assignuser', { state: {
                    id: item.id, username: item.username, type: item.type,
                }}),
                className: 'menu_acciones_permisos'
            },
            formDatauser.permisos.includes(hashToEliminar) && {
                label: 'Eliminar',
                icon: 'Icon_eliminar_talentic_2',
                onClick: () => handleDelete(item.id),
                className: 'menu_acciones_eliminar'
            }
        ].filter(Boolean)
    });

    return (
        <div className='dashboard_content'>
            <main className='main__dashboard_content'>
                <div className='form_content'>
                    <section className='dashboard_titulo'>
                        <h2>Usuarios</h2>
                    </section>
                    <div className='main__dashboard_primera_parte'>
                        <nav className='main__dashboard_menu_interno'>
                            <ul>
                                <li className={` ${roleFilter === 'Todos' ? 'active_item_dash' : ''}`} onClick={() => handleRoleFilterChange('Todos')}>Todos</li>
                                <li className={` ${roleFilter === 'Aprendiz' ? 'active_item_dash' : ''}`} onClick={() => handleRoleFilterChange('Aprendiz')}>Aprendices</li>
                                <li className={` ${roleFilter === 'Instructor' ? 'active_item_dash' : ''}`} onClick={() => handleRoleFilterChange('Instructor')}>Instructores</li>
                                <li onClick={() => handleRoleFilterChange('Todos')}>Más</li>
                            </ul>
                        </nav>
                        <div className='btn_nuevo_dashboard' onClick={() => handleCreateClick(CreateUsers)}>
                            <IconSVG name="Icon_mas_talentic"/> Crear Nuevo
                        </div>
                    </div>
                    <div className='form_content_dashboard'>
                        <div className='filters_dashboard'>
                            <div className='buscador_dashboard'>
                                <SeacrhData
                                    searchTerm={searchTerm}
                                    handleSearchChange={handleSearchChange}
                                />
                            </div>
                            <div className='filtros_content_dashboard'>
                                <SortFilter sortConfig={sortConfig} onSortChange={handleSort}/>
                                <ViewToggle viewType={viewType} showCards={showCards} showList={showList} />
                            </div>
                        </div>
                        {/* Vista en formato de tarjetas */}
                        {viewType === 'cards' && (
                            <div className='layout_cards'>
                                {filteredData.length > 0 ? (
                                filteredData.map((item) => (
                                    <CardView
                                        key={item.id}
                                        titulo={item.nameuser}
                                        subtitulo={item.username}
                                        parrafo={item.type}
                                        actionMenuProps={actionMenuProps}
                                        item={item}
                                        toggleMenuActions={toggleMenuActions}
                                        ref={menuRef}
                                        img={item.img}
                                    />
                                ))
                            ) : (
                                <p>No se encontraron registros</p>
                            )}
                            </div>
                        )}

                        {/* Vista en formato de lista */}
                        {viewType === 'list' && (
                            <div className='layout_list_dashboard'>
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('nombreAsc')}><h4>Usuario<IconSVG name="Icon_flechas_talentic"/></h4></th>
                                        <th><h4>Correo</h4></th>
                                        <th><h4>Rol</h4></th>
                                        <th><h4>Acciones</h4></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length > 0 ? filteredData.map((item) => (
                                        <ListView
                                            key={item.id}
                                            img={item.img}
                                            titulo={item.username}
                                            subtitulo={item.username}
                                            parrafo={item.type}
                                            actionMenuProps={actionMenuProps}
                                            toggleMenuActions={toggleMenuActions}
                                            ref={menuRef}
                                            item={item}
                                        />
                                    )) : (
                                        <tr>
                                            <td colSpan="3">No se encontraron registros</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                            
                        )}
                    </div>
                </div>
            </main>
            <Modal isOpen={isModalOpen} onClose={closeModal} titulo={"Perfil"}>
                {modalContent}
            </Modal>
        </div>
    );
};

export default Users;