// Importa las dependencias necesarias de React, useState, useEffect, forwardRef y axios
import React, { useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import IconSVG from '../contents/icons/icon'; // Componente de icono SVG

// Define el componente SelectDashboard, que es un componente funcional de React
// Se utiliza forwardRef para permitir pasar una referencia (ref) al componente select
const SelectDashboard = forwardRef(({
    label, 
    name, 
    apiEndpoint,  // Endpoint de la API desde el cual obtener las opciones
    requestBody,  // El cuerpo de la solicitud POST que se enviará a la API
    onSelectChange,  // Función para manejar el cambio en el select
    defaultOption,  // Opción por defecto que se muestra
    value,  // Valor seleccionado del select
    className,  // Clase CSS adicional para personalizar el estilo
    icon  // Nombre del icono a mostrar junto al select
}, ref) => {
    // State para almacenar las opciones del select
    const [options, setOptions] = useState([]);

    // useEffect que se ejecuta una vez que el componente se monta o cuando cambian apiEndpoint o requestBody
    useEffect(() => {
        // Realiza una solicitud POST a la API
        axios.post(apiEndpoint, requestBody)
            .then(response => {
                // Si la solicitud es exitosa, almacena las opciones en el estado
                setOptions(response.data);
            })
            .catch(error => {
                // Si ocurre un error, lo muestra en la consola
                console.error('Error al cargar las opciones:', error);
            });
    }, [apiEndpoint, requestBody]); // Dependencias: se ejecuta si cambian apiEndpoint o requestBody

    // El retorno del componente renderiza un select con las opciones obtenidas de la API
    return (
        <div className="input_dashboard">
            <label>{label}</label>  {/* Etiqueta del select */}
            <section className='input_dashboard_content'>
                <IconSVG name={icon}/> {/* Muestra el icono pasado como prop */}
                <select 
                    ref={ref}  // Asigna el ref aquí para referencia directa al DOM
                    name={name}  // Nombre del select, utilizado en los formularios
                    className="required"  // Clase CSS para aplicar estilos
                    data-required={`Debe seleccionar un ${label}`}  // Mensaje de error si no se selecciona una opción
                    onChange={onSelectChange}  // Función para manejar el cambio en el select
                    value={value}  // Valor seleccionado actualmente
                >
                    <option value="">{defaultOption}</option>  {/* Opción por defecto */}
                    {options && options.length > 0 ? (
                        options.map(option => (
                            // Mapea las opciones de la API para crear los elementos <option>
                            <option key={option.key} value={option.key}>
                                {option.value}
                            </option>
                        ))
                    ) : (
                        // Mensaje cuando no hay opciones
                        <option value="">Sin opciones disponibles</option>
                    )}
                </select>
            </section>
        </div>
    );
});

// Exporta el componente SelectDashboard
export default SelectDashboard;
