import React, { useState } from 'react';
import IconSVG from '../../../contents/icons/icon';
import InputDashboard from '../../../components/inputDashboard';
import axios from 'axios';

import UpdatemultimediaProyect from './../updateOrdenMultimedia';
import AddMultimedia from './../addMultimedia';
import AddEnlacesExternos from './../addEnlacesExternos';
import AddKeywords from './../addKeywords';
import UpdatePortada from './../updatePortada';
import UpdateIcon from './../updateIcon';

import { validateFields } from "../utils/validateFields";
import { validationRules } from "../utils/validationRules";
import { toast } from 'react-toastify';
import Modal from '../../../components/modalDashboard';
import { update } from 'lodash';

const Step3 = ({
    formData, handleDragOver, handleDragLeave, handleDrop,
    dragActive, handleFileChange, handleRemoveFile,
    handleKeyPressEnlaces, handleRemoveEnlaces, handleInputChangeEnlaces, inputValueEnlaces,
    onBack, onSubmit, resetFormData, id, action
}) => {
    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const validateStep3 = () => {
    const isValid = validateFields({
        formData,
        rules: validationRules.step3,
        setErrors,
        toast
    });

    if (isValid) {
        onSubmit();
    }
};
const getFileExtension = (filename) => {
    if (!filename) {
        console.warn("El nombre del archivo está vacío o es undefined.");
        return ""; // Retorna una cadena vacía si no hay un nombre válido
    }
    return filename.split('.').pop().toLowerCase();
};
const handleChangeeditar = (newState) => {
    toast.success("Se editaron los datos con éxito");
    setIsModalOpen(false);
};

const closeModalSub = () => {
    setIsModalOpen(false);
    setModalContent(null);
};

const closeModalSuspend = () => {
    setIsModalOpen(false);
    setModalContent(null);
    toast.warning('Estamos subiendo los datos, en breve veras el proyecto.');
  };

const handleCreateClick = (FormComponent) => {
    setIsModalOpen(true);
    setModalContent(<FormComponent project={id} onsumited={closeModalSuspend} refress={resetFormData}/>);
};

const handleEditClickMultimedia = (FormComponent, id, multimedia) => {
    setIsModalOpen(true);
    setModalContent(<FormComponent 
        onsumited={handleChangeeditar}
        project={id}
        multimedia={multimedia}
        refress={resetFormData}
    />);
};

const handleDeleteMultimedia = (itemId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este registro?')) {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "graphics",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "DELETE",
            "id": itemId
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                toast.success("Se eliminaron los datos con éxito");
                resetFormData();
            })
            .catch(error => {
                console.error('Error deleting data:', error);
            });
    }
};
    return (
        <div className="modal_user_content">
            <div className="modal_form">
                {action === "update" ? (
                    <>
                        <h5
                            className='col m-0 text-primary text-start btn btn-outline-primary'
                            onClick={() => handleCreateClick(AddMultimedia)}
                            >
                                Añade Multimedia
                        </h5>
                        <h5
                            className='col m-0 text-primary text-start btn btn-outline-primary'
                            onClick={() => handleCreateClick(AddEnlacesExternos)}
                            >
                                Enlaces Externos
                        </h5>

                    
                    </>
                ) : (
                    <>
                        <div
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            className={`drag-area col-12 ${dragActive ? 'active' : ''}`}
                            style={{
                                border: '1px dashed rgb(204, 204, 204)',
                                borderRadius: '.5rem',
                                padding: '10px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                backgroundColor: dragActive ? '#e3f2fd' : '#fff',
                                marginBottom: '.5rem',
                                position: 'relative',
                            }}
                            onClick={() => document.getElementById('archivos').click()}
                        >
                            {dragActive ? (
                                <p>Suelta los archivos aquí...</p>
                            ) : (
                                <>
                                    <IconSVG name="Icon_multimedia_talentic" width="50px" />
                                    <p
                                        className="text-primary"
                                        style={{ marginBottom: '0', marginLeft: '1rem' }}
                                    >
                                        Arrastra y suelta los archivos aquí o haz clic para seleccionar
                                    </p>
                                </>
                            )}
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="archivos"
                            />
                        </div>
                        <InputDashboard
                            type="text"
                            value={inputValueEnlaces}
                            className='form-control required'
                            onChange={handleInputChangeEnlaces}
                            onKeyDown={handleKeyPressEnlaces}
                            placeholder="enlaces"
                            label="Enlace externos"
                        />
                    </>
                )}

                <div className="layout_list_dashboard">
                    <table>
                        <thead>
                            <tr>
                                <th><h4>Portada</h4></th>
                                <th><h4>Multimedia</h4></th>
                                <th><h4>Tipo</h4></th>
                                <th><h4>Acciones</h4></th>
                            </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(formData.files) ? (
                            formData.files.map((multimedia) => {
                                const fileExtension = getFileExtension(multimedia.imgUrl);

                                return (
                                    <tr key={multimedia.id}>
                                        <td>
                                            <button className="btn btn-outline-primary">
                                                Agregar Portada
                                            </button>
                                        </td>
                                        <td className="d-flex flex-column align-items-center">
                                            {(multimedia.tipo === 'Imagen' || multimedia.tipo === 'Icono') ? (
                                                <img
                                                    src={`http://217.15.168.117:8080/api/Whatever/${multimedia.imgUrl}`}
                                                    style={{ width: 'fit-content', height: '70px', borderRadius: '0.7rem' }}
                                                    alt="multimedia"
                                                />
                                            ) : multimedia.tipo === 'Video' ? (
                                                <video controls style={{ width: '100%', height: '130px', borderRadius: '0.7rem' }}>
                                                    <source
                                                        src={`http://217.15.168.117:8080/api/Whatever/${multimedia.imgUrl}`}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            ) : multimedia.tipo === 'Documentos' && fileExtension === 'pdf' ? (
                                                <div style={{ position: 'relative', width: '100%', height: '130px' }}>
                                                    <object
                                                        data={`http://217.15.168.117:8080/api/Whatever/${multimedia.imgUrl}`}
                                                        type="application/pdf"
                                                        style={{ borderRadius: '0.7rem', width: '100%', height: '130px' }}
                                                    >
                                                        <p>
                                                            Tu navegador no soporta la visualización del PDF.{' '}
                                                            <a href={`http://217.15.168.117:8080/api/Whatever/${multimedia.imgUrl}`}>
                                                                Descárgalo aquí
                                                            </a>
                                                        </p>
                                                    </object>
                                                </div>
                                            ) : multimedia.tipo === 'Documentos' && fileExtension === 'docx' ? (
                                                <div style={{ position: 'relative', width: '100%', height: '130px' }}>
                                                    <object
                                                        data={`https://docs.google.com/viewer?url=http://217.15.168.117:8080/api/Whatever/${multimedia.imgUrl}&embedded=true`}
                                                        style={{ borderRadius: '0.7rem', width: '100%', height: '130px' }}
                                                        rel="noopener noreferrer"
                                                    >
                                                        Ver Documento
                                                    </object>
                                                </div>
                                            ) : (
                                                <p>{multimedia.imgUrl}</p>
                                            )}
                                        </td>
                                        <td>{multimedia.tipo}</td>
                                        <td>
                                            <div className="d-flex">
                                                <div 
                                                    className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                                    style={{ width: '54px' }}
                                                    onClick={(e) => {
                                                        handleEditClickMultimedia(UpdatemultimediaProyect, id, multimedia.id);
                                                    }}
                                                >
                                                    <IconSVG name="Icon_editar_talentic" width="20px" />
                                                    <small>EDITAR</small>
                                                </div>
                                                <div 
                                                    className="btn btn-primary d-flex flex-column align-items-center justify-content-between m-1"
                                                    style={{ width: '54px' }}
                                                    onClick={(e) => {
                                                        handleDeleteMultimedia(multimedia.id);
                                                    }}
                                                >
                                                    <IconSVG name="Icon_eliminar_talentic" width="20px" />
                                                    <small>ELIMINAR</small>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="4">No hay archivos multimedia disponibles.</td>
                            </tr>
                        )}
                            {Array.isArray(formData.enlaces) && formData.enlaces.length > 0 ? (
                                formData.enlaces.map((tag, index) => (
                                    <tr key={index}>
                                        <td>
                                            <button className="btn btn-outline-primary">
                                                Añade portada
                                            </button>
                                        </td>
                                        <td>
                                            {tag}
                                        </td>
                                        <td>Enlace externo</td>
                                        <td>
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => handleRemoveEnlaces(index)}
                                            >
                                                Eliminar
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No hay enlaces disponibles.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="mt-3 col-12 d-flex justify-content-between">
                    <button className="btn btn-outline-primary" onClick={onBack}>
                                Anterior
                        </button>
                    {action === "update" ? (
                        <button className="btn btn-outline-primary" onClick={update}>
                            Actualizar todo
                        </button>
                    ) : (
                        <button className="btn btn-outline-primary" onClick={validateStep3}>
                            Enviar
                        </button>
                    )}

                </div>
                
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModalSub} widthValue='480px'>
                {modalContent}
            </Modal>
        </div>
    );
};

export default Step3;
