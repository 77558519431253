import React from 'react';

// Componente CheckBox que renderiza un elemento de tipo checkbox con un icono y descripción
const CheckBox = ({ icon, name, value, userName, description, checked, handleCheckboxChange, colClassName = ""}) => {
    return (
        // Contenedor principal que incluye una clase dinámica para la disposición de columnas y atributos de usuario
        <div className={`buscarDos usuario__encontrado ${colClassName}`} data-user={`${userName} ${description}`}>
            {/* Etiqueta para el checkbox que incluye el icono y el texto de usuario */}
            <label htmlFor={`${value}${description}`}>
                {/* Icono de usuario que se pasa como propiedad (se espera que el icono sea de un paquete como Boxicons) */}
                <i className={`bx ${icon} display-5 me-3`}></i>
                <div>
                    {/* Nombre del usuario */}
                    <h6 className="mb-0">{userName}</h6>
                    {/* Descripción del usuario */}
                    <small className="text-muted">{description}</small>
                </div>
            </label>
            {/* Contenedor para el checkbox como un interruptor (switch) */}
            <div className="form-check form-switch">
                <input
                    className="form-check-input float-end" // Estilo para alinear el switch a la derecha
                    type="checkbox" // Tipo de input checkbox
                    name={name} // Nombre del checkbox, usado para identificación del input
                    id={`${value}${description}`} // ID dinámico, único para cada checkbox
                    value={value} // Valor del checkbox
                    role="switch" // Indicando el tipo de control como interruptor
                    defaultChecked={checked} // Estado inicial del checkbox basado en la propiedad 'checked'
                    onChange={(e) => handleCheckboxChange(e)} // Función que se ejecuta cuando el checkbox cambia de estado
                />
            </div>
      </div>
    );
};

export default CheckBox;
