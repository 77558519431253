import React, { forwardRef } from 'react';

// Se utiliza forwardRef para pasar una referencia (ref) al componente hijo (textarea).
const Textarea = forwardRef(({
    name = "description",   // Establece el valor por defecto para el atributo name si no se pasa.
    value,                  // Recibe el valor del textarea, probablemente para controlarlo desde un componente padre.
    placeholder = "Escribe aquí...", // Valor por defecto para el placeholder si no se pasa uno.
    label = "Descripción",  // Etiqueta que se muestra junto al campo de texto.
    colClassName = "",      // Permite personalizar la clase CSS para el contenedor.
    onChange                // Función que se ejecuta cuando el valor del textarea cambia.
}, ref) => {
    return (
        <div className={`inputContent col-12 ${colClassName}`}> {/* Contenedor con clases dinámicas, incluye colClassName si es pasado */}
            <div className="form-content-inputs"> {/* Contenedor para los elementos del formulario */}
                <label className="form-label">{label}</label> {/* Etiqueta con el nombre del campo */}
                <textarea
                    ref={ref}                // Se pasa la referencia (ref) al textarea.
                    className="form-control required" // Estilos del textarea, 'required' podría indicar que es obligatorio.
                    name={name}              // El nombre del campo que se usará en el formulario.
                    value={value}            // Controla el valor del textarea desde el componente padre.
                    placeholder={placeholder} // Muestra un texto de ejemplo cuando el campo está vacío.
                    onChange={onChange}      // Establece la función a ejecutar cuando el valor cambie.
                />
            </div>
        </div>
    );
});

export default Textarea; // Exporta el componente para poder utilizarlo en otros lugares.
