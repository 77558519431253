import React from 'react';  // Importa la librería React para crear componentes
import './../css/modal.css';  // Importa los estilos CSS para el modal

// Componente Modal
function Modal({ isOpen, onClose, children, widthValue = '100%' }) {
  // Si el modal no está abierto, no se renderiza nada
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}> {/* Crea la capa de superposición del modal, al hacer clic cierra el modal */}
      <div 
        className="modal-content card" 
        onClick={e => e.stopPropagation()}  // Previene el cierre del modal si se hace clic en el contenido del modal
        style={{ maxWidth: widthValue, marginLeft: '15px', marginRight: '15px'}} // Establece el ancho del modal y margenes laterales
      >
        <button className="modal-close" onClick={onClose}>×</button> {/* Botón para cerrar el modal */}
        {children} {/* Renderiza el contenido pasado como hijo del componente Modal */}
      </div>
    </div>
  );
}

export default Modal;  // Exporta el componente Modal para su uso en otras partes de la aplicación
