import React, { useState, useEffect, useRef } from 'react';  // Importa las librerías necesarias de React y Hooks.
import { Link } from 'react-router-dom';  // Importa Link para la navegación entre rutas dentro de la aplicación.
import axios from 'axios';  // Importa Axios para hacer solicitudes HTTP.

import UserMenu from '../components/userMenu';  // Importa el componente UserMenu para mostrar el menú del usuario.
import Menssages from '../components/menssages';  // Importa el componente Menssages para mostrar los mensajes.
import { useAuth } from '../components/AuthContext';  // Importa el hook useAuth para manejar la autenticación del usuario.
import Notifications from '../components/notifications';  // Importa el componente Notifications para mostrar las notificaciones.

import './../css/styles.css';  // Importa los estilos CSS generales de la aplicación.
import './../css/homesas.css';  // Importa un archivo de estilos específico para la página de inicio o sección "homesas".
import './../css/app-c15aace3.css';  // Importa un archivo de estilos CSS adicional para la aplicación.

import Modal from './modal';  // Importa el componente Modal para mostrar modales emergentes.
import IconSVG from '../contents/icons/icon';  // Importa el componente IconSVG para mostrar iconos SVG personalizados.
import Navigate from '../contents/navigate/navigate';  // Importa el componente Navigate para la navegación personalizada dentro de la app.
import SeacrhProyects from '../contents/searchProyects/serachbox';  // Importa el componente SeacrhProyects para realizar búsquedas de proyectos.

import { isEmpty } from 'lodash';  // Importa la función isEmpty de lodash para verificar si un objeto o array está vacío.
import FormA  from './../forms/FormA';  // Importa el formulario FormA para ser usado en la página.
import Suscribirse  from './../forms/suscribirse';  // Importa el formulario Suscribirse para que los usuarios puedan suscribirse.

import Google  from './../forms/googleLogin';  // Importa el formulario Google

function Menu( ) {
    const [inputValue, setInputValue] = useState('');  // Estado para almacenar el valor del input de búsqueda o similar.
    const [isDivClicked, setIsDivClicked] = useState(false);  // Estado para verificar si un div ha sido clickeado.

    const divRef = useRef(null);  // Crea una referencia a un div específico para manipularlo directamente.
    const { logout } = useAuth();  // Desestructura la función logout del contexto de autenticación para poder usarla.
    const { isAuthenticated } = useAuth();  // Desestructura el valor de isAuthenticated del contexto de autenticación para saber si el usuario está autenticado.
        
    const [top3proyetc, settop3proyetc] = useState([]);  // Estado para almacenar los 3 proyectos más relevantes (top 3).
    const [busquedasRecientes, setbusquedasRecientes] = useState([]);  // Estado para almacenar las búsquedas recientes realizadas.
    const [top3proyetckeyworks, settop3proyetckeyworks] = useState([]);  // Estado para almacenar las 3 palabras clave más importantes de proyectos.

    const [menuActive, setMenuActive] = useState(false);  // Estado para controlar la visibilidad del menú principal (activo o no).
    const [filteredData, setFilteredData] = useState([]);  // Estado para almacenar los datos filtrados (por ejemplo, resultados de búsqueda).
    const [isModalOpen, setIsModalOpen] = useState(false);  // Estado para controlar si un modal está abierto o cerrado.
    const [modalContent, setModalContent] = useState(null);  // Estado para almacenar el contenido que se mostrará en el modal.

    const [submenuActive, setSubmenuActive] = useState(false);  // Estado para controlar si un submenú está activo o no.
        
    const [isActive, setIsActive] = useState(false);  // Estado general para verificar si algo (como un botón) está activo o no.
    const [activeMenu, setActiveMenu] = useState(null);  // Estado para almacenar el menú actualmente activo (si hay alguno).

    const socialLinks = [  // Array que contiene los enlaces de redes sociales con su respectivo icono (en formato HTML) y URL.
        { icon: '&#xf384;', url: 'https://facebook.com' },
        { icon: '&#xf184;', url: 'https://twitter.com' },
        { icon: '&#xe01a;', url: 'https://linkedin.com' }
    ];

    useEffect(() => {
        // Añade un event listener para detectar clics fuera de un div específico.
        document.addEventListener('mousedown', handleClickOutside);
    
        // Verifica si los proyectos principales no están vacíos, si están vacíos, realiza una solicitud a la API para obtenerlos.
        if (isEmpty(top3proyetc)) {
            axios.post('http://217.15.168.117:8080/api/', {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "TYPE": "proyectsTop3",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            })
            .then(response => {
                if (Array.isArray(response.data)) {
                    settop3proyetc(response.data);  // Actualiza el estado con los proyectos obtenidos de la API.
                }
            })
            .catch(error => {
                // Maneja posibles errores en la solicitud.
            });
        }
    
        // Verifica si las palabras clave principales no están vacías, si están vacías, realiza una solicitud a la API para obtenerlas.
        if (isEmpty(top3proyetckeyworks)) {
            axios.post('http://217.15.168.117:8080/api/', {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "TYPE": "keywordsProyectsTop3",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            })
            .then(response => {
                if (Array.isArray(response.data)) {
                    settop3proyetckeyworks(response.data);  // Actualiza el estado con las palabras clave obtenidas de la API.
                }
            })
            .catch(error => {
                // Maneja posibles errores en la solicitud.
            });
        }
    
        // Verifica si las búsquedas recientes están vacías, si están vacías, obtiene los datos desde el localStorage.
        if (isEmpty(busquedasRecientes)) {
            const storedData = localStorage.getItem('recientes');
            let dataArray = storedData ? JSON.parse(storedData) : [];  // Si hay datos en localStorage, los parsea, si no, usa un array vacío.
            setbusquedasRecientes(dataArray);  // Actualiza el estado con las búsquedas recientes obtenidas de localStorage.
        }
    
        // Limpia el event listener cuando el componente se desmonta.
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);  // Solo se ejecuta al montar el componente.
    
    const handleClickOutside = (event) => {
        // Verifica si el clic ocurrió fuera del div referenciado y actualiza el estado en consecuencia.
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsDivClicked(false);  // Cambia el estado si el clic fue fuera del div.
            setInputValue('');  // Limpia el valor del input.
        }
    };

    const reset = () => {
        setIsDivClicked(false);  // Cambia el estado si el clic fue fuera del div.
        setInputValue('');  // Limpia el valor del input.
    };
    
    const handleDivClick = () => {
        setIsDivClicked(true);  // Cambia el estado cuando se hace clic en el div.
    };
    
    const handleDataFiltered = (data) => {
        setFilteredData(data);  // Actualiza el estado con los datos filtrados.
    };
    
    const handleDelete = (id) => {
        // Elimina una palabra clave de la lista de "top3proyetckeyworks" utilizando su id.
        const updatedKeywords = top3proyetckeyworks.filter(item => item.id !== id);
        settop3proyetckeyworks(updatedKeywords);
    };
    
    const handleDeleteR = (id) => {
        // Elimina una búsqueda reciente de la lista utilizando su id.
        const updatedKeywords = busquedasRecientes.filter(item => item.id !== id);
        setbusquedasRecientes(updatedKeywords);
    };
    
    const openModalWithForm = (FormComponent) => {
        setModalContent(<FormComponent />);  // Establece el contenido del modal con el formulario pasado como argumento.
        setIsModalOpen(true);  // Abre el modal.
    };
    
    const handleClickminisearchbtn = () => {
        setIsActive(!isActive);  // Cambia el estado de activación del botón de búsqueda.
    };
    
    const closeModal = () => {
        setIsModalOpen(false);  // Cierra el modal.
        setModalContent(null);  // Limpia el contenido del modal.
    };
    
    const toggleMenu = () => {
        setMenuActive(!menuActive);  // Alterna la visibilidad del menú principal.
    };
    
    const addTextToLocalStorage = () => {
        const storedData = localStorage.getItem('recientes');
        let dataArray = storedData ? JSON.parse(storedData) : [];  // Obtiene las búsquedas recientes del localStorage o usa un array vacío.
        
        const newItem = {
            id: Date.now(),  // Genera un id único utilizando la fecha actual.
            keyword: inputValue  // Almacena el valor del input como una palabra clave.
        };
    
        dataArray.push(newItem);  // Añade el nuevo item a la lista de búsquedas recientes.
    
        setbusquedasRecientes(dataArray);  // Actualiza el estado de las búsquedas recientes.
        localStorage.setItem('recientes', JSON.stringify(dataArray));  // Guarda la lista actualizada en el localStorage.
    };
    
    const handleClick = () => {
        const htmlElement = document.querySelector('html');
        htmlElement.classList.remove('layout-menu-expanded');  // Elimina la clase que indica que el menú está expandido.
        setMenuActive(false);  // Cierra el menú.
    };
    
    const toggleUserMenu = (e) => {
        e.stopPropagation();  // Detiene la propagación del evento para evitar que se cierre el menú al hacer clic en él.
        setSubmenuActive(!submenuActive);  // Alterna la visibilidad del submenú del usuario.
    };
    
    const toggleMenuMobil = (menuName) => {
        setActiveMenu(activeMenu === menuName ? null : menuName);  // Alterna el estado del menú móvil, mostrando el menú especificado o cerrándolo si ya está abierto.
    };    

    return (
        <>
            <nav className="menu__top">
                <div className="menu__all">
                    <div 
                        className={`menu__icon_top ${menuActive ? 'active_menu_icon' : 'desactive_menu_icon'}`} 
                        onClick={toggleMenu}
                    >
                        <IconSVG name="Icon_menu_talentic" width="35px" style={{ margin: '0px', cursor: 'pointer'}} />
                    </div>

                    <section className="menu__first_part">
                        <Link to="/">
                            <div className="menu__img c_gif">
                                <img
                                    src={`http://217.15.168.117:8080/api/Whatever/logo.png`}
                                    alt="Logo Talentic"
                                />
                            </div>
                        </Link>
                        <Navigate/>
                    </section>

                    <div className="user_login">
                        <ul className={`menu_items desactive_menu_items d-flex flex-wrap`}>

                            <div className={`search_content ${isActive ? 'on' : ''}`} ref={divRef} onClick={handleDivClick}>
                                <SeacrhProyects
                                    searchTerm={inputValue}
                                    setSearchTerm={setInputValue}
                                    onDataFiltered={handleDataFiltered}
                                />
                                {(isDivClicked) && (
                                    <div className="searchProyectsList">
                                        {(filteredData.length > 0) ? (
                                            <div 
                                                className={`buscarcuatro usuario__encontrado`}
                                            >
                                                <IconSVG name="Icon_buscador_talentic" width="15px" style={{ marginLeft: '10px'}} />
                                                <strong style={{ width: 'fit-content', marginLeft: '10px' }}>{inputValue}</strong>
                                            </div>
                                        ) : (
                                            <>  
                                                {busquedasRecientes.length > 0 ? (
                                                    <>
                                                        <div style={{ heigth: '25px' }} className='col-12 '>
                                                            <h5 style={{ width: 'fit-content', margin: '0px' }} >Búsquedas recientes</h5>
                                                        </div>
                                                        {busquedasRecientes.map(item => (
                                                            <div key={item.id} className='badge bg-label-gray m-1 d-flex cursor-pointer'>
                                                                <p
                                                                    style={{ margin: '0px' }}
                                                                    className='align-content-center'
                                                                    onClick={() => setInputValue(item.keyword)} // Asumo que `item.value` es la palabra clave
                                                                >
                                                                    {item.keyword}
                                                                </p>
                                                                <IconSVG
                                                                    name="Icon_borrador_talentic"
                                                                    className='align-content-center'
                                                                    width="11px"
                                                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                                                    onClick={() => handleDeleteR(item.id)} // Aquí eliminas el ítem
                                                                />
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <div style={{ heigth: '25px' }} className='col-12 '>
                                                            <h5 style={{ width: 'fit-content', margin: '0px' }} >Búsquedas recomendadas</h5>
                                                        </div>
                                                        {top3proyetckeyworks.map(item => (
                                                            <div key={item.id} className='badge bg-label-gray m-1 d-flex cursor-pointer'>
                                                                <p
                                                                    style={{ margin: '0px' }}
                                                                    className='align-content-center'
                                                                    onClick={() => setInputValue(item.keyword)} // Aquí asumo que `item.keyword` es el valor del texto
                                                                >
                                                                    {item.keyword}
                                                                </p>
                                                                <IconSVG
                                                                    name="Icon_borrador_talentic"
                                                                    className='align-content-center'
                                                                    width="11px"
                                                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                                                    onClick={() => handleDelete(item.id)} // También puedes eliminar este ítem si es necesario
                                                                />
                                                            </div>
                                                        ))}
                                                    </>
                                                )}

                                                <div style={{ heigth: '25px' }} className='col-12 '>
                                                    <h5 style={{ width: 'fit-content', margin: '0px' }} >Proyectos recomendados</h5>
                                                </div>
                                                <div 
                                                    className={`buscartres usuario__encontrado`}
                                                >
                                                    {top3proyetc.map(item => {
                                                        
                                                        const highlightText = (name, searchTerm) => {
                                                            if (!searchTerm) return name;
                                                            const regex = new RegExp(`(${searchTerm})`, 'gi');
                                                            const parts = name.split(regex);
                                                            return parts.map((part, index) =>
                                                                part.toLowerCase() === searchTerm.toLowerCase() ? (
                                                                    <strong key={index}>{part}</strong>
                                                                ) : (
                                                                    part
                                                                )
                                                            );
                                                        };

                                                        return (
                                                            <Link
                                                                to={`/detailsProyects/${item.id}`}
                                                                style={{ padding: '0px' }}
                                                                key={item.id}
                                                                onClick={(event) => {
                                                                    event.preventDefault(); // Detener la navegación momentáneamente
                                                                    reset(); // Ejecutar la función
                                                                    setTimeout(() => {
                                                                      window.location.href = `/detailsProyects/${item.id}`; // Realizar la navegación manualmente
                                                                    }, 100); // Opcional: Ajustar el tiempo si es necesario
                                                                }}
                                                            >
                                                                <div className="d-flex align-items-center my-1">
                                                                    <div className="ms-1">
                                                                        <img
                                                                            src={`http://217.15.168.117:8080/api/Whatever/${item.image}`}
                                                                            className="m-0 searchproyects display-5"
                                                                            alt={item.name}
                                                                        />
                                                                    </div>
                                                                    <div className="ms-2">
                                                                        <h4 className="mb-0 text-start">
                                                                            {/* Usa highlightText para resaltar el término de búsqueda */}
                                                                            {highlightText(item.name, inputValue)}
                                                                        </h4>
                                                                        <small className="text-muted"></small>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        )}

                                        <div 
                                            className={`buscartres usuario__encontrado`}
                                        >
                                            {filteredData.map(item => {
                                                
                                                const highlightText = (name, searchTerm) => {
                                                    if (!searchTerm) return name;
                                                    const regex = new RegExp(`(${searchTerm})`, 'gi');
                                                    const parts = name.split(regex);
                                                    return parts.map((part, index) =>
                                                        part.toLowerCase() === searchTerm.toLowerCase() ? (
                                                            <strong key={index}>{part}</strong>
                                                        ) : (
                                                            part
                                                        )
                                                    );
                                                };

                                                return (
                                                    <Link
                                                        to={`/detailsProyects/${item.id}`}
                                                        style={{ padding: '0px' }}
                                                        key={item.id}
                                                        onClick={(event) => {
                                                            event.preventDefault(); // Detener la navegación momentáneamente
                                                            addTextToLocalStorage();
                                                            reset(); // Ejecutar la función
                                                            setTimeout(() => {
                                                              window.location.href = `/detailsProyects/${item.id}`; // Realizar la navegación manualmente
                                                            }, 100); // Opcional: Ajustar el tiempo si es necesario
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center my-1">
                                                            <div className="ms-1">
                                                                <img
                                                                    src={`http://217.15.168.117:8080/api/Whatever/${item.mainImg}`}
                                                                    className="m-0 searchproyects display-5"
                                                                    alt={item.name}
                                                                />
                                                            </div>
                                                            <div className="ms-2">
                                                                <h4 className="mb-0 text-start">
                                                                    {/* Usa highlightText para resaltar el término de búsqueda */}
                                                                    {highlightText(item.name, inputValue)}
                                                                </h4>
                                                                <small className="text-muted"></small>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="mobile_btn_search_mini" onClick={handleClickminisearchbtn}>
                                <li className="menu_item p-1 m-1">
                                    <IconSVG 
                                        name="Icon_buscador_talentic"
                                        width="20px"
                                        style={{ margin: '0px', cursor: 'pointer'}}
                                    />
                                </li>
                            </div>
                            

                            {isAuthenticated ? (
                                <>
                                    <Notifications/>

                                    <Menssages/>

                                    <UserMenu/>
                                </>
                            ) : (
                                <div className='mobile_menu_desactivated'>
                                    <li 
                                        className="menu_item p-1 m-1" 
                                        onClick={() => openModalWithForm(FormA)}
                                    >
                                        Iniciar sesión
                                    </li>
                                    <img 
                                        onClick={() => openModalWithForm(Google)}
                                        src={require(`../img/nav/google.png`)}  // Carga la imagen desde el path
                                        className=""  // Aplica la clase para el estilo de la imagen
                                        style={{ backgroundColor: 'rgb(255, 255, 255)', width: '20px' }}  // Estilo en línea para el fondo de la imagen
                                    />
                                    <li
                                        className="menu_item p-1 m-1 btn btn-outline-secondary"
                                        onClick={() => openModalWithForm(Suscribirse)}
                                    >
                                        Registrarse
                                    </li>
                                </div>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>

            <Modal isOpen={isModalOpen} onClose={closeModal} widthValue='325px'>
                {modalContent}
            </Modal>

            {(isDivClicked) && (
                <div className="modal-overlay-solo"></div>
            )}

            {(menuActive) && (
                <>
                    <aside id="layout-menu" className={`layout-menu menu-vertical menu bg-menu-theme ${menuActive ? 'active_talentic_menu layout-menu-expanded' : ''}`} style={{ height: '0vh' }}>
                        <div className="app-brand demo">
                            <Link className="app-brand-link" to="/dashboard">
                                <IconSVG name="Logo_Talentic_1" width="80px" style={{ margin: '0px', cursor: 'pointer'}} />
                            </Link>
                            
                            <a className="layout-menu-toggle menu-link text-large ms-autod-block d-xl-none" onClick={handleClick}>
                                <i className="bx bx-x bx-md align-middle"></i>
                            </a>
                        </div>
                        <hr style={{ width: '75%', alignSelf: 'center'}} />

                        <div className="menu-inner-shadow"></div>

                        <ul className="menu-inner py-1" style={{ listStyleType: 'none', paddingLeft: 0 }}>
                            <div style={{ width: '100%'}}>
                                <li className="menu-item">
                                    <Link className="menu-link" to="/">
                                        <IconSVG name="Icon_inicio_talentic" width="15px" style={{ marginRight: '10px', cursor: 'pointer'}} />
                                        <div>Inicio</div>
                                    </Link>
                                </li>

                                {/* PROYECTOS */}
                                <li className="menu-item" onClick={() => toggleMenuMobil('proyectos')}>
                                    <div className="menu-link">
                                        <IconSVG name="Icon_proyectos_talentic_1" width="15px" style={{ marginRight: '10px', cursor: 'pointer'}} />
                                        <span className="menu-header-text">Proyectos</span>
                                    </div>
                                    
                                    <IconSVG name="Icon_flecha_abajo_talentic" width="15px" style={{ marginRight: '40px', cursor: 'pointer'}} />
                                </li>
                                {activeMenu === 'proyectos' && (
                                    <div style={{ marginLeft: '20px', width: '90%'}}>
                                        <hr style={{ marginLeft: '19px', width: '85%'}}/>
                                        <li className="menu-item">
                                            <Link to="/dashboard/metrics" className="menu-link">
                                                <IconSVG name="Icon_ADSI_talentic" width="15px" style={{ marginRight: '10px'}} />
                                                <div>Todos</div>
                                            </Link>
                                        </li>
                                        <li className="menu-item">
                                            <Link to="/dashboard/metrics" className="menu-link">
                                                <IconSVG name="Icon_Animacion_3D_talentic" width="15px" style={{ marginRight: '10px'}} />
                                                <div>Animacion 3d</div>
                                            </Link>
                                        </li>
                                        <li className="menu-item">
                                            <Link to="/dashboard/metrics" className="menu-link">
                                                <IconSVG name="Icon_Produccion_multimedia_talentic" width="15px" style={{ marginRight: '10px'}} />
                                                <div>Producción multimedia</div>
                                            </Link>
                                        </li>
                                        <li className="menu-item">
                                            <Link to="/dashboard/metrics" className="menu-link">
                                                <IconSVG name="Icon_Produccion_audiovisual_talentic" width="15px" style={{ marginRight: '10px'}} />
                                                <div>Otro</div>
                                            </Link>
                                        </li>
                                        <hr style={{ marginLeft: '19px', width: '85%'}}/>
                                    </div>
                                )}

                                <Navigate/>

                            </div>

                            <div className='btn_menu_mobil_active'>
                                <li 
                                    className="menu_item p-1 m-1" 
                                    onClick={() => openModalWithForm(FormA)}
                                    style={{ width: '75%', textAlign: 'center' }}
                                >
                                    Iniciar sesión
                                </li>
                                <li
                                    className="menu_item p-1 m-1 btn btn-outline-secondary"
                                    onClick={() => openModalWithForm(Suscribirse)}
                                    style={{ width: '75%', textAlign: 'center' }}
                                >
                                    Registrarse
                                </li>
                            </div>
                        </ul>
                    </aside>

                    <div className="modal-overlay-menu"></div>
                </>
            )}
        </>
    );
}

export default Menu;