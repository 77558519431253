import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import CheckBox from '../components/checkbox';

const Allocationusersproject = ( ) => {
    const { state: { id, name, type, slug, intro, description } = {} } = useLocation();

    const [searchTermAprendiz, setsearchTermAprendiz] = useState('');
    const [searchTermInstructor, setsearchTermInstructor] = useState('');
    const [apiDataAprendiz, setapiDataAprendiz] = useState([]);
    const [apiDataInstructor, setapiDataInstructor] = useState([]);
    const [formDataAprendices, setformDataAprendices] = useState({ aprendices: [] });
    const [formDataInstructores, setformDataInstructores] = useState({ instructores: [] });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "proyecto": id,
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            };

            const [responseAprendiz, responseInstructor] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectAprendiz" }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "allocationusersprojectInstructor" })
            ]);
            
            const processedDataAperndiz = responseAprendiz.data.map(item => ({
                id: parseInt(item.id, 10),
                name: item.name,
                mail: item.mail,
                asignado: item.asignado,
                state: item.state
            }));
            
            const processedDataInstructor = responseInstructor.data.map(item => ({
                id: parseInt(item.id, 10),
                name: item.name,
                mail: item.mail,
                asignado: item.asignado,
                state: item.state
            }));

            setapiDataAprendiz(processedDataAperndiz);
            setapiDataInstructor(processedDataInstructor);

            const apendicezInit = processedDataAperndiz
                .filter(item => item.asignado === 1)
                .map(item => item.id);
            
            const instructorInit = processedDataInstructor
                .filter(item => item.asignado === 1)
                .map(item => item.id);

            setformDataAprendices({ aprendices: apendicezInit });
            setformDataInstructores({ instructores: instructorInit });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "proyecto": id,
            };

            const [responseAprendiz] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { 
                    ...requestBody,
                    "TYPE": "UPDATE",
                    "RESOURCE": "allocationusersproject",
                    "aprendices": formDataAprendices.aprendices,
                    "instructores": formDataInstructores.instructores
                })
            ]);

            console.log(responseAprendiz)

            fetchData();
        } catch (error) {
            console.error('Error updating permissions:', error);
        }
    };

    const handleCheckboxChangeaprendiz = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setformDataAprendices(prevState => {
            const aprendices = checked
                ? [...prevState.aprendices, id]
                : prevState.aprendices.filter(dataid => dataid !== id);

            return { ...prevState, aprendices };
        });
    };
    const handleCheckboxChangeSpecial = (e) => {
        const { checked, value } = e.target;
        const id = parseInt(value, 10);

        setformDataInstructores(prevState => {
            const instructores = checked
                ? [...prevState.instructores, id]
                : prevState.instructores.filter(dataid => dataid !== id);

            return { ...prevState, instructores };
        });
    };

    const handleSearchChangeAprendiz = (e) => {
        setsearchTermAprendiz(e.target.value.toLowerCase());
    };
    const handleSearchChangeInstructor = (e) => {
        setsearchTermInstructor(e.target.value.toLowerCase());
    };

    const filteredDataAprendiz = apiDataAprendiz.filter(item =>
        (item.name || '').toLowerCase().includes(searchTermAprendiz) ||
        (item.mail || '').toLowerCase().includes(searchTermAprendiz)
    );
    const filteredDataInstructor = apiDataInstructor.filter(item =>
        (item.name || '').toLowerCase().includes(searchTermInstructor) ||
        (item.mail || '').toLowerCase().includes(searchTermInstructor)
    );

    return (
        <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Modulo de administración de usuario a proyectos</h4>

                <div className="row">
                    <div className="col-xl">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Datos básicos del proyecto</h5>
                                <small className="text-muted float-end">Formulario de asociación de usuarios a proyectos</small>
                            </div>

                            <div className="card-body row">
                                <div className="mb-3 col-12 col-lg-6">
                                    <label className="form-label">Nombre del proyecto</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={name}
                                    />
                                </div>
                                <div className="mb-3 col-12 col-lg-6">
                                    <label className="form-label">Tipo de Proyecto</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={type}
                                    />
                                </div>
                                <div className="mb-3 col-12 col-lg-6">
                                    <label className="form-label">Slug</label>
                                    <textarea
                                        className="form-control required"
                                        name="slug"
                                        placeholder="Slug"
                                        style={{ height: '80px' }}
                                        disabled
                                        value={slug}
                                    ></textarea>
                                </div>
                                <div className="mb-3 col-12 col-lg-6">
                                    <label className="form-label">Introducción</label>
                                    <textarea
                                        className="form-control required"
                                        name="intro"
                                        placeholder="Introducción"
                                        style={{ height: '80px' }}
                                        disabled
                                        value={intro}
                                    ></textarea>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Descripción</label>
                                    <textarea
                                        className="form-control required"
                                        name="description"
                                        placeholder="Descripción"
                                        style={{ height: '80px' }}
                                        disabled
                                        value={description}
                                    ></textarea>
                                </div>

                                <div className="mb-3 col-12">
                                    <Link to="/dashboard/projects">
                                        <i className="bx bx-chevron-left"></i> Proyectos
                                    </Link>
                                </div>
                            </div>

                            <div className="card-body">
                                <form id="formModel" className="row" onSubmit={handleSubmit}>
                                    <div className="col-12 p-4 col-lg-6">
                                        <h5>Aprendices</h5>
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                id="searchDos"
                                                className="form-control"
                                                placeholder="Buscar Aprendiz"
                                                aria-label="Buscar Aprendiz"
                                                aria-describedby="button-addon2"
                                                value={searchTermAprendiz}
                                                onChange={handleSearchChangeAprendiz}
                                            />
                                        </div>

                                        <div className="aprendices-list">
                                            {filteredDataAprendiz.length > 0 ? filteredDataAprendiz.map((item) => (
                                                <CheckBox
                                                    key={item.id}
                                                    name="aprendices"
                                                    value={item.id}
                                                    icon="bx-lock-alt"
                                                    userName={item.name}
                                                    description={item.mail}
                                                    checked={item.asignado === 1}
                                                    handleCheckboxChange={handleCheckboxChangeaprendiz}
                                                />
                                            )) : (
                                                <div id="no-results-dos">
                                                    Aprendiz no encontrado
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 p-4 col-lg-6">
                                        <h5>Instructores</h5>
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                id="searchDos"
                                                className="form-control"
                                                placeholder="Buscar Instructor"
                                                aria-label="Buscar Instructor"
                                                aria-describedby="button-addon2"
                                                value={searchTermInstructor}
                                                onChange={handleSearchChangeInstructor}
                                            />
                                        </div>

                                        <div className="aprendices-list">
                                            {filteredDataInstructor.length > 0 ? filteredDataInstructor.map((item) => (
                                                <CheckBox
                                                    key={item.id}
                                                    name="instructores"
                                                    value={item.id}
                                                    icon="bx-lock-alt"
                                                    userName={item.name}
                                                    description={item.mail}
                                                    checked={item.asignado === 1}
                                                    handleCheckboxChange={handleCheckboxChangeSpecial}
                                                />
                                            )) : (
                                                <div id="no-results-dos">
                                                    Instructor no encontrado
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mb-3 col-12">
                                        <Link to="/dashboard/roles" className="btn btn-danger">
                                            Cancelar
                                        </Link>
                                        <button type="submit" className="btn btn-primary">
                                            Actualizar usuarios del proyecto - {name}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Allocationusersproject;
