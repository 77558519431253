import React, { useState, useEffect } from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns'; // Importa funciones de date-fns para formatear fechas
import { Dropdown } from 'react-bootstrap'; // Importa el componente Dropdown de react-bootstrap
import es from 'date-fns/locale/es'; // Importa la configuración regional en español de date-fns
import axios from 'axios'; // Importa axios para hacer peticiones HTTP

import IconSVG from '../contents/icons/icon'; // Importa un componente para mostrar iconos SVG

// Componente para mostrar la fecha relativa de una notificación
const FechaRelativa = ({ fecha }) => {
    const fechaISO = parseISO(fecha); // Convierte la fecha recibida a formato ISO

    return (
        <div>
            {/* Muestra el tiempo relativo desde la fecha hasta el momento actual */}
            {`Hace ${formatDistanceToNow(fechaISO, { addSuffix: true, locale: es })}`}
        </div>
    );
};

const Notifications = () => {
    const [notifications, setNotifications] = useState([]); // Estado para almacenar las notificaciones
    const [formData] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user')); // Obtiene el usuario desde el localStorage
        return {
            user: user?.index_user || '' // Extrae el índice de usuario, o una cadena vacía si no existe
        };
    });

    // Hook useEffect para hacer la petición cuando el componente se monta
    useEffect(() => {
        // Si el usuario está logueado y aún no se han cargado notificaciones
        if (formData.user && notifications.length === 0) {
            axios.post('http://217.15.168.117:8080/api/', {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "TYPE": "userNotifications",
                "user": formData.user,
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            })
            .then(response => {
                // Si la respuesta es un array, actualiza el estado de las notificaciones
                if (Array.isArray(response.data)) {
                    setNotifications(response.data);
                } else {
                    // Si no se recibe un array, no hace nada
                }
            })
            .catch(error => {
                // Manejo de errores en caso de que la petición falle
            });
        }
    }, [formData.user, notifications.length]); // Vuelve a ejecutar el useEffect si cambia el usuario o si las notificaciones están vacías

    return (
        <Dropdown>
            {/* Botón que muestra el ícono de notificaciones */}
            <Dropdown.Toggle className='drop_menu_default'>
                <IconSVG name="Icon_notificaciones_talentic"/>
            </Dropdown.Toggle>

            <Dropdown.Menu 
                align="end" // Alinea el menú al final (a la derecha)
                style={{ 
                    maxWidth: '250px', // Define un tamaño máximo para el menú
                    maxHeight: '250px', // Define un tamaño máximo para el menú
                    overflowY: 'scroll' // Agrega un scroll si el contenido excede el tamaño máximo
                }}
            >
                <Dropdown.ItemText>
                    <div>
                        {/* Título del menú de notificaciones */}
                        <h5 style={{ marginBottom: '20px' }} >Sus notificaciones</h5>
                    </div>
                    <div className={`buscartres usuario__encontrado`} >
                        {/* Si hay notificaciones, muestralas, si no, muestra un mensaje */}
                        {(notifications.length > 0) ? (
                            notifications.map(item => (
                                <div className="d-flex align-items-center my-1 border-bottom pb-1" key={item.id}>
                                    {/* Muestra una imagen asociada con la notificación */}
                                    <div>
                                        <img
                                            src={`http://217.15.168.117:8080/api/Whatever/e5e483c6128e2d812739d384e3c2683a.png`}
                                            className="m-0 notifications display-5"
                                        />
                                    </div>
                                    <div className="ms-2">
                                        {/* Muestra el nombre de la notificación y la fecha relativa */}
                                        <h6 className="mb-0" style={{ fontSize: '12px'}}>
                                            {item.name}
                                        </h6>
                                        <small className="text-muted">
                                            <FechaRelativa fecha={item.date} />
                                        </small>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // Si no hay notificaciones, muestra este mensaje
                            <h5 style={{ marginBottom: '20px' }} >Sin notificaciones</h5>
                        )}
                    </div>
                </Dropdown.ItemText>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default Notifications; // Exporta el componente
