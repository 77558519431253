import React, { useState, useRef } from 'react';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import SelectDashboard from '../../components/selectDashboard';
import BtnSubmitDashboard from '../../components/btnSubmitDashboard';
import InputDashboard from '../../components/inputDashboard';
import TextareaDashboard from '../../components/textareaDashboard';
import OnlyFile from '../../components/onlyFileDashboard';
import ImgUser from '../subirImgUser';

const CreateTypeUsers = ({ onSubmitState }) => {
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        username: '',
        lastName: '',
        email: '',
        sheet: '',
        password: '',
        type: '',
        description: '',
        img: '',
    });

    const [dragActive, setDragActive] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const refs = {
        ImgUser: useRef()
    }
    
    const requestBody = {
        "API": "talentic",
        "MODEL": "talentic",
        "RESOURCE": "select",
        "TYPE": "typeusers",
        "key": "5b8d3b1f084b01c6a8387459e80d4bb9"
    };
    
    const requestBodySheet = {
        "API": "talentic",
        "MODEL": "talentic",
        "RESOURCE": "select",
        "TYPE": "sheets",
        "key": "5b8d3b1f084b01c6a8387459e80d4bb9"
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCreate = async (e) => {
        e.preventDefault();
    
        const formDataToSend = new FormData();
        formDataToSend.append("API", "talentic");
        formDataToSend.append("MODEL", "talentic");
        formDataToSend.append("RESOURCE", "users");
        formDataToSend.append("key", "5b8d3b1f084b01c6a8387459e80d4bb9");
        formDataToSend.append("TYPE", "PUT");
        formDataToSend.append("username", formData.username);
        formDataToSend.append("lastName", formData.lastName);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("sheet", formData.sheet);
        formDataToSend.append("password", CryptoJS.MD5(formData.password).toString());
        formDataToSend.append("description", formData.description);
        formDataToSend.append("type", formData.type);
    
        if (formData.img) {
            formDataToSend.append("img", formData.img);
        }
    
        console.log('Data enviada (formData):');
        for (const [key, value] of formDataToSend.entries()) {
            console.log(`${key}:`, value);
        }
    
        try {
            await axios.post('http://217.15.168.117:8080/api/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            // Resetear datos después de enviar
            setFormData({ 
                username: '',
                lastName: '',
                email: '',
                sheet: '',
                password: '',
                type: '',
                description: '',
                img: ''
            });
            setImageUrl('');
            onSubmitState(true);
        } catch (error) {
            console.error('Error creating data:', error);
            setError('Hubo un error al crear el tipo de usuario. Por favor, inténtelo de nuevo.');
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    
        const file = e.dataTransfer.files[0];
        if (file) {
            setFormData((prevData) => ({
                ...prevData,
                img: file, // Guarda el archivo como parte de formData
            }));
            setImageUrl(URL.createObjectURL(file)); // Actualiza la previsualización
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData((prevData) => ({
                ...prevData,
                img: file, // Guarda el archivo como parte de formData
            }));
            setImageUrl(URL.createObjectURL(file)); // Actualiza la previsualización
        }
    };

    return (
        <div className="modal_user_content">
            <form className='modal_form' method="POST" onSubmit={handleCreate}>
                <OnlyFile
                    ref={refs.ImgUser}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    dragActive={dragActive}
                    imageUrl={imageUrl}
                    handleFileChange={handleFileChange}
                    name='ImgUser'
                />
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="username" 
                        label="Nombre"
                        placeholder="nombre usuario" 
                        value={formData.username}
                        onChange={handleChange}
                        colClassName=""
                    />
                    <InputDashboard 
                        name="lastName" 
                        label="Apellido"
                        placeholder="Apellido" 
                        value={formData.lastName}
                        onChange={handleChange}
                        colClassName=""
                    />
                </div>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="email" 
                        label="Correo"
                        placeholder="correo" 
                        value={formData.email}
                        onChange={handleChange}
                        colClassName=""
                        icon="Icon_correo_talentic_2"
                    />
                </div>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="password" 
                        label="Contraseña"
                        placeholder="Contraseña" 
                        value={formData.password}
                        onChange={handleChange}
                        colClassName=""
                        icon="Icon_permisos_talentic"
                    />
                </div>
                <div className='modal_form_item'>
                    <SelectDashboard
                        label="Ficha de formación"
                        name="sheet"
                        apiEndpoint="http://217.15.168.117:8080/api/"
                        requestBody={requestBodySheet}
                        onSelectChange={handleChange}
                        defaultOption="Selecciona una ficha"
                        className=""
                        value={formData.sheet}
                        icon="Icon_programas_talentic"
                    />
                </div>
                <div className='modal_form_item'>
                    <SelectDashboard
                        label="Rol"
                        name="type"
                        apiEndpoint="http://217.15.168.117:8080/api/"
                        requestBody={requestBody}
                        onSelectChange={handleChange}
                        defaultOption="Selecciona un tipo de usuario"
                        className=""
                        value={formData.type}
                        icon="Icon_roles_talentic"
                    />
                </div>
                    <div className='modal_form_item'>
                        <TextareaDashboard
                            name="description" 
                            label="Descripción"
                            placeholder="nombre usuario o nick" 
                            value={formData.description}
                            onChange={handleChange}
                            colClassName=""
                        />
                    </div>
                <BtnSubmitDashboard text="Guardar"/>
            </form>
        </div>
    );
};

export default CreateTypeUsers;