import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import IconSVG from '../../contents/icons/icon';

// Custom hook para realizar un debouncing del valor de búsqueda
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        // Usamos un timeout para esperar el 'delay' antes de actualizar el valor
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler); // Limpiamos el timeout cuando el valor o el delay cambian
        };
    }, [value, delay]);

    return debouncedValue; // Retorna el valor debounced
};

const SeacrhProyects = ({ onDataFiltered, searchTerm, setSearchTerm }) => {
    const [data, setData] = useState([]); // Almacenamos los datos originales
    const [filteredData, setFilteredData] = useState([]); // Almacenamos los datos filtrados
    const [ulClass, setUlClass] = useState('menu_items desactive_menu_items serach_items_default'); // Clase CSS para el contenedor del listado
    const containerRef = useRef(null); // Referencia al contenedor del input de búsqueda
    const debouncedSearchTerm = useDebounce(searchTerm, 300); // Usamos el hook de debounce para optimizar la búsqueda

    // Se ejecuta cada vez que el término de búsqueda cambia
    useEffect(() => {
        if (debouncedSearchTerm) {
            // Realizamos una petición al API para obtener proyectos que coincidan con el término de búsqueda
            axios.post('http://217.15.168.117:8080/api/', {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "TYPE": "searchProyects",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "search": debouncedSearchTerm
            })
            .then(response => {
                if (Array.isArray(response.data)) {
                    // Si la respuesta es un array, actualizamos el estado de los proyectos filtrados
                    setFilteredData(response.data);
                    onDataFiltered(response.data); // Llamamos la función proporcionada para pasar los datos filtrados
                } else {
                    console.error('El formato de los datos no es un array'); // Manejo de errores si la respuesta no es un array
                }
            })
            .catch(error => {
                console.error('Error al buscar datos:', error); // Manejo de errores en caso de fallo en la petición
            });
        } else {
            // Si no hay término de búsqueda, mostramos todos los datos
            setFilteredData(data);
            onDataFiltered(data); 
        }
    }, [debouncedSearchTerm, data, onDataFiltered]); // Dependencias: cuando cambia el término de búsqueda o los datos

    // Maneja el evento cuando el input recibe foco
    const handleFocus = () => {
        setUlClass('menu_items desactive_menu_items search_items_searchning'); // Cambiamos la clase CSS para mostrar el listado de resultados
    };
    
    // Maneja el evento cuando el input pierde el foco
    const handleBlur = () => {
        setTimeout(() => {
            // Verificamos si el elemento con el foco no está dentro del contenedor
            if (!containerRef.current.contains(document.activeElement)) {
                setUlClass('menu_items desactive_menu_items serach_items_default'); // Restauramos la clase por defecto
            }
        }, 0);
    };

    // Actualiza el estado de búsqueda cuando el valor del input cambia
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase()); // Convertimos el valor a minúsculas para una comparación más sencilla
    };

    // Función para limpiar el campo de búsqueda
    const clearInput = () => {
        setSearchTerm('');  // Limpiar el input al hacer clic en el icono de borrar
    };

    return (
        <div className="searchInputContent" ref={containerRef}> {/* Contenedor del input de búsqueda */}
            <IconSVG name="Icon_buscador_talentic" width="20px" style={{ marginLeft: '10px'}} /> {/* Ícono de búsqueda */}
            <input
                id="searchProyets" // ID del input para realizar búsquedas
                type="text"
                className="form-control"
                placeholder=""
                onFocus={handleFocus} // Evento al recibir el foco
                onBlur={handleBlur} // Evento al perder el foco
                value={searchTerm} // Valor del input es el término de búsqueda
                onChange={handleSearchChange} // Evento al cambiar el valor del input
                autoComplete="off"
            />
            <IconSVG
                name="Icon_borrador_talentic"
                width="20px"
                style={{ margin: '5px', cursor: 'pointer' }}
                onClick={clearInput}  // Llama a la función para limpiar el input
            />
        </div>
    );
};

export default SeacrhProyects;
