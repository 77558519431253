import React, { useEffect, useRef } from 'react'; // Importa React, useState para manejar el estado y useRef para referencias a elementos.
import IconSVG from '../contents/icons/icon'; // Componente compartido para iconos svg.

const ActionMenu = ({ actions, isActive, onClose }) => {
    // Referencia al elemento del menú para detectar clics fuera de él
    const menuRef = useRef(null);

    useEffect(() => {
        // Maneja los clics fuera del menú para cerrarlo
        const handleClickOutside = (event) => {
            // Si el clic no ocurrió dentro del menú, se ejecuta onClose
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };

        // Agregar un evento para detectar clics en el documento
        document.addEventListener('mousedown', handleClickOutside);

        // Limpiar el evento cuando el componente se desmonte
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]); // Dependencia: se asegura de actualizar si onClose cambia

    return (
        // Si isActive es true, se renderiza el menú
        isActive && (
            <ul
                className='menu_acciones'
                ref={menuRef} // Asigna la referencia al elemento ul
                onClick={(event) => event.stopPropagation()} // Evita la propagación del evento al contenedor padre
            >
                {actions.map((action, index) => (
                    <li
                        key={index} // Identificador único para cada acción
                        onClick={action.onClick} // Ejecuta la acción al hacer clic
                        className={action.className} // Clase personalizada para estilos
                    >
                        <IconSVG name={action.icon} /> {/* Icono de la acción */}
                        <span>{action.label}</span> {/* Etiqueta de la acción */}
                    </li>
                ))}
            </ul>
        )
    );
};

export default ActionMenu;
