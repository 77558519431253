// Reglas de validación para cada paso
export const validationRules = {
    step1: [
        { field: "name", message: "El nombre del proyecto es requerido", validate: (value) => !!value },
        { field: "type", message: "El tipo de proyecto es requerido", validate: (value) => !!value },
        { field: "deadline", message: "La fecha de finalización es requerida", validate: (value) => !!value },
        { field: "slug", message: "El texto Slug es requerido", validate: (value) => !!value },
        { field: "intro", message: "El texto introductorio es requerido", validate: (value) => !!value },
        { field: "description", message: "La descripción del proyecto es requerida", validate: (value) => !!value },
        { field: "portada", message: "La portada del proyecto es requerida", validate: (value) => !!value },
        { field: "icon", message: "El icono del proyecto es requerido", validate: (value) => !!value },
        { field: "fichas", message: "Debes seleccionar al menos una ficha", validate: (value) => Array.isArray(value) && value.length > 0,},
        { field: "palabrasClave", message: "Debes agregar al menos una palabra clave", validate: (value) => Array.isArray(value) && value.length > 0 },
    ],
    step2: [
        {
            field: "aprendices",
            message: "Debes seleccionar al menos un aprendiz",
            validate: (value) => Array.isArray(value) && value.length > 0,
        },
        {
            field: "instructores",
            message: "Debes seleccionar al menos un instructor",
            validate: (value) => Array.isArray(value) && value.length > 0,
        }
    ],
    step3: [
        { 
            field: "files", 
            message: "Debes subir al menos un archivo multimedia", 
            validate: (value) => Array.isArray(value) && value.length > 0 
        },
    ],
};