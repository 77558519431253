import React, { useState, useEffect } from 'react';
import axios from 'axios';
import IconSVG from '../../contents/icons/icon';
import { toast } from 'react-toastify';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'; // Importa el CSS para el componente LiteYouTubeEmbed
import LiteYouTubeEmbed from 'react-lite-youtube-embed'; // Importa el componente LiteYouTubeEmbed para incrustar videos de YouTube de manera ligera
import Viewer from '../../components/multimediaViewer';  // Importa el componente para visualizar multimedia
import MultimediaModal from '../../components/multimediaModal';  // Importa el modal para multimedia

const formatDate = (dateString) => {
    if (!dateString) return 'Fecha no disponible';
    try {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('es-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }).format(date);
    } catch (error) {
        return 'Fecha inválida';
    }
};

// Función para obtener el ID del video de YouTube desde su URL
const getIdFromUrl = (url) => {
    const regex = /(?:v=|\/v\/|embed\/|be\/)([a-zA-Z0-9_-]{11})/;  // Expresión regular para capturar el ID del video
    const match = url.match(regex);  // Intenta hacer coincidir la URL con la expresión regular
    return match && match[1];  // Si hay coincidencia, devuelve el ID del video
  };

const ShowCenters = ({ idDefault }) => {
    const [formData, setFormData] = useState({
        name: '',
        type: '',
        slug: '',
        intro: '',
        description: '',
        deadline: '',
        portada: null,
        icon: null,
        aprendices: [],
        instructores: [],
        fichas: '',
        files: [],
        palabrasClave: [],
        enlaces: [],
        state: '',
        center: '',
        programme: '',
    });
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal 1 (abierto o cerrado)
    const [modalContent, setModalContent] = useState(null); // Contenido del Modal 1

    const [step, setStep] = useState(1);

    useEffect(() => {
        if (idDefault) {
            fetchData();
        }
    }, [idDefault]);

    const fetchData = async () => {
        try {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "list",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "proyecto": idDefault
            };

            const [projectResponse, multimediaResponse, autoresResponse, keywordsResponse, responseType] = await Promise.all([
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": 'proyectsDetails' }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": 'proyectsDetailsMultimedia' }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": 'proyectsDetailsAutores' }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": 'proyectsKeywords' }),
                axios.post('http://217.15.168.117:8080/api/', { ...requestBody, "TYPE": "typeproject" }),
            ]);

            const projectData = projectResponse.data[0] || {};
            const multimediaData = multimediaResponse.data || [];
            const autoresData = autoresResponse.data || [];
            const keywordsData = keywordsResponse.data.map((item) => item.name) || [];
            const processedDataType = responseType.data.filter(item => item.id === projectResponse.data[0]?.type).map(item => item.name);
            // Obtiene las fichas del proyecto
            const fichaResponse = await axios.post('http://217.15.168.117:8080/api/', {
                API: "talentic",
                MODEL: "talentic",
                RESOURCE: "list",
                TYPE: "fichas",
                fichas: projectResponse.data[0].fichas, // Utiliza las fichas del proyecto actual
                key: "5b8d3b1f084b01c6a8387459e80d4bb9", // Clave API
            });
            
            const fichaData = fichaResponse.data[0] || {};

            setFormData((prevFormData) => ({
                ...prevFormData,
                name: projectData.name || '',
                type: processedDataType || '',
                slug: projectData.slug || '',
                intro: projectData.intro || '',
                description: projectData.description || '',
                deadline: projectData.deadline || '',
                portada: projectData.portada || '',
                icon: projectData.icon || '',
                state: projectData.state || '',
                files: multimediaData.map((item) => ({
                    id: item.id,
                    file: item.file,
                    type: item.type,
                    dateCreation: item.dateCreate,
                })),
                aprendices: autoresData.filter((item) => item.typeId === 'aprendiz'),
                instructores: autoresData.filter((item) => item.typeId === 'instructor'),
                palabrasClave: keywordsData,
                fichas: fichaData.name,
                center: fichaData.center,
                programme: fichaData.programme,
            }));
        } catch (error) {
            toast.error('Upps, no pudimos obtener los datos del proyecto');
        }
    };

    const handleMultimediaModalClick = (FormComponent, viewer, type, data) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent viewer={viewer} type={type} data={data}/>); // Abre el modal con el contenido adecuado
    };

    // Funciones para cerrar los modales
    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    return (
        <div className="modal_user_content">
            <div className='header_proyect_modal'>
                <figure className='header_proyect_modal_logo'>
                    <img src={`http://217.15.168.117:8080/api/Whatever/${formData.icon || ''}`} alt="Logo del proyecto"/>
                </figure>
                <section className='state_modal'>
                    <div> • Aprobado </div>
                    <p>{formData.type.name} | {formatDate(formData.deadline)}</p>
                </section>
                <div className='info_proyect_header'>
                    <section className='info_header'>
                        <h3>{formData.name}</h3>
                        <p>{formData.fichas} | {formData.programme} | {formData.center} </p>
                    </section>
                    <section className='autores_header'>
                        <p>Autores</p>
                        <div className='all_autores'>
                            <figure>
                                <img src={`http://217.15.168.117:8080/api/Whatever/${formData.icon || ''}`} alt="Logo del proyecto"/>
                            </figure>
                            <figure>
                                <img src={`http://217.15.168.117:8080/api/Whatever/${formData.icon || ''}`} alt="Logo del proyecto"/>
                            </figure>
                            <figure>
                                <img src={`http://217.15.168.117:8080/api/Whatever/${formData.icon || ''}`} alt="Logo del proyecto"/>
                            </figure>
                            <div>+3</div>
                        </div>
                    </section>
                </div>
            </div>
            <nav className='nav_proyect_info'>
                <ul>
                    <li className={`${step === 1 ? "nav_proyect_active" : ""}`} onClick={() => setStep(1)}>Información</li>
                    <li className={`${step === 2 ? "nav_proyect_active" : ""}`} onClick={() => setStep(2)}>Multimedia</li>
                    <li className={`${step === 3 ? "nav_proyect_active" : ""}`} onClick={() => setStep(3)}>Estadisticas</li>
                </ul>
            </nav>
            {step === 1 && (
                <section className='modal_info_proyect'>
                    <div className='modal_info_proyect_in'>
                        <div className='modal_info_user_item'>
                            <div>
                                <h5>Nombre del proyecto</h5> 
                                <p>{formData.name}</p>
                            </div>
                        </div>
                        <div className='modal_info_user_item'>
                            <div>
                                <h5>Tipo de proyecto</h5> 
                                <p>{formData.type}</p>
                            </div>
                        </div>
                        <div className='modal_info_user_item'>
                            <div>
                                <h5>Introducción</h5> 
                                <p>{formData.intro}</p>
                            </div>
                        </div>
                        <div className='modal_info_user_item'>
                            <div>
                                <h5>Descripción</h5> 
                                <p>{formData.description}</p>
                            </div>
                        </div>
                        <div className='modal_info_user_item'>
                            <div>
                                <h5>Slug</h5> 
                                <p>{formData.slug}</p>
                            </div>
                        </div>
                        <div className='modal_info_user_item'>
                            <div>
                                <h5>palabras Clave</h5> 
                                <ul className='modal_info_palabras'>
                                    {formData.palabrasClave.map((tag, index) => (
                                        <li 
                                            key={index}
                                            className='badge bg-label-dark'
                                        >
                                            {tag}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <section className='modal_info_others'>
                        
                        <article>
                            <p>Información de la ficha</p>
                            <div className='modal_info_user_item'>
                                <figure>
                                    <IconSVG name="Icon_usuario_talentic"/>
                                </figure>
                                <div>
                                    <h5>Estado</h5> 
                                    <p>{formData.state}</p>
                                </div>
                            </div>
                            <div className='modal_info_user_item'>
                                <figure>
                                    <IconSVG name="Icon_usuario_talentic"/>
                                </figure>
                                <div>
                                    <h5>Creado</h5> 
                                    <p>{formatDate(formData.dateCreation)}</p>
                                </div>
                            </div>
                            <div className='modal_info_user_item'>
                                <figure>
                                    <IconSVG name="Icon_usuario_talentic"/>
                                </figure>
                                <div>
                                    <h5>Finalización</h5> 
                                    <p>{formatDate(formData.deadline)}</p>
                                </div>
                            </div>
                        </article>

                        <article>
                            <p>Información de la ficha</p>
                            <div className='modal_info_user_item'>
                                <figure>
                                    <IconSVG name="Icon_fichas_talentic"/>
                                </figure>
                                <div>
                                    <h5>Ficha</h5> 
                                    <p>{formData.fichas}</p>
                                </div>
                            </div>
                            <div className='modal_info_user_item'>
                                <figure>
                                    <IconSVG name="Icon_programas_talentic"/>
                                </figure>
                                <div>
                                    <h5>Programa de formación</h5> 
                                    <p>{formData.programme}</p>
                                </div>
                            </div>
                            <div className='modal_info_user_item'>
                                <figure>
                                    <IconSVG name="Icon_centros_talentic"/>
                                </figure>
                                <div>
                                    <h5>Centro de formación</h5> 
                                    <p>{formData.center}</p>
                                </div>
                            </div>
                        </article>
                    </section>
                    
                    <div className='modal_info_autores'>
                        <div className="list_aprendices_autores">
                            {formData.aprendices.filter((item) => item.asignado).length > 0 ? (
                                formData.aprendices
                                    .filter((item) => item.asignado)
                                    .map((aprendiz) => (
                                        <>
                                            {aprendiz.name}
                                            {aprendiz.mail}
                                        </>
                                        
                                    ))
                            ) : (
                                <div id="no-results-dos">No hay aprendices seleccionados</div>
                            )}
                        </div>
                    </div>
                </section>
            )}
            {step === 2 && (
                <section className='modal_conten_multimedia'>
                    {formData.files.map((slide, index) => 
                        slide.type !== 'Video Youtube' ? (
                            <article
                                key={index}
                                onClick={(e) => handleMultimediaModalClick(Viewer, slide.file, slide.type, formData.files)}
                                className="card_multimedia_item"
                            >
                                <figure className="card_multimedia_item_img">
                                    <img
                                        src={`http://217.15.168.117:8080/api/Whatever/${slide.file || ''}`}
                                        alt="Portada del proyecto"
                                    />
                                </figure>
                                <figcaption>
                                    <h4>Titulo de la multimedia</h4>
                                    <p>{slide.type}</p>
                                </figcaption>
                            </article>
                        ) : (
                            <article key={index} className="card_multimedia_item">
                                <figure className="card_multimedia_item_img">
                                    <LiteYouTubeEmbed id={getIdFromUrl(slide.file)} />
                                </figure>
                                <figcaption>
                                    <h4>Titulo de la multimedia</h4>
                                    <p>{slide.type}</p>
                                </figcaption>
                            </article>
                        )
                    )}
                    <MultimediaModal isOpen={isModalOpen} onClose={closeModal} widthValue='350px'>
                        {modalContent}
                    </MultimediaModal>
                </section>
            )}
        </div>
    );
};

export default ShowCenters;
