import React from 'react'; 
import ActionMenu from './actionMenu'; // Importa el componente ActionMenu para mostrar opciones de acción
import IconSVG from '../contents/icons/icon'; // Importa el componente IconSVG para mostrar íconos SVG

// Componente funcional ListView que renderiza una fila en una tabla con diferentes elementos
const ListView = ({ 
    key,          // Propiedad clave para la fila
    img,          // Propiedad para la imagen a mostrar
    svg,          // Propiedad para el ícono SVG a mostrar si no hay imagen
    titulo,       // Título a mostrar en la celda correspondiente
    subtitulo,    // Subtítulo a mostrar en la celda correspondiente
    parrafo,      // Párrafo de texto que describe el ítem
    actionMenuProps, // Props para el menú de acciones, utilizado en el componente ActionMenu
    toggleMenuActions, // Función para manejar el toggle del menú de acciones
    ref,          // Referencia para el componente
    item          // Propiedad que representa un ítem completo con sus datos
}) => (
    // Renderiza una fila de tabla <tr> con sus respectivas celdas <td>
    <tr key={key}>
        {/* Columna de imagen o ícono */}
        <td className='img_list_dashboard'>
            <div className='img_list_dashboard_content'>
                <figure>
                    {/* Si no se pasa una imagen, se muestra un ícono SVG */}
                    {img === undefined ? (
                        <IconSVG name={svg} /> 
                    ) : (
                        // Si hay imagen, se carga desde la URL proporcionada
                        <img src={`http://217.15.168.117:8080/api/Whatever/${img}`} alt={item.id} />
                    )}
                </figure>
                {/* Muestra el título de la fila */}
                {titulo}
            </div>
        </td>

        {/* Muestra el subtítulo de la fila */}
        <td>{subtitulo}</td>

        {/* Muestra el párrafo de la fila */}
        <td>{parrafo}</td>

        {/* Columna para acciones (íconos) */}
        <td>
            <div className='icon_list_action_content'>
                {/* Icono de menú de acciones */}
                <div className='icon_list_action' onClick={() => toggleMenuActions(item.id)}>
                    <IconSVG name="Icon_menu_puntos_talentic"/>
                </div>
                {/* Muestra el menú de acciones utilizando los props pasados */}
                <ActionMenu {...actionMenuProps(item)} />
            </div>
        </td>
    </tr>
);

export default ListView;
