import React from 'react'; // Importa React desde la librería React.
import IconSVG from '../contents/icons/icon'; // Importa el componente IconSVG desde la ruta especificada.

function SeacrhInput({ 
    type = "text", // Prop predeterminada 'text' para el tipo de input.
    name = "name", // Prop predeterminada 'name' para el nombre del input.
    value, // Prop para el valor del input.
    placeholder = "nombre", // Prop predeterminada 'nombre' para el texto de ayuda del input.
    label = "Nombre", // Prop predeterminada 'Nombre' para la etiqueta del input.
    colClassName = "", // Prop predeterminada para las clases de la columna.
    onChange // Prop para el manejador del evento de cambio de valor en el input.
}) {
    return (
        <div className={`${colClassName}`}> {/* Usa las clases de columna proporcionadas */}
            <div className="section_dashboard_buscador"> {/* Contenedor para el buscador */}
                <IconSVG name="Icon_busqueda_talentic"/> {/* Componente IconSVG para mostrar el ícono de búsqueda */}
                <input
                    type={type} // Tipo del input (text por defecto)
                    className="" // Clase vacía (puedes añadir estilos si lo deseas)
                    name={name} // Asigna el nombre al input
                    value={value} // El valor actual del input
                    placeholder={placeholder} // El texto que aparece en el input cuando está vacío
                    onChange={onChange} // Manejador del evento onChange para actualizar el valor
                />
            </div>
        </div>
    );
}

export default SeacrhInput; // Exporta el componente SeacrhInput para poder usarlo en otros archivos.
