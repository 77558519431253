import React, { useState, useEffect } from 'react';  // Importa React y los hooks useState y useEffect
import { Link } from 'react-router-dom';  // Importa el componente Link de react-router-dom para la navegación
import axios from 'axios';  // Importa axios para hacer solicitudes HTTP

const Navigate = () => {  // Define el componente Navigate
    const [componentesCargados, setComponentesCargados] = useState([]);  // Estado para almacenar los componentes cargados

    useEffect(() => {  // Hook useEffect que se ejecuta cuando el componente se monta
        const requestBody = {  // Cuerpo de la solicitud para obtener datos
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "list",
            "TYPE": "allocationNavmenu",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",  // Clave API para la autenticación
        };

        // Realiza una solicitud POST con axios
        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {  // Cuando se recibe la respuesta
                if (Array.isArray(response.data)) {  // Verifica que los datos sean un array
                    const cleanedData = response.data.map(item => ({  // Limpia y organiza los datos
                        id: parseInt(item.id, 10),  // Convierte el id a número
                        name: item.name,  // Obtiene el nombre
                        code: item.code,  // Obtiene el código
                        img: item.img,  // Obtiene la imagen
                        position: item.position  // Obtiene la posición
                    }));
                    setComponentesCargados(cleanedData);  // Actualiza el estado con los datos limpiados
                } else {
                    console.error('El formato de los datos no es un array');  // Muestra un error si los datos no son un array
                }
            })
            .catch(error => {  // Maneja el error en caso de que la solicitud falle
                console.error('Error al cargar componentes:', error);  // Muestra el error
            });
    }, []);  // El array vacío asegura que el efecto solo se ejecute una vez al montar el componente

    return (
        <ul className={`menu__items_all`}>  {/* Crea una lista no ordenada para los elementos del menú*/}
            {componentesCargados.map((item) => {  // Itera sobre los componentes cargados
                return (
                    <Link 
                        to={`/${item.code}`}  // Enlaza a la ruta correspondiente al código del componente
                        key={item.id}  // Usa el id como key en el mapeo
                    >
                        <li className="menu__item c_gif px-3">  {/* Define un ítem de la lista con clases de estilo*/}
                            {item.name}  {/* Muestra el nombre del componente*/}
                            <img 
                                src={require(`../../img/nav/${item.img}`)}  // Carga la imagen desde el path
                                className="gif"  // Aplica la clase para el estilo de la imagen
                                style={{ backgroundColor: 'rgb(255, 255, 255)' }}  // Estilo en línea para el fondo de la imagen
                                alt={`Imagen de ${item.name}`}  // Texto alternativo con el nombre del componente
                            />
                        </li>
                    </Link>
                );
            })}
        </ul>
    );
};

export default Navigate;  // Exporta el componente Navigate
