import React, { useState, useEffect } from 'react';
import axios from 'axios';

import BtnSubmitDashboard from '../../components/btnSubmitDashboard';
import InputDashboard from '../../components/inputDashboard';

const UpdateCenters = ({ onUPSubmitState, idDefault, nameDefault, codDefault }) => {
    const [formData, setFormData] = useState({
        id: idDefault,
        name: nameDefault,
        cod: codDefault,
    });
    const [error, setError] = useState('');

    useEffect(() => {
        setFormData({
            id: idDefault,
            name: nameDefault,
            cod: codDefault,
        });
    }, [idDefault, nameDefault, codDefault]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const { id, name, cod } = formData;

        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "centers",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "UPDATE",
            "id": id,
            "name": name,
            "cod": cod
        };

        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody);
            onUPSubmitState(true);
        } catch (error) {
            console.error('Error updating data:', error);
            setError('Hubo un error al actualizar el tipo de usuario. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className="modal_user_content">
            <form className='modal_form' method="POST" onSubmit={handleUpdate}>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="name" 
                        label="Nombre"
                        placeholder="nombre" 
                        value={formData.name}
                        onChange={handleChange}
                        colClassName=""
                    />
                    
                </div>
                <div className='modal_form_item'>
                    <InputDashboard 
                        name="cod" 
                        label="Abreviación"
                        placeholder="Siglas o Abreviación" 
                        value={formData.cod}
                        onChange={handleChange}
                        colClassName=""
                    />
                </div>
                
                <BtnSubmitDashboard text="Guardar"/>
            </form>
        </div>
    );
};

export default UpdateCenters;
