import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import BtnSubmit from '../components/btnSubmit';
import Input from '../components/input';

function Suscribirse({ PushSubscriptionOptions }) {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
      e.preventDefault();

      const requestBody = {
          "API": "talentic",
          "MODEL": "talentic",
          "RESOURCE": "subscriptions",
          "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
          "TYPE": "PUT",
          "email": email
      };

      try {
          const response = await axios.post('http://217.15.168.117:8080/api/', requestBody, {
            headers: {
            'Content-Type': 'application/json'
            }
          });
          toast.info('Enviamos un correo a tu bandeja de entrada, sigue los pasos y forma parte de nosotros');
          PushSubscriptionOptions(true)
      } catch (error) {
        console.error('Error occurred:', error);
        if (error.response) {
        console.error('Request failed:', error.response.data.message);
        } else if (error.request) {
        console.error('No response received:', error.request);
        } else {
        console.error('Error setting up request:', error.message);
        }
      }
    };

  return (
    <>
      {/* Logo */}
      <div className="app-brand justify-content-center">
        <a href="https://www.talentic.com.co" className="app-brand-link gap-2">
          <span className="app-brand-logo">
            <img
              src="https://www.talentic.com.co/assets/img/logo.gif"
              width="200"
              alt="Talentic Logo"
            />
          </span>
        </a>
      </div>
      {/* /Logo */}
      <h4 className="mb-2 ps-3">Suscribete a Talentic!</h4>
      <p className="mb-4 ps-3">Te enviaremos un correo con tus datos de cuenta</p>

      <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit}>
        <Input 
            name="email" 
            label="Correo electrónico"
            placeholder="Ingresa tu correo electrónico" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)}
        />
        <BtnSubmit />
      </form>
    </>
  );
}

export default Suscribirse;
