export const validateFields = ({ formData, rules, setErrors, toast, refs }) => {
    const newErrors = {};
    let firstErrorField = null;

    for (const { field, message, validate } of rules) {
        const value = formData[field];

        if (!validate(value)) {
            newErrors[field] = message; // Guardar error
            toast?.warning?.(message); // Mostrar mensaje de error

            // Capturar el primer campo con error para enfocarlo
            if (!firstErrorField && refs?.[field]?.current) {
                firstErrorField = refs[field];
            }
        }
    }

    // Enfocar el primer campo con error (si existe)
    firstErrorField?.current?.focus?.();

    setErrors?.(newErrors); // Actualizar errores en el estado
    return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
};