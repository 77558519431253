import React, { useState, useEffect } from 'react';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true, // Activar formato de 12 horas
    }).format(date);
};

const ShowCenters = ({ nameDefault, codDefault, dateCreationDefault }) => {
    const [formData, setFormData] = useState({
        name: nameDefault,
        cod: codDefault,
        dateCreation: formatDate(dateCreationDefault),
    });

    useEffect(() => {
        setFormData({
            name: nameDefault,
            cod: codDefault,
            dateCreation: formatDate(dateCreationDefault), // Formatea la fecha aquí
        });
    }, [nameDefault, codDefault, dateCreationDefault]);

    return (
        <div className="row">
            <div className="col-xl">
                <div className="card">
                    <div className="card-body">
                        <p className='p-1 m-0'>Nombre: {formData.name}</p>
                        <p className='p-1 m-0'>Código: {formData.cod}</p>
                        <p className='p-1 m-0'>Creado {formData.dateCreation}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowCenters;
