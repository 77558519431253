import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, Link } from 'react-router-dom'; 
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';

const AssignUsers = ( ) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [apiData, setApiData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "list",
            "TYPE": "assignusers",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                const cleanedData = response.data.map(item => ({
                    id: item.id,
                    username: item.username,
                    type: item.type
                }));
                setApiData(cleanedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = apiData.filter(item =>
        (item.name || '').toLowerCase().includes(searchTerm) ||
        (item.type || '').toLowerCase().includes(searchTerm)
    );

    return (
        <>
            <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">

                    <h4 className="fw-bold py-3 mb-4">Modulo de administración de usuarios, roles y permisos especiales</h4>

                    <div className="card">
                        <h5 className="card-header d-flex align-items-baseline justify-content-between">Registros encontrados</h5>
                        
                        <div className="card-body">
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    id="search"
                                    className="form-control"
                                    placeholder="Buscar"
                                    aria-label="Buscar"
                                    aria-describedby="button-addon2"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            <div className="table-responsive text-nowrap">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Nombre</th>
                                            <th>Tipo Usuario</th>
                                            <th>Roles | Permisos Especiales</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.length > 0 ? filteredData.map((item) => (
                                            <tr key={item.id} className="buscarUser" data-user={`${item.name} ${item.username}`}>
                                                <td>{item.id}</td>
                                                <td>{item.username}</td>
                                                <td>{item.type}</td>
                                                <td>
                                                    <Link 
                                                        to="/dashboard/assignuser"
                                                        state={{
                                                            id: item.id,
                                                            username: item.username,
                                                            type: item.type,
                                                        }}
                                                    >
                                                        <button className="dropdown-item btn-outline-primary">
                                                            <i className="bx bx-cog me-1"></i> Administrar
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="6" className="text-center">No se encontraron registros</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AssignUsers;
