import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import ShowProgramme from './show';
import CreateProgramme from './create';
import UpdateProgramme from './update';

import ViewToggle from '../../components/viewToggle';
import SortFilter from '../../components/sortFilter';

import ListView from '../../components/listView';
import CardView from '../../components/cardView';

import Modal from '../../components/modalDashboard';
import SeacrhData from '../../components/searchData';
import IconSVG from '../../contents/icons/icon';

const Programme = ( ) => {
    const [apiData, setApiData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    
    const hashToVer = "e04d144294a8d6c0f41ead965f31eec6";
    const hashToCrear = "1ba468bef97f507ad736866ef27c7a4a";
    const hashToEditar = "f3e170c06f36a9c082fdf619dff1c67c";
    const hashToEliminar = "cf815a80c9a7fcc7f1aac8aaad58a887";

    
    const [roleFilter, setRoleFilter] = useState('Todos'); // Estado para el filtro de rol
    const [activeMenuActions, setActiveMenuActions] = useState(null);
    const menuRef = useRef(null);
    const [viewType, setViewType] = useState('cards'); // 'cards' para tarjetas y 'list' para lista
    const [sortConfig, setSortConfig] = useState({ key: 'dateCreation', direction: 'desc' });


    // Alterna entre los tipos de visualización
    const showCards = () => setViewType('cards');
    const showList = () => setViewType('list');

    useEffect(() => {
        // Detectar clics fuera del menú
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveMenuActions(null);
            }
        };

        // Añadir evento al hacer clic en el documento
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Limpiar el evento al desmontar el componente
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSort = (value) => {
        let key, direction;
    
        if (value === 'fechaDesc') {
            key = 'dateCreation';
            direction = 'desc';
        } else if (value === 'fechaAsc') {
            key = 'dateCreation';
            direction = 'asc';
        } else if (value === 'nombreAsc') {
            key = 'username';
            direction = 'asc';
        } else if (value === 'nombreDesc') {
            key = 'username';
            direction = 'desc';
        } else if (value === 'emailAsc') {
            key = 'email';
            direction = 'asc';
        } else if (value === 'emailDesc') {
            key = 'email';
            direction = 'desc';
        } else if (value === 'tipoAsc') {
            key = 'type';
            direction = 'asc';
        } else if (value === 'tipoDesc') {
            key = 'type';
            direction = 'desc';
        }
    
        setSortConfig({ key, direction });
    };

    const toggleMenuActions = (menuName) => {
        setActiveMenuActions(activeMenuActions === menuName ? null : menuName);
    };

    const [formDatauser] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        return {
            permisos: user?.permissions || ''
        };
    });

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = () => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "list",
            "TYPE": "programme",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
        };

        axios.post('http://217.15.168.117:8080/api/', requestBody)
            .then(response => {
                const cleanedData = response.data.map(item => ({
                    id: item.id,
                    name: item.name,
                    icon: item.icon,
                    slung: item.slung,
                    dateCreation: item.dateCreate,
                    description: item.description
                }));
                setApiData(cleanedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };
    
    const handleChangecrear = (newState) => {
        toast.success("Se gestionaron los datos con éxito");
        setIsModalOpen(false);
        setModalContent(null);
        fetchData();
    };

    const handleChangeeditar = (newState) => {
        toast.success("Se editaron los datos con éxito");
        setIsModalOpen(false);
        fetchData();
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = apiData.filter(item =>
        (item.name || '').toLowerCase().includes(searchTerm) ||
        (item.slung || '').toLowerCase().includes(searchTerm) ||
        (item.description || '').toLowerCase().includes(searchTerm) 
    );

    const handleDelete = (itemId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este registro?')) {
            const requestBody = {
                "API": "talentic",
                "MODEL": "talentic",
                "RESOURCE": "programme",
                "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
                "TYPE": "DELETE",
                "id": itemId
            };

            axios.post('http://217.15.168.117:8080/api/', requestBody)
                .then(response => {
                    toast.success("Se eliminaron los datos con éxito");
                    fetchData();
                })
                .catch(error => {
                    console.error('Error deleting data:', error);
                });
        }
    };

    const handleCreateClick = (FormComponent) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent onSubmitState={handleChangecrear}/>);
    };

    const handleEditClick = (FormComponent, id, name, icon, slung, description) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent 
            onUPSubmitState={handleChangeeditar}
            idDefault={id}
            nameDefault={name}
            iconDefault={icon}
            slungDefault={slung}
            descriptionDefault={description}
        />);
    };
    
    const handleShowClick = (FormComponent, name, icon, slung, description, dateCreation) => {
        setIsModalOpen(true);
        setModalContent(<FormComponent
            nameDefault={name}
            iconDefault={icon}
            slungDefault={slung}
            descriptionDefault={description}
            dateCreationDefault={dateCreation}
        />);
    };

    // Función que define el menú de acciones específico para cada usuario
    const actionMenuProps = (item) => ({
        isActive: activeMenuActions === item.id,
        onClose: () => setActiveMenuActions(null),
        actions: [
            formDatauser.permisos.includes(hashToVer) && {
                label: 'Ver',
                icon: 'Icon_ver_talentic_2',
                onClick: () => handleShowClick(ShowProgramme, item.name, item.icon, item.slung, item.description, item.dateCreation),
            },
            formDatauser.permisos.includes(hashToEditar) && {
                label: 'Editar',
                icon: 'Icon_editar_talentic_2',
                onClick: () => handleEditClick(UpdateProgramme, item.id, item.name, item.icon, item.slung, item.description),
                className: 'menu_acciones_editar'
            },
            formDatauser.permisos.includes(hashToEliminar) && {
                label: 'Eliminar',
                icon: 'Icon_eliminar_talentic_2',
                onClick: () => handleDelete(item.id, item.state), // Llamar a la función de eliminación,
                className: 'menu_acciones_eliminar'
            }
        ].filter(Boolean)
    });

    return (
        <div className='dashboard_content'>
            <main className='main__dashboard_content'>
                <div className='form_content'>
                    <section className='dashboard_titulo'>
                        <h2>Programas de Formación</h2>
                    </section>
                    <div className='main__dashboard_primera_parte'>
                        <div className='btn_nuevo_dashboard' onClick={() => handleCreateClick(CreateProgramme)}>
                            <IconSVG name="Icon_mas_talentic"/> Crear Nuevo
                        </div>
                    </div>
                    <div className='form_content_dashboard'>
                        <div className='filters_dashboard'>
                            <div className='buscador_dashboard'>
                                <SeacrhData
                                    searchTerm={searchTerm}
                                    handleSearchChange={handleSearchChange}
                                />
                            </div>
                            <div className='filtros_content_dashboard'>
                                <SortFilter sortConfig={sortConfig} onSortChange={handleSort}/>
                                <ViewToggle viewType={viewType} showCards={showCards} showList={showList} />
                            </div>
                        </div>
                        {/* Vista en formato de tarjetas */}
                        {viewType === 'cards' && (
                            <div className='layout_cards'>
                                {filteredData.length > 0 ? (
                                filteredData.map((item) => (
                                    <CardView
                                        key={item.id}
                                        titulo={item.name}
                                        subtitulo={item.slung}
                                        parrafo={item.dateCreation}
                                        actionMenuProps={actionMenuProps}
                                        item={item}
                                        toggleMenuActions={toggleMenuActions}
                                        ref={menuRef}
                                        svg={item.icon}
                                    />
                                ))
                            ) : (
                                <p>No se encontraron registros</p>
                            )}
                            </div>
                        )}

                        {/* Vista en formato de lista */}
                        {viewType === 'list' && (
                            <div className='layout_list_dashboard'>
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('nombreAsc')}><h4>Programa<IconSVG name="Icon_flechas_talentic"/></h4></th>
                                        <th><h4>Siglas o Abreviación</h4></th>
                                        <th><h4>Fecha</h4></th>
                                        <th><h4>Acciones</h4></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length > 0 ? filteredData.map((item) => (
                                        <ListView
                                            key={item.id}
                                            titulo={item.name}
                                            subtitulo={item.slung}
                                            parrafo={item.dateCreation}
                                            actionMenuProps={actionMenuProps}
                                            item={item}
                                            toggleMenuActions={toggleMenuActions}
                                            ref={menuRef}
                                            svg={item.icon}
                                        />
                                    )) : (
                                        <tr>
                                            <td colSpan="3">No se encontraron registros</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                            
                        )}
                    </div>
                </div>
            </main>
            <Modal isOpen={isModalOpen} onClose={closeModal} titulo={"Programa"}>
                {modalContent}
            </Modal>
        </div>
    );
};

export default Programme;
