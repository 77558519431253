import React from 'react';
import ActionMenu from './actionMenu';  // Importa el componente ActionMenu para manejar las acciones del menú
import IconSVG from '../contents/icons/icon';  // Importa el componente IconSVG para mostrar iconos en formato SVG

const CardView = ({ titulo, img, svg, subtitulo, parrafo, actionMenuProps, item, toggleMenuActions }) => (
    <article className='card_dashboard_uno'>  {/* Contenedor principal de la tarjeta */}
        <figure>
        {img === undefined ? (  // Si no se proporciona una imagen, se muestra un icono SVG
            <IconSVG name={svg} />  // Muestra el ícono basado en el nombre pasado como prop
        ) : (
            <img src={`http://217.15.168.117:8080/api/Whatever/${img}`} alt={item.id} />  // Si se proporciona una imagen, se muestra
        )}
        </figure>
        <div className='card_text_dashboard'>  {/* Contenedor de texto de la tarjeta */}
            <h4>{titulo}</h4>  {/* Muestra el título de la tarjeta */}
            <p>{subtitulo}</p>  {/* Muestra el subtítulo de la tarjeta */}
            <p>{parrafo}</p>  {/* Muestra el párrafo de la tarjeta */}
        </div>
        <div className='icon_card_action_content'>  {/* Contenedor de las acciones del ícono */}
            <div className='icon_card_action' onClick={() => toggleMenuActions(item.id)}>  {/* Acción al hacer clic para activar el menú */}
                <IconSVG name="Icon_menu_puntos_talentic"/>  {/* Icono de menú de puntos (tres puntos) */}
            </div>
        </div>
        <ActionMenu {...actionMenuProps(item)} />  {/* Muestra el menú de acciones con las props pasadas */}
    </article>
);

export default CardView;
