import React, { useState, useEffect } from 'react';
import axios from 'axios';

import BtnSubmit from '../../components/btnSubmit';
import Input from '../../components/input';
import Textarea from '../../components/textarea';

const UpdateTypeUsers = ({ onUPSubmitState, idDefault, nameDefault, descriptionDefault }) => {
    const [formData, setFormData] = useState({
        id: idDefault,
        name: nameDefault,
        description: descriptionDefault,
    });
    const [error, setError] = useState('');

    useEffect(() => {
        setFormData({
            id: idDefault,
            name: nameDefault,
            description: descriptionDefault,
        });
    }, [idDefault, nameDefault, descriptionDefault]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const { id, name, description } = formData;

        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "typeusers",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "UPDATE",
            "id": id,
            "name": name,
            "description": description
        };

        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody);
            onUPSubmitState(true);
        } catch (error) {
            console.error('Error updating data:', error);
            setError('Hubo un error al actualizar el tipo de usuario. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className="row">
            <div className="col-xl">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Editando Tipos de Usuario</h5>
                        <small className="text-muted float-end">Formulario de edición</small>
                    </div>
                    <div className="card-body">
                        <form className="mb-3 row" method="POST" onSubmit={handleUpdate}>
                            <Input 
                                name="name" 
                                label="Nombre"
                                placeholder="nombre" 
                                value={formData.name}
                                onChange={handleChange}
                                colClassName=""
                            />
                            <Textarea 
                                name="description" 
                                label="Descripción"
                                placeholder="descripción" 
                                value={formData.description}
                                onChange={handleChange}
                                colClassName=""
                            />
                            <BtnSubmit />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateTypeUsers;
