import React, { useState, useRef } from 'react'; // Importa React, useState para manejar el estado y useRef para referencias a elementos.
import { Link } from 'react-router-dom'; // Permite crear enlaces de navegación interna.
import { toast } from 'react-toastify'; // Biblioteca para mostrar notificaciones tipo toast.
import axios from 'axios'; // Cliente HTTP para realizar solicitudes al backend.

import { useAuth } from './AuthContext'; // Hook personalizado para manejar la autenticación.
import Modal from './modal'; // Componente modal para mostrar contenido emergente.

import SharedSocial from '../forms/sharedSocial'; // Componente compartido para redes sociales.

import IconSVG from '../contents/icons/icon'; // Componente para renderizar íconos SVG.

const formatDate = (dateString) => {
    // Formateador de fechas: convierte una cadena de fecha a un formato amigable.
    const date = new Date(dateString);
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
};

const Card = ({ imgSrc, name, hoverText, link, date, favorites, asignado, guardado }) => {
    // Referencia para manejar temporizadores.
    const timeoutRef = useRef(null);

    // Hook personalizado para verificar si el usuario está autenticado.
    const { isAuthenticated } = useAuth();

    // Estados locales para manejar diversos comportamientos de la tarjeta.
    const [hovered, setHovered] = useState(false); // Controla si la tarjeta está siendo "hovered".
    const [showIcons, setShowIcons] = useState(false); // Controla la visibilidad de los íconos.
    const [fadeClass, setFadeClass] = useState('fade-in'); // Maneja clases de transición.
    const [isModalOpen, setIsModalOpen] = useState(false); // Controla la visibilidad del modal.
    const [modalContent, setModalContent] = useState(null); // Contenido del modal.

    const [favoritesValue, setfavoritesValue] = useState(favorites); // Valor de los favoritos.
    const [asiganadoValue, setasiganadoValue] = useState(asignado); // Valor de los favoritos.
    const [guardadoValue, setguardadoValue] = useState(guardado); // Valor de guardado.

    // Estado inicial de datos del formulario, cargados desde el almacenamiento local.
    const [formData] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user')); // Obtiene el usuario del almacenamiento local.
        return {
            user: user?.index_user || '' // Recupera `index_user` o una cadena vacía.
        };
    });

    const handle = (newState) => {
        // Cierra el modal.
        setIsModalOpen(false);
    };

    const handleMouseEnter = () => {
        // Configura un temporizador para activar el estado de "hovered" después de 2 segundos.
        timeoutRef.current = setTimeout(() => {
            setHovered(true);
            setShowIcons(true);
        }, 2000);
    };

    const handleMouseLeave = () => {
        // Limpia el temporizador y resetea los estados al salir del hover.
        clearTimeout(timeoutRef.current);
        setHovered(false);
        setShowIcons(false);
    };

    const openModalWithForm = (FormComponent) => {
        // Abre un modal con un formulario específico.
        setModalContent(<FormComponent PushSubscriptionOptions={handle} />);
        setIsModalOpen(true);
    };

    const openModalWithFormMenssaje = (FormComponent, link) => {
        // Abre un modal con un formulario que incluye datos de proyecto y usuario.
        setModalContent(
            <FormComponent
                project={link}
                userSend={formData.user}
                onSubmit={closeModalSuccess}
            />
        );
        setIsModalOpen(true);
    };

    const openModalWithFormSharedSocial = (FormComponent, link) => {
        // Abre un modal con opciones para compartir en redes sociales.
        setModalContent(<FormComponent project={link} />);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        // Cierra el modal y limpia el contenido.
        setIsModalOpen(false);
        setModalContent(null);
    };

    const closeModalSuccess = () => {
        // Muestra un mensaje de éxito y cierra el modal.
        toast.success("Se envió con éxito");
        setIsModalOpen(false);
        setModalContent(null);
    };

    const addFavorites = async (e, link, name) => {
        // Añade un proyecto a los favoritos mediante una solicitud al backend.
        const requestBody = {
            API: "talentic",
            MODEL: "talentic",
            RESOURCE: "favorites",
            key: "5b8d3b1f084b01c6a8387459e80d4bb9",
            TYPE: "PUT",
            proyect: link,
            user: formData.user
        };

        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody, {
                headers: { 'Content-Type': 'application/json' }
            });
            setfavoritesValue(favoritesValue + 1); // Actualiza el estado a no asignado
            setasiganadoValue(1); // Actualiza el estado a no asignado
        } catch (error) {
            handleError(error);
        }
    };
    // Función para eliminar un proyecto de favoritos
    const removeFavorites = async (e, link, name) => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "favorites",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "DELETE_FORCE",
            "Z": "favorites",
            "proyect": link,
            "user": formData.user
        };
    
        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setfavoritesValue(favoritesValue - 1); // Actualiza el estado a no asignado
            setasiganadoValue(0); // Actualiza el estado a no asignado
        } catch (error) {
            // Manejo de errores con mensajes de toast
            toast.error(error);
            if (error.response) {
                toast.error('Request failed:', error.response.data.message);
            } else if (error.request) {
                toast.error('No response received:', error.request);
            } else {
                toast.error('Error setting up request:', error.message);
            }
        }
    };

    const addsaved = async (e, link, name) => {
        // Guarda un proyecto mediante una solicitud al backend.
        const requestBody = {
            API: "talentic",
            MODEL: "talentic",
            RESOURCE: "saved",
            key: "5b8d3b1f084b01c6a8387459e80d4bb9",
            TYPE: "PUT",
            proyect: link,
            user: formData.user
        };

        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody, {
                headers: { 'Content-Type': 'application/json' }
            });
            setguardadoValue(1); // Actualiza el guardado a no asignado
        } catch (error) {
            handleError(error);
        }
    };
    // Función para eliminar un proyecto de la lista de guardados
    const removeSaved = async (e, link, name) => {
        const requestBody = {
            "API": "talentic",
            "MODEL": "talentic",
            "RESOURCE": "saved",
            "key": "5b8d3b1f084b01c6a8387459e80d4bb9",
            "TYPE": "DELETE_FORCE",
            "Z": "saved",
            "proyect": link,
            "user": formData.user
        };
    
        try {
            await axios.post('http://217.15.168.117:8080/api/', requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setguardadoValue(0); // Actualiza el guardado a no asignado
        } catch (error) {
            // Manejo de errores con mensajes de toast
            toast.error(error);
            if (error.response) {
                toast.error('Request failed:', error.response.data.message);
            } else if (error.request) {
                toast.error('No response received:', error.request);
            } else {
                toast.error('Error setting up request:', error.message);
            }
        }
    }; 

    const handleError = (error) => {
        // Maneja errores de solicitud HTTP.
        toast.error(error);
        if (error.response) {
            toast.error('Request failed:', error.response.data.message);
        } else if (error.request) {
            toast.error('No response received:', error.request);
        } else {
            toast.error('Error setting up request:', error.message);
        }
    };

    return (
        <>
            {/* Contenedor principal de la tarjeta */}
            <div className='d-flex flex-column align-content-flex-start minwidth'>
                {/* Tarjeta del proyecto */}
                <div
                    className={`profile-card-2 my-2 mx-4 ${hovered ? `on ${fadeClass}` : ''}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Link to={`/detailsProyects/${link}`}>
                        <img src={imgSrc} alt="Profile" />
                    </Link>

                    {/* Información mostrada al hacer hover */}
                    {hovered && (
                        <div className={`profile-hover-text ${hovered ? { fadeClass } : 'fade-out'}`}>
                            <h4 className='mb-2 mx-1 text-white'>{name}</h4>
                            <p className='mb-2 mx-1 PCardProyects'>{hoverText}</p>
                            <div className='mb-2 d-flex justify-content-between'>
                                <div>
                                    <IconSVG
                                        name={`${asiganadoValue === 1 ? 'Icon_corazon_talentic' : 'Icon_corazon_talentic_bl'}`}
                                        width="25px"
                                        style={{ cursor: 'pointer', marginRight: '5px' }}
                                        onClick={(e) => asiganadoValue === 1 ? 
                                            removeFavorites(e, link, name) : 
                                            addFavorites(e, link, name)
                                        }
                                    />
                                    {favoritesValue === "" ? 0 : favoritesValue}
                                </div>
                                <div>
                                    <small>{formatDate(date)}</small>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Iconos interactivos */}
                    <div className={`profile-icons ${fadeClass}`}>
                        {hovered && showIcons && (
                            isAuthenticated ? (
                                <>
                                    <div>
                                        <IconSVG
                                            name="Icon_compartir_2_talentic_bl"
                                            width="17px"
                                            style={{ cursor: 'pointer', marginRight: '5px' }}
                                            onClick={() => openModalWithFormSharedSocial(SharedSocial, link)}
                                        />
                                    </div>

                                    <div>
                                        <IconSVG
                                            name={`${guardadoValue === 1 ? 'Icon_favorito_talentic' : 'Icon_favorito_talentic_bl'}`}
                                            width="15px"
                                            style={{ cursor: 'pointer', marginRight: '5px' }}
                                            onClick={(e) => guardadoValue === 1 ? 
                                                removeSaved(e, link, name) : 
                                                addsaved(e, link, name)
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <IconSVG
                                            name="Icon_compartir_2_talentic_bl"
                                            width="17px"
                                            style={{ cursor: 'pointer', marginRight: '5px' }}
                                            onClick={() => openModalWithFormSharedSocial(SharedSocial, link)}
                                        />
                                    </div>
                                </>
                            )
                        )}
                    </div>
                </div>
                {/* Nombre del proyecto (fuera de hover) */}
                {!hovered && (
                    <div className="profile-name text-primary mx-4">{name}</div>
                )}
            </div>

            {/* Modal para mostrar formularios o contenido adicional */}
            <Modal isOpen={isModalOpen} onClose={closeModal} widthValue='400px'>
                {modalContent}
            </Modal>
        </>
    );
};

export default Card;
